import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import cx from 'classnames'

import styles from './Tooltip.module.css'

const Tooltip = ({ delayDuration, children }) => {
  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root className={styles.root}>{children}</TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export const TooltipTrigger = TooltipPrimitive.Trigger

export const TooltipContent = ({ children, className, ...rest }) => {
  return (
    <TooltipPrimitive.Portal className={styles.portal}>
      <TooltipPrimitive.Content className={cx(styles.contentContainer, className)} sideOffset={5} {...rest}>
        {children}
        <TooltipPrimitive.Arrow className={styles.arrow} />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  )
}
export default Tooltip
