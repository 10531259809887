import cx from 'classnames'

import styles from './Badge.module.scss'

import Check from 'Icons/check.svg'

const Badge = ({ text, isSelected, onClick, variant }) => {
  const getVariantClass = () => {
    switch (variant) {
      case 'default':
        return styles.Default
      case 'muted':
        return styles.Gray
    }
  }
  const className = cx(styles.Badge, getVariantClass(), { [styles.Clickable]: !!onClick })

  return (
    <div onClick={onClick} className={className}>
      {text}
      {isSelected && <Check width='12' height='12' />}
    </div>
  )
}
export default Badge
