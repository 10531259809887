import { useEffect } from 'react'
import { unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes } from 'react-router-dom'

import { RedirectConfirmationModal } from 'Components/ConfirmationModal/RedirectConfirmationContext'

import history from './history'
import { configureReactModal } from './Modal'
import Login from './pages/public/Login'
import ProtectedRoutes from './ProtectedRoutes'
import { assistAI } from './react-ui'

const App = () => {
  useEffect(() => {
    const isCsrfCookiePresent = document.cookie.split(';').some((item) => item.trim().startsWith('csrftoken='))
    if (isCsrfCookiePresent) {
      document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
  }, [])

  useEffect(() => {
    configureReactModal()
  }, [])

  useEffect(() => {
    document.body.classList.add(assistAI)
  }, [])

  return (
    <HistoryRouter history={history} basename='app'>
      <div id='__page-root'>
        <RedirectConfirmationModal>
          <Routes>
            <Route path='login' element={<Login />} />
            <Route path='/*' element={<ProtectedRoutes />} />
            <Route path='*' element={<Navigate to='/login' replace />} />
          </Routes>
        </RedirectConfirmationModal>
      </div>
      <div id='__modal-root' />
    </HistoryRouter>
  )
}

export default App
