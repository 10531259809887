import { Box, Flex } from '@happyfoxinc/web-components'

import { Fragment, useState } from 'react'

import BackButton from 'Src/componentsv3/BackButton'

import { MODULES, SOFTWARE_ACCESS } from 'Src/pagesv3/Modules/constants'

import SoftwareList from './SoftwareList'

import { SoftwareProvider } from './SoftwareContext'

const SoftwareAccessModule = () => {
  const [view] = useState('configuration')

  return (
    <Fragment>
      <Box className='page-content' style={{ height: '100%' }}>
        <div className='heading-container'>
          <Flex align='center' gap='4px'>
            <BackButton className='back-btn' />
            <h1 className='heading'>{MODULES[SOFTWARE_ACCESS].title}</h1>
          </Flex>
          <p className='sub-heading'>{MODULES[SOFTWARE_ACCESS].description}</p>
        </div>
        {view === 'configuration' ? (
          <SoftwareProvider>
            <SoftwareList />
          </SoftwareProvider>
        ) : view === 'reports' ? (
          <Fragment />
        ) : null}
      </Box>
    </Fragment>
  )
}

export default SoftwareAccessModule
