// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gzCSXtb9fltSj4NnQpQf{margin-top:2rem}.AITR6mFJUfekxe8aAy5I{display:flex;align-items:center;gap:20px;margin-right:auto;width:30%}.aZexwGnqddEyzaqZ3e0y th:not(:first-child):not(:last-child){width:150px}.tsT3lBvYlnLvq9ViCysN{text-align:center;margin:5rem 0}.w7ugxX4fBWFOowTeTWS6{display:flex;flex-direction:column;gap:.7rem;align-items:center}.g4MaHeZ7TPfP_yv4zGXA{border:none;background-color:unset;cursor:not-allowed}.g4MaHeZ7TPfP_yv4zGXA>*{cursor:not-allowed}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Announcements/List/AnnouncementList.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,SAAA,CAGF,4DACE,WAAA,CAGF,sBACE,iBAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,sBAAA,CACA,kBAAA,CAGF,wBACE,kBAAA","sourcesContent":[".Container {\n  margin-top: 2rem;\n}\n\n.SearchInputContainer {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  margin-right: auto;\n  width: 30%;\n}\n\n.AnnouncementTable th:not(:first-child):not(:last-child) {\n  width: 150px;\n}\n\n.NoFilterResults {\n  text-align: center;\n  margin: 5rem 0;\n}\n\n.TooltipContent {\n  display: flex;\n  flex-direction: column;\n  gap: 0.7rem;\n  align-items: center;\n}\n\n.TooltipTrigger {\n  border: none;\n  background-color: unset;\n  cursor: not-allowed;\n}\n\n.TooltipTrigger>* {\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "gzCSXtb9fltSj4NnQpQf",
	"SearchInputContainer": "AITR6mFJUfekxe8aAy5I",
	"AnnouncementTable": "aZexwGnqddEyzaqZ3e0y",
	"NoFilterResults": "tsT3lBvYlnLvq9ViCysN",
	"TooltipContent": "w7ugxX4fBWFOowTeTWS6",
	"TooltipTrigger": "g4MaHeZ7TPfP_yv4zGXA"
};
export default ___CSS_LOADER_EXPORT___;
