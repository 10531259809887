export const navigationItems = [
  { label: 'Dashboard', path: '/dashboard' },
  {
    label: 'Modules',
    path: '/modules',
    children: [
      { label: 'Software Access module', path: '/modules/software-access' },
      { label: 'Company Policy module', path: '/modules/company-policy' },
      { label: 'Survey Management module', path: '/modules/survey-management' }
    ]
  },
  {
    label: 'Answers',
    path: '/answers',
    children: [{ label: 'Create Answer', path: '/answers/create' }]
  },
  {
    label: 'Apps',
    path: '/apps',
    children: [
      { label: 'Knowledge Source', path: '/apps/category/knowledge-sources' },
      { label: 'Ticketing System', path: '/apps/category/ticketing-system' },
      { label: 'Identity Management', path: '/apps/category/identity-management' },
      { label: 'Happyfox Helpdesk', path: '/apps/happyfox' },
      { label: 'Notion', path: '/apps/notion' },
      { label: 'Okta', path: '/apps/okta' },
      { label: 'Gitbook', path: '/apps/gitbook' },
      { label: 'Happyfox ServiceDesk', path: '/apps/happyfox-service-desk' },
      { label: 'Confluence', path: '/apps/confluence' },
      { label: 'Guru', path: '/apps/guru' },
      { label: 'SharePoint', path: '/apps/sharepoint' },
      { label: 'Simpplr', path: '/apps/simpplr' },
      { label: 'Outline', path: '/apps/outline' },
      { label: 'Azure AD', path: '/apps/azure_ad' }
    ]
  },
  {
    label: 'Announcements',
    path: '/announcements',
    children: [{ label: 'Create Announcement', path: '/announcements/create' }]
  },
  { label: 'Channels', path: '/channels' },
  { label: 'Reports', path: '/reports' },
  {
    label: 'User Groups',
    path: '/user-groups',
    children: [{ label: 'Create User Group', path: '/user-groups/create' }]
  },
  { label: 'Admins', path: '/admins' },
  { label: 'Workspaces', path: '/workspaces' }
]
