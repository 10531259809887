import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'

import styles from './GitBook.module.css'

import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import KnowledgeConfiguration from 'Src/pagesv3/Apps/components/KnowledgeConfiguration'
import SyncDisabledAlert from 'Src/pagesv3/Apps/components/SyncDisabledAlert'
import {
  useEnableSyncGitBookMutation,
  useGetGitBookAppQuery,
  useUninstallGitBookMutation
} from 'Src/servicesV3/gitbookApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import GitBookSkeleton from './GitBookSkeleton'
import GitBookSpaces from './GitBookSpaces'

const GitBook = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [, setSearchParams] = useSearchParams()

  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { data: app = {}, isLoading } = useGetGitBookAppQuery({ workspace_id: currentWorkspaceId })
  const [enableSync, { isLoading: isEnablingSync }] = useEnableSyncGitBookMutation()
  const [uninstallApp, { isLoading: isUninstalling }] = useUninstallGitBookMutation()

  const handleEnableSync = async () => {
    const promise = enableSync({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})

    toast.promise(promise, {
      loading: 'Enabling sync...',
      success: 'GitBook sync enabled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || 'Failed to enable sync for Helpdesk. Try again...'
        setIsEnableSyncModalOpen(false)
        return errorMessage
      }
    })
  }

  const handleUninstall = async () => {
    const promise = uninstallApp({ workspace_id: currentWorkspaceId })
    promise.then(() => {
      setIsUninstallModalOpen(false)
    })

    toast.promise(promise, {
      loading: 'Uninstalling...',
      success: 'GitBook uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || `Failed to uninstall Helpdesk. Try again...`
        return errorMessage
      }
    })
  }

  const handleReconnect = () => {
    sessionStorage.setItem('reconnectApp', JSON.stringify(app))

    setSearchParams({ isReconnect: 'true' })
  }

  if (isLoading) {
    return <GitBookSkeleton />
  }

  const isSyncDisabled = !app?.sync_info?.is_sync_enabled

  return (
    <Flex width='100%' gap='25px' direction='column' className={styles.wrapper}>
      {isSyncDisabled && <SyncDisabledAlert title='gitBook' onEnableSync={() => setIsEnableSyncModalOpen(true)} />}
      <AppInstalledCard
        title='GitBook workspace'
        accountUrl={app.user_email_id}
        showButton
        buttonText='Reconnect'
        onButtonClick={handleReconnect}
      />

      <GitBookSpaces />

      <KnowledgeConfiguration app={app} />

      <AppFooter app={app} isUninstalling={isUninstalling} onUninstall={() => setIsUninstallModalOpen(true)} />

      <Modal
        size='small'
        title='Uninstall GitBook'
        open={isUninstallModalOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        onClose={() => setIsUninstallModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
        showCloseButton={false}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isUninstalling} onClick={handleUninstall}>
              Uninstall
            </Button>
            <Button variant='outline' onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        size='default'
        title='Enable Sync for GitBook'
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsEnableSyncModalOpen(false)
        }}
        open={isEnableSyncModalOpen}
        onClose={() => setIsEnableSyncModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
        showCloseButton={false}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.ENABLE_APP_SYNC('GitBook')}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isEnablingSync} onClick={handleEnableSync}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isEnablingSync} onClick={() => setIsEnableSyncModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default GitBook
