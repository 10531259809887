import { Box, Button, Flex, Modal, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import styles from './Helpdesk.module.css'

import RefreshIcon from 'Src/assetsv3/icons/refresh.svg'
import { useFeature } from 'Src/components/Features'
import FormField from 'Src/componentsv3/FormField'
import MergeInput from 'Src/componentsv3/MergeInput'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tab from 'Src/componentsv3/Tab'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Src/componentsv3/Tooltip'
import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'
import { DEFAULT_TICKET_SUBJECT_MERGE_FIELD, TICKET_SUBJECT_MERGE_FIELDS } from 'Src/constants/mergeFields'
import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import { SYNC_STATUS } from 'Src/constants/syncStatus'
import { URLS } from 'Src/constants/urls'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import KnowledgeConfiguration from 'Src/pagesv3/Apps/components/KnowledgeConfiguration'
import SyncDisabledAlert from 'Src/pagesv3/Apps/components/SyncDisabledAlert'
import {
  helpdeskAppApi,
  useEnableHelpdeskSyncMutation,
  useGetHelpdeskAppQuery,
  useUninstallHelpdeskAppMutation,
  useUpdateHelpdeskAppMutation
} from 'Src/servicesV3/helpdeskAppApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import isEmptyObj from 'Src/utils/object'
import { openUrl } from 'Src/utils/url'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'
import noHtmlTagTypeEnclosedText from 'Utils/inputValidation/noHtmlTagTypeEnclosedText'

import HelpdeskLoadingScreen from './HelpdeskLoadingScreen'

const helpdeskSettingsFormValidationSchema = yup
  .object()
  .shape({
    ticketSubject: yup.string().trim().required('Ticket subject is required').test(noHtmlTagTypeEnclosedText),
    allowedCategories: yup.array().min(1, 'Allowed categories is required'),
    solvedTicketStatus: yup.mixed().required('Solved ticket status is required')
  })
  .required()

const HelpdeskForm = ({ app }) => {
  const title = 'Helpdesk'
  const { currentWorkspaceId } = useWorkspace()

  const [updateSettings, updateSettingsResult] = useUpdateHelpdeskAppMutation()

  const isConversationalTicketingEnabled = useFeature([FEATURE_FLAGS.CONVERSATIONAL_TICKETING])
  const isDMWorkflowEnabled = useFeature([CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])

  const formMethods = useForm({
    defaultValues: {
      ticketSubject: app?.subject ?? `{{${DEFAULT_TICKET_SUBJECT_MERGE_FIELD}}}`,
      allowedCategories:
        app?.allowed_categories?.map((categoryId) =>
          app?.all_categories?.find((category) => category.id === categoryId)
        ) ?? [],
      solvedTicketStatus: isEmptyObj(app?.default_solved_status) ? null : app?.default_solved_status,
      showTicketsFromOtherChannels: app?.track_external_tickets ?? false,
      showSuggestionsForTicketsFromOtherChannels: app?.show_suggestion_for_external_tickets ?? false
    },
    resolver: yupResolver(helpdeskSettingsFormValidationSchema)
  })

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
    reset,
    getValues
  } = formMethods

  const handleFormSubmit = () => {
    if (!app) return

    const data = getValues()

    const payload = {
      ticket_subject: data.ticketSubject,
      allowed_categories: data.allowedCategories.map((category) => category.id),
      default_solved_status: data.solvedTicketStatus.id,
      workspace_id: currentWorkspaceId
    }

    if (isDMWorkflowEnabled) {
      payload.track_external_tickets = data.showTicketsFromOtherChannels
      if (isConversationalTicketingEnabled) {
        payload.show_suggestion_for_external_tickets = data.showSuggestionsForTicketsFromOtherChannels
      } else {
        payload.show_suggestion_for_external_tickets = app.show_suggestion_for_external_tickets
      }
    }

    const promise = updateSettings(payload).unwrap()

    toast
      .promise(promise, {
        loading: `Updating settings for ${title}`,
        success: `Successfully updated settings for ${title}`,
        error: parseErrorMessage(`Unable to update ${title} settings. Try again`)
      })
      .then(() => {
        reset(data)
      })
  }

  const showSuggestionsForTicketsLabel = () => {
    const label = <p className={styles.switchLabel}>Show suggestions for tickets created from other channels</p>
    if (isConversationalTicketingEnabled) {
      return label
    } else {
      return (
        <Tooltip delayDuration={250}>
          <TooltipTrigger className={styles.tooltipTrigger}>{label}</TooltipTrigger>
          <TooltipContent side='right' className={styles.upgradeTooltip}>
            <p className={styles.mutedLabel}>Available only in Enterprise plan</p>
            <Button
              size='sm'
              variant='outline'
              onClick={(e) => {
                e.stopPropagation()
                openUrl(URLS.ASSIST_AI)
              }}
            >
              Upgrade Plan
            </Button>
          </TooltipContent>
        </Tooltip>
      )
    }
  }

  const showTicketsFromOtherChannels = watch('showTicketsFromOtherChannels')

  useEffect(() => {
    if (!showTicketsFromOtherChannels) {
      setValue('showSuggestionsForTicketsFromOtherChannels', false, { shouldDirty: true })
    }
  }, [setValue, showTicketsFromOtherChannels])

  const shouldDisableSave = updateSettingsResult.isLoading || !isDirty

  return (
    <Flex direction='column'>
      <FormField>
        <FormField.Field label='You have successfully connected your HappyFox Help Desk account'>
          <Box width='400px'>
            <TextField.Root value={app.account_url} readOnly size='2' radius='small' />
          </Box>
          <p className={styles.helpText}>Example: https://myaccount.helpdesk.com</p>
        </FormField.Field>
      </FormField>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormField>
            <FormField.Field
              label={<Fragment>Ticket Subject</Fragment>}
              error={errors?.ticketSubject?.message}
              isRequired
            >
              <Box width='400px'>
                <MergeInput showTextArea name='ticketSubject' mergeFields={TICKET_SUBJECT_MERGE_FIELDS} />
              </Box>
            </FormField.Field>
          </FormField>

          <FormField>
            <FormField.Field
              label={<Fragment>Allowed categories</Fragment>}
              error={errors?.allowedCategories?.message}
              isRequired
            >
              <Controller
                control={control}
                name='allowedCategories'
                render={({ field }) => (
                  <Box width='400px'>
                    <ReactSelect
                      {...field}
                      isMulti
                      isClearable={false}
                      options={app.all_categories}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </FormField>

          <FormField>
            <FormField.Field
              label={<Fragment>Solved ticket status</Fragment>}
              error={errors?.solvedTicketStatus?.message}
              isRequired
            >
              <Controller
                control={control}
                name='solvedTicketStatus'
                render={({ field }) => (
                  <Box width='300px'>
                    <ReactSelect
                      {...field}
                      options={app.solved_statuses}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </FormField>

          {isDMWorkflowEnabled && (
            <Fragment>
              <FormField>
                <FormField.Field label='' error={errors?.showTicketsFromOtherChannels?.message}>
                  <Flex direction='row' gap='6px' align='center'>
                    <label className={styles.switch}>
                      <input type='checkbox' {...register('showTicketsFromOtherChannels')} />
                      <span className={styles.slider} />
                    </label>

                    <p className={styles.switchLabel}>Show tickets from other channels</p>
                  </Flex>
                </FormField.Field>
              </FormField>

              <FormField>
                <FormField.Field error={errors?.showSuggestionsForTicketsFromOtherChannels?.message}>
                  <Flex direction='row' gap='6px' align='center'>
                    <label className={styles.switch}>
                      <input
                        type='checkbox'
                        {...register('showSuggestionsForTicketsFromOtherChannels')}
                        disabled={!isConversationalTicketingEnabled || !showTicketsFromOtherChannels}
                      />
                      <span className={styles.slider} />
                    </label>

                    {showSuggestionsForTicketsLabel()}
                  </Flex>
                </FormField.Field>
              </FormField>
            </Fragment>
          )}

          <Button type='submit' radius='medium' disabled={shouldDisableSave}>
            Save
          </Button>
        </form>
      </FormProvider>
    </Flex>
  )
}

const Helpdesk = () => {
  const [, setSearchParams] = useSearchParams()

  const [view, setView] = useState('ticket_configuration')
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [showReloadAnimation, setShowReloadAnimation] = useState(false)

  const { currentWorkspaceId } = useWorkspace()

  const { data: app = {}, isLoading } = useGetHelpdeskAppQuery({
    workspace_id: currentWorkspaceId
  })

  const [triggerRefreshQuery] = helpdeskAppApi.useLazyGetHelpdeskAppQuery()

  const [enableSync, { isLoading: isEnablingSync }] = useEnableHelpdeskSyncMutation()
  const [uninstall, { isLoading: isUninstalling }] = useUninstallHelpdeskAppMutation()

  const handleTabChange = (newView) => {
    setView(newView)
  }

  const handleEnableSync = () => {
    if (isEnablingSync) return

    const promise = enableSync({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})

    toast.promise(promise, {
      loading: 'Enabling sync for Helpdesk',
      success: 'Sync enabled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || 'Failed to enable sync for Helpdesk. Try again...'
        setIsEnableSyncModalOpen(false)
        return errorMessage
      }
    })
  }

  const handleUninstall = async () => {
    const promise = uninstall({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: 'Uninstalling Helpdesk App',
      success: 'Helpdesk App uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || `Failed to uninstall Helpdesk App. Try again...`
        return errorMessage
      }
    })
  }

  const handleRefresh = () => {
    setShowReloadAnimation(true)

    const promise = triggerRefreshQuery({
      workspace_id: currentWorkspaceId,
      refresh_metadata: true
    }).unwrap()

    toast
      .promise(promise, {
        loading: 'Refreshing data from Helpdesk...',
        success: 'Data refreshed successfully',
        error: (err) => {
          const message = err?.data?.message || 'Failed to refresh data from Helpdesk. Try again...'
          return message
        }
      })
      .finally(() => {
        setShowReloadAnimation(false)
      })
  }

  const handleReconnect = () => {
    sessionStorage.setItem('reconnectApp', JSON.stringify(app))

    setSearchParams({ isReconnect: 'true' })
  }

  if (isLoading) {
    return <HelpdeskLoadingScreen />
  }

  const isHFSyncEnabled = app?.sync_info?.is_sync_enabled
  const isHFHelpdeskExpired = app?.is_helpdesk_account_expired

  const syncDisabledForAccountExpiryMessage = SYNC_STATUS.DISABLED_FOR_EXPIRY.replace(
    '{{title}}',
    'Happyfox Helpdesk'
  ).replace('{{account_type}}', 'Helpdesk')

  return (
    <Flex direction='column' gap='30px' width='100%'>
      <AppInstalledCard
        title='Happyfox Helpdesk'
        accountUrl={app.account_url}
        showButton
        buttonText='Reconnect'
        onButtonClick={handleReconnect}
      />
      {!isHFSyncEnabled && !isHFHelpdeskExpired && (
        <SyncDisabledAlert title='Happyfox Helpdesk' onEnableSync={() => setIsEnableSyncModalOpen(true)} />
      )}
      {!isHFSyncEnabled && isHFHelpdeskExpired && (
        <SyncDisabledAlert
          title='Happyfox Helpdesk'
          message={syncDisabledForAccountExpiryMessage}
          onEnableSync={() => setIsEnableSyncModalOpen(true)}
        />
      )}
      <Flex justify='space-between' align='center'>
        <Flex align='center' gap='8px'>
          <Tab
            isLoading={isLoading}
            label='Ticket configuration'
            isActive={view === 'ticket_configuration'}
            onClick={() => handleTabChange('ticket_configuration')}
          />
          <Tab
            isLoading={isLoading}
            label='Knowledge configuration'
            isActive={view === 'knowledge_configuration'}
            onClick={() => handleTabChange('knowledge_configuration')}
          />
        </Flex>
        {view === 'ticket_configuration' && (
          <Button className={styles.refreshButton} onClick={handleRefresh}>
            <RefreshIcon
              width='17'
              height='17'
              className={cx(styles.refreshIcon, { [styles.refreshIconAnimation]: showReloadAnimation })}
            />
            Refresh
          </Button>
        )}
      </Flex>
      {view && view === 'ticket_configuration' && <HelpdeskForm app={app} />}
      {view && view === 'knowledge_configuration' && <KnowledgeConfiguration app={app} />}
      <AppFooter app={app} isUninstalling={isUninstalling} onUninstall={() => setIsUninstallModalOpen(true)} />
      <Modal
        size='small'
        title='Uninstall Helpdesk'
        open={isUninstallModalOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        onClose={() => setIsUninstallModalOpen(false)}
        showFooter={false}
        showCloseButton={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isUninstalling} onClick={handleUninstall}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isUninstalling} onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Modal
        size='default'
        title='Enable Sync for Happyfox Helpdesk'
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsEnableSyncModalOpen(false)
        }}
        open={isEnableSyncModalOpen}
        onClose={() => setIsEnableSyncModalOpen(false)}
        showFooter={false}
        showCloseButton={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {isHFHelpdeskExpired && (
            <Flex gap='1rem' className={styles.syncAlert}>
              {CONFIRM_MESSAGES.HF_ACCOUNT_EXPIRED_ALERT('Happyfox Helpdesk')}
            </Flex>
          )}
          {CONFIRM_MESSAGES.ENABLE_APP_SYNC('Happyfox Helpdesk')}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isEnablingSync} onClick={handleEnableSync}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isEnablingSync} onClick={() => setIsEnableSyncModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Helpdesk
