import { Box, Button, Flex, Modal, TextField, Grid } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useEffect, useState, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './AnnouncementForm.module.css'

import FormField from 'Src/componentsv3/FormField'
import MergeTrixEditor from 'Src/componentsv3/MergeTrixEditor'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tips, { Tip } from 'Src/componentsv3/Tips'
import { ACCOUNT_TYPE } from 'Src/constants/account'
import {
  useCreateAnnouncementMutation,
  useSendAnnouncementToSelfMutation,
  useUpdateAnnouncementMutation
} from 'Src/servicesV3/announcementsApi'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetUsersQuery, userGroupsApi } from 'Src/servicesV3/userGroupsApi'
import dayjs from 'Utils/dayjs-helper'

import DeleteModal from '../DeleteAnnouncementModal'
import DateRangePicker from 'Src/pagesv3/Modules/SurveyManagementModule/components/DateRangePicker/DateRangePicker'

const ALL_USERS = 'all_users'
const SPECIFIC_USERS = 'specific_users'
const USER_GROUPS = 'user_groups'

const SEND_ANNOUNCEMENT_TO_OPTIONS = [
  { label: 'All Users', value: ALL_USERS },
  { label: 'Specific User group(s)', value: USER_GROUPS },
  { label: 'Specific Users', value: SPECIFIC_USERS },
]

const SEND_NOW = 'now'
const SCHEDULE_LATER = 'schedule_later'

const SCHEDULE_ANNOUNCEMENT_OPTIONS = [
  { label: 'Send Now', value: SEND_NOW },
  { label: 'Schedule later', value: SCHEDULE_LATER }
]

const timeZone = dayjs.tz.guess()

const getUserIdKey = (accountType) => {
  switch (accountType) {
    case ACCOUNT_TYPE.SLACK:
      return 'slack_user_id'
    case ACCOUNT_TYPE.MS_TEAMS:
      return 'azure_ad_user_id'
    default:
      return null
  }
}

const AnnouncementForm = ({ isEdit = false, isLoading = false }) => {
  const navigate = useNavigate()

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedUserOptions, setSelectedUserOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])

  const { data: account } = useGetAccountQuery()

  const { data: users } = useGetUsersQuery({
    accountType: account?.account_type,
    name: searchTerm
  })

  const [getUserGroups] = userGroupsApi.useLazyGetUserGroupsQuery()

  const [sendToSelf] = useSendAnnouncementToSelfMutation()
  const [updateAnnouncement] = useUpdateAnnouncementMutation()
  const [createAnnouncement] = useCreateAnnouncementMutation()
  const accountType = account?.account_type

  const formMethods = useFormContext()

  const {
    register,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    handleSubmit
  } = formMethods

  const announcement = getValues()
  const readOnly = useMemo(() => isEdit && announcement?.status === 'sent', [isEdit, announcement?.status])

  const selectedVisibility = watch('send_to')
  const schedulePreference = watch('schedule_preference')
  const userAcknowledgementRequired = watch('user_acknowledgement_required')
  const acknowledgementLabelLimit = Math.min(watch('acknowledgement_configuration.button_label')?.length || 0, 25);

  const handlePreview = useCallback(() => {
    const title = getValues('title')
    const content = getValues('content')
    const userAcknowledgementRequired = getValues('user_acknowledgement_required')
    const acknowledgementLabel = getValues('acknowledgement_configuration.button_label')
    if (!content) {
      toast.error('Please enter the content to send preview')
      return
    }
    const payload = {
      content: content,
      title: title,
      user_acknowledgement_required: userAcknowledgementRequired,
      acknowledgement_configuration: {
        button_label: acknowledgementLabel
      }
    }
    const promise = sendToSelf(payload).unwrap()
    toast.promise(promise, {
      loading: 'Sending announcement...',
      success: () => {
        return (
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>Announcement sent successfully!</div>
            <div style={{ fontSize: '13px' }}>Check your DM for the announcement preview</div>
          </div>
        )
      },
      error: 'Unable to send preview. Please try again.'
    })
  }, [sendToSelf, getValues])

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        const options = data.results.map((group) => ({
          id: group.id,
          label: group.name,
          value: group.id
        }))
        cb(options)
      })
  }

  useEffect(() => {
    if (users?.length > 0) {
      const options = users.map((user) => ({
        label: `${user.name} (${user.email})`,
        value: user
      }))
      const filteredOptions = options.filter(
        (option) => !selectedUserOptions.some((selected) => selected.value.email === option.value.email)
      )
      setUserOptions([...selectedUserOptions, ...filteredOptions])
    }
  }, [users, selectedUserOptions])

  useEffect(() => {
    if (isEdit && announcement?.user_groups?.length > 0) {
      const transformedGroups = announcement.user_groups.map((group) => ({
        id: group.id,
        label: group.name,
        value: group.id
      }))
      setValue('visibleToGroups', transformedGroups)
    }
  }, [isEdit, announcement?.user_groups, watch, setValue])

  useEffect(() => {
    if (isEdit && announcement?.scheduled_on) {
      setValue('scheduled_on', announcement.scheduled_on)
    }
  }, [isEdit, announcement?.scheduled_on, setValue])

  const saveAnnouncement = useCallback(
    async (data, shouldSend = false) => {
      const payload = {
        title: data.title,
        content: data.content,
        send_to: data.send_to,
        schedule_preference: data.schedule_preference,
        scheduled_on: data.schedule_preference === SCHEDULE_LATER ? (data.scheduled_on ? dayjs(data.scheduled_on).format() : null) : null,
        user_acknowledgement_required: data.user_acknowledgement_required,
        acknowledgement_configuration: {
          button_label: data.acknowledgement_configuration?.button_label || 'Acknowledge'
        },
        users: []
      }

      if (data.send_to === USER_GROUPS && data.visibleToGroups?.length) {
        payload.user_groups = data.visibleToGroups.map((group) => group.id)
      } else if (data.send_to === SPECIFIC_USERS && data.users?.length) {
        payload.users = data.users.map((user) => user[getUserIdKey(accountType)])
      }

      if (shouldSend) {
        payload.status = 'sent'
        payload.send_announcement = true
      } else {
        payload.status = 'draft'
      }

      let promise
      if (isEdit) {
        promise = updateAnnouncement({
          id: announcement.id,
          ...payload
        }).unwrap()
      } else {
        promise = createAnnouncement(payload).unwrap()
      }

      toast.promise(promise, {
        loading: `${shouldSend ? 'Sending' : isEdit ? 'Updating' : 'Saving'} announcement...`,
        success: `Announcement ${
          data.scheduled_on ? 'scheduled' : shouldSend ? 'sent' : isEdit ? 'updated' : 'saved'
        } successfully.`,
        error: `Error ${shouldSend ? 'sending' : isEdit ? 'updating' : 'saving'} announcement. Please try again.`
      })

      return promise
    },
    [isEdit, announcement?.id, updateAnnouncement, createAnnouncement]
  )

  const handleSend = useCallback(async () => {
    setIsConfirmModalOpen(false)
    try {
      const data = getValues()
      await saveAnnouncement(data, true)
      navigate('..')
    } catch (error) {
      console.error('Error sending announcement:', error)
    }
  }, [getValues, saveAnnouncement])

  const handleSave = useCallback(
    async (data) => {
      try {
        await saveAnnouncement(data, false)
        navigate('..')
      } catch (error) {
        console.error('Error saving announcement:', error)
      }
    },
    [saveAnnouncement, navigate]
  )

  const getConfirmationMessage = useCallback(() => {
    const recipients = (() => {
      switch (selectedVisibility) {
        case ALL_USERS:
          return 'all users'
        case USER_GROUPS:
          return 'specific user group(s)'
        case SPECIFIC_USERS:
          return 'specific users'
        default:
          return 'users'
      }
    })()

    const baseMessage = `Assist AI will send this message as DM to ${recipients}.`

    if (schedulePreference === SCHEDULE_LATER) {
      const scheduledDate = getValues('scheduled_on')
      return `${baseMessage}\nScheduled for ${dayjs(scheduledDate).format('MMMM D, YYYY [at] h:mm A')} (${timeZone})`
    }
    return baseMessage
  }, [schedulePreference, selectedVisibility, getValues])

  const handleCancel = useCallback(() => {
    navigate('..')
  })

  const handleDuplicate = useCallback(() => {
    navigate('../create', { state: { duplicateFrom: announcement } })
  }, [navigate, announcement])

  return (
    <Fragment>
      <Grid columns='1fr 25%' gap='10rem'>
        <Box width='100%'>
          <FormField>
            <FormField.Field label='Title' error={errors?.title?.message} className={styles.w100} isRequired={!readOnly}>
              <TextField.Root
                {...register('title')}
                size='2'
                radius='small'
                placeholder='Enter title'
                className={`${styles.w100} ${readOnly ? styles.disabled : ''}`}
                disabled={readOnly}
              />
            </FormField.Field>
          </FormField>
          <FormField>
            <FormField.Field label='Content' error={errors?.content?.message} className={styles.w100} isRequired={!readOnly}>
              <Box>
                <MergeTrixEditor 
                  name='content' 
                  className={styles.announcementContentEditor} 
                  isDisabled={readOnly} 
                />
                {!readOnly && (
                  <div className={styles.previewMessage}>
                    <span className={styles.previewLabel}>Assist AI will DM this message to you</span>
                    <span className={styles.previewLink} onClick={handlePreview}>
                      Send Preview
                    </span>
                  </div>
                )}
              </Box>
            </FormField.Field>
          </FormField>
          <Flex gap='24px' width='100%'>
            <FormField.Field
              label='Send Announcement To'
              error={errors?.send_to?.message}
              className={styles.w100}
              isRequired={!readOnly}
            >
              <Controller
                name='send_to'
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    placeholder='Select'
                    value={SEND_ANNOUNCEMENT_TO_OPTIONS.find((opt) => opt.value === field.value)}
                    onChange={(opt) => field.onChange(opt.value)}
                    isClearable={false}
                    options={SEND_ANNOUNCEMENT_TO_OPTIONS}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    isDisabled={readOnly}
                  />
                )}
              />
            </FormField.Field>
          </Flex>
          <div className={styles.visibilityOptions}>
            {selectedVisibility === USER_GROUPS && (
              <FormField>
                <FormField.Field
                  label='User Groups'
                  error={errors?.visibleToGroups?.message}
                  className={styles.w100}
                  isRequired={!readOnly}
                >
                  <Controller
                    name='visibleToGroups'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        className={styles.userGroupSelect}
                        isMulti
                        loadOptions={loadOptions}
                        placeholder='Search user groups'
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 2) {
                            return 'Start typing to search...'
                          }
                          return `No data available for "${inputValue}"`
                        }}
                        isDisabled={readOnly}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
            )}
            {selectedVisibility === SPECIFIC_USERS && (
              <FormField>
                <FormField.Field label='Users' error={errors?.users?.message} className={styles.w100} isRequired={!readOnly}>
                  <Controller
                    name='users'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        value={field.value?.map(
                          (user) =>
                            userOptions.find((opt) => opt.value === user) || {
                              label: `${user.name} (${user.email})`,
                              value: user
                            }
                        )}
                        onChange={(selected) => {
                          const selectedUsers = selected?.map((option) => option.value) || []
                          setSelectedUserOptions(selected || [])
                          field.onChange(selectedUsers)
                        }}
                        options={userOptions}
                        onInputChange={(value) => {
                          setSearchTerm(value)
                          return value
                        }}
                        isClearable={false}
                        isMulti
                        creatable={false}
                        placeholder='Select users...'
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 2) {
                            return 'Start typing to search...'
                          }
                          return `No data available for "${inputValue}"`
                        }}
                        closeMenuOnSelect={false}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value.email}
                        isDisabled={readOnly}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
            )}
          </div>
          <Flex gap='24px' align='center' justify='space-between'>
            <FormField className={styles.scheduleDropdown}>
              <FormField.Field
                label='Schedule Preference'
                error={errors?.schedule_preference?.message}
                className={styles.w100}
                isRequired={!readOnly}
              >
                <Controller
                  name='schedule_preference'
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      placeholder='Select'
                      value={SCHEDULE_ANNOUNCEMENT_OPTIONS.find((opt) => opt.value === field.value)}
                      onChange={(opt) => field.onChange(opt.value)}
                      isClearable={false}
                      options={SCHEDULE_ANNOUNCEMENT_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isDisabled={readOnly}
                    />
                  )}
                />
              </FormField.Field>
            </FormField>
            {schedulePreference !== SCHEDULE_LATER && <FormField className={styles.scheduleDropdown} />}
            {schedulePreference === SCHEDULE_LATER && (
              <FormField className={styles.scheduleDropdown}>
                <FormField.Field
                  label={
                    <Flex gap='8px' align='center'>
                      <span>Scheduled On {!readOnly && <span className={styles.requiredAsterisk}>*</span>}</span>
                      <span className={styles.smallText}>({timeZone})</span>
                    </Flex>
                  }
                  className={styles.w100}
                  error={errors?.scheduled_on?.message}
                >
                  <Controller
                    name='scheduled_on'
                    control={control}
                    render={({ field }) => (
                      <DateRangePicker
                        mode='datetime'
                        value={field.value}
                        onDateTimeChange={(date) => field.onChange(date)}
                        disablePastDates
                        className={styles.schedulePicker}
                        disabled={readOnly}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
            )}
          </Flex>
          <label className={`${styles.switchLabel} ${readOnly ? styles.disabled : ''}`}>
            <div className={styles.switchContainer}>
              <label className={styles.switch}>
                <Controller
                  name='user_acknowledgement_required'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <input type='checkbox' checked={value} onChange={onChange} disabled={readOnly} />
                  )}
                />
                <span className={`${styles.slider} ${readOnly ? styles.disabledSlider : ''}`} />
              </label>
            </div>
            <span className={styles.switchLabelText}>Is User Acknowledgement Required?</span>
          </label>
          {userAcknowledgementRequired && (
            <FormField>
              <FormField.Field
                label={
                  <Flex gap='8px' align='center' justify='space-between'>
                    <Flex gap='8px' align='center'>
                      <span>Label</span>
                      <span className={styles.smallText}>(Announcement Button)</span>
                    </Flex>
                    <span className={styles.smallText}>
                      {acknowledgementLabelLimit}/25
                    </span>
                  </Flex>
                }
                error={errors?.acknowledgement_configuration?.button_label?.message}
                className={styles.w100}
              >
                <TextField.Root
                  {...register('acknowledgement_configuration.button_label', {
                    maxLength: 25,
                    onChange: (e) => {
                      if (e.target.value.length > 25) {
                        e.target.value = e.target.value.substring(0, 25)
                      }
                      return e
                    }
                  })}
                  placeholder='Enter text (max 25 characters)'
                  disabled={readOnly}
                />
              </FormField.Field>
            </FormField>
          )}
          <Flex justify='space-between' width='100%' direction='row-reverse' style={{ marginTop: '3.5rem' }}>
            <Flex gap='12px' direction='row-reverse'>
              {readOnly ? (
                <Button variant='solid' onClick={handleDuplicate}>
                  Duplicate
                </Button>
              ) : (
                <>
                  <Button
                    variant='solid'
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault()
                      handleSubmit((data) => {
                        if (data.send_to === USER_GROUPS && !data.visibleToGroups?.length) {
                          toast.error('Please select at least one user group')
                          return
                        }
                        if (data.send_to === SPECIFIC_USERS && (!data.users || !data.users.length)) {
                          toast.error('Please select at least one user')
                          return
                        }
                        if (data.schedule_preference === SCHEDULE_LATER && !data.scheduled_on) {
                          toast.error('Please select a date and time for scheduling')
                          return
                        }
                        setIsConfirmModalOpen(true)
                      })()
                    }}
                  >
                    {schedulePreference === SCHEDULE_LATER ? 'Schedule' : 'Send Now'}
                  </Button>
                  <Button variant='outline' onClick={handleSubmit(handleSave)} disabled={isLoading} type='button'>
                    Save
                  </Button>
                </>
              )}
              {isEdit && (
                <Button variant='outline' className={styles.cancelButton} onClick={handleCancel} type='button'>
                  Cancel
                </Button>
              )}
            </Flex>
            {!isEdit && (
              <Button variant='ghost' className={styles.cancelButton} onClick={handleCancel} type='button'>
                Cancel
              </Button>
            )}
            {isEdit && (
              <Button type='button' className={styles.deleteButton} onClick={() => setIsDeleteModalOpen(true)}>
                Delete
              </Button>
            )}
          </Flex>
        </Box>
        <div className={styles.tipsContainer}>
          <Tips title='Let Your Team Know'>
            <Tip>This one time announcement let's Assist AI to send message to the users in your organization.</Tip>
            <Tip>
              Once the message is customized, Click <strong>'Send Preview'</strong>, for Assist AI to DM this message to
              you on Slack.
            </Tip>
            <Tip>
              If satisfied with the message click <strong>'Send Now'</strong> to send the message to the respective
              users or click <strong>'Schedule'</strong> and set the date and time to schedule the message for later.
            </Tip>
            {readOnly && (
              <Tip>
                If you wish to send another announcement with the same configuration, please click the <strong>'Duplicate'</strong> button.
              </Tip>
            )}
          </Tips>
        </div>
        <Modal
          size='small'
          open={isConfirmModalOpen}
          onOpenChange={() => setIsConfirmModalOpen(false)}
          onClose={() => setIsConfirmModalOpen(false)}
          title={schedulePreference === SCHEDULE_LATER ? 'Confirm Schedule' : 'Confirm Send'}
          showFooter={false}
          bodyClassName={styles.modalBody}
          showCloseButton={false}
        >
          <span>{getConfirmationMessage()}</span>
          <Flex gap='12px' justify='center' className={styles.confirmationModal}>
            <Button variant='solid' onClick={handleSubmit(handleSend)}>
              {schedulePreference === SCHEDULE_LATER ? 'Schedule' : 'Send'}
            </Button>
            <Button variant='outline' onClick={() => setIsConfirmModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Modal>
        <DeleteModal
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false)
          }}
          onOpenChange={() => {
            setIsDeleteModalOpen(false)
          }}
          id={announcement?.id}
          data={announcement}
          title='Delete Announcement'
          showFooter={false}
          bodyClassName={styles.modalBody}
        />
      </Grid>
    </Fragment>
  )
}

export default AnnouncementForm
