import { Button, Flex, Modal, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { usePagination } from 'react-table'
import { useTable } from 'react-table/dist/react-table.development'

import styles from './Sharepoint.module.css'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import {
  sharepointApi,
  useAddSharepointSiteMutation,
  useGetSharepointSitesQuery,
  useRemoveSharepointSiteMutation
} from 'Src/servicesV3/sharepointApi'
import debounce from 'Src/utils/debounce'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { SharepointSitesSkeleton } from './SharepointSkeleton'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}

const SitesList = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [selectedSiteId, setSelectedSiteId] = useState(null)

  const { data = {}, isLoading } = useGetSharepointSitesQuery(queryParams)
  const { results: sites = [], meta: paginationDetails = {} } = data
  const [removeSite, { isLoading: isRemovingSite }] = useRemoveSharepointSiteMutation()

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Sites',
        accessor: 'site_name',
        disableSortBy: true
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: sites,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams, currentWorkspaceId])

  const handleRemoveSite = async () => {
    const payload = {
      workspace_id: currentWorkspaceId,
      site_id: selectedSiteId
    }

    try {
      const promise = removeSite(payload).unwrap()

      toast.promise(promise, {
        loading: 'Removing site...',
        success: 'Site removed successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Failed to add site. Try again...'
          return errorMessage
        }
      })

      setSelectedSiteId(null)
      setIsRemoveModalOpen(false)
    } catch (error) {}
  }

  if (isLoading) {
    return <SharepointSitesSkeleton />
  }

  return (
    <Fragment>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <h2 className={styles.heading}>Sites currently syncing from</h2>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>
        {sites?.length === 0 ? (
          <div className={styles.emptyState}>Currently not syncing any sites. Add sites to start syncing</div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                  <TableOptions>
                    <TableOption
                      onClick={() => {
                        setSelectedSiteId(row.original.site_id)
                        setIsRemoveModalOpen(true)
                      }}
                    >
                      Remove
                    </TableOption>
                  </TableOptions>
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table.Root>
      <Modal
        size='small'
        title='Remove SharePoint Site'
        open={isRemoveModalOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsRemoveModalOpen(false)
        }}
        onClose={() => setIsRemoveModalOpen(false)}
        showFooter={false}
        showCloseButton={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          Are you sure you want to remove the SharePoint site?
          <Flex gap='15px'>
            <Button variant='solid' disabled={isRemovingSite} onClick={handleRemoveSite}>
              Remove
            </Button>
            <Button variant='outline' onClick={() => setIsRemoveModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Fragment>
  )
}

const SharepointSites = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [searchSites, searchSitesResult] = sharepointApi.useLazySearchSharepointSitesQuery()
  const [addSite, { isLoading: addSiteLoading }] = useAddSharepointSiteMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      site: null
    }
  })

  const handleSiteAddition = async (data) => {
    const { site } = data

    try {
      const promise = addSite({ workspace_id: currentWorkspaceId, site_id: site.SiteId }).unwrap()

      toast.promise(promise, {
        loading: 'Adding site...',
        success: 'Site added successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Failed to add site. Try again...'
          return errorMessage
        }
      })

      reset()
    } catch (error) {}
  }

  const loadOptions = async (inputValue, cb) => {
    return searchSites({ site_name: inputValue, workspace_id: currentWorkspaceId }).unwrap().then(cb)
  }

  return (
    <div>
      <h2 className={styles.heading}>Add a SharePoint site to sync from</h2>
      <form onSubmit={handleSubmit(handleSiteAddition)} className={styles.AddSiteForm}>
        <FormField>
          <FormField.Field error={errors.site?.message}>
            <Flex direction='row' align='center' gap='4rem'>
              <Controller
                control={control}
                name='site'
                rules={{ required: 'Search and select a site to add' }}
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      className={styles.SearchSite}
                      minCharsBeforeLoadingOptions={3}
                      loadOptions={loadOptions}
                      getOptionLabel={(option) => option.Title}
                      getOptionValue={(option) => option.Path}
                      isLoading={searchSitesResult.isLoading}
                      loadingMessage={({ inputValue }) => {
                        return `Searching for sites matching "${inputValue}"...`
                      }}
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length < 3) {
                          return 'Type alteast 3 characters to start searching'
                        }

                        return `No sites found for input "${inputValue}"`
                      }}
                    />
                  )
                }}
              />
              <Button variant='outline' type='submit' disabled={addSiteLoading}>
                + Add
              </Button>
            </Flex>
          </FormField.Field>
        </FormField>
      </form>
      <SitesList />
    </div>
  )
}

export default SharepointSites
