import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import cx from 'classnames'

import styles from './MergeTrixEditor.module.css'

import { getConcatenatedMergeFieldText } from 'Utils/mergeFields'

import Editor from '../Editor'
import InsertMergeFields from '../InsertMergeFields'

const MergeTrixEditor = ({ name, mergeFields, className, isDisabled }) => {
  const fieldRef = useRef(null)

  const { control } = useFormContext()

  const handleAddMergeFields = (addedFields) => {
    if (isDisabled) return;
    
    const cursorPosition = fieldRef.current.editor.getPosition()
    const currentTextLength = fieldRef.current.innerText.length

    const isTheCursorAtTheStart = cursorPosition === 0
    const isTheCursorAtTheEnd = cursorPosition === currentTextLength

    const canAddSpacerAtStart = !isTheCursorAtTheStart
    const canAddSpacerAtEnd = !isTheCursorAtTheEnd

    const concatenatedMergeFieldText = getConcatenatedMergeFieldText(
      addedFields,
      canAddSpacerAtStart,
      canAddSpacerAtEnd
    )

    fieldRef.current.editor.insertString(concatenatedMergeFieldText)
  }

  return (
    <div className={cx(
      styles.FieldContainer, 
      className,
      isDisabled ? styles.disabled : ''
    )}>
      {mergeFields && !isDisabled && (
        <div className={styles.MergeFieldContainer}>
          <InsertMergeFields fields={mergeFields} onAdd={handleAddMergeFields} />
        </div>
      )}

      <div className={styles.editorWrapper}>
        {isDisabled && <div className={styles.disabledOverlay}></div>}
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <Editor 
              defaultValue={value} 
              onChange={isDisabled ? undefined : onChange} 
              ref={fieldRef} 
              disabled={isDisabled}
            />
          )}
        />
      </div>
    </div>
  )
}

export default MergeTrixEditor
