import { Box, Flex } from '@happyfoxinc/web-components'

import { yupResolver } from '@hookform/resolvers/yup'

import { FormProvider, useForm } from 'react-hook-form'

import { useParams } from 'react-router-dom'

import styles from './Announcements.module.css'

import BackButton from 'Src/componentsv3/BackButton'
import { useGetAnnouncementQuery } from 'Src/servicesV3/announcementsApi'

import AnnouncementForm from './AnnouncementForm'
import announcementFormValidationSchema from './AnnouncementForm/announcement-form-validation-schema'

import AnnouncementFormSkeleton from './AnnouncementForm/AnnouncementFormSkeleton'

const AnnouncementFormHandler = ({ announcement }) => {
  const formMethods = useForm({
    defaultValues: {
      id: announcement.id || null,
      title: announcement.title || '',
      content: announcement.content || '',
      send_to: announcement.send_to || null,
      users: announcement.users || null,
      user_groups: announcement.user_groups || null,
      schedule_preference: announcement.schedule_preference || null,
      scheduled_on: announcement.scheduled_on || null,
      status: announcement.status || null,
      user_acknowledgement_required: announcement.user_acknowledgement_required || false,
      acknowledgement_configuration: {
        button_label: announcement.acknowledgement_configuration?.button_label || ''
      }
    },
    resolver: yupResolver(announcementFormValidationSchema)
  })

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Edit Announcement</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <AnnouncementForm isEdit />
        </FormProvider>
      </Box>
    </Box>
  )
}

const AnnouncementEdit = () => {
  const { id } = useParams()

  const { data: announcement, isLoading } = useGetAnnouncementQuery(id)

  if (isLoading) {
    return <AnnouncementFormSkeleton title='Edit Announcement' showHeader={true} />
  }

  return <AnnouncementFormHandler announcement={announcement} />
}

export default AnnouncementEdit
