import { TextField, Button } from '@happyfoxinc/web-components'
import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import styles from './CampaignConfiguration.module.css'

import InfoIcon from 'Src/assetsv3/icons/info.svg'
import ChevronRight from 'Src/assetsv3/icons/white-chevron-right.svg'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetSurveysQuery } from 'Src/servicesV3/surveyApi'
import { useGetUserGroupsQuery, useGetUsersQuery } from 'Src/servicesV3/userGroupsApi'
import InfoTooltip from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/InfoTooltip'

import DateRangePicker from '../components/DateRangePicker/DateRangePicker'
import { formatDateForInput } from '../ConfigModule/components/UtilityComponents'
import CommonContentLoader from './components/CommonContentLoader/CommonContentLoader'
import { Flex } from '@happyfoxinc/react-ui'

export const campaignValidationSchema = yup.object().shape({
  name: yup.string().required('Campaign name is required'),
  surveyId: yup.string().required('Survey is required'),
  type: yup.string().required('Distribution schedule type is required'),
  scheduledAt: yup.string().when('type', {
    is: (val) => val === 'scheduled' || val === 'recurring',
    then: () =>
      yup
        .string()
        .required('Schedule time is required')
        .test('is-future-date', 'Please select a future date or time', function (value) {
          if (!value) return true
          const currentDate = new Date()
          const expiryDate = new Date(value)
          return expiryDate > currentDate
        })
  }),
  expiryDate: yup
    .string()
    .nullable()
    .test('is-future-date', 'Please select a future date or time', function (value) {
      if (!value) return true
      const currentDate = new Date()
      const expiryDate = new Date(value)
      return expiryDate > currentDate
    }),
  distributionType: yup.string().required('Distribution type is required'),
  userEmails: yup.array().when('distributionType', {
    is: 'specific_user_emails',
    then: () => yup.array().min(1, 'At least one user is required')
  }),
  userGroups: yup.array().when('distributionType', {
    is: 'specific_user_groups',
    then: () => yup.array().min(1, 'At least one user group is required')
  })
})

const CampaignConfiguration = ({ editingCampaign, isLoading, selectedCampaign, refetchCampaign, onCancel, onSave }) => {
  const [activeTab, setActiveTab] = useState('create')
  const [userGroupOptions, setUserGroupOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedUserOptions, setSelectedUserOptions] = useState([])

  const {
    control,
    watch,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useFormContext()

  const campaignType = watch('type')
  const distributionType = watch('distributionType')

  const { data: surveys } = useGetSurveysQuery()
  const { data: userGroupsData, isLoading: isUserGroupLoading } = useGetUserGroupsQuery()
  const { data: account } = useGetAccountQuery()
  const { data: users } = useGetUsersQuery({
    accountType: account?.account_type,
    name: searchTerm
  })

  const scheduleTypeOptions = [
    { value: 'immediately', label: 'Immediately' },
    { value: 'scheduled', label: 'Scheduled' },
    // { value: 'recurring', label: 'Recurring' }
  ]

  const repeatOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' }
  ]

  const distributionTypeOptions = [
    { value: 'all_users', label: 'All Users' },
    { value: 'specific_user_groups', label: 'Specific User Group(s)' },
    { value: 'specific_user_emails', label: 'Specific User(s)' }
  ]

  const surveyOptions =
    surveys?.results
      ?.filter((survey) => survey.questions_count > 0)
      ?.map((survey) => ({
        value: survey.id,
        label: survey.title
      })) || []

  useEffect(() => {
    if (userGroupsData?.results) {
      const options = userGroupsData.results.map((group) => ({
        value: group.id,
        label: group.name
      }))
      setUserGroupOptions(options)
    }
  }, [userGroupsData])

  useEffect(() => {
    if (users?.length > 0) {
      const options = users.map((user) => ({
        label: `${user.name} (${user.email})`,
        value: user.email
      }))

      setUserOptions((prevOptions) => {
        const newOptions = options.filter(
          (option) => !selectedUserOptions.some((selected) => selected.value === option.value)
        )
        return [...selectedUserOptions, ...newOptions]
      })
    }
  }, [users, selectedUserOptions])

  useEffect(() => {
    if (selectedCampaign?.campaign && editingCampaign) {
      refetchCampaign()
      const campaign = selectedCampaign.campaign
      const distributionDetails = campaign.properties?.distribution_details || {}

      reset({
        name: campaign.name,
        description: campaign.description,
        type: campaign.distribution_schedule_type,
        scheduledAt: formatDateForInput(distributionDetails.scheduled_at),
        expiryDate: formatDateForInput(distributionDetails.expiry_date),
        repeat: distributionDetails.repeat,
        surveyId: campaign.survey_id,
        isAnonymous: campaign.properties?.is_anonymous,
        allowMultipleResponses: campaign.properties?.allow_multiple_responses,
        userEmails: distributionDetails.distribute_to?.user_email_ids || [],
        userGroups: distributionDetails.distribute_to?.user_group_ids || [],
        distributionType: distributionDetails.distribute_to?.distribution_type || [],
        appRequesterProfileIds: distributionDetails.distribute_to?.app_requester_profile_ids || []
      })
    }
  }, [selectedCampaign, editingCampaign, reset])

  const handleDistributionChange = (type) => {
    reset({
      ...watch(),
      distributionType: type,
      userEmails: [],
      userGroups: []
    })
  }

  const formatDisplayDateTime = (dateString) => {
    if (!dateString) return ''
    return new Date(dateString).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
  }

  const createScheduleTooltipContent = () => {
    const scheduledAt = watch('scheduledAt')
    const expiryDate = watch('expiryDate')
    const type = watch('type')
    const repeat = watch('repeat')
    const surveyId = watch('surveyId')

    const selectedSurvey = surveyOptions.find((option) => option.value === surveyId)
    if (!selectedSurvey) return null

    if (type === 'immediately') {
      return (
        <Fragment>
          The survey - "<span className={styles.boldDate}>{selectedSurvey.label}</span>" will be distributed{' '}
          <span className={styles.boldDate}>immediately to the target audience</span>
          {expiryDate && (
            <Fragment>
              {' '}
              and will remain active until <span className={styles.boldDate}>{formatDisplayDateTime(expiryDate)}</span>
            </Fragment>
          )}
        </Fragment>
      )
    }

    if (!scheduledAt) return null

    const startDate = formatDisplayDateTime(scheduledAt)

    if (type === 'recurring' && repeat) {
      return (
        <Fragment>
          Starting from <span className={styles.boldDate}>{startDate}</span>, the survey - "
          <span className={styles.boldDate}>{selectedSurvey.label}</span>" will be distributed{' '}
          <span className={styles.boldDate}>{repeat}</span> to users in the audience
          {expiryDate && (
            <Fragment>
              {' '}
              and will remain active until <span className={styles.boldDate}>{formatDisplayDateTime(expiryDate)}</span>.
            </Fragment>
          )}
        </Fragment>
      )
    }

    return (
      <Fragment>
        Starting from <span className={styles.boldDate}>{startDate}</span>, the survey - "
        <span className={styles.boldDate}>{selectedSurvey.label}</span>" will be distributed to users in the audience
        {expiryDate && (
          <Fragment>
            {' '}
            and will remain active until <span className={styles.boldDate}>{formatDisplayDateTime(expiryDate)}</span>.
          </Fragment>
        )}
      </Fragment>
    )
  }

  const renderProperties = () => (
    <div className={styles.propertiesContainer}>
      <Fragment>
        <FormField>
          <FormField.Field error={errors?.name?.message} isRequired label='Campaign Name'>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField.Root
                  {...field}
                  size='1'
                  radius='small'
                  className={styles.campaignName}
                  placeholder='Enter a recognizable campaign name for internal tracking and management'
                />
              )}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field label='Campaign Description'>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  placeholder='Provide a brief description to outline the purpose or context of this campaign'
                  className={styles.textarea}
                />
              )}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field error={errors?.surveyId?.message} isRequired label='Survey'>
            <Controller
              name='surveyId'
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={surveyOptions}
                  placeholder="Select the survey you'd like to distribute in this campaign"
                  value={surveyOptions.find((option) => option.value === field.value)}
                  onChange={(option) => field.onChange(option?.value)}
                  noOptionsMessage={() => 'No surveys found'}
                />
              )}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field error={errors?.type?.message} isRequired label='Distribution Schedule Type'>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={scheduleTypeOptions}
                  placeholder='Choose how and when your survey will be sent to users'
                  value={scheduleTypeOptions.find((option) => option.value === field.value)}
                  onChange={(option) => field.onChange(option.value)}
                />
              )}
            />
          </FormField.Field>
        </FormField>

        {campaignType !== 'immediately' && (
          <FormField>
            <FormField.Field error={errors?.scheduledAt?.message} isRequired label='Publish on'>
              <Controller
                name='scheduledAt'
                control={control}
                render={({ field }) => (
                  <DateRangePicker
                    mode='datetime'
                    value={field.value}
                    onDateTimeChange={(date) => field.onChange(date)}
                    disablePastDates
                  />
                )}
              />
            </FormField.Field>
          </FormField>
        )}

        {campaignType === 'recurring' && (
          <FormField>
            <FormField.Field error={errors?.repeat?.message} label='Repeat *'>
              <Controller
                name='repeat'
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={repeatOptions}
                    placeholder='Select repeat interval'
                    value={repeatOptions.find((option) => option.value === field.value)}
                    onChange={(option) => field.onChange(option.value)}
                  />
                )}
              />
            </FormField.Field>
          </FormField>
        )}

        <FormField>
          <FormField.Field
            error={errors?.expiryDate?.message}
            isRequired={campaignType !== 'immediately'}
            label={`End response collection on`}
          >
            <Controller
              name='expiryDate'
              control={control}
              render={({ field }) => (
                <DateRangePicker
                  mode='datetime'
                  value={field.value}
                  onDateTimeChange={(date) => field.onChange(date)}
                  disablePastDates
                />
              )}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field
            error={errors?.distributionType?.message}
            label={
              <div className={styles.targetAudienceContainer}>
                <div align='center' className={styles.targetAudienceLabel}>
                  Target Audience
                </div>
                <InfoTooltip message='Define who should receive this survey.' />
              </div>
            }
            isRequired={true}
          >
            <Controller
              name='distributionType'
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={distributionTypeOptions}
                  placeholder='Select target audience'
                  value={distributionTypeOptions.find((option) => option.value === field.value)}
                  onChange={(option) => handleDistributionChange(option.value)}
                />
              )}
            />
          </FormField.Field>
        </FormField>

        {distributionType === 'specific_user_emails' && (
          <FormField>
            <FormField.Field error={errors?.userEmails?.message} isRequired label='User(s)'>
              <Controller
                name='userEmails'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ReactSelect
                    value={field.value?.map(
                      (email) => userOptions.find((opt) => opt.value === email) || { label: email, value: email }
                    )}
                    onChange={(selected) => {
                      const emails = selected?.map((option) => option.value) || []
                      setSelectedUserOptions(selected || [])
                      field.onChange(emails)
                    }}
                    options={userOptions}
                    onInputChange={(value) => {
                      setSearchTerm(value)
                      return value
                    }}
                    isClearable={false}
                    isMulti
                    creatable={false}
                    placeholder='select user emails...'
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue.length < 2) {
                        return 'Start typing to search...'
                      }
                      return `No user emails found for input "${inputValue}"`
                    }}
                    closeMenuOnSelect={false}
                    formatCreateLabel={(inputValue) => `Add email: ${inputValue}`}
                  />
                )}
              />
            </FormField.Field>
          </FormField>
        )}

        {distributionType === 'specific_user_groups' && (
          <FormField>
            <FormField.Field error={errors?.userGroups?.message} isRequired label='User Groups'>
              <Controller
                name='userGroups'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ReactSelect
                    value={userGroupOptions.filter((option) => field.value?.includes(option.value))}
                    onChange={(selected) => {
                      const ids = selected?.map((option) => option.value) || []
                      field.onChange(ids)
                    }}
                    options={userGroupOptions}
                    isMulti
                    isClearable={false}
                    isLoading={isUserGroupLoading}
                    loadingMessage={({ inputValue }) => `Searching for user groups matching "${inputValue}"...`}
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue.length < 3) {
                        return 'Type alteast 3 characters to start searching'
                      }
                      return `No user groups found for input "${inputValue}"`
                    }}
                    closeMenuOnSelect={false}
                    placeholder='Select user groups...'
                  />
                )}
              />
            </FormField.Field>
          </FormField>
        )}
        <label className={styles.switchLabel}>
          <div className={styles.tooltipLabel}>
            <div className={styles.toggleLabel}>Anonymous Responses </div>
            <InfoTooltip message='Enable to ensure respondent identities remain anonymous.' />
          </div>
          <div className={styles.fullAccessToggle}>
            <label className={styles.switch}>
              <Controller
                name='isAnonymous'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <input type='checkbox' value={value} checked={value} onChange={onChange} />
                )}
              />
              <span className={styles.slider} />
            </label>
          </div>
        </label>

        <label className={styles.switchLabel}>
          <div className={styles.tooltipLabel}>
            <div className={styles.toggleLabel}>Multiple Responses </div>
            <InfoTooltip message='Allow respondents to submit more than one response.' />
          </div>
          <div className={styles.fullAccessToggle}>
            <label className={styles.switch}>
              <Controller
                name='allowMultipleResponses'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <input type='checkbox' value={value} checked={value} onChange={onChange} />
                )}
              />
              <span className={styles.slider} />
            </label>
          </div>
        </label>
        {createScheduleTooltipContent() && (
          <div className={styles.scheduleTooltip}>
            <InfoIcon className={styles.infoIcon} />
            <div className={styles.tooltipText}>{createScheduleTooltipContent()}</div>
          </div>
        )}
      </Fragment>
    </div>
  )

  const handleSaveSettings = () => {
    handleSubmit((data) => onSave(data, false))()
  }

  const handlePublish = () => {
    handleSubmit((data) => onSave(data, true))()
  }

  if (isLoading) {
    return <CommonContentLoader fields={6} />
  }

  return (
    <Flex gap='24px' className={styles.container}>
      <div className={styles.leftSection}>
        <h2 className={styles.title}>Configure Your Campaign</h2>
        <div className={styles.buttonGroup}>
          <div
            className={`${styles.configButton} ${activeTab === 'create' ? styles.activeButton : ''}`}
            onClick={() => setActiveTab('create')}
          >
            <div className={styles.buttonContent}>
              <div className={styles.buttonText}>
                <h3>Campaign Details</h3>
                <p>
                  Configure your campaign settings, including audience selection, survey distribution schedule, response
                  settings, and anonymity preferences.
                </p>
              </div>
              {activeTab === 'create' && (
                <Flex align='center' className={styles.arrowIcon}>
                  <ChevronRight />
                </Flex>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.rightSection}>
        <h3 className={styles.propertiesTitle}>{editingCampaign ? 'Edit Campaign' : 'Create Campaign'}</h3>
        {renderProperties()}
        <div className={styles.footerButtons}>
          <div className={styles.leftButton}>
            <Button variant='outline' onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <div className={styles.rightButtons}>
            <Button variant='outline' onClick={handleSaveSettings} disabled={isLoading || isSubmitting}>
              Save Settings
            </Button>
            <Button variant='solid' onClick={handlePublish} disabled={isLoading || isSubmitting}>
              Publish
            </Button>
          </div>
        </div>
      </div>
    </Flex>
  )
}

export default CampaignConfiguration
