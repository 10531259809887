import { Route, Routes } from 'react-router-dom'

import AnnouncementCreate from './AnnouncementCreate'
import AnnouncementEdit from './AnnouncementEdit'
import Announcements from './Announcements'

const AnnouncementsRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Announcements />} />
      <Route path='create' element={<AnnouncementCreate />} />
      <Route path=':id' index element={<AnnouncementEdit />} />
    </Routes>
  )
}

export default AnnouncementsRouter
