import { useSortable } from '@dnd-kit/sortable'
import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import styles from '../../ConfigModule.module.css'

import ArrowDownIcon from 'Src/assets/icons/arrow-down.svg'
import ArrowRightIcon from 'Src/assets/icons/arrow-right.svg'
import Check from 'Src/assets/icons/check.svg'
import CopyIcon from 'Src/assets/icons/new-copy.svg'
import Edit from 'Src/assetsv3/icons/edit.svg'
import KebabMenu from 'Src/assetsv3/icons/kebab-menu.svg'
import RemoveIcon from 'Src/assetsv3/icons/remove.svg'
import AddIcon from 'Src/assetsv3/icons/plus.svg'

import { getQuestionTypeLabel, QUESTION_TYPES } from '../../constants'
import { DragHandle } from '../UtilityComponents'
import { ChoiceContent, TextContent } from './QuestionContent'
import { Flex } from '@happyfoxinc/web-components'

export const QuestionBlock = ({
  questionNumber,
  question,
  onChange,
  onRemove,
  onDuplicate,
  onAddBelow,
  dragHandleProps,
  errors
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const [showTypeOptions, setShowTypeOptions] = useState(false)
  const [showAddBelowOptions, setShowAddBelowOptions] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const optionsRef = useRef(null)
  const typeOptionsRef = useRef(null)
  const addBelowOptionsRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false)
      }
      if (typeOptionsRef.current && !typeOptionsRef.current.contains(event.target)) {
        setShowTypeOptions(false)
      }
      if (addBelowOptionsRef.current && !addBelowOptionsRef.current.contains(event.target)) {
        setShowAddBelowOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleRequired = () => {
    onChange({
      ...question,
      isRequired: !question.isRequired
    })
  }

  const handleHeaderClick = (e) => {
    if (optionsRef.current && optionsRef.current.contains(e.target)) {
      return
    }
    setIsCollapsed(!isCollapsed)
  }

  const handleTypeChange = (newType) => {
    onChange({
      ...question,
      type: newType,
      options: newType !== 'open_ended' ? question.options || ['', '', ''] : undefined,
      isModified: true
    })
    setShowTypeOptions(false)
    setShowOptions(false)
    setIsCollapsed(false)
  }

  const handleAddQuestionBelow = (questionType) => {
    onAddBelow(questionType)
    setShowAddBelowOptions(false)
    setShowOptions(false)
  }

  return (
    <div
      id={`question-${question.id}`}
      className={cx(styles.questionBlock, {
        [styles.questionBlockCollapsed]: isCollapsed,
        [styles.newlyAdded]: question.id.startsWith('new-'),
        [styles.hasError]: errors
      })}
    >
      <div className={styles.stepNumber}>{questionNumber}</div>
      <div className={styles.questionHeader} onClick={handleHeaderClick}>
        <div className={styles.questionCollapsedHeader}>
          <DragHandle dragHandleProps={dragHandleProps} />
          <ArrowDownIcon
            className={cx(styles.collapseArrow, {
              [styles.collapsed]: isCollapsed
            })}
          />
          <div className={styles.questionCollapsedTitle}>
            {`Question ${questionNumber}`}
            <span className={styles.questionTypeLabel}>- {getQuestionTypeLabel(question.type)}</span>
            {!question.isRequired && <span className={styles.requiredStatus}>(Optional)</span>}
          </div>
        </div>
        <div className={styles.questionHeaderRight} ref={optionsRef}>
          <button className={styles.optionsButton} onClick={() => setShowOptions(!showOptions)}>
            <KebabMenu />
          </button>
          {showOptions && (
            <div className={styles.optionsDropdown} onClick={(e) => e.stopPropagation()}>
              <button
                className={cx(styles.optionsItem, styles.typeOption)}
                onMouseEnter={() => setShowTypeOptions(true)}
                onMouseLeave={() => setTimeout(() => {
                  if (!typeOptionsRef.current?.contains(document.activeElement)) {
                    setShowTypeOptions(false)
                  }
                }, 100)}
              >
                <Flex align='center' gap='8px'>
                  <Edit style={{ width: '12px', height: '12px' }} />
                  <span>Change Question Type</span>
                </Flex>
                <ArrowRightIcon style={{ width: '12px', height: '12px' }} />
                {showTypeOptions && (
                  <div className={styles.typeOptionsDropdown} ref={typeOptionsRef}>
                    {QUESTION_TYPES.map((type) => (
                      <button
                        key={type.key}
                        className={cx(styles.typeOptionItem, {
                          [styles.activeType]: question.type === type.key
                        })}
                        onClick={() => handleTypeChange(type.key)}
                      >
                        {type.label}
                      </button>
                    ))}
                  </div>
                )}
              </button>
              <button
                className={cx(styles.optionsItem, styles.typeOption)}
                onMouseEnter={() => setShowAddBelowOptions(true)}
                onMouseLeave={() => setTimeout(() => {
                  if (!addBelowOptionsRef.current?.contains(document.activeElement)) {
                    setShowAddBelowOptions(false)
                  }
                }, 100)}
              >
                <Flex align='center' gap='8px'>
                    <div className={styles.addIconContainer}> 
                      <AddIcon className={styles.addIcon}/>
                    </div>
                  <span>Add Question Below</span>
                </Flex>
                <ArrowRightIcon style={{ width: '12px', height: '12px' }} />
                {showAddBelowOptions && (
                  <div className={styles.typeOptionsDropdown} ref={addBelowOptionsRef}>
                    {QUESTION_TYPES.map((type) => (
                      <button
                        key={type.key}
                        className={styles.typeOptionItem}
                        onClick={() => handleAddQuestionBelow(type.key)}
                      >
                        {type.label}
                      </button>
                    ))}
                  </div>
                )}
              </button>
              <button className={styles.optionsItem} onClick={toggleRequired}>
                <Check style={{ width: '12px', height: '12px' }} />
                {question.isRequired ? 'Mark as Optional' : 'Mark as Required'}
              </button>
              <button
                className={styles.optionsItem}
                onClick={() => {
                  onDuplicate()
                  setShowOptions(false)
                }}
              >
                <CopyIcon style={{ width: '12px', height: '12px' }} />
                Duplicate
              </button>
              <button
                className={styles.optionsItem}
                onClick={() => {
                  onRemove()
                  setShowOptions(false)
                }}
              >
                <RemoveIcon style={{ width: '12px', height: '12px' }} />
                Remove
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(styles.questionContent, {
          [styles.expanded]: !isCollapsed
        })}
      >
        {errors?.text && <div className={styles.errorMessage}>{errors.text}</div>}
        {question.type === 'open_ended' ? (
          <TextContent question={question} onChange={onChange} error={errors?.text} />
        ) : (
          <ChoiceContent question={question} onChange={onChange} error={errors?.choices} />
        )}
      </div>
    </div>
  )
}

export const SortableQuestionBlock = ({ id, isDragging, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: isSortableDragging
  } = useSortable({
    id,
    transition: {
      duration: 200,
      easing: 'ease'
    }
  })

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    position: 'relative',
    zIndex: isSortableDragging ? 2 : 1
  }

  return (
    <div ref={setNodeRef} {...attributes} style={style} className={styles.sortable}>
      {isDragging ? (
        <div className={styles.dragPlaceholder} />
      ) : (
        <QuestionBlock
          {...props}
          dragHandleProps={{ ...listeners }}
          className={cx(styles.questionBlock, {
            [styles.dragging]: isSortableDragging
          })}
        />
      )}
    </div>
  )
}
