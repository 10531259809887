import { Button, FormControl, FormErrorText, Input, Label, Switch, Text } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import _ from 'lodash'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from '../Announcements.module.scss'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import ReactSelect from 'Components/ReactSelect'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import TrixEditor from 'Components/TrixEditor'
import { ACCOUNT_TYPE } from 'Constants/account'
import { ANNOUNCEMENT_STATUS } from 'Constants/announcement-status'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import { APP_NOT_INSTALLED_MSG, MS_TEAMS_ID_KEY, SLACK_ID_KEY } from 'Constants/user-groups'
import api from 'Services/api'
import { Prompt } from 'Src/history'
import dayjs from 'Utils/dayjs-helper'
import parseErrorMessage from 'Utils/error-message-parser'

import validationSchema from './announcement-form-validation-schema'

const timeZone = dayjs.tz.guess()

const ALL_USERS = 'all_users'
const SPECIFIC_USERS = 'specific_users'
const USER_GROUPS = 'user_groups'

const FORM_TYPE = {
  BASIC: 'basic',
  ADVANCED: 'advanced'
}

const SEND_ANNOUNCEMENT_TO_OPTIONS = [
  { label: 'All Users', value: ALL_USERS },
  { label: 'Specific User group(s)', value: USER_GROUPS },
  { label: 'Specific Users', value: SPECIFIC_USERS }
]

const SEND_NOW = 'now'
const SCHEDULE_LATER = 'schedule_later'

const SCHEDULE_ANNOUNCEMENT_OPTIONS = [
  { label: 'Send Now', value: SEND_NOW },
  { label: 'Schedule later', value: SCHEDULE_LATER }
]

const getUserIdKey = (accountType) => {
  switch (accountType) {
    case ACCOUNT_TYPE.SLACK:
      return SLACK_ID_KEY
    case ACCOUNT_TYPE.MS_TEAMS:
      return MS_TEAMS_ID_KEY
    default:
      return null
  }
}

const AnnouncementFormHandler = ({ announcement }) => {
  const formMethods = useForm({
    defaultValues: {
      form_type: FORM_TYPE.BASIC,
      title: announcement.title,
      content: announcement.content,
      send_to: announcement.send_to
        ? SEND_ANNOUNCEMENT_TO_OPTIONS.find(({ value }) => value === announcement.send_to)
        : null,
      users: announcement?.users || null,
      user_groups: announcement?.user_groups || null,
      schedule_preference: announcement.schedule_preference
        ? SCHEDULE_ANNOUNCEMENT_OPTIONS.find(({ value }) => value === announcement.schedule_preference)
        : null,
      scheduled_on: announcement.scheduled_on
        ? dayjs(announcement.scheduled_on).tz(timeZone).format('YYYY-MM-DDTHH:mm')
        : '',
      user_acknowledgement_required: announcement.user_acknowledgement_required,
      acknowledgement_configuration: {
        button_label: announcement.acknowledgement_configuration?.button_label || 'Acknowledge'
      }
    },
    resolver: yupResolver(validationSchema)
  })
  const {
    control,
    setError,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitted, errors, isDirty },
    getValues,
    setValue,
    watch
  } = formMethods

  const sendTo = watch('send_to')
  const schedulePreference = watch('schedule_preference')
  const userAcknowledgementRequired = watch('user_acknowledgement_required')

  const isEdit = useMemo(() => announcement.id !== null, [announcement.id])

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const navigate = useNavigate()

  const [getUsers, getUsersResult] = api.useLazyGetUsersQuery()
  const [getUserGroups, getUserGroupsResult] = api.useLazyGetUserGroupsQuery()

  const [sendToSelf] = api.useSendAnnouncementToSelfMutation()
  const [create, createResult] = api.useCreateAnnouncementMutation()
  const [update, updateResult] = api.useUpdateAnnouncementMutation()

  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS

  const _prepareData = useCallback(
    (data) => {
      let payload = { ...data, send_to: data.send_to?.value, schedule_preference: data.schedule_preference?.value }

      if (payload.send_to === SPECIFIC_USERS) {
        payload.users = data.users
          .map((user) => {
            return user[getUserIdKey(accountType)]
          })
          .filter(Boolean)
        payload = _.omit(payload, ['user_groups'])
      } else if (payload.send_to === USER_GROUPS) {
        payload.user_groups = data.user_groups.map(({ id }) => id)
        payload.users = []
      } else {
        payload = _.omit(payload, ['user_groups'])
        payload.users = []
      }

      if (payload.schedule_preference === SCHEDULE_LATER) {
        payload.scheduled_on = dayjs(payload.scheduled_on).tz(timeZone).format()
        payload.time_zone = dayjs().tz(timeZone).utcOffset()
      } else {
        payload.scheduled_on = null
      }

      payload = _.omit(payload, ['form_type'])
      return payload
    },
    [accountType]
  )

  const _validate_scheduled_on = useCallback(
    (payload) => {
      if (payload.scheduled_on && payload.scheduled_on < dayjs().tz(timeZone).format()) {
        setError('scheduled_on', {
          type: 'manual',
          message: 'Scheduled date and time should be in the future'
        })
        return false
      }
      return true
    },
    [setError]
  )

  const _getLoaderMessages = (is_scheduled, is_save) => {
    if (is_save) {
      return {
        loading: 'Saving the announcement...',
        success: 'Announcement saved successfully.',
        error: 'Unable to save the announcement.'
      }
    }
    return {
      loading: is_scheduled ? 'Scheduling the announcement...' : 'Sending the announcement...',
      success: is_scheduled ? 'Announcement scheduled successfully.' : 'Announcement sent successfully.',
      error: is_scheduled ? 'Unable to schedule the announcement.' : 'Unable to send the announcement.'
    }
  }

  const saveAnnouncement = useCallback(
    (data) => {
      const { loading, success, error } = _getLoaderMessages(
        data.schedule_preference === SCHEDULE_LATER,
        !data?.send_announcement
      )
      if (isEdit) {
        const promise = update({ id: announcement.id, ...data }).unwrap()
        toast.promise(promise, {
          loading,
          success,
          error: parseErrorMessage(error)
        })
      } else {
        data.status = ANNOUNCEMENT_STATUS.DRAFT
        const promise = create(data).unwrap()
        toast.promise(promise, {
          loading,
          success,
          error: parseErrorMessage(error)
        })
        promise.then((response) => navigate(`/announcements/edit/${response.id}`))
      }
    },
    [announcement.id, create, isEdit, update, navigate]
  )

  const handleSend = useCallback(() => {
    setIsConfirmationModalOpen(false)

    const payload = _prepareData(getValues())

    const is_valid = _validate_scheduled_on(payload)
    if (!is_valid) {
      reset(undefined, { keepValues: true, keepErrors: true })
      return
    }

    payload.send_announcement = true
    saveAnnouncement(payload)
  }, [getValues, reset, saveAnnouncement, _validate_scheduled_on, _prepareData])

  const handleSave = useCallback(
    (data) => {
      const payload = _prepareData(data)

      const is_valid = _validate_scheduled_on(payload)
      if (!is_valid) {
        reset(undefined, { keepValues: true, keepErrors: true })
        return
      }

      saveAnnouncement(payload)
    },
    [saveAnnouncement, reset, _validate_scheduled_on, _prepareData]
  )

  const handlePreview = useCallback(() => {
    const payload = {
      content: getValues('content')
    }
    const promise = sendToSelf(payload).unwrap()
    toast.promise(promise, {
      loading: 'Sending the announcement...',
      success: 'Announcement sent successfully.',
      error: parseErrorMessage('Unable to send the announcement.')
    })
  }, [sendToSelf, getValues])

  const disableButton = isSubmitting || createResult.isLoading || updateResult.isLoading

  const ButtonContainer = () => (
    <div className={styles.ButtonContainer}>
      <Button
        variant='primary'
        disabled={disableButton}
        onClick={() => {
          setValue('form_type', FORM_TYPE.ADVANCED)
          reset(undefined, { keepValues: true, keepErrors: false })
          handleSubmit(() => {
            if (sendTo?.value === SPECIFIC_USERS && !getValues('users').length) {
              toast.error('Select at least one user to send the announcement to.')
              return
            }

            setIsConfirmationModalOpen(true)
          })()
        }}
      >
        {schedulePreference?.value === SCHEDULE_LATER ? 'Schedule' : 'Send Now'}
      </Button>
      <Tooltip delayDuration={250}>
        <TooltipTrigger asChild className={styles.TooltipTrigger}>
          <SecondaryButton
            disabled={disableButton}
            onClick={() => {
              setValue('form_type', FORM_TYPE.BASIC)
              reset(undefined, { keepValues: true, keepErrors: false })
              handleSubmit(handleSave)()
            }}
          >
            Save
          </SecondaryButton>
        </TooltipTrigger>
        <TooltipContent side='top'>
          <Text variant='muted'>Save to keep as draft without sending it.</Text>
        </TooltipContent>
      </Tooltip>
      <Button className={styles.CancelButton} variant='link-muted' onClick={() => navigate('..')}>
        Cancel
      </Button>
    </div>
  )

  const ModalContainer = () => (
    <Fragment>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        message={CONFIRM_MESSAGES.SEND_ANNOUNCEMENT_TO(sendTo?.label)}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleSend}
        confirmButtonText='Yes, Proceed'
      />
    </Fragment>
  )

  const getUsersFieldOptionValue = (opt) => opt[getUserIdKey(accountType)]

  const getUsersFieldOptionLabel = (opt) => (opt.disabled ? `${opt.name} (${APP_NOT_INSTALLED_MSG})` : opt.name)

  const getUserOptions = (inputValue, cb) => {
    getUsers({ name: inputValue, accountType })
      .unwrap()
      .then((data) => {
        const options = data.map(({ is_app_installed, ...rest }) => ({
          disabled: isMsTeamsAccountType ? !is_app_installed : false,
          ...rest
        }))
        cb(options)
      })
  }

  const getUserGroupOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  return (
    <Fragment>
      <FormProvider {...formMethods}>
        <form>
          <FormControl isInvalid={errors.title}>
            <Label>Title</Label>
            <Controller
              control={control}
              name='title'
              render={({ field: { value, onChange } }) => <Input value={value} onChange={onChange} />}
            />
            {errors.title && <FormErrorText>{errors.title.message}</FormErrorText>}
          </FormControl>
          <FormControl isInvalid={errors.content}>
            <div className={styles.TrixEditorContainer}>
              <Label>Message</Label>
              <Controller
                control={control}
                name='content'
                render={({ field: { value, onChange } }) => <TrixEditor defaultValue={value} onChange={onChange} />}
              />
              <div
                className={cx(styles.PreviewContainer, {
                  [styles.Active]: !errors.content,
                  [styles.EditorError]: errors.content
                })}
              >
                <Text variant='muted'>Assist AI will DM this message to you on Slack</Text>
                <a className={styles.PreviewLink} onClick={handlePreview}>
                  Preview Message
                </a>
              </div>
              {errors.content && <FormErrorText>{errors.content.message}</FormErrorText>}
            </div>
          </FormControl>
          <div className={styles.AnnouncementFormContainer}>
            <Label className={styles.AnnouncementFormHeader}>Recipients</Label>
            <div className={styles.AnnouncementFormSection}>
              <FormControl className={styles.AnnouncementFormControl}>
                <Label>Send announcement to</Label>
                <Controller
                  name='send_to'
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      className={styles.SendAnnouncementTo}
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: errors?.send_to ? '#ff0000' : base.borderColor,
                          '&:hover': { borderColor: errors?.send_to ? '#ff0000' : base.borderColor },
                          boxShadow: errors?.send_to ? 'none' : base.boxShadow
                        })
                      }}
                      isSearchable={false}
                      options={SEND_ANNOUNCEMENT_TO_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(opt) => field.onChange(opt)}
                    />
                  )}
                />
                {errors.send_to && <FormErrorText>{errors.send_to.message}</FormErrorText>}
              </FormControl>
              {sendTo?.value === USER_GROUPS && (
                <FormControl className={styles.UsersGroupField}>
                  <Label>Select user group(s) for which the answer will be recommended</Label>
                  <Controller
                    name='user_groups'
                    control={control}
                    shouldUnregister
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: errors?.user_groups ? '#ff0000' : base.borderColor,
                            '&:hover': { borderColor: errors?.user_groups ? '#ff0000' : base.borderColor },
                            boxShadow: errors?.user_groups ? 'none' : base.boxShadow,
                            minHeight: '5rem',
                            alignItems: 'flex-start'
                          })
                        }}
                        className={styles.user_groups}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder='Search user groups'
                        isClearable={false}
                        isMulti
                        options={[]}
                        loadOptions={getUserGroupOptions}
                        isLoading={getUserGroupsResult.isLoading}
                        loadingMessage={({ inputValue }) => `Searching for sites matching "${inputValue}"...`}
                        noOptionsMessage={({ inputValue }) =>
                          inputValue.length < 3
                            ? 'Type at least 3 characters to start searching'
                            : `No sites found for input "${inputValue}"`
                        }
                      />
                    )}
                  />
                  {errors.user_groups && <FormErrorText>{errors.user_groups.message}</FormErrorText>}
                </FormControl>
              )}
              {sendTo?.value === SPECIFIC_USERS && (
                <FormControl className={styles.UsersField}>
                  <Label>Select users</Label>
                  <Controller
                    control={control}
                    name='users'
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: errors?.users ? '#ff0000' : base.borderColor,
                            '&:hover': { borderColor: errors?.users ? '#ff0000' : base.borderColor },
                            boxShadow: errors?.users ? 'none' : base.boxShadow,
                            minHeight: '5rem',
                            alignItems: 'flex-start'
                          })
                        }}
                        placeholder='Search users'
                        isClearable={false}
                        isMulti
                        options={[]}
                        getOptionLabel={getUsersFieldOptionLabel}
                        getOptionValue={getUsersFieldOptionValue}
                        loadOptions={getUserOptions}
                        isOptionDisabled={(opt) => opt.disabled}
                        isLoading={getUsersResult.isLoading}
                        loadingMessage={() => 'Searching for users...'}
                        minCharsBeforeLoadingOptions={2}
                        noOptionsMessage={({ inputValue }) =>
                          inputValue.length < 2
                            ? 'Type at least 2 characters to start searching'
                            : `No users found for input "${inputValue}"`
                        }
                        components={{ DropdownIndicator: null }}
                      />
                    )}
                  />
                  {errors.users && <FormErrorText>{errors.users.message}</FormErrorText>}
                </FormControl>
              )}
            </div>
          </div>
          <div className={styles.AnnouncementFormContainer}>
            <Label className={styles.AnnouncementFormHeader}>Schedule</Label>
            <div className={styles.AnnouncementFormSection}>
              <FormControl className={styles.AnnouncementFormControl}>
                <Label>Schedule preference</Label>
                <Controller
                  name='schedule_preference'
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      className={styles.SendOptionsDropdown}
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: errors?.schedule_preference ? '#ff0000' : base.borderColor,
                          '&:hover': { borderColor: errors?.schedule_preference ? '#ff0000' : base.borderColor },
                          boxShadow: errors?.schedule_preference ? 'none' : base.boxShadow
                        })
                      }}
                      isSearchable={false}
                      options={SCHEDULE_ANNOUNCEMENT_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(opt) => field.onChange(opt)}
                    />
                  )}
                />
                {errors.schedule_preference && <FormErrorText>{errors.schedule_preference.message}</FormErrorText>}
              </FormControl>
              {schedulePreference?.value === SCHEDULE_LATER && (
                <FormControl className={styles.AnnouncementFormControl}>
                  <Label>
                    Scheduled on <span className={styles.TimeZone}>(Time Zone: {timeZone})</span>
                  </Label>
                  <Controller
                    control={control}
                    name='scheduled_on'
                    render={({ field: { value, onChange } }) => (
                      <Input
                        type='datetime-local'
                        value={value}
                        min={dayjs().tz(timeZone).format('YYYY-MM-DDTHH:mm')}
                        max={dayjs().tz(timeZone).add(1, 'year').format('YYYY-MM-DDTHH:mm')}
                        onChange={(e) => onChange(e)}
                        placeholder='Select date and time'
                        className={cx(styles.ScheduledOnDatePicker, { [styles.DatePickerError]: errors.scheduled_on })}
                      />
                    )}
                  />
                  {errors.scheduled_on && <FormErrorText>{errors.scheduled_on.message}</FormErrorText>}
                </FormControl>
              )}
            </div>
          </div>
          <div className={styles.AnnouncementFormContainer}>
            <Label className={styles.AnnouncementFormHeader}>User Acknowledgement</Label>
            <div className={styles.AnnouncementFormSection}>
              <FormControl className={styles.AnnouncementFormControl}>
                <div className={styles.UserAcknowledgementWrapper}>
                  <Controller
                    control={control}
                    name='user_acknowledgement_required'
                    render={({ field: { value, onChange, ref } }) => (
                      <Switch checked={value} onCheckedChange={onChange} ref={ref} className={styles.AckSwitch} />
                    )}
                  />
                  <Label>Is user acknowledgement required?</Label>
                </div>
              </FormControl>
              {userAcknowledgementRequired && (
                <FormControl className={styles.AnnouncementFormControl}>
                  <Label>Label</Label>
                  <Controller
                    control={control}
                    name='acknowledgement_configuration.button_label'
                    render={({ field: { value, onChange } }) => (
                      <Input
                        placeholder='Enter text'
                        value={value}
                        onChange={onChange}
                        className={styles.AckButtonText}
                      />
                    )}
                  />
                </FormControl>
              )}
            </div>
          </div>
        </form>
        <ButtonContainer />
      </FormProvider>
      <ModalContainer />
      <Prompt when={!isSubmitted && isDirty} />
    </Fragment>
  )
}

const AnnouncementForm = ({ announcement = null }) => {
  const defaultValues = {
    id: null,
    title: '',
    content: '',
    send_to: null,
    users: null,
    user_groups: null,
    schedule_preference: null,
    scheduled_on: null,
    user_acknowledgement_required: false,
    acknowledgement_configuration: {
      button_label: 'Acknowledge'
    }
  }
  return <AnnouncementFormHandler announcement={announcement || defaultValues} />
}

export default AnnouncementForm
