import { baseApi, TAGS } from './baseApi'

export const surveyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query({
      query: (params) => ({
        url: 'v1/surveys',
        params
      }),
      providesTags: [TAGS.SURVEYS.LIST]
    }),

    getSurvey: builder.query({
      query: (id) => `v1/surveys/${id}`,
      providesTags: (id) => [{ type: TAGS.SURVEYS.DETAILS, id }]
    }),

    createSurvey: builder.mutation({
      query: (data) => ({
        url: 'v1/surveys',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SURVEYS.LIST]
    }),

    updateSurvey: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/surveys/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ({ id }) => [TAGS.SURVEYS.LIST, { type: TAGS.SURVEYS.DETAILS, id }]
    }),

    deleteSurvey: builder.mutation({
      query: (id) => ({
        url: `v1/surveys/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.SURVEYS.LIST]
    }),

    getQuestions: builder.query({
      query: (surveyId) => `v1/surveys/${surveyId}/questions`,
      providesTags: (surveyId) => [{ type: TAGS.SURVEYS.QUESTIONS, id: surveyId }]
    }),

    createQuestions: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/surveys/${id}/questions`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SURVEYS.QUESTIONS]
    }),

    updateQuestion: builder.mutation({
      query: ({ questionId, surveyId, data }) => ({
        url: `v1/surveys/${surveyId}/questions/${questionId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.SURVEYS.QUESTIONS]
    }),

    deleteQuestion: builder.mutation({
      query: ({ questionId, surveyId }) => ({
        url: `v1/surveys/${surveyId}/questions/${questionId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.SURVEYS.QUESTIONS]
    }),

    previewSurvey: builder.mutation({
      query: ({surveyId, data}) => ({
        url: `v1/surveys/${surveyId}/preview`,
        method: 'POST',
        body: data
      })
    }),
  })
})

export const {
  useGetSurveysQuery,
  useGetSurveyQuery,
  useCreateSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  useGetQuestionsQuery,
  useCreateQuestionsMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
  usePreviewSurveyMutation
} = surveyApi
