// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._Mvk1CjYLPBSLJTLfv4U{border-radius:0px 0px var(--border-radius-lg) var(--border-radius-lg)}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/AzureAD/AzureAD.module.css"],"names":[],"mappings":"AAAA,sBACE,qEAAA","sourcesContent":[".modalBody {\n  border-radius: 0px 0px var(--border-radius-lg) var(--border-radius-lg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "_Mvk1CjYLPBSLJTLfv4U"
};
export default ___CSS_LOADER_EXPORT___;
