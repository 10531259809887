import { Box, Button, Flex, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import styles from './InstallScreen.module.css'

import FormField from 'Src/componentsv3/FormField'

const InstallForm = ({ schema, fields, onSubmit, onCancel = () => {}, isLoading, isReconnect, defaultValues = {} }) => {
  const [appData, setAppData] = useState(null)

  useEffect(() => {
    if (isReconnect) {
      try {
        const storedApp = sessionStorage.getItem('reconnectApp')
        if (storedApp) {
          const parsedApp = JSON.parse(storedApp)
          setAppData(parsedApp)
        }
      } catch (error) {}
    }
  }, [isReconnect])

  const getFormDefaultValues = useCallback(() => {
    if (!appData || !fields) return defaultValues

    const mappedValues = { ...defaultValues }

    fields.forEach((field) => {
      if (appData[field.name] !== undefined) {
        mappedValues[field.name] = appData[field.name]
      }
    })

    return mappedValues
  }, [appData, fields, defaultValues])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: getFormDefaultValues()
  })

  useEffect(() => {
    if (appData) {
      reset(getFormDefaultValues())
    }
  }, [appData, getFormDefaultValues, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field) => (
        <FormField key={field.name}>
          <FormField.Field error={errors[field?.name]?.message} label={field.label}>
            <Box width='450px'>
              <TextField.Root
                {...register(field.name)}
                type={field.type}
                size='2'
                radius='small'
                placeholder={field.placeholder}
              />
            </Box>
          </FormField.Field>
        </FormField>
      ))}

      <Box width='450px'>
        <Flex align='center' justify='space-between' width='100%'>
          <Button type='submit' size='small' radius='medium' className={styles.submitButton} disabled={isLoading}>
            {isLoading ? (isReconnect ? 'Authorizing...' : 'Installing...') : isReconnect ? 'Authorize' : 'Install'}
          </Button>
          {isReconnect && (
            <Button
              type='button'
              size='small'
              radius='medium'
              variant='outline'
              onClick={() => {
                sessionStorage.removeItem('reconnectApp')
                onCancel()
              }}
            >
              Cancel
            </Button>
          )}
        </Flex>
      </Box>
    </form>
  )
}

export default InstallForm
