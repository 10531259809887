import keyMap from 'Constants/key-map'

import baseApi, { TAGS } from './baseApi'

const { OKTA, AZURE_AD } = keyMap

export const aiActionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAppsAIActions: builder.mutation({
      query: ({ appName, appId, payload }) => ({
        url: `/v1/apps/${appName}/ai-actions/${appId}`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: (_result, _error, { appName }) => {
        const tagMap = {
          [OKTA.ID]: [TAGS.OKTA.OKTA_AI_ACTIONS],
          [AZURE_AD.ID]: [TAGS.AZURE_AD.AZURE_AD_AI_ACTIONS]
        }
        return tagMap[appName] || []
      }
    })
  })
})

export const { useUpdateAppsAIActionsMutation } = aiActionsApi
