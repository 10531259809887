import { Box, Flex, Grid } from '@happyfoxinc/web-components'

import styles from './AppsList.module.css'
import skeletonStyles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

import WorkspaceSelect from 'Src/componentsv3/WorkspaceSelect'

import { AppCard } from '../../components/AppCard'
import { CategoryTabs } from '../../components/CategoryTabs'
import { useAppsData } from '../../hooks/useAppsData'

import { PAGES, APPS } from 'Src/pagesv3/constants'

const AppCardSkeleton = () => (
  <div className={skeletonStyles.card}>
    <div className={skeletonStyles.cardContent}>
      <div className={skeletonStyles.header}>
        <div className={skeletonStyles.logoWrapper}>
          <div className={skeletonStyles.logoSkeleton} />
        </div>

        <div className={skeletonStyles.titleSection}>
          <div className={`${skeletonStyles.shimmerBg} ${skeletonStyles.titleSkeleton}`} />
          <div className={`${skeletonStyles.shimmerBg} ${skeletonStyles.descriptionSkeleton}`} />
        </div>
      </div>

      <div className={skeletonStyles.body}>
        <div className={`${skeletonStyles.shimmerBg} ${skeletonStyles.accountTypeSkeleton}`} />
        <div className={`${skeletonStyles.shimmerBg} ${skeletonStyles.buttonSkeleton}`} />
      </div>
    </div>
  </div>
)

const AppsList = () => {
  const { apps, isLoading } = useAppsData()
  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <h1 className='heading'>{PAGES[APPS].title}</h1>
        <Flex width='100%' align='center' justify='space-between'>
          <p className='sub-heading'>{PAGES[APPS].description}</p>
          <div style={{ width: '200px' }}>
            <WorkspaceSelect />
          </div>
        </Flex>
      </div>

      <div className={styles.content}>
        <aside className={styles.sidebar}>
          <CategoryTabs />
        </aside>

        <main className={styles.main}>
          <Grid columns='repeat(auto-fill, minmax(260px, 1fr))' gap='20px' p='0 10px'>
            {isLoading
              ? Array(11)
                  .fill(0)
                  .map((_, index) => <AppCardSkeleton key={index} />)
              : apps.map((app) => <AppCard key={app.id} app={app} />)}
          </Grid>
        </main>
      </div>
    </Box>
  )
}

export default AppsList
