import { Fragment, memo } from 'react'
import { Flex } from '@happyfoxinc/web-components'
import { useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import styles from './Provisioning.module.css'

import StartFlagIcon from 'Src/assetsv3/icons/start-flag.svg'
import AddStepButton from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/AddStepButton'
import ProvisioningSkeleton from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/ProvisioningSkeleton'
import StepForm from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/StepForm'
import StepReadOnly from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/StepReadOnly'
import { useGetProvisioningMetaQuery } from 'Src/servicesV3/softwareAccessModuleApi'

const Provisioning = memo(({ app, addStep, updateStep, removeStep, insertStep, isLoading = false }) => {
  const { data: metadata = [], isSuccess, isFetching, isLoading: isMetadataLoading } = useGetProvisioningMetaQuery()

  const { watch } = useFormContext()
  const steps = watch('steps', [])

  if (isLoading || isMetadataLoading || isFetching || !steps) {
    return <ProvisioningSkeleton />
  }

  const handleAddStep = ({ stepName, position }) => {
    const step = metadata.find((data) => data.name === stepName)
    if (!step || !stepName) return

    const payload = {
      name: stepName,
      type: step?.step_type || '',
      label: step.label || '',
      description: step.description || '',
      fields: step.fields || {},
      info: step.info || null,
      isEdit: true,
      uniqueKey: uuidv4()
    }

    position === 'top' ? insertStep(0, payload) : addStep(payload)
  }

  return (
    <Fragment>
      {isSuccess && (
        <div className={styles.workflowContainer}>
          <div className={styles.startIconWrapper}>
            <StartFlagIcon />
          </div>
          <div className={`${styles.stepLine} ${styles.firstLine}`} />
          <AddStepButton
            key='add-step-button-top'
            position='top'
            metadata={metadata}
            onAddStep={(data) => handleAddStep(data)}
          />
          <div className={`${styles.stepLine} ${styles.secondLine}`} />
          {steps.map((step, index) => (
            <div key={step.uniqueKey || `step-${index}`} id={`step-${index}`} className={styles.stepWrapper}>
              <Flex gap='16px' align='flex-start'>
                <div className={styles.stepNumber}>{index + 1}</div>
                {step.isEdit ? (
                  <StepForm
                    key={step.uniqueKey}
                    app={app}
                    step={step}
                    index={index}
                    onDelete={() => removeStep(index)}
                  />
                ) : (
                  <StepReadOnly
                    key={step.uniqueKey}
                    app={app}
                    step={step}
                    index={index}
                    updateStep={updateStep}
                    onDelete={() => removeStep(index)}
                  />
                )}
              </Flex>
            </div>
          ))}
          <AddStepButton
            key='add-step-button-bottom'
            position='bottom'
            metadata={metadata}
            onAddStep={(data) => handleAddStep(data)}
          />
        </div>
      )}
    </Fragment>
  )
})

export default Provisioning
