import { Navigate, useParams } from 'react-router-dom'

import styles from './Announcements.module.scss'

import PageLoader from 'Components/PageLoader'
import PageTitle from 'Components/PageTitle'
import api from 'Services/api'

import AnnouncementForm from './AnnouncementForm'
import TipsContent from './TipsContent'

const Edit = () => {
  const { id } = useParams()

  const { isLoading, data } = api.useGetAnnouncementByIdQuery(id)
  if (isLoading) {
    return <PageLoader />
  }

  if (!id) {
    return <Navigate to='..' replace />
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.MainContent}>
          <div className={styles.FormContainer}>
            <div className={styles.PageTitle}>
              <PageTitle backTo='..'>Announcements</PageTitle>
            </div>
            <AnnouncementForm announcement={data} />
          </div>
        </div>
        <div className={styles.SidebarContent}>
          <TipsContent />
        </div>
      </div>
    </div>
  )
}

export default Edit
