export const DEFAULT_TICKET_SUBJECT_MERGE_FIELD = 'message'
export const TICKET_SUBJECT_MERGE_FIELDS = [DEFAULT_TICKET_SUBJECT_MERGE_FIELD, 'requester.name', 'requester.email']

export const ANSWER_CONTENT_MERGE_FIELDS = ['requester.name', 'requester.email']

export const ANNOUNCEMENT_CONTENT_MERGE_FIELDS = ['requester.name', 'requester.email']

export const AI_ACTION_MERGE_FIELDS = ['requester.name', 'requester.email']

export const SOFTWARE_ACCESS_MERGE_FIELDS = ['app.name', 'requester.name', 'requester.email']
