import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import toast from 'react-hot-toast'

import { useDeleteAnnouncementMutation } from 'Src/servicesV3/announcementsApi'

const DeleteModal = ({ id, data, onClose, ...rest }) => {
  const [deleteAnnouncement, deleteAnnouncementApiResults] = useDeleteAnnouncementMutation()
  const navigate = useNavigate()

  const handleDelete = useCallback(() => {
    const promise = deleteAnnouncement(id).unwrap()

    toast.promise(promise, {
      loading: 'Deleting announcement',
      success: `Deleted announcement '${data.title}' successfully...`,
      error: `Error while deleting announcement '${data.title}'. Try again.`
    })
    onClose()
    navigate('..')
  }, [id, data, deleteAnnouncement, onClose])

  return (
    <Modal size='small' {...rest}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this announcement?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteAnnouncementApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteAnnouncementApiResults.isLoading} onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default DeleteModal
