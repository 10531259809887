import { Button, Flex, Modal, Pagination, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import cx from 'classnames'
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import styles from './KnowledgeConfiguration.module.scss'

import AlertIcon from 'Icons/alert.svg'
import ArrowRightIcon from 'Icons/arrow-right.svg'

import { KNOWLEDGE_GROUP_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import UserGroupBadge from 'Src/components/UserGroupBadge'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import {
  responseGroupApi,
  useGetResponseGroupChildrenQuery,
  useGetResponseGroupQuery,
  useGetResponseGroupsQuery,
  useUpdateResponseGroupMutation
} from 'Src/servicesV3/responseGroupApi'
import { useGetUserGroupsQuery } from 'Src/servicesV3/userGroupsApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import useInfinitePageData from 'Src/utils/hooks/useInfinitePageData'
import { canGoToNextPage, canGoToPreviousPage, getPaginationQueryObject } from 'Src/utils/pagination'
import { usePlanContext } from 'Src/utilsV3/hooks/usePlanContext'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { useAppDetailContext } from '../../hooks/AppDetailContext'
import KBZeroIcon from './KB_Zero.svg'
import KnowledgeConfigurationSkeleton, { ResponseGroupChildrenSkeleton } from './KnowledgeConfigurationSkeleton'

export const Accordion = AccordionPrimitive.Root
export const AccordionItem = AccordionPrimitive.Item
const AccordionHeader = AccordionPrimitive.Header
export const AccordionTrigger = forwardRef((props, ref) => {
  return <AccordionPrimitive.Trigger {...props} className={cx(styles.accordionTrigger, props.className)} ref={ref} />
})
AccordionTrigger.displayName = 'AccordionTrigger'
export const AccordionContent = AccordionPrimitive.Content

const ITEMS_PER_PAGE = 15

const userGroupsError = 'Please select at least 1 user group'

const knowledgeConfigurationValidationSchema = yup
  .object()
  .shape({
    visibility: yup.string().oneOf(Object.values(KNOWLEDGE_GROUP_VISIBILITY_STATES)).required(),
    userGroups: yup.array().when('visibility', {
      is: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC,
      then: (schema) => schema.min(1, userGroupsError).required(userGroupsError)
    })
  })
  .required()

const VisibilityModal = ({ responseGroup, onClose, hasUserGroups, workspaceId }) => {
  const navigate = useNavigate()
  const { id: appName } = useAppDetailContext()
  const selectPortalRef = useRef()

  const [forceUpdateChildren, setForceUpdateChildren] = useState(false)

  const {
    visibility,
    possible_visibilities: possibleVisibilities,
    mapped_user_groups: mappedUserGroups,
    user_groups_available_to_map: userGroupsAvailableToMap,
    title,
    id: responseGroupId
  } = responseGroup

  const [updateResponseGroup, { isLoading }] = useUpdateResponseGroupMutation()

  const [checkResponseGroupVisibilityUpdate, checkResponseGroupVisibilityUpdateResult] =
    responseGroupApi.useLazyCheckResponseGroupVisibilityUpdateQuery()

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      visibility,
      userGroups: mappedUserGroups
    },
    resolver: yupResolver(knowledgeConfigurationValidationSchema)
  })

  const visibilityStatuses = useMemo(() => {
    return KNOWLEDGE_GROUP_VISIBILITY_OPTIONS.filter((status) => {
      return possibleVisibilities?.includes(status.value)
    })
  }, [possibleVisibilities])

  const handleFormSubmit = (data) => {
    const payload = {
      appName,
      id: responseGroupId,
      workspace_id: workspaceId,
      visibility: data.visibility
    }

    if (data.userGroups) {
      payload.mapped_user_groups = data.userGroups.map((usergroup) => usergroup.id)
    }

    const promise = updateResponseGroup(payload).unwrap()

    promise.then(() => onClose())
    toast.promise(promise, {
      loading: 'Updating user group visibility',
      success: 'Visibility status updated successfully',
      error: parseErrorMessage('Failed to update visibility status. Try again...')
    })
  }

  const handleCreateUserGroupClick = () => {
    navigate('/user-groups/create')
  }

  const visibilityStatus = watch('visibility')

  const disableSaveButton = isSubmitting || isLoading || checkResponseGroupVisibilityUpdateResult.isLoading
  const showCreateUseGroupOption = visibilityStatus === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && !hasUserGroups

  const submitButtonText = forceUpdateChildren ? 'Proceed' : 'Save'

  const handleVisibilityUpdate = useCallback(
    ({ visibility, userGroups }) => {
      setForceUpdateChildren(false)

      const checkVisibilityUpdateAPI = async () => {
        const params = {
          appName,
          id: responseGroupId,
          visibility
        }

        if (visibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC) {
          if (!userGroups) {
            return
          }

          const mappedUserGroups = userGroups.map((group) => group.id)
          if (mappedUserGroups.length === 0) {
            return
          }
          params.mapped_user_groups = mappedUserGroups.toString()
        }

        try {
          const result = await checkResponseGroupVisibilityUpdate(params).unwrap()
          setForceUpdateChildren(result.will_force_update_children)
        } catch {}
      }

      checkVisibilityUpdateAPI()
    },
    [appName, checkResponseGroupVisibilityUpdate, responseGroupId]
  )

  useEffect(() => {
    const subscription = watch((formValue, { type }) => {
      if (type === 'change') {
        handleVisibilityUpdate(formValue)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleVisibilityUpdate])

  return (
    <Fragment>
      <div ref={selectPortalRef} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormField>
          <FormField.Field error={errors.visibility?.message} label={`${title} Visible to`}>
            <Controller
              name='visibility'
              control={control}
              shouldUnregister
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    options={visibilityStatuses}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    value={visibilityStatuses.find(({ value }) => value === field.value)}
                    onChange={({ value }) => field.onChange(value)}
                    inDialog
                  />
                )
              }}
            />
          </FormField.Field>
        </FormField>
        {showCreateUseGroupOption && (
          <p className={styles.warningText}>
            Hey! Looks like you haven't created any user groups yet. Please create a user group and then set visibility
            for the added knowledge source.
          </p>
        )}

        {visibilityStatus === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && !showCreateUseGroupOption && (
          <FormField>
            <FormField.Field error={errors.userGroups?.message} label='Select user group(s)'>
              <Controller
                name='userGroups'
                control={control}
                shouldUnregister
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isMulti
                      isClearable={false}
                      options={userGroupsAvailableToMap}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      inDialog
                    />
                  )
                }}
              />
            </FormField.Field>
          </FormField>
        )}

        {forceUpdateChildren && (
          <div className={styles.visibilityAlert}>
            <div className={styles.alertIconContainer}>
              <AlertIcon />
            </div>
            <p className={styles.alertTextContainer} variant='muted'>
              Restricting the visibility on this level might affect the visibility of a few articles or sections under
              this section. Would you still like to proceed ?
            </p>
          </div>
        )}

        <Flex align='flex-start' gap='6px'>
          {showCreateUseGroupOption && (
            <Button variant='solid' onClick={handleCreateUserGroupClick}>
              Create User Groups
            </Button>
          )}
          {!showCreateUseGroupOption && (
            <Button type='submit' variant='solid' disabled={disableSaveButton}>
              {submitButtonText}
            </Button>
          )}
          <Button
            type='button'
            variant='ghost'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onClose()
            }}
          >
            Cancel
          </Button>
        </Flex>
      </form>
    </Fragment>
  )
}

const VisibilityModalContainer = ({ isOpen, id, onClose, onAfterSave }) => {
  const { id: appName } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()
  const { data: account } = useGetAccountQuery()
  const accountType = account?.account_type

  const { data = {}, isLoading } = useGetResponseGroupQuery({ appName, id, workspaceId: currentWorkspaceId })
  const { data: userGroups = {}, isLoading: userGroupsLoading } = useGetUserGroupsQuery({
    accountType
  })

  const hasUserGroups = userGroups?.results?.length > 0

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOpenChange={onClose}
      title='Visibility Configuration'
      isLoading={isLoading || userGroupsLoading}
      showFooter={false}
      showCloseButton={false}
      bodyClassName={styles.modalBody}
    >
      <VisibilityModal
        responseGroup={data}
        onClose={onClose}
        onAfterSave={onAfterSave}
        hasUserGroups={hasUserGroups}
        workspaceId={currentWorkspaceId}
      />
    </Modal>
  )
}

const ResponseGroupChildren = (props) => {
  const { id: appName } = useAppDetailContext()
  const [currentPage, setCurrentPage] = useState(1)
  const limit = 15

  const { data = {}, isLoading } = useGetResponseGroupChildrenQuery(
    {
      appName,
      responseGroupId: props.id,
      params: {
        page: currentPage,
        limit
      }
    },
    { refetchOnMountOrArgChange: true }
  )

  const items = useInfinitePageData(data)

  const handleLoadMore = useCallback(() => {
    setCurrentPage((previousPage) => previousPage + 1)
  }, [])

  const canShowLoadmore = canGoToNextPage(currentPage, data.meta?.total, limit)

  if (isLoading) {
    return <ResponseGroupChildrenSkeleton depth={props.depth} />
  }

  return (
    <Fragment>
      {items.map((item) => (
        <ResponseGroup key={item.id} {...item} depth={props.depth} />
      ))}
      {canShowLoadmore && (
        <Button variant='ghost' onClick={handleLoadMore} className={styles.loadMoreButton}>
          Load More
        </Button>
      )}
    </Fragment>
  )
}

const ResponseGroup = (props) => {
  const depth = props.depth ?? 0

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isFreePlan } = usePlanContext()

  const handleSetVisibilityClick = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const showOptions = !isFreePlan && props.parent_visibility !== KNOWLEDGE_GROUP_VISIBILITY_STATES.NONE

  return (
    <Fragment>
      <Accordion type='single' collapsible className={cx(styles.accordion, { [styles.accordionRoot]: depth === 0 })}>
        <AccordionItem value={props.id}>
          <AccordionHeader className={styles.accordionHeader}>
            <AccordionTrigger className={cx({ [styles.hidden]: !props.has_children })}>
              <span className={styles.accordionTriggerIcon}>
                <ArrowRightIcon width='1em' height='1em' />
              </span>
            </AccordionTrigger>
            <div className={styles.contentTitle}>{props.title}</div>
            <div>
              <UserGroupBadge {...props} />
            </div>
            <div className={styles.tableOptionsContainer}>
              {showOptions && (
                <TableOptions triggerClassName={styles.tableOptions} className={styles.dropdownOption}>
                  <TableOption onClick={handleSetVisibilityClick}>Set visibility</TableOption>
                </TableOptions>
              )}
            </div>
          </AccordionHeader>
          <AccordionContent className={styles.accordionContent}>
            <ResponseGroupChildren id={props.id} depth={depth + 1} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <VisibilityModalContainer isOpen={isModalOpen} id={props.id} onClose={handleModalClose} />
    </Fragment>
  )
}

const KnowledgeConfiguration = ({ app, additionalTableOptions }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { id: appName, title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()
  const { data = {}, isLoading } = useGetResponseGroupsQuery({
    appName,
    params: {
      ...getPaginationQueryObject({ currentPage, itemsPerPage: ITEMS_PER_PAGE }),
      ...(appName === 'sharepoint' || appName === 'gitbook' ? { workspace_id: currentWorkspaceId } : {})
    }
  })

  if (isLoading) {
    return <KnowledgeConfigurationSkeleton />
  }

  const { results: responseGroups = [], meta: paginationDetails = {} } = data

  const handlePreviousPageClick = () => {
    setCurrentPage((currentValue) => currentValue - 1)
  }

  const handleNextPageClick = () => {
    setCurrentPage((currentValue) => currentValue + 1)
  }

  if (responseGroups.length === 0) {
    return (
      <div className={styles.zeroState}>
        <KBZeroIcon />
        <p className={styles.zeroStateText}>
          Hey! It looks like you don't have any pages from <br />
          <span className={styles.zeroStateTitle}>{title} KnowledgeBase</span>
        </p>
      </div>
    )
  }

  return (
    <Fragment>
      <div>
        <Flex justify='space-between' className={styles.tableActionContainer}>
          <h2 className={styles.heading}>Synced Articles ({app.total_synced_articles})</h2>
          <Pagination
            currentPage={currentPage}
            pageSize={ITEMS_PER_PAGE}
            totalItems={paginationDetails.total}
            canPreviousPage={canGoToPreviousPage(currentPage)}
            canNextPage={canGoToNextPage(currentPage, paginationDetails.total, ITEMS_PER_PAGE)}
            previousPage={handlePreviousPageClick}
            nextPage={handleNextPageClick}
          />
        </Flex>
        <div className={styles.tableContainer} role='table'>
          <div className={styles.tableHeader}>
            <div>Sections</div>
            <div>Visibility</div>
            <div />
          </div>
          <div className={styles.tableBody}>
            {responseGroups.map((res) => {
              return <ResponseGroup key={res.id} {...res} depth={0} />
            })}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default KnowledgeConfiguration
