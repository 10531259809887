import { Button, Flex } from '@happyfoxinc/web-components'
import { useNavigate } from 'react-router-dom'

import styles from './AppCard.module.css'

export const AppCard = ({ app }) => {
  const navigate = useNavigate()
  const { id, title, logo: Logo, status, description, accountType = 'Single Account', planCategory } = app

  const handleCardClick = () => {
    navigate(`/apps/${id}`)
  }
  const isLinked = status === 'linked'
  const showPremiumBadge = !isLinked && planCategory === 'premium'

  return (
    <div className={styles.card} onClick={handleCardClick}>
      <div className={styles.cardContent}>
        <div className={styles.header}>
          <div className={styles.logoWrapper}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.titleSection}>
            <h3 className={styles.title}>{title}</h3>
            <span className={styles.placeholder}>{description}</span>
          </div>
        </div>

        <div className={styles.body}>
          {showPremiumBadge ? (
            <div className={styles.premiumBadge}>Premium</div>
          ) : (
            <span className={styles.accountType}>{accountType}</span>
          )}
          {status === 'linked' ? (
            <Flex gap='4px' align='center' className={styles.status}>
              Connected
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                width='16px'
                height='16px'
                baseProfile='basic'
              >
                <path fill='#2583ef' d='M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z' />
                <polygon fill='#fff' points='7,12 3.48,8.48 4.894,7.066 7,9.172 11.71,4.462 13.124,5.876' />
              </svg>
            </Flex>
          ) : (
            <Button variant='outline' size='small' radius='medium' className={styles.installButton}>
              Install
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
