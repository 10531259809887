import { Box, Flex, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import _ from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './Channels.module.css'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Src/componentsv3/Tooltip'
import KB_LINKS from 'Constants/kb-links'
import SearchIcon from 'Src/assetsv3/icons/search.svg'
import WarningIcon from 'Src/assetsv3/icons/warning.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetSlackChannelsQuery } from 'Src/servicesV3/channelsApi'
import debounce from 'Src/utils/debounce'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { getInitialApiQueryParams, getInitialParams, getModeCellValue } from './channels-helper'

import TipToAddInChannels from './TipToAddInChannels'

import { PAGES, CHANNELS } from 'Src/pagesv3/constants'

const AlertToolTip = ({ message }) => {
  return (
    <Tooltip delayDuration={150}>
      <TooltipTrigger className={styles.alertToolTip}>
        <WarningIcon className={styles.alertIcon} />
      </TooltipTrigger>
      <TooltipContent className={styles.tooltipContent}>
        <span variant='muted'>{message}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const ChannelsList = () => {
  const [, setSearchParams] = useSearchParams()

  const initialParams = useMemo(getInitialParams, [])
  const { workspaces } = useWorkspace()

  const navigate = useNavigate()

  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams)
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const { data: accountApiResponse } = useGetAccountQuery()
  const { data: channelsApiResponse = {}, isLoading: isChannelApiLoading } = useGetSlackChannelsQuery(queryParams)

  const isTicketingConfigured = accountApiResponse?.is_ticketing_integration_configured
  const isWorkspacesEnabled = accountApiResponse?.is_workspaces_enabled
  const { results: channels = [], meta: paginationDetails = {} } = channelsApiResponse

  const canEditChannel = isWorkspacesEnabled || isTicketingConfigured

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }) => (
          <Flex gap='3px'>
            <span>{_.startCase(value)}</span>
            {row.original.workspace_id === null && <AlertToolTip message={`Workspace isn't configured`} />}
          </Flex>
        )
      },
      {
        Header: 'Show ticket info publicly',
        accessor: 'sync_to_channel_thread',
        Cell: ({ cell: { value } }) => (value ? 'Yes' : 'No')
      },
      {
        Header: 'Mode',
        accessor: (row) => row,
        Cell: ({ cell: { value } }) => getModeCellValue(value)
      }
    ]

    if (isWorkspacesEnabled) {
      baseColumns.splice(1, 0, {
        Header: 'Workspace',
        accessor: 'workspace_id',
        Cell: ({ value }) => {
          const workspace = workspaces.find((w) => w.id === value)
          return workspace ? workspace.name : '-'
        }
      })
    }

    return baseColumns
  }, [isWorkspacesEnabled, workspaces])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: channels,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, searchText, sortBy, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  if (isChannelApiLoading) {
    return <TableLoader rows={5} columns={4} showOptions={false} />
  }

  return (
    <Fragment>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <div className='search-container'>
          <div className='search-wrapper'>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              className='search-input'
              placeholder='Search Channels'
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className='no-records-found'>
                No data available
              </Table.Cell>
            </Table.Row>
          )}
          {rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell
                        key={key}
                        {...restCellProps}
                        onClick={() => navigate(row.original.channel_id, { state: row.original })}
                        style={{ cursor: 'pointer' }}
                      >
                        <Flex align='center' gap='5px'>
                          {cell.render('Cell')}
                          {cell.value?.convert_messages === 'automatic' && (
                            <AlertToolTip
                              message='To enable automatic ticket creation, ensure your ticket fields in Helpdesk are not mandatory (Name, Email,
  Status, and Priority can remain mandatory).'
                            />
                          )}
                        </Flex>
                      </Table.Cell>
                    )
                  })}
                  {canEditChannel ? (
                    <TableOptions>
                      <TableOption onClick={() => navigate(row.original.channel_id, { state: row.original })}>
                        Edit
                      </TableOption>
                    </TableOptions>
                  ) : (
                    <Table.Cell />
                  )}
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table.Root>
    </Fragment>
  )
}

const Channels = () => {
  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <h1 className='heading'>{PAGES[CHANNELS].title}</h1>
        <p className='sub-heading'>
          {PAGES[CHANNELS].description.split('{{here}}')[0]}
          <a className={styles.link} href={KB_LINKS.SLACK_CHANNEL_CONFIGURATION} target='_blank' rel='noreferrer'>
            here
          </a>
          {PAGES[CHANNELS].description.split('{{here}}')[1]}
          <span>
            &#160;
            <TipToAddInChannels triggerText='Quick Tip' /> for adding Assist AI to Slack channels.
          </span>
        </p>
      </div>
      <ChannelsList />
    </Box>
  )
}

export default Channels
