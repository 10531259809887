import { Box, Button, Flex } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import styles from './QuickActions.module.css'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { agentsApi } from 'Src/servicesV3/agentsApi'
import { useUpdateQuickActionsMutation } from 'Src/servicesV3/oktaApi'
import parseErrorMessage from 'Src/utils/error-message-parser'

import { useAppDetailContext } from '../../hooks/AppDetailContext'

const quickActionsConfigValidationSchema = yup.object().shape({
  enabled: yup.boolean(),
  agents: yup.array().when('enabled', {
    is: true,
    then: (schema) => schema.min(1, 'No agents selected').required()
  })
})

const QuickActionsConfig = ({ data }) => {
  const formMethods = useForm({
    defaultValues: {
      enabled: data.enabled,
      agents: data.agents
    },
    resolver: yupResolver(quickActionsConfigValidationSchema)
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    resetField,
    reset,
    formState: { isDirty, errors }
  } = formMethods

  const enabled = watch('enabled')
  const { title: appTitle, id: appName } = useAppDetailContext()

  const [getAgents, { isLoading: isAgentsLoading }] = agentsApi.useLazyGetAgentsQuery()
  const [updateQuickActions, { isLoading: isUpdateLoading }] = useUpdateQuickActionsMutation()

  const loadAgentOptions = (inputValue, cb) => {
    getAgents({ name: inputValue })
      .unwrap()
      .then((data) => {
        const options = data.map(({ user_profile_id: id, name, email }) => ({ id, name, email }))
        cb(options)
      })
  }

  const handleAgentsReset = useCallback(() => {
    resetField('agents')
  }, [resetField])

  const handleEnabledStatusToggle = useCallback(
    ({ enabled: status }) => {
      handleAgentsReset()

      const promise = updateQuickActions({ app_id: appName, enabled: status }).unwrap()

      toast.promise(promise, {
        loading: `${status ? 'Enabling' : 'Disabling'} quick actions for ${appTitle}...`,
        success: `Quick actions for ${appTitle} ${status ? 'enabled' : 'disabled'} successfully`,
        error: parseErrorMessage(
          `Unable to ${status ? 'enable' : 'disable'} quick actions for ${appTitle}. Try again...`
        )
      })

      promise.catch(() => setValue('enabled', !status))
    },
    [appName, appTitle, handleAgentsReset, setValue, updateQuickActions]
  )

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      if (name === 'enabled' && type === 'change') {
        handleEnabledStatusToggle(formValue)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleEnabledStatusToggle])

  const handleAgentsUpdate = ({ agents }) => {
    const agentIds = agents.map(({ id }) => id)

    const data = {
      agents: agentIds,
      enabled: true
    }

    const promise = updateQuickActions({ app_id: appName, ...data }).unwrap()

    toast.promise(promise, {
      loading: `Updating agents for ${appTitle}...`,
      success: `Agents for ${appTitle} updated successfully`,
      error: parseErrorMessage(`Unable to update agents for ${appTitle}. Try again...`)
    })

    promise.then((data) => reset(data)).catch(() => {})
  }

  const formatAgentsOptionLabel = ({ name, email }, { context }) => {
    if (context === 'menu') {
      return `${name} (${email})`
    }
    return name
  }

  const ButtonContainer = () => {
    const primaryButtonText = data?.agents?.length > 0 ? 'Update' : 'Save'
    const disableButton = isUpdateLoading

    return (
      <Flex gap='15px'>
        <Button
          variant='solid'
          type='submit'
          disabled={disableButton || !isDirty}
          onClick={handleSubmit(handleAgentsUpdate)}
        >
          {primaryButtonText}
        </Button>
        <Button variant='outline' disabled={disableButton} onClick={handleAgentsReset}>
          Cancel
        </Button>
      </Flex>
    )
  }

  return (
    <div className={styles.actionConfigContainer}>
      <div className={styles.actionConfigHeader}>
        <Flex gap='15px' align='center'>
          <Controller
            name='enabled'
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <label className={styles.switch}>
                  <input type='checkbox' checked={value} onChange={onChange} />
                  <span className={styles.slider} />
                </label>
              )
            }}
          />

          <p className={styles.switchInputLabel}>Grant app action access</p>
        </Flex>
      </div>
      {enabled && (
        <div className={styles.actionConfigBody}>
          <FormField>
            <FormField.Field label='Select Agents' error={errors?.agents?.message}>
              <Controller
                name='agents'
                control={control}
                render={({ field }) => (
                  <Box width='650px'>
                    <ReactSelect
                      {...field}
                      formatOptionLabel={formatAgentsOptionLabel}
                      getOptionValue={({ id }) => id}
                      placeholder='Type to search'
                      isClearable={false}
                      isMulti
                      options={[]}
                      loadOptions={loadAgentOptions}
                      isLoading={isAgentsLoading}
                      loadingMessage={() => 'Searching for agents...'}
                      minCharsBeforeLoadingOptions={2}
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length < 2) {
                          return 'Type alteast 2 characters to start searching'
                        }
                        return `No agents found for input "${inputValue}"`
                      }}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </FormField>
          <ButtonContainer />
        </div>
      )}
    </div>
  )
}

export default QuickActionsConfig
