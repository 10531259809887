import { Button, Flex, Modal, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'

import styles from './Notion.module.css'

import { useGetNotionSyncedPagesQuery, useRemoveNotionPageMutation } from 'Src/servicesV3/notionApi'
import debounce from 'Src/utils/debounce'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { NotionPagesSkeleton } from './NotionSkeleton'

const PAGE_SIZE = 15
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}

const DeleteModal = (props) => {
  const { id, data, workspace_id, ...restProps } = props
  const [removePage, removePageResult] = useRemoveNotionPageMutation()

  const handleRemovePage = useCallback(() => {
    const toastId = toast.loading('Removing page...')
    const promise = removePage({ page_id: id, workspace_id }).unwrap()
    promise
      .then(() => {
        toast.dismiss(toastId)
        toast.success('Page removed successfully.')
        props.onClose()
      })
      .catch(() => {
        toast.dismiss(toastId)
        toast.error('Unable to remove page. Try again...')
      })
  }, [removePage, id, workspace_id])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='1.5rem' align='center'>
        Are you sure to remove this page from syncing?
        <Flex gap='15px'>
          <Button variant='solid' disabled={removePageResult.isLoading} onClick={handleRemovePage}>
            Confirm
          </Button>
          <Button variant='outline' disabled={removePageResult.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const NotionPages = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))

  const [selectedPage, setSelectedPage] = useState(null)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  const { data = {}, isLoading } = useGetNotionSyncedPagesQuery(queryParams)
  const { results: pages = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Pages',
        accessor: 'title',
        disableSortBy: true
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: pages,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams, currentWorkspaceId])

  if (isLoading) {
    return <NotionPagesSkeleton />
  }

  const handleCancel = () => {
    setIsRemoveModalOpen(false)
    setSelectedPage(null)
  }

  return (
    <div>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <h2 className={styles.heading}>Pages currently syncing from</h2>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>
        {pages?.length === 0 ? (
          <div className={styles.emptyState}>Currently not syncing any pages. Select pages to start syncing</div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell key={key} {...restCellProps} className={styles.tableCell}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                  <TableOptions>
                    <TableOption
                      onClick={() => {
                        setSelectedPage(row.original)
                        setIsRemoveModalOpen(true)
                      }}
                    >
                      Remove
                    </TableOption>
                  </TableOptions>
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table.Root>
      <DeleteModal
        onClose={handleCancel}
        onOpenChange={handleCancel}
        title='Remove Page'
        showFooter={false}
        showCloseButton={false}
        data={selectedPage}
        id={selectedPage?.id}
        open={isRemoveModalOpen}
        workspace_id={currentWorkspaceId}
        bodyClassName={styles.modalBody}
      />
    </div>
  )
}

export default NotionPages
