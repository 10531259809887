import { Box, Flex } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './UserGroups.module.css'

import BackButton from 'Src/componentsv3/BackButton'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useAddUserGroupMutation } from 'Src/servicesV3/userGroupsApi'
import parseErrorMessage from 'Utils/error-message-parser'

import UserGroupForm from './UserGroupForm'
import userGroupFormValidationSchema from './UserGroupForm/user-group-form-validation-schema'
import { createPayloadFromUserGroupForm } from './usergroups-helper'

const DEFAULT_FORM_VALUES = {
  name: '',
  users: [],
  addedFrom: null,
  syncedFromGroup: null,
  conditions: []
}

const CreateUserGroup = () => {
  const navigate = useNavigate()
  const { data: accountData } = useGetAccountQuery()
  const accountType = accountData?.account_type

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(userGroupFormValidationSchema)
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods
  const [addUserGroup, { isLoading: isAddingUserGroup }] = useAddUserGroupMutation()

  const handleFormSubmit = async (formData) => {
    const userGroupPayload = createPayloadFromUserGroupForm(formData, accountType)

    try {
      const createGroupPromise = addUserGroup(userGroupPayload).unwrap()

      await toast.promise(createGroupPromise, {
        loading: 'Creating user group',
        success: 'User group created successfully',
        error: parseErrorMessage('Unable to create user group. Try again...')
      })

      navigate('../', { replace: true })
    } catch (error) {}
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Create User Group</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <UserGroupForm onSubmit={handleSubmit(handleFormSubmit)} isLoading={isSubmitting || isAddingUserGroup} />
        </FormProvider>
      </Box>
    </Box>
  )
}

export default CreateUserGroup
