// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S8p0qrYFo8L5AWLJvv7A{background:linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.2392156863) 37%, rgba(190, 190, 190, 0.2) 63%);background-size:400% 100%;animation:PM7FzZaBHB0DzcxeZjXt 1.4s ease infinite;border-radius:var(--border-radius-sm)}.BDweNJx9BgUyjszgAmHs{border-radius:var(--border-radius-circle)}@keyframes PM7FzZaBHB0DzcxeZjXt{0%{background-position:100% 50%}100%{background-position:0 50%}}", "",{"version":3,"sources":["webpack://./../src/componentsv3/Skeleton/Skeleton.module.css"],"names":[],"mappings":"AAAA,sBACE,oIAAA,CAIA,yBAAA,CACA,iDAAA,CACA,qCAAA,CAGF,sBACE,yCAAA,CAGF,gCACE,GACE,4BAAA,CAGF,KACE,yBAAA,CAAA","sourcesContent":[".skeleton {\n  background: linear-gradient(90deg,\n      #bebebe33 25%,\n      #8181813d 37%,\n      #bebebe33 63%);\n  background-size: 400% 100%;\n  animation: skeleton-loading 1.4s ease infinite;\n  border-radius: var(--border-radius-sm);\n}\n\n.circle {\n  border-radius: var(--border-radius-circle);\n}\n\n@keyframes skeleton-loading {\n  0% {\n    background-position: 100% 50%;\n  }\n\n  100% {\n    background-position: 0 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "S8p0qrYFo8L5AWLJvv7A",
	"skeleton-loading": "PM7FzZaBHB0DzcxeZjXt",
	"circle": "BDweNJx9BgUyjszgAmHs"
};
export default ___CSS_LOADER_EXPORT___;
