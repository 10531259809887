import { Fragment, memo } from 'react'
import { Flex } from '@happyfoxinc/web-components'
import cx from 'classnames'

import styles from './Components.module.css'

import InfoTooltip from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/InfoTooltip'
import ReadOnlyFields from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/ReadOnlyFields'

const StepReadOnly = memo(({ app, step, index, updateStep, onDelete }) => {
  const isAccessProvisionStep = step.name === 'access_provision'

  return (
    <div
      className={cx(
        styles.stepContent,
        isAccessProvisionStep && styles.defaultStep,
        !isAccessProvisionStep && styles.editableStep
      )}
      id={`${step.name}-readonly-${index}-field`}
    >
      {isAccessProvisionStep ? (
        <Fragment>
          <Flex justify='space-between' align='center' className={styles.stepHeader} id={`access-provision-${index}`}>
            <Flex align='flex-end' wrap='wrap' gap='3px' style={{ width: '100%' }}>
              <h3 className={styles.stepTitle}>{step.label}</h3>
              {step.info && (
                <div className={styles.infoButton}>
                  <InfoTooltip message={step.info} />
                </div>
              )}
            </Flex>
          </Flex>
          <p className={cx(styles.stepDescription, styles.clampParagraph)} title={step.description}>
            {step.description}
          </p>
        </Fragment>
      ) : (
        <ReadOnlyFields step={step} stepLabel={step.label} index={index} updateStep={updateStep} onDelete={onDelete} />
      )}
    </div>
  )
})

export default StepReadOnly
