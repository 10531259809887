import { Button, FormControl } from '@happyfoxinc/react-ui'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { components } from 'react-select'

import styles from './AccessControl.modules.scss'

import CloseIcon from 'Icons/close.svg'

import ReactSelect from 'Components/ReactSelect'

import { useAccessCondition } from './AccessConditionContext'

const generateId = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`

const AccessConditionForm = () => {
  const { dropdownOptions } = useAccessCondition()

  return (
    <div className={styles.AccessConditionForm}>
      <div className={styles.Header}>
        <h2>Access Condition</h2>
      </div>
      <div className={styles.ConditionsContainer}>
        <div className={styles.GridHeader}>
          <div className={styles.ColumnLabel}>Field</div>
          <div className={styles.ColumnLabel}>Value</div>
        </div>
        <ConditionComponent valueOptions={dropdownOptions.values} fieldOptions={dropdownOptions.fields} />
      </div>
    </div>
  )
}

const ConditionComponent = ({ valueOptions, fieldOptions }) => {
  const {
    watch,
    control,
    getValues,
    formState: { errors }
  } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'conditions'
  })

  const conditions = watch('conditions')

  const customStyles = useMemo(
    () => ({
      control: (base) => ({
        ...base,
        minHeight: 38,
        boxShadow: 'none',
        background: 'white',
        borderColor: '#e2e8f0',
        '&:hover': {
          borderColor: 'var(--primary-light)'
        }
      }),
      valueContainer: (base) => ({
        ...base,
        flexWrap: 'nowrap',
        padding: '0 6px'
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: 'var(--primary-lightest)',
        borderRadius: '4px'
      })
    }),
    []
  )

  // function will filter the field options based on previously selected fields
  // Eg: If "IT" is selected in row1, "IT" should not be available in the consecutive rows
  const getFilteredFieldOptions = useCallback(() => {
    const selectedFields = conditions.map((condition) => condition.field?.id)
    return fieldOptions.filter((option) => !selectedFields.includes(option.id))
  }, [conditions, fieldOptions])

  const handleAddCondition = useCallback(() => {
    const newId = generateId()
    append({
      id: newId,
      field: null,
      values: []
    })
  }, [append])

  const handleFieldChange = useCallback(
    (selectedOption, index) => {
      update(index, { field: selectedOption, values: [] })
    },
    [update]
  )

  const handleValuesChange = useCallback(
    (selectedOptions, index) => {
      const field = getValues(`conditions.${index}.field`)
      update(index, { field, values: selectedOptions })
    },
    [getValues, update]
  )

  const MultiValue = (props) => {
    const { index, getValue } = props
    const value = getValue()
    const displayChips = 2

    const title = []
    for (let i = 0; i < value.length; i++) {
      if (i >= displayChips) {
        title.push(value[i].label)
      }
    }

    if (index < displayChips) {
      return <components.MultiValue {...props} />
    } else if (index === displayChips) {
      return (
        <div className={styles.MoreSelected} title={title.join(', ')}>{`+${value.length - displayChips} more`}</div>
      )
    } else {
      return null
    }
  }

  const getMultiDropdownOptions = useCallback(
    (index) => {
      const selectedFieldOption = getValues(`conditions.${index}.field`)
      return selectedFieldOption ? valueOptions[selectedFieldOption.id] : []
    },
    [getValues, valueOptions]
  )

  return (
    <React.Fragment>
      {fields.length === 0 && (
        <div className={styles.NoConditionRow}>
          <p>No access condition. Click '+ Add' to configure condition.</p>
        </div>
      )}
      {fields.length > 0 &&
        fields.map((condition, index) => (
          <div key={condition.id} className={styles.ConditionRow}>
            <input type='hidden' {...control.register(`conditions.${index}.name`)} value='Access Condition 1' />
            <FormControl isInvalid={errors[`conditions.${index}.field`]} className={styles.FormControl}>
              <Controller
                name={`conditions.${index}.field`}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    onChange={(option) => handleFieldChange(option, index)}
                    options={getFilteredFieldOptions()}
                    styles={customStyles}
                    placeholder='Select field'
                    className={styles.FieldSelect}
                    isSearchable={false}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
            </FormControl>
            <div className={styles.ValueContainer}>
              <FormControl isInvalid={errors[`conditions.${index}.values`]} className={styles.FormControl}>
                <Controller
                  name={`conditions.${index}.values`}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      isMulti
                      {...field}
                      isClearable={false}
                      onChange={(options) => handleValuesChange(options, index)}
                      options={getMultiDropdownOptions(index)}
                      styles={customStyles}
                      placeholder='Select value'
                      className={styles.ValueSelect}
                      components={{ MultiValue }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
              </FormControl>
              <button onClick={() => remove(index)} className={styles.RemoveButton}>
                <CloseIcon className={styles.CloseIcon} />
              </button>
            </div>
          </div>
        ))}
      {fieldOptions.length > conditions.length && (
        <Button variant='primary' onClick={handleAddCondition} className={styles.AddButton}>
          + Add
        </Button>
      )}
    </React.Fragment>
  )
}

ConditionComponent.propTypes = {
  valueOptions: PropTypes.object.isRequired,
  fieldOptions: PropTypes.array.isRequired
}

export default AccessConditionForm
