// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RHMO5OVvZH6hR15VIZfp{display:inline-flex;-moz-column-gap:5px;column-gap:5px;align-items:center;-webkit-user-select:none;user-select:none;font-size:var(--text-xs);padding:1px 8px;border-radius:var(--border-radius-sm);background-color:var(--color-surface-muted);color:var(--color-text-default)}.RHMO5OVvZH6hR15VIZfp.K67yqXO7OtnRpKYllbci{background-color:var(--color-accent-subtle);color:var(--color-text-default)}.RHMO5OVvZH6hR15VIZfp.THk2W7kNw0Z9YYdgFdXC{background-color:var(--color-surface-muted);color:var(--color-text-default)}.RHMO5OVvZH6hR15VIZfp.fez9_WgVzzq_AMuF8wwm{cursor:pointer}", "",{"version":3,"sources":["webpack://./../src/componentsv3/Badge/Badge.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,mBAAA,CAAA,cAAA,CACA,kBAAA,CACA,wBAAA,CAAA,gBAAA,CACA,wBAAA,CACA,eAAA,CACA,qCAAA,CACA,2CAAA,CACA,+BAAA,CAEA,2CACE,2CAAA,CACA,+BAAA,CAGF,2CACE,2CAAA,CACA,+BAAA,CAGF,2CACE,cAAA","sourcesContent":[".Badge {\n  display: inline-flex;\n  column-gap: 5px;\n  align-items: center;\n  user-select: none;\n  font-size: var(--text-xs);\n  padding: 1px 8px;\n  border-radius: var(--border-radius-sm);\n  background-color: var(--color-surface-muted);\n  color: var(--color-text-default);\n\n  &.Default {\n    background-color: var(--color-accent-subtle);\n    color: var(--color-text-default);\n  }\n\n  &.Muted {\n    background-color: var(--color-surface-muted);\n    color: var(--color-text-default);\n  }\n\n  &.Clickable {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Badge": "RHMO5OVvZH6hR15VIZfp",
	"Default": "K67yqXO7OtnRpKYllbci",
	"Muted": "THk2W7kNw0Z9YYdgFdXC",
	"Clickable": "fez9_WgVzzq_AMuF8wwm"
};
export default ___CSS_LOADER_EXPORT___;
