import { Box, Button, Flex, Grid, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { Fragment } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import styles from './Channels.module.css'

import BackButton from 'Src/componentsv3/BackButton'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tips, { Tip } from 'Src/componentsv3/Tips'
import { AUTOMATIC, COMMON_TEXT, MODE_OPTIONS, SYNC_TO_CHANNEL_OPTIONS } from 'Src/constants/channels'
import { TICKETING_INTEGRATION } from 'Src/constants/ticketingIntegration'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useEditSlackChannelMutation } from 'Src/servicesV3/channelsApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { getEditChannelPayload, getModeFormValue } from './channels-helper'
import { HelpDeskFields, ServiceDeskFields } from './TicketingSpecificFields'

import KB_LINKS from 'Constants/kb-links'

const getValidationSchema = (isHfHelpdeskTicketingConnected, isHfServicedeskTicketingConnected) => {
  const defaultCategoryOrTeamForManualModeKey = isHfHelpdeskTicketingConnected
    ? 'defaultCategoryForManualMode'
    : 'defaultTeamForManualMode'

  const { ALLOWED_CATEGORIES_ERROR, ALLOWED_TEAMS_ERROR, DEFAULT_CATEGORY_ERROR, DEFAULT_TEAM_ERROR } = COMMON_TEXT

  const allowedCategoriesOrTeamsError = isHfHelpdeskTicketingConnected ? ALLOWED_CATEGORIES_ERROR : ALLOWED_TEAMS_ERROR
  const defaultCategoryOrTeamError = isHfHelpdeskTicketingConnected ? DEFAULT_CATEGORY_ERROR : DEFAULT_TEAM_ERROR

  return yup
    .object()
    .shape({
      syncToChannelThread: yup.boolean().required('Sync to Channel is required'),
      mode: yup
        .string()
        .nullable()
        .when('syncToChannelThread', {
          is: true,
          then: (schema) => schema.required('Field is required'),
          otherwise: (schema) => schema.notRequired()
        }),
      workspace_id: yup.string().when('$showWorkspaceDropdown', {
        is: true,
        then: (schema) => schema.required('Workspace is required'),
        otherwise: (schema) => schema.notRequired()
      }),
      allowedCategories: yup
        .array()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) =>
            isHfHelpdeskTicketingConnected && syncToChannelThread && mode && mode !== AUTOMATIC,
          then: (schema) => schema.min(1, allowedCategoriesOrTeamsError).required(allowedCategoriesOrTeamsError),
          otherwise: (schema) => schema.notRequired()
        }),
      allowedTeams: yup
        .array()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) =>
            isHfServicedeskTicketingConnected && syncToChannelThread && mode && mode !== AUTOMATIC,
          then: (schema) => schema.min(1, allowedCategoriesOrTeamsError).required(allowedCategoriesOrTeamsError),
          otherwise: (schema) => schema.notRequired()
        }),
      defaultCategoryForAutoMode: yup
        .object()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) =>
            isHfHelpdeskTicketingConnected && syncToChannelThread && mode === AUTOMATIC,
          then: (schema) => schema.required(defaultCategoryOrTeamError),
          otherwise: (schema) => schema.notRequired()
        }),
      defaultTeamForAutoMode: yup
        .object()
        .notRequired()
        .when(['syncToChannelThread', 'mode'], {
          is: (syncToChannelThread, mode) =>
            isHfServicedeskTicketingConnected && syncToChannelThread && mode === AUTOMATIC,
          then: (schema) => schema.required(defaultCategoryOrTeamError),
          otherwise: (schema) => schema.notRequired()
        }),
      [defaultCategoryOrTeamForManualModeKey]: yup.object().notRequired()
    })
    .required()
}

const getDefaultValues = (channel, { isHfHelpdeskTicketingConnected, isHfServicedeskTicketingConnected }) => {
  if (!channel) {
    return {}
  }

  const isAutoMode = channel?.sync_to_channel_thread && channel?.convert_messages === AUTOMATIC
  const isManualMode = channel?.sync_to_channel_thread && channel?.convert_messages !== AUTOMATIC

  return {
    name: channel?.name ? _.startCase(channel?.name) : '',
    syncToChannelThread: channel?.sync_to_channel_thread || false,
    mode: channel ? getModeFormValue(channel) : null,
    workspace_id: channel?.workspace_id || null,

    ...(isHfHelpdeskTicketingConnected && {
      defaultCategoryForAutoMode: isAutoMode ? channel?.default_category : null,
      defaultCategoryForManualMode: isManualMode ? channel?.default_category : null,
      allowedCategories: channel?.allowed_categories || []
    }),

    ...(isHfServicedeskTicketingConnected && {
      defaultTeamForAutoMode: isAutoMode ? channel?.default_team : null,
      defaultTeamForManualMode: isManualMode ? channel?.default_team : null,
      allowedTeams: channel?.allowed_teams || []
    })
  }
}

const ChannelForm = ({
  channelId,
  showWorkspaceDropdown,
  isHfHelpdeskTicketingConnected,
  isHfServicedeskTicketingConnected
}) => {
  const navigate = useNavigate()
  const { workspaces } = useWorkspace()

  const [editSlackChannel, editSlackChannelResult] = useEditSlackChannelMutation()

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty }
  } = useFormContext()

  const showModeSelection = watch('syncToChannelThread')

  const handleFormSubmit = (value) => {
    const payload = getEditChannelPayload(value, {
      isHfHelpdeskTicketingConnected,
      isHfServicedeskTicketingConnected
    })

    if (payload.workspace_id === null) {
      const defaultWorkspace = workspaces.find((workspace) => workspace.settings.default === true)

      payload.workspace_id = defaultWorkspace.id
    }

    const promise = editSlackChannel({ channelId, ...payload }).unwrap()

    toast.promise(promise, {
      loading: 'Updating the channel configuration',
      success: 'Channel configuration updated successfully',
      error: parseErrorMessage('Unable to update the channel configuration. Try again...')
    })
  }

  const shouldDisableSave = !isDirty || editSlackChannelResult.isLoading

  const formatOptionLabel = ({ label, subLabel }, { context }) => {
    if (context === 'menu') {
      return (
        <Fragment>
          <p>{label}</p>
          <p className={styles.optionSubMenu}>{subLabel}</p>
        </Fragment>
      )
    }

    return label
  }

  return (
    <Fragment>
      <Grid columns='1fr 25%' gap='10rem' width='100%' height='100%'>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Flex direction='column' align='flex-start' justify='space-between' gap='1rem' width='100%' height='100%'>
            <Box width='100%'>
              <FormField>
                <FormField.Field label='Name' error={errors?.name?.message} isRequired>
                  <TextField.Root {...register('name')} size='2' radius='small' placeholder='Name' disabled />
                </FormField.Field>
              </FormField>
              {showWorkspaceDropdown && (
                <FormField>
                  <FormField.Field label='Workspace' error={errors?.workspace_id?.message} isRequired>
                    <Controller
                      name='workspace_id'
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          value={workspaces.find((opt) => opt.id === field.value)}
                          onChange={(opt) => field.onChange(opt.id)}
                          isClearable={false}
                          options={workspaces}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        />
                      )}
                    />
                  </FormField.Field>
                </FormField>
              )}
              <FormField>
                <FormField.Field
                  label='Should Assist AI post ticket info & updates publicly in the channel ?'
                  error={errors?.syncToChannelThread?.message}
                  isRequired
                >
                  <Controller
                    control={control}
                    name='syncToChannelThread'
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        isSearchable={false}
                        options={SYNC_TO_CHANNEL_OPTIONS}
                        value={SYNC_TO_CHANNEL_OPTIONS.find(({ value }) => field.value === value)}
                        onChange={({ value }) => field.onChange(value)}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        formatOptionLabel={formatOptionLabel}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
              {showModeSelection && (
                <FormField>
                  <FormField.Field
                    label='How do you want Assist AI to create tickets ?'
                    error={errors?.mode?.message}
                    isRequired
                  >
                    <Controller
                      name='mode'
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          isSearchable={false}
                          options={MODE_OPTIONS}
                          value={MODE_OPTIONS.find(({ value }) => field.value === value)}
                          onChange={({ value }) => field.onChange(value)}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          formatOptionLabel={formatOptionLabel}
                        />
                      )}
                    />
                  </FormField.Field>
                </FormField>
              )}
              {isHfHelpdeskTicketingConnected && <HelpDeskFields />}
              {isHfServicedeskTicketingConnected && <ServiceDeskFields />}
            </Box>
            <Flex gap='12px' mt='24px' direction='row-reverse' justify='space-between' align='center' width='100%'>
              <Button type='submit' disabled={shouldDisableSave} variant='solid'>
                Save
              </Button>
              <Button type='button' onClick={() => navigate('/channels')} variant='outline'>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </form>
        <div className={styles.tipsContainer}>
          <Tips title='Tips for Channel Configuration:'>
            <Tip>
              Set up how Assist AI should behave in the connected Slack channels. You can configure whether Assist AI should post ticket information and updates publicly in the channel or keep them private.
            </Tip>
            <Tip>
              Map the workspace to the channel. Once the workspace is mapped, the channels' scope will be limited to the selected workspace.
            </Tip>
            <Tip>
              If you choose to have Assist AI post ticket information publicly, you can configure how tickets should be created - either automatically or manually. You can also select the categories in which users can create tickets from the Slack channel.
            </Tip>
            <Tip>
              Learn more about{' '}
              <a href={KB_LINKS.SLACK_CHANNEL_CONFIGURATION} target='_blank' rel='noreferrer' className={styles.link}>
              Channel Configuration and Assist AI behavior
              </a>
              .
            </Tip>
          </Tips>
        </div>
      </Grid>
    </Fragment>
  )
}

const EditChannel = () => {
  const navigate = useNavigate()
  const { state: channel } = useLocation()

  const { data: account } = useGetAccountQuery()

  const showWorkspaceDropdown = account?.is_workspaces_enabled
  const isHfHelpdeskTicketingConnected = account?.connected_ticketing_integration === TICKETING_INTEGRATION.HF_HELP_DESK
  const isHfServicedeskTicketingConnected =
    account?.connected_ticketing_integration === TICKETING_INTEGRATION.HF_SERVICE_DESK

  const formMethods = useForm({
    defaultValues: getDefaultValues(channel, {
      isHfHelpdeskTicketingConnected,
      isHfServicedeskTicketingConnected
    }),
    resolver: yupResolver(getValidationSchema(isHfHelpdeskTicketingConnected, isHfServicedeskTicketingConnected)),
    context: { showWorkspaceDropdown }
  })

  if (!channel) {
    toast.error('Unable to fetch channel. Try again...', {
      id: 'fetch-error'
    })
    navigate('/channels')
    return null
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Edit Channel</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <ChannelForm
            channelId={channel.channel_id}
            showWorkspaceDropdown={showWorkspaceDropdown}
            isHfHelpdeskTicketingConnected={isHfHelpdeskTicketingConnected}
            isHfServicedeskTicketingConnected={isHfServicedeskTicketingConnected}
          />
        </FormProvider>
      </Box>
    </Box>
  )
}

export default EditChannel
