import baseApi, { TAGS } from './baseApi'

export const announcementsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: (params) => {
        const queryParams = { ...params };
        if (queryParams.status && Array.isArray(queryParams.status)) {
          queryParams.status = queryParams.status.join(',');
        }
        return {
          url: 'v1/announcements',
          method: 'GET',
          params: queryParams
        };
      },
      providesTags: [TAGS.ANNOUNCEMENTS]
    }),

    getAnnouncement: builder.query({
      query: (id) => ({
        url: `v1/announcements/${id}`,
        method: 'GET'
      }),
      providesTags: [TAGS.ANNOUNCEMENTS]
    }),

    createAnnouncement: builder.mutation({
      query: (data) => ({
        url: 'v1/announcements',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.ANNOUNCEMENTS]
    }),

    updateAnnouncement: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `v1/announcements/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.ANNOUNCEMENTS]
    }),

    deleteAnnouncement: builder.mutation({
      query: (id) => ({
        url: `v1/announcements/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.ANNOUNCEMENTS]
    }),

    sendAnnouncementToSelf: builder.mutation({
        query: (body) => ({
          url: 'v1/announcements/send/self',
          method: 'POST',
          body
        })
    }),

    stopScheduledAnnouncement: builder.mutation({
      query: (id) => ({
        url: `v1/announcements/${id}`,
        method: 'PATCH',
        body: {
          action: 'stop_schedule'
        }
      }),
      invalidatesTags: [TAGS.ANNOUNCEMENTS]
    }),

    sendReminder: builder.mutation({
      query: (id) => ({
        url: `v1/announcements/${id}/send-reminder`,
        method: 'PATCH'
      })
    })
  })
})

export const {
  useGetAnnouncementsQuery,
  useGetAnnouncementQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useSendAnnouncementToSelfMutation,
  useStopScheduledAnnouncementMutation,
  useSendReminderMutation
} = announcementsApi