import KB_LINKS from 'Constants/kb-links'
import keyMap from 'Constants/key-map'

import { APP_CATEGORY } from './appTypes'
import { INSTALL_FORM_SCHEMAS } from './formSchemas'
import { logos } from './logos'

const {
  SHAREPOINT,
  HAPPYFOX_HELPDESK,
  HAPPYFOX_SERVICE_DESK,
  NOTION,
  SIMPPLR,
  CONFLUENCE,
  GURU,
  OTHER_TICKETING_SYSTEM,
  OKTA,
  AZURE_AD,
  WEBSITE,
  OUTLINE,
  GDRIVE,
  GITBOOK
} = keyMap

export const APPS_CONFIG = {
  [SHAREPOINT.KEY]: {
    id: SHAREPOINT.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'SharePoint',
    description:
      'Sharepoint is used as a secure place to store, organize, share, and access information from any device. All you need is a web browser and you are good to go.',
    logo: logos.sharepoint,
    kbLink: KB_LINKS.SHAREPOINT_INTEGRATION,
    planCategory: 'standard',
    accountType: ''
  },

  [HAPPYFOX_HELPDESK.KEY]: {
    id: HAPPYFOX_HELPDESK.ID,
    category: [APP_CATEGORY.TICKETING_SYSTEM, APP_CATEGORY.KNOWLEDGE_SOURCES],
    title: 'HappyFox Helpdesk',
    description:
      'HappyFox is an all-in-one help desk ticketing system that provides faster and better support for your customers.',
    logo: logos.happyfox,
    kbLink: KB_LINKS.HAPPYFOX_HELPDESK_INTEGRATION,
    planCategory: 'standard',
    accountType: '',
    installFields: [
      {
        name: 'account_url',
        label: 'HappyFox Helpdesk account URL',
        type: 'text',
        placeholder: 'https://myaccount.happyfox.com'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.helpdesk
  },

  [NOTION.KEY]: {
    id: NOTION.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Notion',
    description:
      'Notion is a project management and note-taking software.Notion is a software designed to help members of a company or organization coordinate deadlines, objectives, and assignments for the sake of efficiency and productivity.',
    logo: logos.notion,
    kbLink: KB_LINKS.NOTION_INTEGRATION,
    planCategory: 'standard',
    accountType: ''
  },

  [SIMPPLR.KEY]: {
    id: SIMPPLR.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Simpplr',
    description:
      'Simpplr helps companies connect their workforce by streamlining internal communications and forging employee connections',
    logo: logos.simpplr,
    kbLink: KB_LINKS.SIMPPLR_INTEGRATION,
    planCategory: 'premium',
    accountType: '',
    installFields: [
      {
        name: 'consumer_key',
        label: 'Consumer Key',
        type: 'text',
        placeholder: 'Enter your Consumer Key'
      },
      {
        name: 'client_secret',
        label: 'Client Secret',
        type: 'text',
        placeholder: 'Enter your Client Secret'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.simpplr
  },

  [CONFLUENCE.KEY]: {
    id: CONFLUENCE.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Confluence',
    description:
      'Confluence is a team collaboration tool that gives your team a place to create, capture, and collaborate on any project or idea',
    logo: logos.confluence,
    kbLink: KB_LINKS.CONFLUENCE_INTEGRATION,
    planCategory: 'standard',
    accountType: '',
    installFields: [
      {
        name: 'account_url',
        label: 'Account URL',
        type: 'text',
        placeholder: 'https://myaccount.confluence.com'
      },
      {
        name: 'installation_code',
        label: 'Installation Code',
        type: 'text',
        placeholder: 'API Token'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.confluence
  },

  [GURU.KEY]: {
    id: GURU.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Guru',
    description:
      'Guru is a collaborative knowledge management solution that is used to share knowledge within a company. With Guru, teams can effortlessly access knowledge from across your company.',
    logo: logos.guru,
    kbLink: KB_LINKS.GURU_INTEGRATION,
    planCategory: 'standard',
    accountType: '',
    installFields: [
      {
        name: 'user_email_id',
        label: 'User Email address',
        type: 'text',
        placeholder: 'Enter your email'
      },
      {
        name: 'user_token',
        label: 'API Key',
        type: 'text',
        placeholder: 'Enter your API Key'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.guru
  },

  [OTHER_TICKETING_SYSTEM.KEY]: {
    id: OTHER_TICKETING_SYSTEM.ID,
    route: 'category/ticketing-system/other',
    category: APP_CATEGORY.TICKETING_SYSTEM,
    title: 'Other Ticketing system',
    description: 'Connect to other ticketing system',
    logo: logos.otherTicketingSystem,
    kbLink: KB_LINKS.OTHER_TICKETING_INTEGRATION,
    planCategory: 'standard',
    accountType: ''
  },

  [OKTA.KEY]: {
    id: OKTA.ID,
    category: APP_CATEGORY.IDENTITY_MANAGEMENT,
    title: 'Okta',
    description:
      "The Okta System for Cross-domain Identity Management (SCIM) allows admins to manage Users and User group information between Okta's Universal Directory and Assist AI. Integrating with Okta streamlines and automates managing user accounts, attributes between business systems.",
    logo: logos.okta,
    kbLink: KB_LINKS.OKTA_INTEGRATION,
    planCategory: 'premium',
    accountType: '',
    installFields: [
      {
        name: 'account_url',
        label: 'Okta account URL',
        type: 'text',
        placeholder: 'https://myaccount.okta.com'
      },
      {
        name: 'api_token',
        label: 'API Token',
        type: 'password',
        placeholder: 'API Token'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.okta
  },

  [AZURE_AD.KEY]: {
    id: AZURE_AD.ID,
    category: APP_CATEGORY.IDENTITY_MANAGEMENT,
    title: 'Azure Active Directory',
    description:
      "Azure AD is Microsoft's cloud-based identity and access management service, providing a robust solution for managing users, groups, and applications. Seamlessly connect your Azure Active Directory with Assist AI to streamline user management and enhance productivity. With this integration, you can leverage quick actions and AI actions to automate common tasks and provide intelligent assistance to your users.",
    logo: logos.azureAd,
    planCategory: 'premium',
    accountType: ''
  },

  [HAPPYFOX_SERVICE_DESK.KEY]: {
    id: HAPPYFOX_SERVICE_DESK.ID,
    category: APP_CATEGORY.TICKETING_SYSTEM,
    title: 'HappyFox Service Desk',
    description:
      'HappyFox Service Desk is a robust system that enables you to deliver employee support and achieve ITSM goals. By connecting to HappyFox Assist AI with Service Desk, your employees can raise queries, issues and requests from within Slack. Agents can resolve those incidents and service requests through conversational ticketing, quick actions and more!',
    logo: logos.hfServiceDesk,
    planCategory: 'standard',
    accountType: '',
    installFields: [
      {
        name: 'account_url',
        label: 'HappyFox Service Desk account URL',
        type: 'text',
        placeholder: 'https://myaccount.happyfox.com'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.servicedesk
  },

  [WEBSITE.KEY]: {
    id: WEBSITE.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Website',
    description:
      'Automatically scrape information from any URL and provide quick, accurate responses directly from your website',
    logo: logos.website,
    planCategory: 'standard',
    accountType: ''
  },

  [OUTLINE.KEY]: {
    id: OUTLINE.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Outline',
    description:
      'Outline is an open-source knowledge base and wiki platform designed to help teams and organizations manage and collaborate on their documentation efficiently. It enables users to create, edit, and organize documents in a simple and intuitive way, supporting real-time collaboration, markdown formatting, and rich media embeds.',
    logo: logos.outline,
    planCategory: 'standard',
    accountType: '',
    installFields: [
      {
        name: 'client_url',
        label: 'Outline Base URL',
        type: 'text',
        placeholder: 'Enter your Outline Base URL'
      },
      {
        name: 'api_key',
        label: 'API Key',
        type: 'text',
        placeholder: 'Enter your API Key'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.outline
  },

  [GDRIVE.KEY]: {
    id: GDRIVE.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Google Drive',
    description:
      'Google Drive is a file storage and synchronization service developed by Google. It allows users to store files in the cloud, synchronize files across devices, and share files.',
    logo: logos.gDrive,
    planCategory: 'standard',
    accountType: ''
  },

  [GITBOOK.KEY]: {
    id: GITBOOK.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'GitBook',
    description:
      'GitBook is a modern documentation platform where teams can document everything from products to internal knowledge-bases and APIs.',
    logo: logos.gitbook,
    planCategory: 'standard',
    accountType: '',
    installFields: [
      {
        name: 'access_token',
        label: 'API Key',
        type: 'text',
        placeholder: 'Enter your API Token'
      }
    ],
    formSchema: INSTALL_FORM_SCHEMAS.gitbook
  }
}

export default APPS_CONFIG
