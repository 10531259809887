import { useCallback } from 'react'

import { Button, Flex, Modal } from '@happyfoxinc/web-components'

import toast from 'react-hot-toast'

import { useSendReminderMutation } from 'Src/servicesV3/announcementsApi'

const ReminderModal = ({ id, data, onClose, ...rest }) => {
  const [sendReminder, sendReminderApiResults] = useSendReminderMutation()

  const handleReminder = useCallback(() => {
    const promise = sendReminder(id).unwrap()

    toast.promise(promise, {
      loading: 'Sending reminders',
      success: `Sent reminders to users successfully...`,
      error: `Error while sending reminders. Try again.`
    })

    onClose()
  }, [id, data, sendReminder, onClose])

  return (
    <Modal size='small' {...rest}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure you want to send reminders to the users linked to this announcement?
        <Flex gap='15px'>
          <Button variant='solid' disabled={sendReminderApiResults.isLoading} onClick={handleReminder}>
            Send
          </Button>
          <Button variant='outline' disabled={sendReminderApiResults.isLoading} onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ReminderModal
