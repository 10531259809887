import { Box, Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './InstallScreen.module.css'

import InstallIcon from 'Src/assetsv3/icons/install-app.png'
import { useFeature } from 'Src/components/Features'
import FEATURE_FLAGS from 'Src/constants/feature-flags'
import { URLS } from 'Src/constants/urls'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { externalRedirect, getDomainFromUrl, openUrl } from 'Src/utils/url'

import { useAppDetailContext } from '../../hooks/AppDetailContext'
import { useInstallApp } from '../../hooks/useInstallApp'
import InstallForm from './InstallForm'

const UPGRADE_MESSAGES = {
  premium: {
    title: 'Your current plan does not support this integration',
    message: 'Upgrade your plan to connect.'
  },
  limitReached: {
    title: 'You have reached your Integration Limit.',
    message: 'Upgrade your plan to connect more integrations.'
  }
}

export const shouldShowUpgradePrompt = (planCategory, isPremiumAppsEnabled, account) => {
  if (planCategory === 'premium' && !isPremiumAppsEnabled) {
    return UPGRADE_MESSAGES.premium
  }
  if (account.is_integrations_limit_reached) {
    return UPGRADE_MESSAGES.limitReached
  }
  return null
}

export const useAccountAndPlan = () => {
  const { data: account } = useGetAccountQuery()
  const { planCategory } = useAppDetailContext()
  const isPremiumAppsEnabled = useFeature([FEATURE_FLAGS.PREMIUM_INTEGRATIONS])

  return { account, planCategory, isPremiumAppsEnabled }
}

export const RedirectModal = ({ isOpen, onClose, appTitle, redirectUrl }) => {
  if (!isOpen) return null

  return (
    <Modal
      size='default'
      open={isOpen}
      onOpenChange={onClose}
      onCancel={onClose}
      title={`Redirect to ${appTitle}`}
      showFooter={false}
      bodyClassName={styles.modalBody}
    >
      <p className={styles.redirectModalDescription}>
        You will be redirected to {appTitle} to complete the installation process
      </p>
      <p className={styles.redirectUrlSection}>Redirect URL : {getDomainFromUrl(redirectUrl)} </p>
      <Button
        size='small'
        radius='medium'
        className={styles.redirectButton}
        onClick={() => {
          externalRedirect(redirectUrl)
          onClose()
        }}
      >
        Redirect
      </Button>
    </Modal>
  )
}

const InstallScreen = ({ app, isReconnect: propIsReconnect = false, connectionDetails = null }) => {
  const { account, planCategory, isPremiumAppsEnabled } = useAccountAndPlan()
  const upgradeMessage = shouldShowUpgradePrompt(planCategory, isPremiumAppsEnabled, account)

  const isReconnect = propIsReconnect

  const [showForm, setShowForm] = useState(!!isReconnect)
  const [modalState, setModalState] = useState({
    isOpen: false,
    redirectUrl: null
  })

  const navigate = useNavigate()

  const { redirectUrl: apiRedirectUrl, isLoading, isRedirectApp, installApp } = useInstallApp(app.id)

  const clearParamsAndRedirect = () => {
    sessionStorage.removeItem('reconnectApp')

    navigate(`/apps/${app.id}`)
  }

  const handleInitialInstall = () => {
    if (isRedirectApp && apiRedirectUrl) {
      setModalState({
        isOpen: true,
        redirectUrl: apiRedirectUrl
      })
      return
    }
    setShowForm(true)
  }

  const handleFormSubmit = async (formData) => {
    const result = await installApp(formData)

    if (result?.success && result?.requiresRedirect && result?.redirectUrl) {
      setModalState({
        isOpen: true,
        redirectUrl: result.redirectUrl
      })
    } else if (result?.success) {
      clearParamsAndRedirect()
    }
  }

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      redirectUrl: null
    })

    clearParamsAndRedirect()
  }

  const handleCancelForm = () => {
    setShowForm(false)
    sessionStorage.removeItem('reconnectApp')

    if (isReconnect) {
      navigate(`/apps/${app.id}`)
    }
  }

  if (showForm) {
    return (
      <Flex direction='column' gap='20px'>
        <h4 className={styles.formHeader}>{isReconnect ? `Reconnect to ${app.title}` : `Connect to ${app.title}`}</h4>
        <InstallForm
          appId={app.id}
          isReconnect={isReconnect}
          schema={app.formSchema}
          fields={app.installFields}
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
          onCancel={handleCancelForm}
          defaultValues={connectionDetails || {}}
        />
        <RedirectModal
          isOpen={modalState.isOpen}
          onClose={handleCloseModal}
          appTitle={app.title}
          redirectUrl={modalState.redirectUrl}
        />
      </Flex>
    )
  }

  if (upgradeMessage) {
    return (
      <div className={styles.container}>
        <Box width='650px'>
          <p className={styles.installDescription}>
            {upgradeMessage.title}
            <br />
            {upgradeMessage.message}
          </p>
          <Flex gap='20px'>
            <Button
              size='small'
              radius='medium'
              className={styles.submitButton}
              onClick={() => openUrl(URLS.ASSIST_AI)}
              loading={isLoading}
            >
              Upgrade Plan
            </Button>
            <Button
              size='small'
              radius='medium'
              variant='outline'
              className={styles.submitButton}
              onClick={() => openUrl(URLS.ASSIST_AI_PLAN_COMPARISON)}
            >
              Plan comparison
            </Button>
          </Flex>
        </Box>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {app?.ticketingIntegrationStatus?.isIntegrated ? (
        <Box width='650px'>
          <p className={styles.warningInstallDescription}>
            Only one ticketing integration can be connected at a time.
            <br />
            To install {app.title} please uninstall other ticketing integration that you have connected.
          </p>
        </Box>
      ) : (
        <Box width='650px'>
          <p className={styles.installDescription}>
            You haven't linked your {app.title} account yet, click Get started to begin the process
          </p>
          <Button
            size='small'
            radius='medium'
            className={styles.submitButton}
            onClick={handleInitialInstall}
            loading={isLoading}
          >
            Get started
          </Button>
        </Box>
      )}
      <div className={styles.installIconWrapper}>
        <img src={InstallIcon} alt='Install icon' />
      </div>
      <RedirectModal
        isOpen={modalState.isOpen}
        onClose={handleCloseModal}
        appTitle={app.title}
        redirectUrl={modalState.redirectUrl}
      />
    </div>
  )
}

export default InstallScreen
