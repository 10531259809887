import { Box, Button, Flex, Modal, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import _ from 'lodash'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { usePagination, useTable } from 'react-table'

import styles from './Admins.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import SearchIcon from 'Src/assetsv3/icons/search.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useDeleteAdminMutation, useGetAdminsQuery, useResendInviteMutation } from 'Src/servicesV3/adminsApi'
import { useGetProfileQuery } from 'Src/servicesV3/authApi'
import debounce from 'Src/utils/debounce'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Src/utils/query-params'

import InviteAdmin from './InviteAdmin'

import { PAGES, ADMINS } from 'Src/pagesv3/constants'

const DeleteModal = (props) => {
  const { id, data, ...restProps } = props
  const [deleteAdmin, deleteAdminApiResults] = useDeleteAdminMutation()

  const handleDelete = useCallback(() => {
    const promise = deleteAdmin(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting admin',
      success: `Deleted admin '${data.email}'. Successfully...`,
      error: parseErrorMessage(`Error while deleting admin '${data.email}'. Try again.`)
    })
    props.onClose()
  }, [id, data, deleteAdmin, props])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this admin?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteAdminApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteAdminApiResults.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const StatusHighlight = ({ status }) => {
  const statusText = status.toLowerCase() || 'deactivated'
  return (
    <div className={styles.statusWrapper}>
      <div className={`${styles.statusDot} ${styles[statusText]}`} />
      <span>{_.startCase(statusText)}</span>
    </div>
  )
}

const defaultParams = {
  defaultSearch: ''
}

const getInitialParams = () => {
  return getInitialParamsForTable(defaultParams)
}

const getInitialApiQueryParams = () => {
  const initialParams = getInitialParams()
  return {
    ...getInitialApiQueryParamsForTable(initialParams)
  }
}

const AdminList = () => {
  const [, setSearchParams] = useSearchParams()

  const initialParams = useMemo(getInitialParams, [])

  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams())
  const [pageSize] = useState(initialParams.size)

  const { data: currentUser } = useGetProfileQuery()
  const { data: adminsApiResponse = {}, isLoading: isAdminsApiLoading } = useGetAdminsQuery(queryParams)
  const { results: userGroups = [], meta: paginationDetails = {} } = adminsApiResponse

  const [resendInvitation] = useResendInviteMutation()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  const [searchText, setSearchText] = useState(initialParams.search)

  const handleDeleteClick = (admin) => {
    setSelectedAdmin(admin)
    setIsDeleteModalOpen(true)
  }

  const debouncedSearch = useCallback(
    debounce(
      (searchText, pageIndex, pageSize, setQueryParams, setSearchParams) => {
        const params = {
          search: searchText || ''
        }

        const apiParams = {
          offset: pageIndex * pageSize,
          limit: pageSize,
          ...params
        }

        const urlParams = {
          page: pageIndex + 1,
          size: pageSize,
          ...params
        }

        setQueryParams(apiParams)
        setSearchParams(urlParams, { replace: true })
      },
      500,
      { leading: true, trailing: true }
    ),
    []
  )

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return <StatusHighlight status={value} />
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: userGroups,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize)
    },
    usePagination
  )

  const handleResendInvitation = useCallback(
    (id) => {
      const promise = resendInvitation(id).unwrap()

      toast.promise(promise, {
        loading: 'Resending invitation...',
        success: 'Invitation resent successfully.',
        error: 'Unable to resend invitation. Try again....'
      })
    },
    [resendInvitation]
  )

  useEffect(() => {
    debouncedSearch(searchText, pageIndex, pageSize, setQueryParams, setSearchParams)
  }, [pageIndex, pageSize, searchText, debouncedSearch, setSearchParams])

  const handleModalClose = () => {
    setIsDeleteModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleSearchInputChange = (e) => {
    const value = e.target.value
    gotoPage(0)
    setSearchText(value)
  }

  if (isAdminsApiLoading) {
    return <TableLoader rows={5} columns={3} showOptions={false} />
  }

  return (
    <Fragment>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <div className='search-container'>
          <div className='search-wrapper'>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              value={searchText}
              className='search-input'
              placeholder='Search Admins'
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className='no-records-found'>
                No data available
              </Table.Cell>
            </Table.Row>
          )}
          {rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              const admin = row.original

              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                  {rows.length > 0 && currentUser?.email !== admin?.email ? (
                    <TableOptions>
                      {admin.status === 'expired' && (
                        <TableOption onClick={() => handleResendInvitation(admin.id)}>Resend Invitation</TableOption>
                      )}
                      <TableOption onClick={() => handleDeleteClick(admin)}>Delete</TableOption>
                    </TableOptions>
                  ) : (
                    <Table.Cell />
                  )}
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table.Root>
      <DeleteModal
        open={isDeleteModalOpen}
        onOpenChange={handleModalClose}
        onClose={handleModalClose}
        title='Delete Admin'
        showFooter={false}
        bodyClassName={styles.modalBody}
        id={selectedAdmin?.id}
        data={selectedAdmin}
        showCloseButton={false}
      />
    </Fragment>
  )
}

const Admins = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='12px'>
          <h1 className='heading'>{PAGES[ADMINS].title}</h1>
          <Button variant='solid' size='small' radius='full' className={styles.addButton} onClick={handleOpenModal}>
            <PlusIcon />
          </Button>
        </Flex>
        <p className='sub-heading'>{PAGES[ADMINS].description}</p>
      </div>
      <AdminList />
      <InviteAdmin open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  )
}

export default Admins
