import { Box, Button, Flex } from '@happyfoxinc/web-components'

import styles from './AppInstalledCard.module.css'

import SlackIcon from 'Src/assetsv3/logos/slack.svg'

const AppInstalledCard = ({
  title = '',
  accountUrl = '',
  showButton = false,
  showIcon = false,
  buttonText = 'Action',
  onButtonClick = () => {}
}) => {
  return (
    <Box width='100%' height='70px' className={styles.wrapper}>
      <Flex className={styles.contentWrapper}>
        <p className={styles.successMessage}>
          Successfully connected to <span>{title}</span> workspace.
          <br />
          {accountUrl && (
            <span>
              The account is tied to <span className={styles.connectedAccountInfo}>{accountUrl}</span>
            </span>
          )}
        </p>
      </Flex>
      {showButton && (
        <Box className={styles.buttonContainer}>
          <Button radius='medium' variant='outline' size='small' onClick={onButtonClick} className={styles.button}>
            {showIcon && <SlackIcon width='12' height='12' />}
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AppInstalledCard
