import { Route, Routes } from 'react-router-dom'

import CreateWorkspace from './CreateWorkspace'
import EditWorkspace from './EditWorkspace'
import Workspaces from './Workspaces'

const WorkspaceRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Workspaces />} />
      <Route path='create' element={<CreateWorkspace />} />
      <Route path=':id' index element={<EditWorkspace />} />
    </Routes>
  )
}

export default WorkspaceRouter
