import React, { useState } from 'react'
import ReactSelect, { components } from 'react-select'

import styles from './Filters.module.css'

import { TopicIcon } from '../assets'

const TopicDropdown = (props) => {
  const { onChange, topics, default_value = null, placeholder = 'Topic', isClearable = false, ...restProps } = props

  const [selectedTopic, setSelectedTopic] = useState(default_value)

  const customStyles = {
    container: (base) => ({
      ...base,
      width: '210px'
    }),
    control: (base) => ({
      ...base,
      outline: 'none',
      boxShadow: 'none',
      height: '30px',
      minHeight: '30px',
      cursor: 'pointer',
      backdropFilter: 'blur(6px)',
      border: '1px solid var(--color-border-default)',
      '&:hover': {
        border: '1px solid var(--color-border-default)'
      }
    })
  }

  const reactSelectProps = {
    isSearchable: false,
    styles: customStyles,
    classNamePrefix: 'tpc-dropdown',
    value: topics.find((option) => option.value === selectedTopic),
    options: topics,
    placeholder,
    isClearable,
    onChange: (option) => {
      setSelectedTopic(option?.value)
      onChange(option?.value)
    },
    getOptionLabel: (option) => option.label,
    getOptionValue: (option) => option.value,
    components: ({ children, ...props }) => {
      return (
        <components.Control {...props}>
          <TopicIcon className={styles.icon} />
          {children}
        </components.Control>
      )
    },
    ...restProps
  }

  return <ReactSelect {...reactSelectProps} />
}

export default TopicDropdown
