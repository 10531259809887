export const FIELD_CONFIG = {
  input: {
    height: '40px',
    labelWidth: '120px',
    labelHeight: '16px'
  },
  textarea: {
    height: '120px',
    labelWidth: '120px',
    labelHeight: '16px'
  },
  switch: {
    width: '24px',
    height: '24px',
    labelWidth: '100px',
    labelHeight: '14px'
  },
  action: {
    width: '100px',
    height: '36px'
  }
}
