import React from 'react'

import Tips, { Tip } from 'Components/Tips'

const TipsContent = () => (
  <Tips title='Let Your Team Know'>
    <Tip>You can let Assist AI introduce itself on DM to everyone in the Slack workspace.</Tip>
    <Tip>This is a sample welcome message, which you are welcome to edit based on your organisation needs.</Tip>
    <Tip>
      Once the message is customized, Click <strong>'Save & Preview'</strong>, for Assist AI to DM this welcome message
      to you on Slack. If satisfied with the message click <strong>'Send'</strong> to DM all or specific employees with
      this welcome message.
    </Tip>
  </Tips>
)

export default TipsContent
