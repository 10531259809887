import { Box, Button, Flex, Pagination, Table } from '@happyfoxinc/web-components'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'

import styles from './Outline.module.css'

import { useAddCollectionsMutation, useGetCollectionsQuery } from 'Src/servicesV3/outlineApi'
import debounce from 'Src/utils/debounce'

import { AddCollectionsSkeleton } from './OutlineSkeleton'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const AddCollectionsTable = ({ checkedItems, setCheckedItems }) => {
  const [queryParams, setQueryParams] = useState(defaultApiParams)
  const { data = {}, isLoading } = useGetCollectionsQuery(queryParams)
  const { results: collections = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Collections',
        accessor: 'name'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: collections,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]))
  }

  const isChecked = (id) => checkedItems.includes(id)

  if (isLoading) {
    return <AddCollectionsSkeleton />
  }

  return (
    <Box className={styles.tableContainer}>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <h2 className={styles.heading}>Add collections to sync from</h2>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { id, name } = row.original
            return (
              <Table.Row key={id} {...row.getRowProps()}>
                <Table.Cell onClick={() => handleCheckboxChange(id)}>
                  <Flex
                    align='center'
                    style={{
                      fontFamily: 'inherit',
                      fontSize: 'var(--text-sm)',
                      cursor: 'pointer'
                    }}
                  >
                    <input
                      type='checkbox'
                      checked={isChecked(id)}
                      // onChange={() => handleCheckboxChange(id)}
                      style={{ marginRight: '10px', fontFamily: 'inherit', fontSize: 'var(--text-xs)' }}
                    />
                    {name}
                  </Flex>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </Box>
  )
}

const AddCollections = () => {
  const [selectedCollections, setSelectedCollections] = useState([])
  const [addCollections, { isLoading: addCollectionsLoading }] = useAddCollectionsMutation()

  const handleAddCollections = () => {
    const promise = addCollections({ collection_ids: selectedCollections }).unwrap()
    promise.then(() => setSelectedCollections([]))

    toast.promise(promise, {
      loading: 'Adding collections',
      success: 'Collections added successfully',
      error: 'Unable to add collections. Try again'
    })
  }

  const disableSubmitButton = selectedCollections.length === 0 || addCollectionsLoading

  return (
    <Box>
      <AddCollectionsTable checkedItems={selectedCollections} setCheckedItems={setSelectedCollections} />

      <Button
        onClick={handleAddCollections}
        disabled={disableSubmitButton}
        isLoading={addCollectionsLoading}
        variant='solid'
      >
        Add collections
      </Button>
    </Box>
  )
}

export default AddCollections
