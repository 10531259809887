// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WJ0Ac3hrOVmBsQn2zDAg{display:flex;align-items:center;font-size:var(--text-sm);margin-top:auto;background-color:#fbfbfb;width:100%;padding:.6rem .9rem;border-radius:var(--border-radius-xs);box-shadow:rgba(17,17,26,.1) 0px 4px 16px,rgba(17,17,26,.05) 0px 8px 32px}.Jn01VNE44KxxuE_5SMdw{margin-left:1rem;font-size:var(--text-xs);color:var(--color-text-muted)}.sXO155uGkIjHJUfLJrR0{margin-left:auto;padding:0;display:flex;align-items:center;gap:.5rem;font-size:var(--text-xs);height:auto;color:#ff4447}.sXO155uGkIjHJUfLJrR0:hover{background-color:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/components/AppFooter/AppFooter.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA,CACA,wBAAA,CACA,UAAA,CACA,mBAAA,CACA,qCAAA,CACA,yEAAA,CAGF,sBACE,gBAAA,CACA,wBAAA,CACA,6BAAA,CAGF,sBACE,gBAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA,CACA,wBAAA,CACA,WAAA,CACA,aAAA,CAGF,4BACE,8BAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  font-size: var(--text-sm);\n  margin-top: auto;\n  background-color: #fbfbfb;\n  width: 100%;\n  padding: 0.6rem 0.9rem;\n  border-radius: var(--border-radius-xs);\n  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px\n}\n\n.status {\n  margin-left: 1rem;\n  font-size: var(--text-xs);\n  color: var(--color-text-muted);\n}\n\n.uninstallButton {\n  margin-left: auto;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  font-size: var(--text-xs);\n  height: auto;\n  color: #FF4447;\n}\n\n.uninstallButton:hover {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WJ0Ac3hrOVmBsQn2zDAg",
	"status": "Jn01VNE44KxxuE_5SMdw",
	"uninstallButton": "sXO155uGkIjHJUfLJrR0"
};
export default ___CSS_LOADER_EXPORT___;
