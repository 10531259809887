import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import Create from './Create'
import Edit from './Edit'
import AnnouncementList from './List/AnnouncementList'

const Announcements = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='' index element={<AnnouncementList />} />
        <Route path='create' element={<Create />} />
        <Route path='edit/:id' index element={<Edit />} />
        <Route path='*' index element={<Navigate to='' />} />
      </Route>
    </Routes>
  )
}

export default Announcements
