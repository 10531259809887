import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { Fragment, useState } from 'react'

import styles from './QuickActions.module.css'

import SparkIcon from 'Icons/spark.svg'

import { useAppDetailContext } from '../../hooks/AppDetailContext'
import quickActions from './quick-actions'
import QuickActionsConfig from './QuickActionsConfig'

const QuickActionsModal = ({ isOpen, onHide }) => {
  const { id } = useAppDetailContext()
  const content = quickActions[id]

  return (
    <Modal
      size='large'
      title='Quick Actions'
      open={isOpen}
      showFooter={false}
      bodyClassName={styles.modalBody}
      onCancel={onHide}
      onOpenChange={onHide}
    >
      {content.map((item, index) => (
        <div key={index} className={styles.contentItem}>
          <h2>{item.title}</h2>
          <p>{item.description}</p>
        </div>
      ))}
    </Modal>
  )
}

const QuickActions = ({ data }) => {
  const { title } = useAppDetailContext()

  const [showAllActionsModal, setShowAllActionsModal] = useState(false)

  return (
    <Fragment>
      <Flex gap='15px' align='center' justify='space-between' className={styles.wrapper}>
        <Flex gap='6px' direction='column'>
          <h2 className={styles.heading}>Quick Actions</h2>
          <p className={styles.helpText}>
            By enabling this toggle, you can grant access to {title} quick actions to specific agents.
          </p>
        </Flex>
        <Button variant='ghost' onClick={() => setShowAllActionsModal(true)}>
          View all actions
          <SparkIcon width='1em' height='1em' />
        </Button>
      </Flex>
      <QuickActionsConfig data={data} />
      <QuickActionsModal isOpen={showAllActionsModal} onHide={() => setShowAllActionsModal(false)} />
    </Fragment>
  )
}

export default QuickActions
