/* eslint-disable no-console */
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'

import { redirect } from 'Utils/url'

import { baseApi } from './servicesV3/baseApi'

const LOGOUT_ENDPOINTS = ['getCsrfToken', 'getProfile', 'getAccount']

export const paramsSerializer = (params) => {
  return queryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true
  })
}

const prepareHeaders = async (headers, { endpoint, getState }) => {
  if (endpoint === 'getCsrfToken') {
    return headers
  }

  try {
    const selector = baseApi.endpoints.getCsrfToken.select()
    const { data: csrfTokenData } = selector(getState())

    if (csrfTokenData?.csrf_token) {
      headers.set('X-CSRFToken', csrfTokenData.csrf_token)
    }
  } catch (error) {
    console.error('Error setting CSRF token:', error)
  }

  return headers
}

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  credentials: 'same-origin',
  paramsSerializer,
  prepareHeaders
})

export const customBaseQuery = async (args, api, extraOptions) => {
  try {
    const result = await baseQuery(args, api, extraOptions)

    if (result.error) {
      const isUnauthorized = result.error.status === 401
      const isCsrfFailedError = result.error.status === 403 && result.error.data?.code === 'CSRF_FAILED'

      const endpointName = api.endpoint

      if (isCsrfFailedError || isUnauthorized) {
        redirect('logout')
        return new Promise(() => {})
      }

      if (isUnauthorized) {
        return result
      }
    }

    return result
  } catch (error) {
    console.error('Query execution error:', error)
    return { error: { status: 'FETCH_ERROR', data: { message: 'Request failed' } } }
  }
}
