import { createApi } from '@reduxjs/toolkit/query/react'

import keyMap, { getIdFromKey } from 'Constants/key-map'
import { invalidateList, invalidateListById, providesList } from 'Utils/rtk-query'

import { rtkBaseQuery } from '../api'

const { OKTA, AZURE_AD } = keyMap

const getAccountTypeForUrl = (type) => getIdFromKey(type)
const getAppNameForUrl = (key) => getIdFromKey(key)

const getTagFromApp = (id) => {
  if (id === OKTA.ID) {
    return ['Okta']
  }
  if (id === AZURE_AD.ID) {
    return ['AzureAD']
  }
  return []
}

const getAIActionTagFromApp = (id) => {
  if (id === OKTA.ID) {
    return ['OktaAIActions']
  }
  if (id === AZURE_AD.ID) {
    return ['AzureADAIActions']
  }
  return []
}

export const api = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'api',
  tagTypes: [
    'Profile',
    'Account',
    'Users',
    'UserGroups',
    'Workspaces',
    'Dashboard',
    'Answers',
    'Answer',
    'Notifications',
    'Admins',
    'SaaSApps',
    'Apps',
    'ResponseGroups',
    'ResponseGroupItem',
    'ResponseGroupsChildren',
    'HappyFoxHelpdesk',
    'HappyFoxHelpDeskEmployeeNotifications',
    'Guru',
    'Notion',
    'NotionAccounts',
    'NotionPages',
    'NotionSyncedPages',
    'Simpplr',
    'SimpplrSites',
    'Sharepoint',
    'SharepointSites',
    'Confluence',
    'Okta',
    'AzureAD',
    'ReportMetrics',
    'Announcement',
    'TimeZones',
    'SlackChannels',
    'HappyFoxServiceDesk',
    'OktaAIActions',
    'AzureADAIActions',
    'AIAction',
    'Website',
    'Outline',
    'GoogleDrive',
    'GoogleDriveFolder',
    'GitBook',
    'GitBookSpaces',
    'V2_Announcements',
    'V2_Announcement'
  ],
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    // me
    getProfile: builder.query({
      query: () => 'v1/profile',
      providesTags: ['Profile']
    }),

    // account
    getAccount: builder.query({
      query: () => 'v1/account',
      providesTags: ['Account']
    }),

    getCsrfToken: builder.query({
      query: () => 'v1/csrf-token'
    }),

    // get users
    getUsers: builder.query({
      query: ({ accountType, ...params }) => {
        return {
          url: `v1/${getAccountTypeForUrl(accountType)}/users`,
          method: 'GET',
          params
        }
      },
      providesTags: ['Users']
    }),

    // user groups
    getUserGroups: builder.query({
      query: ({ accountType, ...params }) => {
        return {
          url: `v1/user-groups`,
          method: 'GET',
          params
        }
      },
      providesTags: ['UserGroups']
    }),
    getUserGroup: builder.query({
      query: ({ id }) => `v1/user-groups/${id}`,
      providesTags: ['UserGroups']
    }),
    createUserGroup: builder.mutation({
      query: ({ accountType, ...data }) => {
        return {
          url: `v1/user-groups`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['UserGroups']
    }),
    updateUserGroup: builder.mutation({
      query: ({ id, accountType, ...payload }) => {
        return {
          url: `v1/user-groups/${id}`,
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['UserGroups']
    }),
    deleteUserGroup: builder.mutation({
      query: ({ id }) => {
        return {
          url: `v1/user-groups/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['UserGroups']
    }),
    getUserGroupMetaInfo: builder.query({
      query: () => `v1/user-groups/meta-info`
    }),
    reSyncUserGroup: builder.mutation({
      query: ({ id }) => ({
        url: `v1/user-groups/${id}/sync`,
        method: 'POST'
      }),
      invalidatesTags: ['UserGroups']
    }),
    getAppUserGroups: builder.query({
      query: ({ appName, ...params }) => {
        return {
          url: `v1/apps/${getAppNameForUrl(appName)}/user-groups`,
          method: 'GET',
          params
        }
      }
    }),

    // workspace
    getWorkspaces: builder.query({
      query: (params) => ({
        url: 'v1/workspaces',
        method: 'GET',
        params
      }),
      providesTags: ['Workspaces']
    }),
    createWorkspace: builder.mutation({
      query: (data) => ({
        url: 'v1/workspaces',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Workspaces']
    }),
    updateWorkspace: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/workspaces/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Workspaces']
    }),
    deleteWorkspace: builder.mutation({
      query: (id) => ({
        url: `v1/workspaces/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Workspaces']
    }),

    // dashboard
    getDashboard: builder.query({
      query: () => 'v1/dashboard',
      providesTags: ['Dashboard']
    }),

    // new announcement
    getAnnouncements: builder.query({
      query: (params) => ({
        url: 'v1/announcements',
        method: 'GET',
        params
      }),
      providesTags: ['V2_Announcements']
    }),
    getAnnouncementById: builder.query({
      query: (id) => `/v1/announcements/${id}`,
      providesTags: ['V2_Announcement']
    }),
    createAnnouncement: builder.mutation({
      query: (payload) => {
        return {
          url: '/v1/announcements',
          method: 'POST',
          body: payload
        }
      },
      invalidatesTags: ['V2_Announcements']
    }),
    updateAnnouncement: builder.mutation({
      query: (data) => {
        const { id, ...payload } = data
        return {
          url: `/v1/announcements/${id}`,
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['V2_Announcement']
    }),
    deleteAnnouncement: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/announcements/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['V2_Announcements', 'V2_Announcement']
    }),
    sendAnnouncementToSelf: builder.mutation({
      query: (body) => ({
        url: 'v1/announcements/send/self',
        method: 'POST',
        body
      })
    }),

    // old announcement
    getAnnouncement: builder.query({
      query: () => 'v1/introductory-announcement',
      providesTags: ['Announcement']
    }),
    saveAnnouncement: builder.mutation({
      query: (body) => ({
        url: 'v1/introductory-announcement',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Announcement']
    }),
    previewAnnouncement: builder.mutation({
      query: (body) => ({
        url: 'v1/introductory-announcement/send/self',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Announcement']
    }),
    sendAnnouncementToAll: builder.mutation({
      query: (body) => ({
        url: 'v1/introductory-announcement/send/all',
        method: 'POST',
        body
      }),
      /* no need to invalidate `Dashboard` as the same is manually invalidated
      in Announcement page based on an user action */
      invalidatesTags: ['Announcement']
    }),

    // answer
    listAnswers: builder.query({
      query: (params) => {
        return {
          url: `/v1/answers`,
          method: 'GET',
          params
        }
      },
      providesTags: ['Answers']
    }),
    getAnswerById: builder.query({
      query: (id) => `/v1/answers/${id}`,
      providesTags: ['Answer']
    }),
    createAnswer: builder.mutation({
      query: (payload) => {
        return {
          url: '/v1/answers',
          method: 'POST',
          body: payload
        }
      },
      invalidatesTags: ['Answers']
    }),
    updateAnswer: builder.mutation({
      query: (data) => {
        const { id, ...payload } = data

        return {
          url: `/v1/answers/${id}`,
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['Answer']
    }),
    deleteAnswer: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/answers/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Answers', 'Answer']
    }),

    // notifications
    getNotifications: builder.query({
      query: () => 'v1/notifications',
      providesTags: ['Notifications']
    }),
    updateNotifications: builder.mutation({
      query: (payload) => {
        return {
          method: 'PATCH',
          url: 'v1/notifications',
          body: payload
        }
      },
      invalidatesTags: ['Notifications']
    }),

    // admins
    getAdmins: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: '/v1/admins',
          params
        }
      },
      providesTags: (response) => providesList(response?.results, 'Admins')
    }),
    deleteAdmin: builder.mutation({
      query: (id) => {
        return {
          method: 'DELETE',
          url: `v1/admins/${id}`
        }
      },
      invalidatesTags: (_result, _error, id) => invalidateListById('Admins', id)
    }),
    inviteAdmin: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: `v1/admins/invite`,
          body: data
        }
      },
      invalidatesTags: invalidateList('Admins')
    }),
    resendInvitation: builder.mutation({
      query: (id) => {
        return {
          method: 'POST',
          url: `v1/admins/${id}/re-invite`
        }
      },
      invalidatesTags: (_result, _error, id) => invalidateListById('Admins', id)
    }),
    updateAdmin: builder.mutation({
      query: (data) => {
        const { id, ...payload } = data
        return {
          method: 'PATCH',
          url: `/v1/admins/${id}`,
          body: payload
        }
      },
      invalidatesTags: (_result, _error, data) => invalidateListById('Admins', data.id)
    }),

    // agents
    getAgents: builder.query({
      query: (name) => {
        return {
          method: 'GET',
          url: '/v1/agents',
          params: {
            name
          }
        }
      }
    }),

    // saas
    getSassApps: builder.query({
      query: () => 'v1/saas',
      providesTags: ['SaaSApps']
    }),
    connectSaasApp: builder.mutation({
      query: (key) => {
        return {
          method: 'POST',
          url: `v1/saas/${key}`
        }
      },
      invalidatesTags: ['SaaSApps']
    }),
    disconnectSaasApp: builder.mutation({
      query: (key) => {
        return {
          method: 'DELETE',
          url: `v1/saas/${key}`
        }
      },
      invalidatesTags: ['SaaSApps']
    }),

    // Apps
    getApps: builder.query({
      query: (params) => ({
        url: 'v1/apps',
        params
      }),
      providesTags: ['Apps']
    }),

    // response groups
    getResponseGroups: builder.query({
      query: ({ appName, params }) => {
        return {
          url: `/v1/apps/${appName}/response-groups`,
          method: 'GET',
          params
        }
      },
      providesTags: (response) => providesList(response?.results, 'ResponseGroups')
    }),
    getResponseGroup: builder.query({
      query: ({ appName, id, workspaceId }) => {
        return {
          url: `/v1/apps/${appName}/response-groups/${id}`,
          method: 'GET',
          params: {
            workspace_id: workspaceId
          }
        }
      },
      providesTags: (_result, _error, id) => [{ type: 'ResponseGroupItem', id }, 'ResponseGroupItem']
    }),
    getResponseGroupChildren: builder.query({
      query: ({ appName, id, params }) => {
        return {
          url: `/v1/apps/${appName}/response-groups/${id}/children`,
          method: 'GET',
          params
        }
      },
      providesTags: (response) => providesList(response?.results, 'ResponseGroupsChildren')
    }),
    updateResponseGroup: builder.mutation({
      query: ({ id, appName, ...data }) => {
        return {
          url: `/v1/apps/${appName}/response-groups/${id}`,
          method: 'PATCH',
          body: data
        }
      },
      invalidatesTags: (_result, _error, data) => [
        { type: 'ResponseGroupItem', id: data.id },
        { type: 'ResponseGroups', id: data.id },
        { type: 'ResponseGroupsChildren', id: data.id },
        'ResponseGroupsChildren',
        'ResponseGroupItem',
        'GoogleDriveFolder'
      ]
    }),
    checkResponseGroupVisibilityUpdate: builder.query({
      query: ({ id, appName, ...data }) => {
        return {
          url: `/v1/apps/${appName}/response-groups/${id}/check`,
          method: 'GET',
          params: data
        }
      }
    }),

    // happyfox helpdesk
    getHappyFoxApp: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/happyfox-helpdesk?refresh_metadata=true',
          method: 'GET',
          params
        }
      },
      providesTags: ['HappyFoxHelpdesk']
    }),
    authorizeHappyFoxApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/happyfox-helpdesk/authorize',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['HappyFoxHelpdesk']
    }),
    uninstallHappyFoxApp: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/happyfox-helpdesk',
          method: 'DELETE',
          params
        }
      },
      invalidatesTags: ['HappyFoxHelpdesk', 'Apps', 'Account']
    }),
    updateHappyFoxApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/happyfox-helpdesk',
          method: 'PATCH',
          body: data
        }
      },
      invalidatesTags: ['HappyFoxHelpdesk', 'Account']
    }),
    enableSyncHappyfoxApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/happyfox-helpdesk/sync/enable',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['HappyFoxHelpdesk']
    }),
    getHappyFoxEmployeeNotifications: builder.query({
      query: (params) => {
        return {
          url: 'v1/apps/happyfox-helpdesk/requester/notifications',
          method: 'GET',
          params
        }
      },
      providesTags: ['HappyFoxHelpDeskEmployeeNotifications']
    }),
    updateHappyFoxEmployeeNotifications: builder.mutation({
      query: (payload) => {
        return {
          method: 'PATCH',
          url: 'v1/apps/happyfox-helpdesk/requester/notifications',
          body: payload
        }
      },
      invalidatesTags: ['HappyFoxHelpDeskEmployeeNotifications']
    }),
    getHelpdeskMetaInfo: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/happyfox-helpdesk/metadata',
          method: 'GET',
          params
        }
      },
      providesTags: ['HappyFoxHelpdesk']
    }),

    // guru
    getGuruApp: builder.query({
      query: () => '/v1/apps/guru',
      providesTags: ['Guru']
    }),
    installGuru: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/guru',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Guru', 'Apps', 'Account']
    }),
    uninstallGuru: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/guru',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Guru', 'Apps', 'Account']
    }),
    enableSyncGuru: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/guru/sync/enable',
          method: 'POST',
          body: {}
        }
      },
      invalidatesTags: ['Guru']
    }),

    // notion
    getNotionApp: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/notion',
          method: 'GET',
          params
        }
      },
      providesTags: ['Notion']
    }),
    getNotionUserAccounts: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/notion/accounts',
          method: 'GET',
          params
        }
      },
      providesTags: ['NotionAccounts']
    }),
    getAllNotionPages: builder.query({
      query: (params) => {
        return {
          url: `/v1/apps/notion/pages`,
          method: 'GET',
          params
        }
      },
      providesTags: ['NotionPages']
    }),
    searchNotionPages: builder.query({
      query: ({ title, workspaceId }) => {
        return {
          url: `/v1/apps/notion/pages`,
          method: 'GET',
          params: {
            page_title: title,
            workspace_id: workspaceId
          }
        }
      },
      providesTags: ['NotionPages']
    }),
    syncNotionPages: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/apps/notion/pages/sync`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: [
        'NotionPages',
        'ResponseGroups',
        'ResponseGroupsChildren',
        'ResponseGroupItem',
        'NotionSyncedPages'
      ]
    }),
    getNotionSyncedPages: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/notion/pages/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['NotionSyncedPages']
    }),
    removeNotionPage: builder.mutation({
      query: ({ page_id, workspace_id }) => {
        return {
          url: `/v1/apps/notion/pages/sync/${page_id}`,
          method: 'DELETE',
          params: {
            workspace_id
          }
        }
      },
      invalidatesTags: ['NotionSyncedPages', 'NotionPages']
    }),
    enableSyncNotion: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/notion/sync/enable',
          method: 'POST',
          params
        }
      },
      invalidatesTags: ['Notion', 'NotionPages', 'NotionSyncedPages']
    }),
    uninstallNotion: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/notion',
          method: 'DELETE',
          params
        }
      },
      invalidatesTags: ['Notion', 'Apps', 'Account']
    }),

    // simpplr
    getSimpplrApp: builder.query({
      query: () => '/v1/apps/simpplr',
      providesTags: ['Simpplr']
    }),
    installSimpplr: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/simpplr/authorize',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Simpplr', 'Account']
    }),
    uninstallSimpplr: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/simpplr',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Simpplr', 'Apps', 'Account']
    }),
    getSimpplrSites: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/simpplr/sites/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['SimpplrSites']
    }),
    addSimpplrSite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/simpplr/sites/sync`,
          method: 'POST',
          body: {
            site_id: id
          }
        }
      },
      invalidatesTags: ['SimpplrSites', 'ResponseGroups', 'ResponseGroupsChildren', 'ResponseGroupItem']
    }),
    removeSimpplrSite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/simpplr/sites/sync/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['SimpplrSites', 'ResponseGroups', 'ResponseGroupsChildren', 'ResponseGroupItem']
    }),
    searchSimpplrSites: builder.query({
      query: (siteName) => {
        return {
          url: `/v1/apps/simpplr/sites`,
          method: 'GET',
          params: {
            site_name: siteName
          }
        }
      }
    }),
    enableSyncSimpplr: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/simpplr/sync/enable',
          method: 'POST',
          body: {}
        }
      },
      invalidatesTags: ['Simpplr', 'SimpplrSites']
    }),

    // sharepoint
    getSharePointApp: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/sharepoint',
          method: 'GET',
          params
        }
      },
      providesTags: ['Sharepoint']
    }),
    getSharePointSites: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/sharepoint/sites/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['SharepointSites']
    }),
    uninstallSharePoint: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/sharepoint',
          method: 'DELETE',
          params
        }
      },
      invalidatesTags: [
        'Sharepoint',
        'ResponseGroups',
        'ResponseGroupsChildren',
        'ResponseGroupItem',
        'Apps',
        'Account'
      ]
    }),
    removeSharePointSite: builder.mutation({
      query: ({ site_id, workspace_id }) => {
        return {
          url: `/v1/apps/sharepoint/sites/sync/${site_id}`,
          method: 'DELETE',
          params: {
            workspace_id
          }
        }
      },
      invalidatesTags: ['SharepointSites', 'ResponseGroups', 'ResponseGroupsChildren', 'ResponseGroupItem']
    }),
    searchSharePointSites: builder.query({
      query: ({ site_name, workspace_id }) => {
        return {
          url: `/v1/apps/sharepoint/sites`,
          method: 'GET',
          params: {
            site_name,
            workspace_id
          }
        }
      }
    }),
    addSharePointSite: builder.mutation({
      query: ({ site_id, workspace_id }) => {
        return {
          url: `/v1/apps/sharepoint/sites/sync`,
          method: 'POST',
          body: {
            site_id,
            workspace_id
          }
        }
      },
      invalidatesTags: ['SharepointSites', 'ResponseGroups', 'ResponseGroupsChildren', 'ResponseGroupItem']
    }),
    enableSyncSharePoint: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/sharepoint/sync/enable',
          method: 'POST',
          params
        }
      },
      invalidatesTags: ['Sharepoint', 'SharepointSites']
    }),

    // confluence
    getConfluenceApp: builder.query({
      query: () => '/v1/apps/confluence',
      providesTags: ['Confluence']
    }),
    installConfluence: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/confluence',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Confluence', 'Apps', 'Account']
    }),
    uninstallConfluence: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/confluence',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Confluence', 'Apps', 'Account']
    }),
    enableSyncConfluence: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/confluence/sync/enable',
          method: 'POST',
          body: {}
        }
      },
      invalidatesTags: ['Confluence']
    }),

    // okta
    getOktaApp: builder.query({
      query: () => '/v1/apps/okta',
      providesTags: ['Okta']
    }),
    installOkta: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/okta',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Okta', 'Apps', 'Account']
    }),
    uninstallOkta: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/okta',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Okta', 'UserGroups', 'Apps', 'Account']
    }),
    updateQuickActions: builder.mutation({
      query: ({ appName, ...data }) => {
        return {
          url: `/v1/apps/${appName}/quick-actions`,
          method: 'PATCH',
          body: data
        }
      },
      invalidatesTags: (_result, _error, { appName }) => getTagFromApp(appName)
    }),
    getAllOktaAIActions: builder.query({
      query: () => '/v1/apps/okta/ai-actions',
      providesTags: ['OktaAIActions']
    }),

    // ai action
    updateAppsAIAction: builder.mutation({
      query: ({ appName, id, payload }) => {
        return {
          url: `/v1/apps/${appName}/ai-actions/${id}`,
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: (_result, _error, { appName }) => getAIActionTagFromApp(appName)
    }),

    // azure ad
    getAzureADApp: builder.query({
      query: () => '/v1/apps/azure-ad',
      providesTags: ['AzureAD']
    }),
    uninstallAzureAD: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/azure-ad',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['AzureAD', 'UserGroups', 'Apps', 'Account']
    }),
    getAllAzureADAIActions: builder.query({
      query: () => '/v1/apps/azure-ad/ai-actions',
      providesTags: ['AzureADAIActions']
    }),

    // reports metrics
    getReportMetrics: builder.query({
      query: (params) => ({
        url: 'v1/report-metrics',
        method: 'GET',
        params
      }),
      providesTags: ['ReportMetrics']
    }),
    exportReportMetrics: builder.mutation({
      query: (params) => ({
        url: 'v1/report-metrics/export',
        method: 'GET',
        params
      }),
      providesTags: ['ReportMetrics']
    }),
    getAllTopics: builder.query({
      query: () => '/v1/topics',
      providesTags: ['ReportMetrics']
    }),

    // timezone
    getAllTimeZones: builder.query({
      query: () => '/v1/timezone/all'
    }),
    getUserTimeZone: builder.query({
      query: () => '/v1/timezone/user',
      providesTags: ['TimeZones']
    }),
    updateUserTimeZone: builder.mutation({
      query: (data) => ({
        url: '/v1/timezone/user',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['TimeZones']
    }),
    // slack teams
    getSlackTeams: builder.query({
      query: () => `v1/slack/teams`
    }),

    // slack channels
    getSlackChannels: builder.query({
      query: (params) => {
        return {
          url: `/v1/slack/channels`,
          method: 'GET',
          params
        }
      },
      providesTags: ['SlackChannels']
    }),
    editSlackChannel: builder.mutation({
      query: ({ channelId, ...body }) => {
        return {
          url: `/v1/slack/channels/${channelId}`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['SlackChannels']
    }),

    // happyfox service desk
    getHfServiceDeskApp: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/happyfox-service-desk?refresh_metadata=true',
          method: 'GET',
          params
        }
      },
      providesTags: ['HappyFoxServiceDesk']
    }),
    authorizeHfServiceDeskApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/happyfox-service-desk/authorize',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['HappyFoxServiceDesk']
    }),
    uninstallHfServiceDeskApp: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/happyfox-service-desk',
          method: 'DELETE',
          params
        }
      },
      invalidatesTags: ['HappyFoxServiceDesk', 'Apps', 'Account']
    }),
    updateHfServiceDeskApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/happyfox-service-desk',
          method: 'PATCH',
          body: data
        }
      },
      invalidatesTags: ['HappyFoxServiceDesk', 'Account']
    }),
    enableSyncHfServiceDeskApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/happyfox-service-desk/sync/enable',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['HappyFoxServiceDesk']
    }),
    getHfServiceDeskMetaInfo: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/happyfox-service-desk/metadata',
          method: 'GET',
          params
        }
      },
      providesTags: ['HappyFoxServiceDesk']
    }),

    // AI Actions
    getAIActions: builder.query({
      query: (params) => {
        return {
          url: '/v1/ai-actions',
          method: 'GET',
          params
        }
      },
      providesTags: ['AIAction']
    }),
    createAIAction: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/ai-actions',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['AIAction']
    }),
    getAIActionById: builder.query({
      query: (id) => {
        return {
          url: `/v1/ai-actions/${id}`,
          method: 'GET'
        }
      },
      providesTags: ['AIAction']
    }),
    updateAIAction: builder.mutation({
      query: ({ id, payload }) => {
        return {
          url: `/v1/ai-actions/${id}`,
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['AIAction']
    }),
    getAIActionSettings: builder.query({
      query: () => '/v1/ai-actions-settings',
      providesTags: ['AIAction']
    }),
    toggleAIActionActivation: builder.mutation({
      query: ({ id, newStatus }) => {
        // activate,deactivate
        return {
          url: `/v1/ai-actions/${id}/${newStatus}`,
          method: 'PATCH'
        }
      },
      invalidatesTags: ['AIAction']
    }),
    deleteAIAction: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/ai-actions/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['AIAction']
    }),

    // website
    getWebsite: builder.query({
      query: () => '/v1/apps/website',
      providesTags: ['Website']
    }),
    getWebsiteSync: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/website/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['Website']
    }),
    addWebsite: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/website',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Website']
    }),
    addWebsiteSync: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/v1/apps/website/${id}`,
          method: 'PATCH',
          body: data
        }
      },
      invalidatesTags: ['Website']
    }),
    removeWebsite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/website/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Website']
    }),
    // outline
    getOutlineApp: builder.query({
      query: () => '/v1/apps/outline',
      providesTags: ['Outline']
    }),
    installOutline: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/outline',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Outline']
    }),
    uninstallOutline: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/outline',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Outline']
    }),
    getCollections: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/outline/collections',
          method: 'GET',
          params
        }
      },
      providesTags: ['Outline']
    }),
    addCollections: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/outline/collections/sync',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Outline']
    }),
    getSyncedCollections: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/outline/collections/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['Outline']
    }),
    removeSyncedCollection: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/outline/collections/sync/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Outline']
    }),

    // g-drive
    getGDriveApp: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/google_drive',
          method: 'GET',
          params
        }
      },
      providesTags: ['GoogleDrive']
    }),
    configureGDrive: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/google_drive',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['GoogleDrive', 'Apps', 'Account']
    }),
    getGDriveFolders: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/google_drive/folders',
          method: 'GET',
          params
        }
      },
      providesTags: ['GoogleDriveFolder']
    }),
    excludeGDriveFolder: builder.mutation({
      query: ({ folder_id, workspace_id }) => {
        return {
          url: `/v1/apps/google_drive/folder/sync/${folder_id}`,
          method: 'POST',
          body: { workspace_id }
        }
      },
      invalidatesTags: ['GoogleDriveFolder']
    }),
    syncGDriveFolder: builder.mutation({
      query: ({ folder_id, workspace_id }) => {
        return {
          url: `/v1/apps/google_drive/folder/sync/${folder_id}`,
          method: 'DELETE',
          body: {
            workspace_id
          }
        }
      },
      invalidatesTags: ['GoogleDriveFolder']
    }),
    getExcludedGDriveFolders: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/google_drive/folder/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['GoogleDriveFolder']
    }),
    uninstallGDrive: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/google_drive',
          method: 'DELETE',
          params
        }
      },
      invalidatesTags: ['GoogleDrive', 'GoogleDriveFolder', 'Apps', 'Account']
    }),
    getGitBookApp: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/gitbook',
          method: 'GET',
          params
        }
      },
      providesTags: ['GitBook']
    }),
    connectGitBookApp: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/gitbook',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['GitBook', 'Apps', 'Account']
    }),
    disconnectGitBookApp: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/gitbook',
          method: 'DELETE',
          params
        }
      },
      invalidatesTags: ['GitBook', 'Apps', 'Account']
    }),
    getAvailableGitBookSpaces: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/gitbook/spaces',
          method: 'GET',
          params
        }
      },
      providesTags: ['GitBookSpaces']
    }),
    getCurrentlySyncingGitBookSpaces: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/gitbook/spaces/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['GitBookSpaces']
    }),
    addSpaceToSync: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/gitbook/spaces/sync',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['GitBook', 'GitBookSpaces', 'ResponseGroups', 'ResponseGroupsChildren', 'ResponseGroupItem']
    }),
    removeSpaceFromSync: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/gitbook/spaces/sync',
          method: 'DELETE',
          body: data
        }
      },
      invalidatesTags: ['GitBook', 'GitBookSpaces', 'ResponseGroups', 'ResponseGroupsChildren', 'ResponseGroupItem']
    }),
    enableSyncGitBook: builder.mutation({
      query: (params) => {
        return {
          url: '/v1/apps/gitbook/spaces/sync/enable',
          method: 'POST',
          body: params
        }
      },
      invalidates: ['GitBook']
    })
  })
})

export default api
