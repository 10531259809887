import { Button, Dropdown, Flex, Modal } from '@happyfoxinc/web-components'
import cx from 'classnames'
import { Command } from 'cmdk'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './Topbar.module.css'

import ModalV2 from 'Components/Modal'
import KB_LINKS from 'Constants/kb-links'
import SearchIcon from 'Src/assetsv3/icons/search.svg'
import SupportHelpIcon from 'Src/assetsv3/icons/support-copy.svg'
import UserIcon from 'Src/assetsv3/icons/user.svg'
import SlackLogo from 'Src/assetsv3/logos/slack.svg'
import { useFeature } from 'Src/components/Features'
// import WorkspaceSelect from 'Src/componentsv3/WorkspaceSelect'
import { ACCOUNT_TYPE } from 'Src/constants/account'
import { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'
import Notifications from 'Src/pagesv3/Notifications'
import TimeZone from 'Src/pagesv3/Settings/TimeZone'
import { useGetAccountQuery, useGetProfileQuery } from 'Src/servicesV3/authApi'
import { useGetAllTimeZonesQuery, useGetNotificationsQuery, useGetUserTimeZoneQuery } from 'Src/servicesV3/settingsApi'
import { openUrl, redirect } from 'Src/utils/url'

import { navigationItems } from './navigationConfig'

export const useImageWithFallback = (src) => {
  const [imgSrc, setImgSrc] = useState(src)
  const [error, setError] = useState(false)

  useEffect(() => {
    setImgSrc(src)
    setError(false)
  }, [src])

  const onError = useCallback(() => {
    setError(true)
    setImgSrc(null)
  }, [])

  return { imgSrc, error, onError }
}

const SettingsModal = ({
  selectedTab,
  setSelectedTab,
  isModalOpen,
  setIsModalOpen,
  isTicketingIntegrationConnected,
  showTimeZone
}) => {
  const { isLoading: isNotificationsLoading } = useGetNotificationsQuery()
  const { data: timeZones = [], isLoading: isAllTimeZonesLoading } = useGetAllTimeZonesQuery()
  const { data: userTimeZone = {}, isLoading: isUserTimeZoneLoading } = useGetUserTimeZoneQuery()

  const SETTINGS_TABS = useMemo(() => {
    return {
      settings: {
        label: 'Settings',
        hidden: !isTicketingIntegrationConnected
      },
      timeZone: {
        label: 'Time Zone',
        hidden: !showTimeZone
      }
    }
  }, [showTimeZone, isTicketingIntegrationConnected])

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleTabChange = useCallback(
    (tabName) => {
      setSelectedTab(tabName)
    },
    [setSelectedTab]
  )

  const isLoading = isNotificationsLoading || isAllTimeZonesLoading || isUserTimeZoneLoading

  return (
    <ModalV2
      onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      isOpen={isModalOpen}
      onCancel={handleModalClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
    >
      <form className={styles.formContainer}>
        <div className={styles.modalHeader}>
          <div className={styles.modalTabContainer}>
            {Object.keys(SETTINGS_TABS).map(
              (tabName) =>
                !SETTINGS_TABS[tabName].hidden && (
                  <div
                    key={tabName}
                    className={cx(styles.modalTabItem, selectedTab === tabName && styles.active)}
                    onClick={() => handleTabChange(tabName)}
                  >
                    {SETTINGS_TABS[tabName]?.label}
                  </div>
                )
            )}
          </div>
          <Button type='button' className={styles.closeButton} variant='secondary-outline' onClick={handleModalClose}>
            X
          </Button>
        </div>
        <div className={styles.contentContainer}>
          {selectedTab === 'settings' && <Notifications />}
          {selectedTab === 'timeZone' && (
            <TimeZone
              isLoading={isLoading}
              timeZones={timeZones}
              userTimeZone={userTimeZone}
              closeModal={handleModalClose}
            />
          )}
        </div>
      </form>
    </ModalV2>
  )
}

const CommandSearch = ({
  isOpen,
  searchQuery,
  setSearchQuery,
  onItemSelect,
  onClose,
  placeholder,
  isModal = false,
  inputRef,
  onFocus,
  enabledFeatures = [],
  accountType
}) => {
  const commandRef = useRef(null)

  const filteredItems = useMemo(() => {
    let navigationItemsCopy = JSON.parse(JSON.stringify(navigationItems))

    if (accountType === ACCOUNT_TYPE.SLACK) {
      const appsItem = navigationItemsCopy.find((item) => item.label === 'Apps')

      if (appsItem && appsItem.children) {
        appsItem.children = appsItem.children.filter((child) => child.path !== '/apps/azure_ad')
      }
    } else if (accountType === ACCOUNT_TYPE.MS_TEAMS) {
      navigationItemsCopy = navigationItemsCopy.filter((item) => item.label !== 'Channels')
    }

    const filteredNavigationItems = navigationItemsCopy.filter((item) => {
      if (item.label !== 'Reports' && item.label !== 'User Groups') {
        return true
      }

      if (item.label === 'Reports') {
        return enabledFeatures.includes('reports')
      }

      if (item.label === 'User Groups') {
        return enabledFeatures.includes('user_groups')
      }

      return false
    })

    if (!searchQuery) {
      return filteredNavigationItems
    }

    const lowercaseQuery = searchQuery.toLowerCase()
    const allItems = []

    filteredNavigationItems.forEach((item) => {
      if (item.label.toLowerCase().includes(lowercaseQuery)) {
        allItems.push(item)
      }

      if (item.children) {
        const matchingChildren = item.children.filter((child) => child.label.toLowerCase().includes(lowercaseQuery))
        allItems.push(...matchingChildren)
      }
    })

    return allItems
  }, [accountType, searchQuery, enabledFeatures])

  if (isModal) {
    return (
      <div className={styles.centerModalOverlay} onClick={onClose}>
        <div className={styles.centerModal} onClick={(e) => e.stopPropagation()}>
          <Command
            ref={commandRef}
            className={styles.centerCommand}
            filter={(value, search) => {
              if (!search) return 1
              return value.toLowerCase().includes(search.toLowerCase()) ? 1 : 0
            }}
          >
            <div className={styles.centerInputWrapper} cmdk-input-wrapper=''>
              <Command.Input
                ref={inputRef}
                autoFocus
                value={searchQuery}
                onValueChange={setSearchQuery}
                className={styles.centerInput}
                placeholder={placeholder || 'Go to...'}
              />
            </div>
            <Command.List className={styles.centerCommandList}>
              {filteredItems.length === 0 ? (
                <Command.Empty className={styles.noResults}>No records found</Command.Empty>
              ) : (
                filteredItems.map((item) => (
                  <Command.Item
                    key={item.path}
                    value={item.label}
                    onSelect={() => onItemSelect(item.path)}
                    className={styles.commandItem}
                  >
                    {item.label}
                  </Command.Item>
                ))
              )}
            </Command.List>
          </Command>
        </div>
      </div>
    )
  }

  return (
    <Command ref={commandRef} className={styles.commandWrapper}>
      <SearchIcon className='search-icon' />
      <Command.Input
        ref={inputRef}
        value={searchQuery}
        onValueChange={setSearchQuery}
        className='search-input'
        placeholder={placeholder || 'Navigate to..'}
        onFocus={onFocus}
      />

      {isOpen && (
        <div className={styles.searchDropdown}>
          <Command.List className={styles.commandList}>
            {filteredItems.length === 0 ? (
              <Command.Empty className={styles.noResults}>No records found</Command.Empty>
            ) : (
              filteredItems.map((item) => (
                <Command.Item key={item.path} onSelect={() => onItemSelect(item.path)} className={styles.commandItem}>
                  {item.label}
                </Command.Item>
              ))
            )}
          </Command.List>
        </div>
      )}
    </Command>
  )
}

const UserMenu = ({ user, account, showSettings, onSettingsClick }) => {
  const [openLogoutDropdown, setOpenLogoutDropdown] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const { imgSrc, error, onError } = useImageWithFallback(user?.avatar_url)

  const handleLogout = useCallback(async () => {
    setIsLogoutModalOpen(false)
    setOpenLogoutDropdown(false)
    redirect('logout')
  }, [])

  return (
    <Fragment>
      <Dropdown.Root open={openLogoutDropdown} onOpenChange={setOpenLogoutDropdown}>
        <Dropdown.Trigger asChild>
          {imgSrc && !error ? (
            <img src={imgSrc} alt='User avatar' className={styles.avatar} onError={onError} />
          ) : (
            <div className={styles.userIcon}>
              <UserIcon />
            </div>
          )}
        </Dropdown.Trigger>
        <Dropdown.Content
          className={styles.dropdownContent}
          sideOffset={6}
          align='end'
          onEscapeKeyDown={() => setOpenLogoutDropdown(false)}
          onInteractOutside={() => setOpenLogoutDropdown(false)}
        >
          <Fragment>
            <Flex justify='space-between' align='center'>
              <div className={styles.userInfo}>
                <p className={styles.userName}>{user?.name}</p>
                <p className={styles.userEmail}>{user?.email}</p>
              </div>
              <Flex justify='flex-start' align='center' width='100%' className={styles.badges}>
                <div className={styles.premiumBadge}>{account?.plan_name}</div>
              </Flex>
            </Flex>
            <div className={styles.divider} />
            <div className={styles.dropdownItemContainer}>
              {showSettings && (
                <div
                  className={styles.dropdownItem}
                  onClick={() => {
                    setOpenLogoutDropdown(false)
                    onSettingsClick()
                  }}
                >
                  Settings
                </div>
              )}
              <div
                className={styles.dropdownItem}
                onClick={() => {
                  setOpenLogoutDropdown(false)
                  setIsLogoutModalOpen(true)
                }}
              >
                Logout
              </div>
            </div>
          </Fragment>
        </Dropdown.Content>
      </Dropdown.Root>

      <Modal
        size='small'
        title='Logout'
        showFooter={false}
        open={isLogoutModalOpen}
        onOpenChange={setIsLogoutModalOpen}
        onCancel={() => setIsLogoutModalOpen(false)}
        bodyClassName={styles.modalBody}
        contentClassName={styles.modalContent}
      >
        <Flex direction='column' gap='12px' align='center'>
          <p>Are you sure you want to logout?</p>
          <Flex gap='15px'>
            <Button variant='solid' onClick={handleLogout}>
              Logout
            </Button>
            <Button variant='outline' onClick={() => setIsLogoutModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Fragment>
  )
}

const Topbar = () => {
  const navigate = useNavigate()
  const searchRef = useRef(null)
  const centerInputRef = useRef(null)
  const searchWrapperRef = useRef(null)

  const [dropdownSearchQuery, setDropdownSearchQuery] = useState('')
  const [centerSearchQuery, setCenterSearchQuery] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCenterModalOpen, setIsCenterModalOpen] = useState(false)

  const [settingsState, setSettingsState] = useState({
    isSettingsModalOpen: false,
    showSettings: true,
    selectedTab: 'settings'
  })

  const [sidebarCollapsed, setSidebarCollapsed] = useState(localStorage.getItem('sidebar_collapsed_state') === 'true')

  const { data: user = {} } = useGetProfileQuery()
  const { data: account = {} } = useGetAccountQuery()
  const showTimeZone = useFeature([CLIENT_FEATURE_FLAGS.MS_TEAMS_ACCOUNT])

  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS
  const enabledFeatures = account.enabled_features || []

  const updateSettingsState = useCallback((updates) => {
    setSettingsState((prev) => ({ ...prev, ...updates }))
  }, [])

  const handleItemSelect = useCallback(
    (path) => {
      navigate(path)
      setIsDropdownOpen(false)
      setIsCenterModalOpen(false)
      setDropdownSearchQuery('')
      setCenterSearchQuery('')

      requestAnimationFrame(() => {
        if (document.activeElement) {
          document.activeElement.blur()
        }
      })
    },
    [navigate]
  )

  const handleAccountNameClick = useCallback(() => {
    openUrl(account.app_url)
  }, [account.app_url])

  const handleOpenSettings = useCallback(() => {
    updateSettingsState({ isSettingsModalOpen: true })
  }, [updateSettingsState])

  useEffect(() => {
    const targetNode = document.documentElement
    const config = { attributes: true, childList: true, subtree: true }

    const callback = (mutationsList, observer) => {
      const currentState = localStorage.getItem('sidebar_collapsed_state') === 'true'
      if (currentState !== sidebarCollapsed) {
        setSidebarCollapsed(currentState)
      }
    }

    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)

    return () => observer.disconnect()
  }, [sidebarCollapsed])

  // Handle CMD+K keyboard shortcut
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        setIsCenterModalOpen(true)
        setIsDropdownOpen(false)
        setCenterSearchQuery(dropdownSearchQuery)
      }

      if (event.key === 'Escape') {
        setIsCenterModalOpen(false)
        setIsDropdownOpen(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [dropdownSearchQuery])

  // Handle click outside search dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isCenterModalOpen && centerInputRef.current) {
      requestAnimationFrame(() => {
        centerInputRef.current.focus()
      })
    }
  }, [isCenterModalOpen])

  useEffect(() => {
    let showSettings = true
    let selectedTab = settingsState.selectedTab

    if (isSlackAccountType && !account.is_ticketing_integration_connected) {
      showSettings = false
    } else if (isMsTeamsAccountType) {
      if (!showTimeZone && !account.is_ticketing_integration_connected) {
        showSettings = false
      } else if (showTimeZone && !account.is_ticketing_integration_connected) {
        selectedTab = 'timeZone'
      }
    }

    updateSettingsState({ showSettings, selectedTab })
  }, [
    isSlackAccountType,
    isMsTeamsAccountType,
    account.is_ticketing_integration_connected,
    showTimeZone,
    updateSettingsState,
    settingsState.selectedTab
  ])

  return (
    <Flex>
      <div className={styles.topbarContainer}>
        <div className={styles.placeholderDiv} />

        {/* Search Section */}
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper} ref={searchWrapperRef}>
            <CommandSearch
              isOpen={isDropdownOpen}
              searchQuery={dropdownSearchQuery}
              setSearchQuery={setDropdownSearchQuery}
              onItemSelect={handleItemSelect}
              placeholder='Navigate to..'
              inputRef={searchRef}
              enabledFeatures={enabledFeatures}
              accountType={account.account_type}
              onFocus={() => {
                setIsDropdownOpen(true)
                setIsCenterModalOpen(false)
              }}
            />

            <div className={styles.searchShortcut}>
              <span className={styles.shortcutKey}>CMD ⌘</span>
              <span>K</span>
            </div>
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.iconButton} onClick={() => openUrl(KB_LINKS.AAI_HELP)}>
            <SupportHelpIcon />
          </div>

          {/* <WorkspaceSelect /> */}

          <div className={styles.AccountItem} onClick={handleAccountNameClick}>
            {isSlackAccountType && <SlackLogo height='20' width='20' />}
            {isMsTeamsAccountType && <div className={styles.MicrosoftLogo} />}
            {account.account_name}
          </div>

          <UserMenu
            user={user}
            account={account}
            showSettings={settingsState.showSettings}
            onSettingsClick={handleOpenSettings}
          />

          <SettingsModal
            selectedTab={settingsState.selectedTab}
            showTimeZone={showTimeZone}
            setSelectedTab={(tab) => updateSettingsState({ selectedTab: tab })}
            isModalOpen={settingsState.isSettingsModalOpen}
            setIsModalOpen={(isOpen) => updateSettingsState({ isSettingsModalOpen: isOpen })}
            isTicketingIntegrationConnected={account.is_ticketing_integration_connected}
          />
        </div>

        {/* Center Modal */}
        {isCenterModalOpen && (
          <CommandSearch
            isOpen
            isModal
            searchQuery={centerSearchQuery}
            setSearchQuery={setCenterSearchQuery}
            onItemSelect={handleItemSelect}
            onClose={() => setIsCenterModalOpen(false)}
            placeholder='Go to...'
            inputRef={centerInputRef}
            enabledFeatures={enabledFeatures}
            accountType={account.account_type}
          />
        )}
      </div>
    </Flex>
  )
}

export default Topbar
