import { baseApi, TAGS } from './baseApi'

const FIELD_ICONS = {
  location: '📍',
  department: '🏢',
  role: '👤',
  user_groups: '👥'
}

export const softwareAccessModuleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getModuleMetaInfo: builder.query({
      query: () => `v1/access-control/meta-info`,
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.PROVISION_STEPS]
    }),

    getAccessControlMeta: builder.query({
      query: () => `v1/access-control/meta-info`,
      transformResponse: (response) => {
        if (!response || !response.fields) return []

        return response.fields.map((field) => {
          field.icon = FIELD_ICONS[field.name]
          return field
        })
      }
    }),

    getProvisioningMeta: builder.query({
      query: () => `v1/modules/software-access/provision-steps/meta-info`
    }),

    saveSoftwareAccessControl: builder.mutation({
      query: ({ catalogItemId, ...payload }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}/access-control`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [TAGS.SOFTWARE_ACCESS_MODULE.ACCESS_CONTROL]
    }),

    getSoftwareAccessControl: builder.query({
      query: ({ catalogItemId }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}/access-control`,
        method: 'GET'
      }),
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.ACCESS_CONTROL]
    }),

    saveSoftwareProvisionSteps: builder.mutation({
      query: ({ catalogItemId, steps }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}/provision-steps`,
        method: 'POST',
        body: steps
      }),
      invalidatesTags: [TAGS.SOFTWARE_ACCESS_MODULE.PROVISION_STEPS]
    }),

    getSoftwareProvisionSteps: builder.query({
      query: ({ catalogItemId }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}/provision-steps`,
        method: 'GET'
      }),
      invalidatesTags: [TAGS.SOFTWARE_ACCESS_MODULE.PROVISION_STEPS]
    }),

    getTicketingConfiguration: builder.query({
      query: ({ catalogItemId }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}/ticketing-configuration`,
        method: 'GET'
      }),
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.TICKET_CONFIGURATION]
    }),

    saveTicketingConfiguration: builder.mutation({
      query: ({ catalogItemId, ...payload }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}/ticketing-configuration`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [TAGS.SOFTWARE_ACCESS_MODULE.TICKET_CONFIGURATION]
    }),

    getSoftwares: builder.query({
      query: () => `v1/modules/software-access/softwares`,
      providesTags: [TAGS.SOFTWARE_ACCESS_MODULE.SOFTWARES]
    }),

    startAppConfiguration: builder.mutation({
      query: (data) => ({
        url: 'v1/modules/software-access/softwares/configure/start',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SOFTWARE_ACCESS_MODULE]
    }),

    updateSoftware: builder.mutation({
      query: ({ catalogItemId, ...payload }) => ({
        url: `v1/modules/software-access/softwares/${catalogItemId}`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: [TAGS.SOFTWARE_ACCESS_MODULE.SOFTWARES]
    })
  })
})

export const {
  useGetModuleMetaInfoQuery, // TODO: remove if not used
  useGetAccessControlMetaQuery,
  useGetProvisioningMetaQuery,
  useSaveSoftwareAccessControlMutation,
  useSaveSoftwareProvisionStepsMutation,
  useGetSoftwaresQuery,
  useGetSoftwareAccessControlQuery,
  useGetSoftwareProvisionStepsQuery,
  useGetTicketingConfigurationQuery,
  useSaveTicketingConfigurationMutation,
  useStartAppConfigurationMutation,
  useUpdateSoftwareMutation
} = softwareAccessModuleApi
