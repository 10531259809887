import { Box, Button, Flex, Modal } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import cx from 'classnames'

import styles from './CompanyPolicyModule.module.css'

import ChevronRightIcon from 'Src/assetsv3/icons/chevron-right-white.svg'
import CloseIcon from 'Src/assetsv3/icons/close.svg'
import AlertCircleIcon from 'Src/assetsv3/icons/octagon-alert.svg'
import BackButton from 'Src/componentsv3/BackButton'
import Tab from 'Src/componentsv3/Tab'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useGetPoliciesQuery, useSavePoliciesMutation } from 'Src/servicesV3/companyPolicyModuleApi'

import CompanyPolicyList from './CompanyPolicyList'
import ConfigModule from './ConfigModule'

import { MODULES, COMPANY_POLICIES } from 'Src/pagesv3/Modules/constants'

const CompanyPolicyModule = () => {
  const validationTimeoutRef = useRef(null)

  const [isSetupStarted, setIsSetupStarted] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(0)
  const [policyData, setPolicyData] = useState([])
  const [showValidation, setShowValidation] = useState(false) // Simplified state
  const [validationMessage, setValidationMessage] = useState('')
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [dropdownErrors, setDropdownErrors] = useState({})

  const { data = {}, isLoading } = useGetPoliciesQuery({ page: 1 })
  const { data: policiesList = [] } = data || {}
  const [savePolicies] = useSavePoliciesMutation()

  const [view, setView] = useState(policiesList.length > 0 ? 'overview' : 'configuration')

  const originalPolicyDataRef = useRef([])

  useEffect(() => {
    if (showValidation) {
      validationTimeoutRef.current = setTimeout(() => {
        setShowValidation(false)
        setValidationMessage('')
      }, 20 * 1000)
    }

    return () => {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current)
      }
    }
  }, [showValidation])

  const handleCloseValidation = () => {
    setShowValidation(false)
    setValidationMessage('')
    if (validationTimeoutRef.current) {
      clearTimeout(validationTimeoutRef.current)
    }
  }

  const handleConfigUpdate = (config) => {
    setPolicyData(config)
    const configChanged = JSON.stringify(config) !== JSON.stringify(originalPolicyDataRef.current)
    setHasUnsavedChanges(configChanged)
  }

  const handleStartSetup = () => {
    setIsSetupStarted((prev) => !prev)
    setCurrentScreen(0)
    setHasUnsavedChanges(false)
    originalPolicyDataRef.current = policyData
  }

  const transformPolicyData = (policies) => {
    return policies.map((policy) => ({
      policy_id: policy.policy_id,
      policy_name: policy.policy_name,
      file_id: policy.file_id,
      file_name: policy.file_name,
      source: policy.source,
      access_control: {
        isFullAccess: policy.accessConfig.isFullAccess,
        conditions: policy.accessConfig.conditions
      },
      ticket_creation: policy.ticket_creation
    }))
  }

  const handleNextScreen = async () => {
    if (currentScreen === 0) {
      if (policiesList.length === 0) {
        setValidationMessage('Please create at least one policy type before proceeding')
        setShowValidation(true)
        return
      }
    }

    if (currentScreen === 1) {
      if (policyData.length === 0) {
        setValidationMessage('Please add at least one policy before proceeding')
        setShowValidation(true)
        return
      }

      const policiesWithoutType = policyData.filter((policy) => !policy.policy_id || !policy.policy_name)

      if (policiesWithoutType.length > 0) {
        const errors = {}
        policiesWithoutType.forEach((policy) => {
          errors[policy.file_id] = true
        })
        setDropdownErrors(errors)
        return
      }
    }

    if (currentScreen === 2) {
      const unconfiguredPolicies = policyData.filter((policy) => !policy.accessConfig?.configured)

      if (unconfiguredPolicies.length > 0) {
        setValidationMessage(
          `Please configure access control for ${
            unconfiguredPolicies.length === 1 ? 'the remaining policy' : 'all policies'
          } before proceeding`
        )
        setShowValidation(true)
        return
      }

      try {
        const promise = handleSavePolicies(policyData)
        await promise
        return
      } catch {
        return
      }
    }

    // Only move to next screen if we get here
    if (currentScreen < 2) {
      setCurrentScreen((prev) => prev + 1)
      setValidationMessage('')
    }
  }

  const handleCancelButton = () => {
    if (currentScreen > 0) {
      setCurrentScreen((prev) => prev - 1)
    } else {
      setIsSetupStarted(false)
    }
  }

  const handleModalCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true)
    } else {
      handleStartSetup()
    }
  }

  const handleSavePolicies = async (policies) => {
    try {
      const configuredPolicies = policies.filter((policy) => policy.accessConfig?.configured)
      const transformedData = transformPolicyData(configuredPolicies)

      const promise = savePolicies(transformedData).unwrap()

      await toast.promise(promise, {
        loading: 'Saving policies...',
        success: 'Policies saved successfully',
        error: (err) => err?.data?.error || 'Failed to save policies'
      })

      setIsSetupStarted(false)
      setCurrentScreen(0)
      setHasUnsavedChanges(false)
    } catch {}
  }

  const handleSkipPolicies = () => {
    const configuredPolicies = policyData.filter((policy) => {
      return policy.policy_id && policy.policy_name
    })
    setPolicyData(configuredPolicies)
    setShowWarning(false)
    setDropdownErrors({})
    setCurrentScreen((prev) => prev + 1)
  }

  useEffect(() => {
    setView(policiesList.length > 0 ? 'overview' : 'configuration')
  }, [policiesList])

  return (
    <Fragment>
      <Modal
        size='full'
        open={isSetupStarted}
        onOpenChange={handleModalCloseAttempt}
        showProgressBar
        currentStep={currentScreen + 1}
        totalSteps={3}
        confirmText={currentScreen < 2 ? 'Proceed' : 'Finish'}
        cancelText={currentScreen > 0 ? 'Back' : 'Cancel'}
        onCancel={handleCancelButton}
        onConfirm={handleNextScreen}
        title='Configure Company Policies'
        bodyClassName={styles.configModalBody}
      >
        {showValidation && (
          <div className={styles.validationBanner}>
            <div className={styles.validationContent}>
              <div className={styles.validationLeft}>
                <AlertCircleIcon className={styles.validationIcon} />
                <span className={styles.validationMessage}>{validationMessage}</span>
              </div>
              <button
                onClick={handleCloseValidation}
                className={styles.validationCloseButton}
                aria-label='Close validation message'
              >
                <CloseIcon size={16} />
              </button>
            </div>
          </div>
        )}

        {showUnsavedDialog && (
          <div className={styles.warningBanner}>
            <div className={styles.warningContent}>
              <div className={styles.warningMessage}>You have unsaved changes. Are you sure you want to leave?</div>
              <div className={styles.warningActions}>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={() => setShowUnsavedDialog(false)}
                  className={cx(styles.warningButton, styles.cancel)}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='primary'
                  onClick={() => {
                    setShowUnsavedDialog(false)
                    setHasUnsavedChanges(false)
                    handleStartSetup()
                  }}
                  className={cx(styles.warningButton, styles.leave)}
                >
                  Leave
                </Button>
              </div>
            </div>
          </div>
        )}

        {showWarning && (
          <div className={styles.warningBanner}>
            <div className={styles.warningContent}>
              <div className={styles.warningMessage}>
                Some policies are missing policy types. Please assign policy types to continue.
              </div>
              <div className={styles.warningActions}>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={() => setShowWarning(false)}
                  className={styles.warningButton}
                >
                  Cancel
                </Button>
                <Button type='submit' variant='primary' onClick={handleSkipPolicies} className={styles.warningButton}>
                  Skip These Policies
                </Button>
              </div>
            </div>
          </div>
        )}
        <ConfigModule
          currentScreen={currentScreen}
          onConfigUpdate={handleConfigUpdate}
          showValidation={showValidation}
          setValidationError={setShowValidation}
          dropdownErrors={dropdownErrors}
          setDropdownErrors={setDropdownErrors}
        />
      </Modal>

      <Box className='page-content'>
        <div className='heading-container'>
          <Flex align='center' gap='4px'>
            <BackButton className='back-btn' />
            <h1 className='heading'>{MODULES[COMPANY_POLICIES].title}</h1>
          </Flex>
          <Box width='65%'>
            <p className='sub-heading'>{MODULES[COMPANY_POLICIES].description}</p>
          </Box>
        </div>

        <Flex className={styles.tabContainer} align='center' gap='20px'>
          {policiesList.length > 0 ? (
            <Tab label='Overview' isActive={view === 'overview'} onClick={() => setView('overview')} />
          ) : (
            <Tab label='Configuration' isActive={view === 'configuration'} onClick={() => setView('configuration')} />
          )}
          {/* <Tab label='Reports' isActive={view === 'reports'} onClick={() => setView('reports')} /> */}
        </Flex>

        {isLoading ? (
          <TableLoader rows={10} columns={4} showOptions />
        ) : policiesList.length > 0 ? (
          <Fragment>
            <Flex align='center' justify='space-between'>
              <h2 className={styles.policiesListTitle}>Policies</h2>
              <Button variant='solid' onClick={handleStartSetup}>
                Configure Policy
              </Button>
            </Flex>
            <CompanyPolicyList onRowClick={handleStartSetup} />
          </Fragment>
        ) : (
          <Flex
            direction='column'
            width='100%'
            height='280px'
            align='center'
            justify='center'
            gap='25px'
            p='40px'
            className={styles.ConfigurationContainer}
          >
            <h2 className={styles.NoConfigTitle}>No Policies Configured Yet</h2>
            <Box width='450px'>
              <p className={styles.NoConfigDescription}>
                Start configuring your company policies so Assist AI can instantly respond to employee inquiries about
                organizational policies.
              </p>
            </Box>
            <Button onClick={handleStartSetup}>
              Configure Policies
              <ChevronRightIcon />
            </Button>
          </Flex>
        )}
      </Box>
    </Fragment>
  )
}

export default CompanyPolicyModule
