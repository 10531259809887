import { Box, Button, Flex } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useRef, useState } from 'react'

import styles from './AccessControl.module.css'

import CloseIcon from 'Src/assetsv3/icons/close.svg'
import EditIcon from 'Src/assetsv3/icons/edit.svg'
import TrashIcon from 'Src/assetsv3/icons/trash.svg'

import { getFieldOptionsFromAccessControl } from './ConfigModuleUtils'
import SelectCheckbox from './SelectCheckbox'

const SavedCondition = ({ condition, onEdit, onDelete, conditionNumber, accessControlData }) => {
  const fieldOptions = getFieldOptionsFromAccessControl(accessControlData)

  const groupedFields = Object.entries(condition).map(([fieldId, idArray]) => {
    const field = accessControlData.find((f) => f.id === parseInt(fieldId))
    const values = idArray.map((id) => field?.allowed_values.find((v) => v.id === id)?.label).filter(Boolean)
    const fieldOption = fieldOptions.find((f) => f.id === parseInt(fieldId))

    return {
      fieldId: parseInt(fieldId),
      fieldName: fieldOption?.label || '',
      fieldIcon: fieldOption?.icon,
      values,
      totalValues: values.length
    }
  })

  const visibleFields = groupedFields.slice(0, 2)
  const remainingFieldsCount = groupedFields.length - 2

  return (
    <div className={styles.savedConditionContainer} onClick={() => onEdit(conditionNumber - 1)}>
      <div className={styles.savedConditionHeader}>
        <h3 className={styles.savedConditionTitle}>Access Condition {conditionNumber}</h3>
        <Flex gap='6px'>
          <button
            className={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation()
              onDelete(conditionNumber - 1)
            }}
          >
            <TrashIcon />
          </button>
          <button className={styles.editButton} onClick={() => onEdit(conditionNumber - 1)}>
            <EditIcon />
          </button>
        </Flex>
      </div>
      <div className={styles.savedConditionContent}>
        {visibleFields.map((field, index) => {
          const firstValue = field.values[0]
          const additionalCount = field.values.length - 1

          return (
            <div key={`${field.fieldId}-${index}`} className={styles.fieldContainer}>
              <div className={styles.fieldValue}>
                <span className={styles.fieldIcon}>{field.fieldIcon}</span>
                <span className={styles.fieldLabel}>{field.fieldName}:</span>
                <span className={styles.fieldValueText}>
                  {firstValue}
                  {additionalCount > 0 && ` +${additionalCount}`}
                </span>
              </div>
              {index === visibleFields.length - 1 && remainingFieldsCount > 0 && (
                <div className={styles.remainingCount}>+{remainingFieldsCount}</div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const FieldValuePair = ({
  availableFieldOptions = [],
  selectedField,
  selectedValues = [],
  onFieldChange,
  onValueChange,
  onDelete,
  isOnly,
  availableValues = [],
  usedFields = []
}) => {
  const selectedFieldNum = selectedField !== '' && selectedField != null ? Number(selectedField) : null
  const usedFieldNums = usedFields.filter((id) => id !== '').map((id) => Number(id))

  const fieldSelectOptions = availableFieldOptions
    .map((field) => ({
      id: Number(field.id),
      value: String(field.id),
      label: field.label || field.name,
      icon: field.icon
    }))
    .filter((field) => {
      const fieldId = Number(field.id)
      const isUsed = usedFieldNums.includes(fieldId)
      const isSelected = selectedFieldNum === fieldId
      return !isUsed || isSelected
    })

  const valueSelectOptions = availableValues.map((value) => ({
    value: String(value.id),
    label: value.label
  }))

  const selectedFieldOption = availableFieldOptions.find((f) => Number(f.id) === selectedFieldNum)

  return (
    <div className={styles.dropdownContainer}>
      <Box width='240px'>
        <SelectCheckbox
          value={
            selectedFieldNum != null
              ? {
                  value: String(selectedFieldNum),
                  label: selectedFieldOption?.label || selectedFieldOption?.name,
                  icon: selectedFieldOption?.icon
                }
              : null
          }
          options={fieldSelectOptions}
          onChange={(option) => {
            if (option) {
              onFieldChange(option.value)
            }
          }}
          placeholder='Select Field'
        />
      </Box>
      <Box width='240px'>
        <SelectCheckbox
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={selectedValues.map((value) => ({
            value: String(value.id),
            label: value.label
          }))}
          options={valueSelectOptions}
          onChange={(selectedOptions) => {
            const selectedValueObjs = selectedOptions
              .map((option) => availableValues.find((v) => String(v.id) === option.value))
              .filter(Boolean)
            onValueChange(selectedValueObjs)
          }}
          isDisabled={selectedFieldNum == null}
          placeholder='Please select'
        />
      </Box>

      {!isOnly && (
        <button className={styles.deleteFieldButton} onClick={onDelete}>
          <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='12' height='11'>
            <path
              fill='none'
              stroke='black'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='bevel'
              transform='matrix(0.707107 0.707107 -0.707107 0.707107 7.73165 2.68822)'
              d='M0.25471932 0.25471932L0.25471932 6.8774219'
              fillRule='evenodd'
            />
            <path
              fill='none'
              stroke='black'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='bevel'
              transform='matrix(0.707107 0.707107 -0.707107 0.707107 3.22881 2.86833)'
              d='M0 0.25471932L6.6227026 0.25471932'
              fillRule='evenodd'
            />
          </svg>
        </button>
      )}
    </div>
  )
}

const ConditionForm = ({
  onSave,
  onCancel,
  conditionNumber = 1,
  initialData = null,
  accessControlData,
  onValidChange,
  onDataChange
}) => {
  const formRef = useRef(null)

  const getInitialFieldPairs = () => {
    if (initialData) {
      return Object.entries(initialData).map(([fieldId, idArray]) => {
        const numericFieldId = Number(fieldId)
        const field = accessControlData.find((f) => f.id === numericFieldId)

        const values = idArray
          .map((id) => {
            const valueObj = field?.allowed_values.find((v) => v.id === id)
            return valueObj
              ? {
                  id: valueObj.id,
                  label: valueObj.label
                }
              : null
          })
          .filter(Boolean)

        return {
          field: numericFieldId,
          values
        }
      })
    }
    return [{ field: '', values: [] }]
  }

  const [fieldPairs, setFieldPairs] = useState(getInitialFieldPairs)

  // Create isValid function without useCallback
  const isValid = () => {
    const validPairs = fieldPairs.filter((pair) => pair.field !== '' && pair.field != null && pair.values.length > 0)
    return validPairs.length > 0
  }

  // Create getCurrentFormData as regular function
  const getCurrentFormData = () => {
    const validPairs = fieldPairs.filter((pair) => pair.field !== '' && pair.field != null && pair.values.length > 0)

    return validPairs.reduce((acc, pair) => {
      acc[String(pair.field)] = pair.values.map((value) => value.id)
      return acc
    }, {})
  }

  // Split the effects for validity and data changes
  useEffect(() => {
    if (onValidChange) {
      onValidChange(isValid())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPairs, onValidChange])

  useEffect(() => {
    if (onDataChange) {
      onDataChange(getCurrentFormData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPairs, onDataChange])

  const getAvailableValuesForField = (fieldId) => {
    if (fieldId === '' || fieldId == null) return []
    const numericFieldId = Number(fieldId)
    const field = accessControlData.find((f) => f.id === numericFieldId)
    return field?.allowed_values || []
  }

  const handleFieldChange = (index, fieldId) => {
    const numericFieldId = Number(fieldId)
    const newPairs = [...fieldPairs]
    newPairs[index] = { field: numericFieldId, values: [] }
    setFieldPairs(newPairs)
  }

  const handleValueChange = (index, values) => {
    const newPairs = [...fieldPairs]
    newPairs[index] = { ...newPairs[index], values }
    setFieldPairs(newPairs)
  }

  const handleDeletePair = (index) => {
    const newPairs = fieldPairs.filter((_, i) => i !== index)
    setFieldPairs(newPairs)
  }

  const handleAddPair = () => {
    setFieldPairs([...fieldPairs, { field: '', values: [] }])
  }

  const handleSave = () => {
    const formData = getCurrentFormData()

    if (Object.keys(formData).length > 0) {
      onSave(formData, conditionNumber - 1)
    } else {
      onCancel()
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        // Check if the click target is part of react-select components
        const isReactSelectClick =
          event.target.closest('.react-select__clear-indicator') ||
          event.target.closest('.react-select__dropdown-indicator') ||
          event.target.closest('.react-select__menu')

        // Check if clicked on add condition button
        const isAddConditionClick =
          event.target.closest(`.${styles.addConditionButton}`) ||
          event.target.closest(`.${styles.addConditionButton}`) !== null

        // Only save/close if it's not a react-select interaction or add condition button
        if (!isReactSelectClick && !isAddConditionClick) {
          handleSave()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPairs, onSave, onCancel, conditionNumber])

  const getAllFields = () => getFieldOptionsFromAccessControl(accessControlData)
  const usedFields = fieldPairs.map((pair) => pair.field?.toString()).filter(Boolean)
  const allFields = getAllFields()
  const showAddButton = usedFields.length < allFields.length

  return (
    <div className={styles.conditionContainer} ref={formRef}>
      <div className={styles.conditionHeader}>
        <h3 className={styles.conditionTitle}>Access Condition {conditionNumber}</h3>
        <div className={styles.headerButtons}>
          <button className={styles.closeButton} onClick={onCancel}>
            <CloseIcon />
          </button>
        </div>
      </div>

      <div className={styles.dropdownRow}>
        <div className={styles.dropdownLabel}>Field</div>
        <div className={styles.dropdownLabel}>Value</div>
      </div>

      {fieldPairs.map((pair, index) => (
        <FieldValuePair
          key={index}
          availableFieldOptions={allFields}
          selectedField={pair.field}
          selectedValues={pair.values}
          onFieldChange={(field) => handleFieldChange(index, field)}
          onValueChange={(values) => handleValueChange(index, values)}
          onDelete={() => handleDeletePair(index)}
          isOnly={fieldPairs.length === 1}
          availableValues={getAvailableValuesForField(pair.field)}
          usedFields={usedFields}
        />
      ))}

      {showAddButton && (
        <Button variant='outline' className={styles.addFieldButton} onClick={handleAddPair}>
          + Add
        </Button>
      )}
    </div>
  )
}

const AccessControl = ({ accessControlData, app, onAccessConfigChange, appName = 'App' }) => {
  const [isFullAccess, setIsFullAccess] = useState(app.accessConfig?.isFullAccess ?? true)
  const [conditions, setConditions] = useState(app.accessConfig?.conditions || [])
  const [editingIndex, setEditingIndex] = useState(null)
  const [isAddingNew, setIsAddingNew] = useState(false)
  const [isCurrentFormValid, setIsCurrentFormValid] = useState(false)
  const [currentFormData, setCurrentFormData] = useState(null)

  const [, setActiveFormCount] = useState(0)

  const handleSaveCondition = (condition, index) => {
    const newConditions = [...conditions]

    if (Object.keys(condition).length > 0) {
      if (typeof index === 'number') {
        newConditions[index] = condition
      } else {
        newConditions.push(condition)
      }
      setConditions(newConditions)
      setEditingIndex(null)
      setIsAddingNew(false)
      setActiveFormCount((prev) => Math.max(0, prev - 1))

      onAccessConfigChange({
        ...app.accessConfig,
        isFullAccess,
        conditions: newConditions,
        configured: isFullAccess ?? newConditions.length > 0
      })
    }
  }

  const handleFullAccessChange = (checked) => {
    setIsFullAccess(checked)
    onAccessConfigChange({
      ...app.accessConfig,
      isFullAccess: checked,
      conditions,
      configured: checked ?? conditions.length > 0
    })
  }

  const handleDeleteCondition = (index) => {
    const newConditions = conditions.filter((_, i) => i !== index)
    setConditions(newConditions)
    onAccessConfigChange({
      ...app.accessConfig,
      isFullAccess,
      conditions: newConditions,
      configured: isFullAccess ?? newConditions.length > 0
    })
  }

  const handleEdit = (index) => {
    if ((isAddingNew && !isCurrentFormValid) || (editingIndex !== null && !isCurrentFormValid)) {
      return
    }

    setEditingIndex(index)
    setIsAddingNew(false)
    setActiveFormCount(1)

    setIsCurrentFormValid(true)
  }

  const handleAddNew = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e) {
      e.nativeEvent.stopImmediatePropagation()
    }

    if (editingIndex !== null || isAddingNew) {
      if (isCurrentFormValid && currentFormData) {
        if (editingIndex !== null) {
          handleSaveCondition(currentFormData, editingIndex)
        } else if (isAddingNew) {
          handleSaveCondition(currentFormData, null)
        }

        setTimeout(() => {
          setIsAddingNew(true)
          setActiveFormCount((prev) => prev + 1)
          setIsCurrentFormValid(false)
        }, 50)
        return
      } else {
        return
      }
    }

    setIsAddingNew(true)
    setActiveFormCount((prev) => prev + 1)
    setIsCurrentFormValid(false)
  }

  const handleCancel = () => {
    setEditingIndex(null)
    setIsAddingNew(false)
    setActiveFormCount((prev) => Math.max(0, prev - 1))
    setIsCurrentFormValid(false)
  }

  const handleFormValidChange = (isValid) => {
    setIsCurrentFormValid(isValid)
  }

  return (
    <div className={styles.accessControlContainer}>
      <div className={styles.conditionsWrapper}>
        <div className={styles.fullAccessContainer}>
          {isFullAccess && (
            <Fragment>
              <h3 className={styles.fullAccessTitle}>Full Access</h3>
              <p className={styles.fullAccessDescription}>{` enabled for ${appName}`}</p>
            </Fragment>
          )}
          {!isFullAccess && (
            <Fragment>
              <h3 className={styles.fullAccessTitle}>Conditional Access</h3>
              <p className={styles.fullAccessDescription}>{` enabled for ${appName}`}</p>
            </Fragment>
          )}
          <div className={styles.fullAccessToggle}>
            <label className={styles.switch}>
              <input
                type='checkbox'
                checked={isFullAccess}
                onChange={(e) => handleFullAccessChange(e.target.checked)}
              />
              <span className={styles.slider} />
            </label>
          </div>
        </div>
        {!isFullAccess && (
          <Fragment>
            {conditions.map((condition, index) =>
              editingIndex === index ? (
                <ConditionForm
                  key={index}
                  conditionNumber={index + 1}
                  initialData={condition}
                  accessControlData={accessControlData}
                  onSave={handleSaveCondition}
                  onCancel={handleCancel}
                  onValidChange={handleFormValidChange}
                  onDataChange={setCurrentFormData}
                />
              ) : (
                <SavedCondition
                  key={index}
                  condition={condition}
                  conditionNumber={index + 1}
                  accessControlData={accessControlData}
                  onEdit={handleEdit}
                  onDelete={handleDeleteCondition}
                />
              )
            )}

            {isAddingNew && (
              <ConditionForm
                conditionNumber={conditions.length + 1}
                accessControlData={accessControlData}
                onSave={handleSaveCondition}
                onCancel={handleCancel}
                onValidChange={handleFormValidChange}
                onDataChange={setCurrentFormData}
              />
            )}
          </Fragment>
        )}
        {!isFullAccess && (
          <Button
            variant='outline'
            radius='full'
            className={styles.addConditionButton}
            onClick={handleAddNew}
            data-add-condition='true'
            disabled={(editingIndex !== null || isAddingNew) && !isCurrentFormValid}
          >
            + Add Condition
          </Button>
        )}
      </div>
    </div>
  )
}

export default AccessControl
