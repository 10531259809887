// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MeTkvX7KWXAGk_OpxHrE{font-size:var(--text-lg);color:var(--color-text-default);font-weight:var(--font-weight-normal)}.L6ztFfB45SNedFGRBO_w{display:flex;align-items:center;margin-bottom:1rem}._6NdCbNATnwKLmJWSn33P{width:100%}.UhzfHpGXenZHEv_WfGKC{border-radius:0px 0px var(--border-radius-lg) var(--border-radius-lg)}.VmQnmoZkkbN52Q69Ep10{margin:20px 0}.fGytw43KO0ugHCxln6tM{margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/Outline/Outline.module.css"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,+BAAA,CACA,qCAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAGF,uBACE,UAAA,CAGF,sBACE,qEAAA,CAGF,sBACE,aAAA,CAGF,sBACE,kBAAA","sourcesContent":[".heading {\n  font-size: var(--text-lg);\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-normal);\n}\n\n.headingWrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 1rem;\n}\n\n.wrapper {\n  width: 100%;\n}\n\n.modalBody {\n  border-radius: 0px 0px var(--border-radius-lg) var(--border-radius-lg);\n}\n\n.tableActionContainer {\n  margin: 20px 0;\n}\n\n.tableContainer {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "MeTkvX7KWXAGk_OpxHrE",
	"headingWrapper": "L6ztFfB45SNedFGRBO_w",
	"wrapper": "_6NdCbNATnwKLmJWSn33P",
	"modalBody": "UhzfHpGXenZHEv_WfGKC",
	"tableActionContainer": "VmQnmoZkkbN52Q69Ep10",
	"tableContainer": "fGytw43KO0ugHCxln6tM"
};
export default ___CSS_LOADER_EXPORT___;
