import { baseApi, TAGS } from './baseApi'

export const gitBookApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGitBookApp: builder.query({
      query: (params) => ({
        url: '/v1/apps/gitbook',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.GITBOOK.GITBOOK]
    }),

    installGitBook: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/gitbook',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.GITBOOK.GITBOOK, TAGS.APPS, TAGS.ACCOUNT]
    }),

    uninstallGitBook: builder.mutation({
      query: (params) => ({
        url: '/v1/apps/gitbook',
        method: 'DELETE',
        params
      }),
      invalidatesTags: [TAGS.GITBOOK.GITBOOK, TAGS.APPS, TAGS.ACCOUNT]
    }),

    getAvailableGitBookSpaces: builder.query({
      query: (params) => ({
        url: '/v1/apps/gitbook/spaces',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.GITBOOK.GITBOOK_SPACES]
    }),

    getCurrentlySyncingGitBookSpaces: builder.query({
      query: (params) => ({
        url: '/v1/apps/gitbook/spaces/sync',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.GITBOOK.GITBOOK_SPACES]
    }),

    addSpaceToSync: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/gitbook/spaces/sync',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [
        TAGS.GITBOOK.GITBOOK,
        TAGS.GITBOOK.GITBOOK_SPACES,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUPS,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUP_ITEMS,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUPS_CHILDREN
      ]
    }),

    removeSpaceFromSync: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/gitbook/spaces/sync',
        method: 'DELETE',
        body: data
      }),
      invalidatesTags: [
        TAGS.GITBOOK.GITBOOK,
        TAGS.GITBOOK.GITBOOK_SPACES,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUPS,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUP_ITEMS,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUPS_CHILDREN
      ]
    }),

    enableSyncGitBook: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/gitbook/spaces/sync/enable',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.GITBOOK]
    })
  })
})

export const {
  useGetGitBookAppQuery,
  useInstallGitBookMutation,
  useUninstallGitBookMutation,
  useGetAvailableGitBookSpacesQuery,
  useGetCurrentlySyncingGitBookSpacesQuery,
  useAddSpaceToSyncMutation,
  useRemoveSpaceFromSyncMutation,
  useEnableSyncGitBookMutation
} = gitBookApi
