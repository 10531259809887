import { Button } from '@happyfoxinc/web-components'

import styles from './UserGroupBadge.module.css'

import RightAngleIcon from 'Icons/arrow-right.svg'

import Badge from 'Src/componentsv3/Badge'

import { usePlanContext } from 'Src/utilsV3/hooks/usePlanContext'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Src/componentsv3/Tooltip'

import { URLS } from 'Constants/urls'

import { openUrl } from 'Utils/url'

export const KNOWLEDGE_GROUP_VISIBILITY_STATES = {
  ALL: 'all_users',
  SAME_AS_PARENT: 'same_as_parent',
  SPECIFIC: 'specific_user_groups',
  NONE: 'no_users',
  SAME_AS_ORIGIN: 'same_as_origin'
}

const UserGroupBadge = ({ visibility, mapped_user_groups = [] }) => {
  const { isFreePlan } = usePlanContext()

  let text = 'No Users'
  let variant = 'purple'

  const { SPECIFIC, ALL, SAME_AS_PARENT, SAME_AS_ORIGIN } = KNOWLEDGE_GROUP_VISIBILITY_STATES

  if (visibility === SPECIFIC) {
    text = `${mapped_user_groups.length} User groups`
    variant = 'muted'
  } else if (visibility === ALL) {
    text = 'All Users'
    variant = 'default'
  } else if (visibility === SAME_AS_PARENT) {
    text = 'Same as parent'
    variant = 'default'
  } else if (visibility === SAME_AS_ORIGIN) {
    text = 'Same as origin'
    variant = 'default'
  }

  const userGroupNames = mapped_user_groups.map((ug) => ug.name).join(', ')

  return (
    <Tooltip delayDuration={1000}>
      <TooltipTrigger className={styles.tooltipTrigger}>
        {isFreePlan && <Badge text={text} />}
        {!isFreePlan && <Badge text={text} variant={variant} />}
      </TooltipTrigger>
      {isFreePlan && (
        <TooltipContent side='left' className={styles.upgradeTooltip}>
          <span variant='muted'>
            User Groups available only <br />
            in Pro and Enterprise plans.
          </span>
          <Button
            variant='outline'
            className={styles.upgradeButton}
            onClick={(e) => {
              e.stopPropagation()
              openUrl(URLS.ASSIST_AI)
            }}
          >
            Upgrade Plan <RightAngleIcon width='1em' height='1em' />
          </Button>
        </TooltipContent>
      )}
      {userGroupNames && !isFreePlan && <TooltipContent side='left'>{userGroupNames}</TooltipContent>}
    </Tooltip>
  )
}

export default UserGroupBadge
