// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JmXoe6VaiEgmb37FQnx4{cursor:pointer;padding:5px 25px;border-radius:25px;background-color:var(--tab-bg);border:1px solid var(--tab-border-color)}.JmXoe6VaiEgmb37FQnx4:hover{opacity:.8;background-color:var(--color-surface-muted)}.JmXoe6VaiEgmb37FQnx4.dpBS4wjrxUO5Fyz424Qk{background-color:var(--tab-selected-bg);color:var(--color-surface-default);border:none;opacity:1}.JmXoe6VaiEgmb37FQnx4.wSpRUPkkAAsHMiSS9PSF{width:120px;height:40px;border-radius:9999px;background-color:var(--color-surface-muted)}", "",{"version":3,"sources":["webpack://./../src/componentsv3/Tab/Tab.module.css"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,8BAAA,CACA,wCAAA,CAGF,4BACE,UAAA,CACA,2CAAA,CAGF,2CACE,uCAAA,CACA,kCAAA,CACA,WAAA,CACA,SAAA,CAGF,2CACE,WAAA,CACA,WAAA,CACA,oBAAA,CACA,2CAAA","sourcesContent":[".tabHeading {\n  cursor: pointer;\n  padding: 5px 25px;\n  border-radius: 25px;\n  background-color: var(--tab-bg);\n  border: 1px solid var(--tab-border-color);\n}\n\n.tabHeading:hover {\n  opacity: 0.8;\n  background-color: var(--color-surface-muted);\n}\n\n.tabHeading.active {\n  background-color: var(--tab-selected-bg);\n  color: var(--color-surface-default);\n  border: none;\n  opacity: 1;\n}\n\n.tabHeading.skeleton {\n  width: 120px;\n  height: 40px;\n  border-radius: 9999px;\n  background-color: var(--color-surface-muted);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabHeading": "JmXoe6VaiEgmb37FQnx4",
	"active": "dpBS4wjrxUO5Fyz424Qk",
	"skeleton": "wSpRUPkkAAsHMiSS9PSF"
};
export default ___CSS_LOADER_EXPORT___;
