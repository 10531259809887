import { Box, Button, Flex, Grid, Modal, TextField } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './AnswerForm.module.css'

import FormField from 'Src/componentsv3/FormField'
import MergeTrixEditor from 'Src/componentsv3/MergeTrixEditor'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tips, { Tip } from 'Src/componentsv3/Tips'
import { ANSWER_CONTENT_MERGE_FIELDS } from 'Src/constants/mergeFields'
import { ANSWER_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Src/constants/user-groups'
import { useDeleteAnswerMutation } from 'Src/servicesV3/answersApi'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  control: (provided) => ({
    ...provided,
    outline: 'none',
    boxShadow: 'none',
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px',
    border: '1px solid var(--color-border-default)',
    '&:hover': {
      border: '1px solid var(--color-border-default)'
    }
  })
}

const DeleteModal = (props) => {
  const navigate = useNavigate()

  const { id, data, ...restProps } = props
  const [deleteAnswer, deleteAnswerApiResults] = useDeleteAnswerMutation()

  const handleDelete = useCallback(() => {
    const promise = deleteAnswer(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting answer',
      success: `Deleted answer '${data.name}'. Successfully...`,
      error: `Error while deleting answer '${data.name}'. Try again.`
    })
    props.onClose()
    navigate('/answers')
  }, [id, data, deleteAnswer, props])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this answer?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteAnswerApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteAnswerApiResults.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const AnswerForm = ({ isEdit = false, isLoading = false, onSubmit, onSaveAndAdd, showSaveAndAdd = true }) => {
  const navigate = useNavigate()

  const { currentWorkspaceId, workspaces } = useWorkspace()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { data: account } = useGetAccountQuery()
  const [getUserGroups, getUserGroupsResult] = userGroupsApi.useLazyGetUserGroupsQuery()

  const accountType = account?.account_type
  const showWorkspaceDropdown = account?.is_workspaces_enabled

  const formMethods = useFormContext()

  const {
    register,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue
  } = formMethods

  const answer = getValues()

  const selectedVisibility = watch('visibility')

  useEffect(() => {
    if (!watch('workspace_id')) {
      setValue('workspace_id', currentWorkspaceId)
    }
  }, [currentWorkspaceId, setValue, watch])

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const handleSaveAndAdd = (e) => {
    onSaveAndAdd(e)
  }

  return (
    <Fragment>
      <Grid columns='1fr 25%' gap='10rem'>
        <Box width='100%'>
          <form onSubmit={onSubmit}>
            <FormField>
              <FormField.Field label='Title' error={errors?.name?.message} className={styles.w100} isRequired>
                <TextField.Root
                  {...register('name')}
                  size='2'
                  radius='small'
                  placeholder='Enter title'
                  className={styles.w100}
                />
              </FormField.Field>
            </FormField>
            <FormField>
              <FormField.Field label='Content' error={errors?.content?.message} className={styles.w100} isRequired>
                <MergeTrixEditor
                  name='content'
                  mergeFields={ANSWER_CONTENT_MERGE_FIELDS}
                  className={styles.answerContentEditor}
                />
              </FormField.Field>
            </FormField>
            <FormField>
              <Flex gap='24px' width='100%'>
                {!isEdit && showWorkspaceDropdown && (
                  <FormField.Field
                    label='Workspace'
                    isRequired
                    error={errors?.workspace_id?.message}
                    className={styles.dropdownContainer}
                  >
                    <Controller
                      name='workspace_id'
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          value={workspaces.find((opt) => opt.id === field.value)}
                          onChange={(opt) => field.onChange(opt.id)}
                          isClearable={false}
                          options={workspaces}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder='Select Workspace'
                        />
                      )}
                    />
                  </FormField.Field>
                )}
                <FormField.Field
                  label='Show answer to'
                  isRequired
                  error={errors?.visibility?.message}
                  className={styles.dropdownContainer}
                >
                  <Controller
                    name='visibility'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        placeholder='Select'
                        value={ANSWER_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                        onChange={(opt) => field.onChange(opt.value)}
                        isClearable={false}
                        options={ANSWER_VISIBILITY_OPTIONS}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </FormField.Field>
              </Flex>
            </FormField>
            {selectedVisibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
              <FormField>
                <FormField.Field
                  label='Select user group(s) for which the answer will be recommended'
                  isRequired
                  error={errors?.visibleToGroups?.message}
                  className={styles.w100}
                >
                  <Controller
                    name='visibleToGroups'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        placeholder='Select Groups'
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isMulti
                        options={[]}
                        isClearable={false}
                        loadOptions={loadOptions}
                        isLoading={getUserGroupsResult.isLoading}
                        loadingMessage={({ inputValue }) => {
                          return `Searching for sites matching "${inputValue}"...`
                        }}
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 3) {
                            return 'Type atleast 3 characters to start searching'
                          }
                          return `No sites found for input "${inputValue}"`
                        }}
                        styles={customSelectStyles}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
            )}
            <Flex justify='space-between' width='100%' direction='row-reverse' style={{ marginTop: '3.5rem' }}>
              <Flex gap='12px' direction='row-reverse'>
                <Button type='submit' disabled={isLoading}>
                  Save
                </Button>
                {showSaveAndAdd && (
                  <Button type='button' variant='outline' disabled={isLoading} onClick={handleSaveAndAdd}>
                    Save and Add
                  </Button>
                )}
                {!showSaveAndAdd && (
                  <Button
                    type='button'
                    variant={showSaveAndAdd ? 'ghost' : 'outline'}
                    onClick={() => navigate('/answers')}
                    className={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                )}
              </Flex>
              {!showSaveAndAdd && (
                <Button type='button' className={styles.deleteButton} onClick={() => setIsDeleteModalOpen(true)}>
                  Delete
                </Button>
              )}
              {showSaveAndAdd && (
                <Button
                  type='button'
                  variant={showSaveAndAdd ? 'ghost' : 'outline'}
                  onClick={() => navigate('/answers')}
                  className={styles.cancelButton}
                >
                  Cancel
                </Button>
              )}
            </Flex>
          </form>
          <DeleteModal
            open={isDeleteModalOpen}
            onOpenChange={() => {
              setIsDeleteModalOpen(false)
            }}
            onClose={() => {
              setIsDeleteModalOpen(false)
            }}
            id={answer?.id}
            data={answer}
            title='Delete Answer'
            showFooter={false}
            bodyClassName={styles.modalBody}
          />
        </Box>
        <div className={styles.tipsContainer}>
          <Tips title='Tips to add answers content'>
            <Tip>
              Ensure your answers are clear, concise, and consist of the required keywords — Assist AI will
              automatically parse Answers for keywords and phrases
            </Tip>
            <Tip>
              Images from the web can be copied and pasted into an answer, but local images from the device are not
              supported.
            </Tip>
            <Tip>Once you have created an answer, hit Save to add more</Tip>
            <Tip>
              These Answers will be displayed to users as search results when they type their question to Assist AI.
            </Tip>
          </Tips>
        </div>
      </Grid>
    </Fragment>
  )
}

export default AnswerForm
