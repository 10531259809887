import AnswersIcon from 'Src/assetsv3/icons/answers.svg'
import AppsIcon from 'Src/assetsv3/icons/apps.svg'
import ChannelsIcon from 'Src/assetsv3/icons/channels.svg'
import DashboardIcon from 'Src/assetsv3/icons/dashboard.svg'
import ManageAdminsIcon from 'Src/assetsv3/icons/manage-admin.svg'
import ModulesIcon from 'Src/assetsv3/icons/modules.svg'
import ReportsIcon from 'Src/assetsv3/icons/reports.svg'
import UserGroupsIcon from 'Src/assetsv3/icons/user-groups.svg'
import WorkspaceIcon from 'Src/assetsv3/icons/workspace.svg'
import AnnouncementsIcon from 'Src/assets/icons/announcement.svg'

export const DASHBOARD = 'Dashboard'
export const ANSWERS = 'Answers'
export const MODULES = 'Modules'
export const APPS = 'Apps'
export const CHANNELS = 'Channels'
export const REPORTS = 'Reports'
export const ANNOUNCEMENTS = 'Announcements'
export const USER_GROUPS = 'User Groups'
export const ADMINS = 'Admins'
export const USERS = 'Users'
export const WORKSPACES = 'Workspaces'

export const PAGES = {
  [DASHBOARD]: {
    icon: DashboardIcon,
    title: 'Dashboard',
    description: '',
    path: '/dashboard'
  },
  [ANSWERS]: {
    icon: AnswersIcon,
    title: 'Answers',
    description:
      'Answers let you create and manage all commonly asked questions within Assist AI. Answers complement other existing knowledge sources.',
    path: '/answers'
  },
  [MODULES]: {
    icon: ModulesIcon,
    title: 'Modules',
    description:
      "Select from our ready-to-use modules designed for common business needs. Each module can be easily configured and customized to fit your organization's unique workflow.",
    path: '/modules'
  },
  [APPS]: {
    icon: AppsIcon,
    title: 'Apps',
    description:
      'Connect knowledge sources & ticketing apps to Assist AI. The number of apps that you can integrate is determined by your plan.',
    path: '/apps'
  },
  [CHANNELS]: {
    icon: ChannelsIcon,
    title: 'Channels',
    description:
      'Slack Channels to which Assist AI is added are listed below. Assist AI can now respond to the queries posted on these channels. Learn more about channel configuration {{here}}.',
    path: '/channels'
  },
  [REPORTS]: {
    icon: ReportsIcon,
    title: 'Reports',
    description: 'Overview of various metrics tied to your account.',
    path: '/reports'
  },
  [ANNOUNCEMENTS]: {
    icon: AnnouncementsIcon,
    title: 'Announcements',
    description: 'Announcements are used to communicate important information to specific users or team.',
    path: '/announcements'
  },
  [USER_GROUPS]: {
    icon: UserGroupsIcon,
    title: 'User Groups',
    description: 'Users can be grouped together to restrict answers suggested to the users.',
    path: '/user-groups'
  },
  [ADMINS]: {
    icon: ManageAdminsIcon,
    title: 'Admins',
    description:
      "Admins can manage all the settings and configurations of the platform. They can add, edit, and delete users, groups, and permissions, and configure the platform to suit the organization's requirements.",
    path: '/admins'
  },
  [USERS]: {
    icon: ManageAdminsIcon,
    title: 'Users',
    description:
      'Users are the individuals who will be using Assist AI. They can be assigned different roles and permissions based on their responsibilities within the organization.',
    path: '/users'
  },
  [WORKSPACES]: {
    icon: WorkspaceIcon,
    title: 'Workspaces',
    description:
      'Workspace classification directs your requests to the appropriate department. Each workspace is designed to manage specific tasks and projects.',
    path: '/workspaces'
  }
}
