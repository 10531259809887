import { Box, Flex } from '@happyfoxinc/web-components'

import toast from 'react-hot-toast'

import { useNavigate, useLocation } from 'react-router-dom'

import styles from './Workspaces.module.css'

import BackButton from 'Src/componentsv3/BackButton'

import { KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Src/constants/user-groups'

import { useUpdateWorkspaceMutation } from 'Src/servicesV3/workspaceApi'

import WorkspaceForm from './WorkspaceForm'

const EditWorkspace = () => {
  const navigate = useNavigate()
  const { state: workspace } = useLocation()

  const [updateWorkspace, updateWorkspaceResult] = useUpdateWorkspaceMutation()

  const handleUpdateWorkspace = async (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      visibility: data.visibility,
      example_queries: data.example_queries.filter(Boolean) ?? []
    }

    if (data.visibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && data.visibleToGroups) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    try {
      const promise = updateWorkspace({ workspaceId: workspace.id, data: payload }).unwrap()

      toast.promise(promise, {
        loading: 'Updating workspace',
        success: 'Workspace updated successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Unable to update workspace'
          return errorMessage
        }
      })

      await promise
    } catch {}
  }

  if (!workspace) {
    toast.error('Unable to fetch workspace. Try again...', {
      id: 'fetch-error'
    })
    navigate('/workspaces')
    return null
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Edit Workspace</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <WorkspaceForm
          isEdit={true}
          workspace={workspace}
          onSubmit={handleUpdateWorkspace}
          isSubmitting={updateWorkspaceResult.isLoading}
          onCancel={() => navigate('/workspaces')}
        />
      </Box>
    </Box>
  )
}

export default EditWorkspace
