import { Fragment } from 'react'
import { Toaster } from 'react-hot-toast'
import { Navigate, Route, Routes } from 'react-router-dom'

import AppLoader from 'Src/components/AppLoader'
import ErrorBoundary from 'Src/components/ErrorBoundary'
import FeaturesProvider, { canAllowFeature } from 'Src/components/Features'
import { ACCOUNT_TYPE } from 'Src/constants/account'
import { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'
import Main from 'Src/layoutv3/Main'
import { useGetAccountQuery, useGetCsrfTokenQuery, useGetProfileQuery } from 'Src/servicesV3/authApi'
import { useGetWorkspacesQuery } from 'Src/servicesV3/workspaceApi'
import { SidebarToggleProvider } from 'Src/utils/hooks/useSidebarToggle'
import { PlanProvider } from 'Src/utilsV3/hooks/usePlanContext'
import { WorkspaceProvider } from 'Src/utilsV3/hooks/useWorkspaceContext'

import Admins from './Admins'
import AIAssistant from './AIAssistant'
import Announcements from './Announcements'
import Answers from './Answers'
import Apps from './Apps'
import Channels from './Channels'
import Dashboard from './Dashboard'
import Modules from './Modules'
import Reports from './Reports'
import UserGroups from './UserGroups'
import Workspaces from './Workspaces'

const getFeatureFlags = (account) => {
  if (!account) {
    return []
  }

  const {
    enabled_features,
    is_ticketing_integration_connected,
    is_dm_workflow_enabled,
    account_type,
    is_ai_actions_enabled,
    is_workspaces_enabled
  } = account

  const flags = [...enabled_features]

  if (is_ticketing_integration_connected) {
    flags.push(CLIENT_FEATURE_FLAGS.ENABLE_TICKETING)
  }

  if (is_dm_workflow_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE)
  }

  if (is_ai_actions_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.ACTIONS)
  }

  if (is_workspaces_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.WORKSPACES)
  }

  if (account_type === ACCOUNT_TYPE.SLACK) {
    flags.push(CLIENT_FEATURE_FLAGS.SLACK_ACCOUNT)
  }

  if (account_type === ACCOUNT_TYPE.MS_TEAMS) {
    flags.push(CLIENT_FEATURE_FLAGS.MS_TEAMS_ACCOUNT)
  }

  if (account_type === ACCOUNT_TYPE.MS_TEAMS || (is_dm_workflow_enabled && is_ticketing_integration_connected)) {
    flags.push(CLIENT_FEATURE_FLAGS.SETTINGS)
  }

  if (canAllowFeature(flags, [CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE, CLIENT_FEATURE_FLAGS.SLACK_ACCOUNT])) {
    flags.push(CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS)
  }

  return flags
}

const Pages = () => {
  const { isLoading: isLoadingCsrfToken } = useGetCsrfTokenQuery()
  const { data: account, isLoading: isLoadingAccount } = useGetAccountQuery()
  const { isLoading: isLoadingProfile } = useGetProfileQuery()
  const { isLoading: isLoadingWorkspace } = useGetWorkspacesQuery()

  const isLoading = isLoadingCsrfToken || isLoadingAccount || isLoadingProfile || isLoadingWorkspace

  if (!isLoading && !account?.is_v3_enabled) {
    return <Navigate to='/dashboard' replace />
  }

  const planDetails = {
    planName: account?.plan_name,
    integrationsLimit: account?.integrations_limit
  }

  const featureFlags = getFeatureFlags(account)

  return (
    <Fragment>
      <Toaster
        position='top-center'
        containerStyle={{
          left: 'var(--sidebar-width)'
        }}
        toastOptions={{
          duration: 3000
        }}
      />
      <ErrorBoundary>
        <AppLoader isLoading={isLoading}>
          <WorkspaceProvider>
            <PlanProvider planDetails={planDetails}>
              <FeaturesProvider features={featureFlags}>
                <SidebarToggleProvider>
                  <Routes>
                    <Route index element={<Navigate to='dashboard' replace />} />
                    <Route path='/' element={<Main />}>
                      <Route path='dashboard' element={<Dashboard />} />
                      <Route path='modules/*' element={<Modules />} />
                      <Route path='ai-assistant/*' element={<AIAssistant />} />
                      <Route path='answers/*' element={<Answers />} />
                      <Route path='channels/*' element={<Channels />} />
                      <Route path='user-groups/*' element={<UserGroups />} />
                      <Route path='admins/*' element={<Admins />} />
                      <Route path='apps/*' element={<Apps />} />
                      <Route path='workspaces/*' element={<Workspaces />} />
                      <Route path='reports' element={<Reports />} />
                      <Route path='announcements/*' element={<Announcements />} />
                    </Route>
                    <Route path='*' element={<Navigate to='dashboard' replace />} />
                  </Routes>
                </SidebarToggleProvider>
              </FeaturesProvider>
            </PlanProvider>
          </WorkspaceProvider>
        </AppLoader>
      </ErrorBoundary>
    </Fragment>
  )
}

export default Pages
