import { memo } from 'react'
import { Box, Flex } from '@happyfoxinc/web-components'
import { Controller } from 'react-hook-form'
import _ from 'lodash'

import styles from './Components.module.css'

import Editor from 'Src/componentsv3/Editor'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import MultiSelect from 'Src/pagesv3/Modules/SoftwareAccessModule/components/MultiSelectCheckbox'
import InfoTooltip from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/InfoTooltip'
import { useStepForm } from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/hooks/useStepForm'

const StepFormField = memo(({ field, fieldIndex, index }) => {
  const { control, getFieldError, isFieldDirty } = useStepForm(index, fieldIndex)

  const renderField = () => {
    const fieldOptions =
      field.values?.map((item) => ({
        label: `${item.name} ${item.email ? `(${item.email})` : ''}`,
        value: item.id.toString()
      })) || []

    switch (field.name) {
      case 'recipients':
      case 'approvers':
        return (
          <Flex
            direction='column'
            gap='6px'
            id={`steps.${index}.fields.${fieldIndex}.${field.name}`}
            key={`steps.${index}.fields.${fieldIndex}.${field.name}`}
          >
            <label className={styles.formLabel}>{field.label}</label>
            <Box width='100%'>
              <Controller
                name={`steps.${index}.fields.${fieldIndex}.${field.name}`}
                control={control}
                render={({ field: formField }) => {
                  if (field.type === 'multi_select') {
                    return (
                      <MultiSelect
                        options={fieldOptions}
                        field={formField}
                        placeholder={`Select ${_.startCase(field.name)}...`}
                        inDialog
                      />
                    )
                  } else {
                    return (
                      <ReactSelect
                        inDialog
                        modalContainerClassName='modal-content'
                        value={fieldOptions.find((opt) => opt.value === formField.value)}
                        options={fieldOptions}
                        onChange={(option) => formField.onChange(option.value)}
                        placeholder={`Select ${_.startCase(field.name)}...`}
                        styles={{ menu: () => ({ zIndex: 10 }) }}
                      />
                    )
                  }
                }}
              />
              {isFieldDirty(field.name) && getFieldError()?.[field.name] && (
                <span className={styles.errorMsg}>{getFieldError()?.[field.name].message}</span>
              )}
            </Box>
          </Flex>
        )

      case 'instruction':
        return (
          <Flex
            direction='column'
            gap='6px'
            id={`steps.${index}.fields.${fieldIndex}.instruction`}
            key={`steps.${index}.fields.${fieldIndex}.instruction`}
          >
            <label className={styles.formLabel}>{field.label}</label>
            <Controller
              control={control}
              name={`steps.${index}.fields.${fieldIndex}.instruction`}
              render={({ field: { value, onChange }, ref }) => (
                <Editor
                  defaultValue={value}
                  onChange={onChange}
                  styles={styles}
                  toolbarId={`provisioningToolbar-${index}-${fieldIndex}`}
                  id={`editor-${index}-${fieldIndex}`}
                  ref={ref}
                />
              )}
            />
            {isFieldDirty('instruction') && getFieldError()?.instruction && (
              <span className={styles.errorMsg}>{getFieldError()?.instruction.message}</span>
            )}
          </Flex>
        )

      case 'user_acknowledgement':
        return (
          <Flex
            align='center'
            gap='8px'
            id={`steps.${index}.fields.${fieldIndex}.user_acknowledgement`}
            key={`steps.${index}.fields.${fieldIndex}.user_acknowledgement`}
          >
            <label className={styles.switch}>
              <Controller
                name={`steps.${index}.fields.${fieldIndex}.user_acknowledgement`}
                control={control}
                render={({ field: { value = false, onChange } }) => (
                  <input id='user_acknowledgement_toggle' type='checkbox' checked={value} onChange={onChange} />
                )}
              />
              <span className={styles.slider} />
            </label>
            <label className={styles.switchLabel} htmlFor='user_acknowledgement_toggle' style={{ cursor: 'pointer' }}>
              {field.label}
            </label>
            {field.info && (
              <div className={styles.infoButton}>
                <InfoTooltip message={field.info} />
              </div>
            )}
          </Flex>
        )

      default:
        return null
    }
  }

  return (
    <Flex
      direction='column'
      gap='16px'
      className={styles.instructionForm}
      id={`steps.${index}.fields.${fieldIndex}-instruction-form`}
      key={`steps.${index}.fields.${fieldIndex}-instruction-form`}
    >
      {renderField()}
    </Flex>
  )
})

export default StepFormField
