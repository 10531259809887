import { Box } from '@happyfoxinc/web-components'

import React from 'react'

import styles from './Tips.module.css'

import BulbIcon from 'Icons/bulb.svg'

export const Tip = (props) => {
  return <li {...props} className={styles.tip} />
}

const Tips = ({ title, children, style }) => {
  return (
    <Box className={styles.tipContainer} style={{ ...style?.container }}>
      <BulbIcon />
      <p className={styles.tipTitle}>{title}</p>
      <ul className={styles.tipContent}>{children}</ul>
    </Box>
  )
}

export default Tips
