import * as yup from 'yup'

const validationSchema = yup
  .object({
    title: yup
      .string()
      .trim()
      .required('Title is required'),
    content: yup
      .string()
      .trim()
      .required('Content is required'),
    send_to: yup
      .string()
      .required('Send announcement to is required'),
    users: yup
      .array()
      .when('send_to', {
        is: 'specific_users',
        then: (schema) => schema
          .required('At least one user is required')
          .min(1, 'At least one user selection is required'),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    visibleToGroups: yup
      .array()
      .when('send_to', {
        is: 'user_groups',
        then: (schema) => schema
          .required('At least one user group is required')
          .min(1, 'At least one user group selection is required'),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    schedule_preference: yup
      .string()
      .required('Schedule preference is required'),
    scheduled_on: yup
      .string()
      .nullable()
      .when('schedule_preference', {
        is: 'schedule_later',
        then: (schema) => schema.required('Scheduled date and time is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test(
        'is-future-date',
        'Please select a future date or time',
        function(value) {
          const { schedule_preference } = this.parent;
          if (schedule_preference !== 'schedule_later' || !value) return true;
          const currentDate = new Date();
          const scheduled_on = new Date(value);
          return scheduled_on > currentDate;
        }
      ),
    user_acknowledgement_required: yup
      .boolean()
      .default(false),
    acknowledgement_configuration: yup
      .object({
        button_label: yup
          .string()
          .when('../user_acknowledgement_required', {
            is: true,
            then: (schema) => schema
              .required('Please enter button text')
              .max(25, 'Button text cannot exceed 25 characters'),
            otherwise: (schema) => schema.nullable()
          })
      })
  })
  .required()

export default validationSchema
