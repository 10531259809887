import styles from './Tile.module.css'

import InfoIcon from 'Icons/info.svg'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Src/componentsv3/Tooltip'

const InfoToolTip = ({ info }) => {
  if (!info) {
    return null
  }
  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger className={styles.toolTip}>
        <InfoIcon />
      </TooltipTrigger>
      <TooltipContent className={styles.tooltipContent}>
        <span variant='muted'>{info}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const Tile = ({ title, info, icon, children }) => {
  return (
    <div className={styles.tile}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <InfoToolTip info={info} />
        <span className={styles.spacer} />
        {icon && <div>{icon}</div>}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  )
}

export default Tile
