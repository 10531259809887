import { Box, Button, Flex, Grid, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './Workspaces.module.css'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tips, { Tip } from 'Src/componentsv3/Tips'

import { KNOWLEDGE_GROUP_VISIBILITY_STATES, WORKSPACE_VISIBILITY_OPTIONS } from 'Src/constants/user-groups'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'

import DeleteModal from './DeleteWorkspaceModal'


const CirclePlusIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#030303'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-plus'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M8 12h8' />
    <path d='M12 8v8' />
  </svg>
)

const CircleMinusIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#030303'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-minus'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M8 12h8' />
  </svg>
)

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  control: (provided) => ({
    ...provided,
    outline: 'none',
    boxShadow: 'none',
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px',
    border: '1px solid var(--color-border-default)',
    '&:hover': {
      border: '1px solid var(--color-border-default)'
    }
  })
}

const workspaceValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  visibility: yup.string().oneOf(Object.values(KNOWLEDGE_GROUP_VISIBILITY_STATES)).required(),
  visibleToGroups: yup.array().when('visibility', {
    is: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC,
    then: (schema) => schema.min(1, 'Please select at least 1 User group').required()
  }),
  example_queries: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().trim()
      })
    )
    .notRequired()
})

const WorkspaceForm = ({ workspace, onSubmit, onCancel, isSubmitting, isEdit = false }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const navigate = useNavigate()

  const { data: account } = useGetAccountQuery()
  const [getUserGroups, getUserGroupsResult] = userGroupsApi.useLazyGetUserGroupsQuery()

  const accountType = account?.account_type

  const transformQueriesToFormData = (queries = []) => {
    const result = queries.map((query) => ({ value: query }))
    if (result.length == 0) {
      result.push({ value: '' })
    }
    return result
  }

  const getInitialVisibleGroups = () => {
    if (!workspace?.user_groups) return []
    return workspace.user_groups.map((group) => ({
      id: group.id,
      name: group.name
    }))
  }

  const defaultValues = {
    name: workspace?.name || '',
    description: workspace?.description || '',
    visibility: workspace?.visibility || KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL,
    visibleToGroups: getInitialVisibleGroups(),
    example_queries: transformQueriesToFormData(workspace?.example_queries || [])
  }

  const {
    register,
    control,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm({
    defaultValues,
    resolver: yupResolver(workspaceValidationSchema)
  })

  const {
    fields: queryFields,
    append: appendQuery,
    remove: removeQuery
  } = useFieldArray({
    control,
    name: 'example_queries',
    rules: {
      minLength: 3
    }
  })

  const handleRemove = (index) => {
    if (queryFields.length > 1) {
      removeQuery(index)
    }
  }

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const handleFormSubmit = (formData) => {
    const transformedData = {
      ...formData,
      example_queries: formData.example_queries.map((query) => query.value)
    }
    onSubmit(transformedData)
  }

  const selectedVisibility = watch('visibility')

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  return (
    <Fragment>
      <Grid columns='1fr 25%' gap='10rem' width='100%' height='100%'>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Flex direction='column' align='flex-start' justify='space-between' gap='1rem' width='100%' height='100%'>
            <Box width='100%'>
              <FormField>
                <FormField.Field error={errors?.name?.message} label='Name' isRequired>
                  <TextField.Root {...register('name')} size='2' radius='small' placeholder='Enter Name' />
                </FormField.Field>
              </FormField>
              <FormField>
                <FormField.Field error={errors?.description?.message} label='Description' isRequired>
                  <textarea
                    {...register('description')}
                    placeholder='Enter Description'
                    className={styles.descriptionTextarea}
                  />
                </FormField.Field>
              </FormField>
              <FormField>
                <FormField.Field label='Visibility' error={errors?.visibility?.message} isRequired>
                  <Controller
                    name='visibility'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        placeholder='Select'
                        value={WORKSPACE_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                        onChange={(opt) => field.onChange(opt.value)}
                        isClearable={false}
                        options={WORKSPACE_VISIBILITY_OPTIONS}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
              {selectedVisibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
                <FormField>
                  <FormField.Field label='Select Groups' error={errors?.visibleToGroups?.message} isRequired>
                    <Controller
                      name='visibleToGroups'
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          placeholder='Select Groups'
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isMulti
                          options={[]}
                          isClearable={false}
                          loadOptions={loadOptions}
                          isLoading={getUserGroupsResult.isLoading}
                          loadingMessage={({ inputValue }) => `Searching for sites matching "${inputValue}"...`}
                          noOptionsMessage={({ inputValue }) => {
                            if (inputValue.length < 3) {
                              return 'Type alteast 3 characters to start searching'
                            }
                            return `No sites found for input "${inputValue}"`
                          }}
                          styles={customSelectStyles}
                        />
                      )}
                    />
                  </FormField.Field>
                </FormField>
              )}
              <FormField>
                <FormField.Field label='Example Queries'>
                  {queryFields.map((query, index) => (
                    <Flex key={query.id} align='center' className={styles.queriesWrapper}>
                      <TextField.Root
                        {...register(`example_queries.${index}.value`)}
                        size='2'
                        radius='small'
                        placeholder='Enter Query'
                        className={styles.queryInput}
                      />
                      {errors?.example_queries?.[index]?.value && (
                        <p className={styles.error}>{errors.example_queries[index].value.message}</p>
                      )}
                      <Box className={styles.queryButtons}>
                        {index === queryFields.length - 1 && queryFields.length >= 1 && (
                          <Button type='button' variant='ghost' onClick={() => appendQuery({ value: '' })}>
                            <CirclePlusIcon />
                          </Button>
                        )}
                        {queryFields.length > 1 && (
                          <Button type='button' variant='ghost' onClick={() => handleRemove(index)}>
                            <CircleMinusIcon />
                          </Button>
                        )}
                      </Box>
                    </Flex>
                  ))}
                </FormField.Field>
              </FormField>
            </Box>
            <Flex gap='12px' mt='24px' direction='row-reverse' justify='space-between' align='center' width='100%'>
              <Flex gap='12px' direction='row-reverse'>
                <Button variant='solid' type='submit' disabled={isSubmitting}>
                  Save
                </Button>
                {isEdit && (
                  <Button variant='outline' type='button' onClick={onCancel}>
                    Cancel
                  </Button>
                )}
              </Flex>
              {isEdit && (
                <Button type='button' className={styles.deleteButton} onClick={() => setIsDeleteModalOpen(true)}>
                  Delete
                </Button>
              )}
              {!isEdit && (
                <Button variant='outline' type='button' onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
        <div className={styles.tipsContainer}>
          <Tips title='Tips to add Workspace:'>
            <Tip>
              Workspaces organize department-specific knowledge and support resources with tailored environments featuring relevant KB sources and apps for different departments.
            </Tip>
            <Tip>
              Provide a clear name and detailed description for each workspace, outlining its scope and key activities. This helps AssistAI to handle queries more effectively.
            </Tip>
            <Tip>
              Configure who can access the workspace by selecting the appropriate visibility option. You can choose to make it visible to all users or restrict it to specific user groups.
              To create a new user group, click {' '}<a onClick={() => navigate('/user-groups')} className={styles.link}>here</a>.
            </Tip>
            <Tip>
              Include relevant example queries to illustrate the types of queries handled within each workspace.
            </Tip>
          </Tips>

        </div>
      </Grid>
      {isEdit && (
        <Fragment>
          <DeleteModal
            open={isDeleteModalOpen}
            onClose={handleDeleteModalClose}
            onOpenChange={handleDeleteModalClose}
            id={workspace?.id}
            data={workspace}
            title='Delete Workspace'
            showFooter={false}
            showCloseButton={false}
            bodyClassName={styles.modalBody}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default WorkspaceForm
