import { Box, Button, Flex } from '@happyfoxinc/web-components'
import { useParams, useSearchParams } from 'react-router-dom'

import styles from './AppDetails.module.css'

import keyMap from 'Constants/key-map'
import BackButton from 'Src/componentsv3/BackButton'
import { openUrl } from 'Src/utils/url'

import InstallScreen from '../../components/InstallScreen'
import AppDetailContext from '../../hooks/AppDetailContext'
import { useAppsData } from '../../hooks/useAppsData'
import AzureAD from './apps/AzureAD'
import Confluence from './apps/Confluence'
import GitBook from './apps/GitBook'
import Guru from './apps/Guru'
import Helpdesk from './apps/Helpdesk'
import Notion from './apps/Notion'
import Okta from './apps/Okta'
import Outline from './apps/Outline'
import Servicedesk from './apps/Servicedesk'
import Sharepoint from './apps/Sharepoint'
import Simpplr from './apps/Simpplr'

const {
  HAPPYFOX_HELPDESK,
  NOTION,
  OKTA,
  SHAREPOINT,
  AZURE_AD,
  CONFLUENCE,
  GURU,
  SIMPPLR,
  GITBOOK,
  HAPPYFOX_SERVICE_DESK,
  OUTLINE
} = keyMap

const componentList = {
  [HAPPYFOX_HELPDESK.ID]: Helpdesk,
  [NOTION.ID]: Notion,
  [OKTA.ID]: Okta,
  [SHAREPOINT.ID]: Sharepoint,
  [AZURE_AD.ID]: AzureAD,
  [CONFLUENCE.ID]: Confluence,
  [GURU.ID]: Guru,
  [SIMPPLR.ID]: Simpplr,
  [GITBOOK.ID]: GitBook,
  [HAPPYFOX_SERVICE_DESK.ID]: Servicedesk,
  [OUTLINE.ID]: Outline
}

const AppDetails = () => {
  const { appId } = useParams()
  const { getAppById } = useAppsData()
  const app = getAppById(appId)

  const [searchParams] = useSearchParams()

  if (!app) {
    return <div>App not found</div>
  }

  const isReconnect = searchParams.get('isReconnect')

  const isInstalled = isReconnect ? false : app?.status === 'linked'

  const ManageScreen = componentList[app.id]
  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Apps</h1>
        </Flex>
      </div>
      <div className={styles.container}>
        <aside className={styles.sidebar}>
          <div className={styles.logoWrapper}>
            <app.logo className={styles.logo} />
          </div>
          <h1 className={styles.title}>{app.title}</h1>
          <p className={styles.description}>{app.description}</p>
          {app.kbLink && (
            <Button
              onClick={() => openUrl(app.kbLink)}
              size='small'
              radius='medium'
              variant='outline'
              className={styles.readMoreButton}
            >
              Detailed Guide
            </Button>
          )}
        </aside>

        <main className={styles.content}>
          {isInstalled ? (
            <AppDetailContext.Provider value={{ ...app }}>
              <ManageScreen app={app} />{' '}
            </AppDetailContext.Provider>
          ) : (
            <InstallScreen app={app} isReconnect={isReconnect} />
          )}
        </main>
      </div>
    </Box>
  )
}

export default AppDetails
