// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlM8L6hsev0L4t5NLbwA{width:100%}.l7jVxt_jmvGyK2PPqNUD{font-size:var(--text-md);color:var(--color-text-default);font-weight:var(--font-weight-normal);margin:6px 0}.uV3fik6RRVUYXxvmeI7Q{padding:16px;color:var(--color-text-muted);font-size:var(--text-xs);text-align:center}.z47GKMy1aVToGlsoKoqb{margin-bottom:10px}.ftVhYEUvbFf6UnfSMVea{border-radius:0px 0px var(--border-radius-lg) var(--border-radius-lg)}.s3UjnAZbo7Ynqw32_6xF{width:100%;display:flex;align-items:center;justify-content:flex-end}.TuD0IfMbeaVYc3d6j01B{padding:10px 12px !important}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/Notion/Notion.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,wBAAA,CACA,+BAAA,CACA,qCAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,qEAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,4BAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.heading {\n  font-size: var(--text-md);\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-normal);\n  margin: 6px 0;\n}\n\n.emptyState {\n  padding: 16px;\n  color: var(--color-text-muted);\n  font-size: var(--text-xs);\n  text-align: center;\n}\n\n.tableActionContainer {\n  margin-bottom: 10px;\n}\n\n.modalBody {\n  border-radius: 0px 0px var(--border-radius-lg) var(--border-radius-lg);\n}\n\n.syncButtonContainer {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.tableCell {\n  padding: 10px 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FlM8L6hsev0L4t5NLbwA",
	"heading": "l7jVxt_jmvGyK2PPqNUD",
	"emptyState": "uV3fik6RRVUYXxvmeI7Q",
	"tableActionContainer": "z47GKMy1aVToGlsoKoqb",
	"modalBody": "ftVhYEUvbFf6UnfSMVea",
	"syncButtonContainer": "s3UjnAZbo7Ynqw32_6xF",
	"tableCell": "TuD0IfMbeaVYc3d6j01B"
};
export default ___CSS_LOADER_EXPORT___;
