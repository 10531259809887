import { Fragment, useEffect, useMemo, useState } from 'react'

import { Box, Button, Flex, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'

import dayjs from 'dayjs'

import { useNavigate, useSearchParams } from 'react-router-dom'

import { usePagination, useSortBy, useTable } from 'react-table'

import _ from 'lodash'

import styles from './Announcements.module.css'
import toast from 'react-hot-toast'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import SearchIcon from 'Src/assetsv3/icons/search.svg'
import TableLoader from 'Src/componentsv3/TableLoader'

import { useGetAnnouncementsQuery, useStopScheduledAnnouncementMutation } from 'Src/servicesV3/announcementsApi'

import debounce from 'Src/utils/debounce'

import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Src/utils/query-params'

import DeleteModal from './DeleteAnnouncementModal'
import ReminderModal from './ReminderModal'

import ReactSelect from 'Src/componentsv3/ReactSelect'
import { components } from 'react-select'

import { PAGES, ANNOUNCEMENTS } from 'Src/pagesv3/constants'

const defaultParams = {
  defaultSearch: '',
  supportedSortColumns: ['created_at', 'updated_at'],
  defaultSortedColumn: 'created_at',
  defaultSortOrder: 'desc'
}

const getInitialParams = () => {
  return getInitialParamsForTable(defaultParams)
}

const getInitialApiQueryParams = () => {
  const initialParams = getInitialParams()
  return {
    ...getInitialApiQueryParamsForTable(initialParams)
  }
}

const STATUS_OPTIONS = [
  { label: 'Draft', value: 'draft' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Sent', value: 'sent' }
]

const MultiValue = (props) => {
  const { index, getValue } = props
  const value = getValue()
  const displayChips = 2

  const title = []
  for (let i = 0; i < value.length; i++) {
    if (i >= displayChips) {
      title.push(value[i].label)
    }
  }

  if (index < displayChips) {
    return <components.MultiValue {...props} />
  } else if (index === displayChips) {
    return <div className={styles.moreSelected} title={title.join(', ')}>{`+${value.length - displayChips}`}</div>
  } else {
    return null
  }
}

const AnnouncementsList = () => {
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const initialParams = useMemo(getInitialParams, [])

  const [queryParams, setQueryParams] = useState(() => getInitialApiQueryParams())
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null)

  const {
    data: announcementsApiResponse = {},
    isLoading: announcementApiLoading,
    refetch
  } = useGetAnnouncementsQuery(queryParams)
  const { results: announcementsList = [], meta: paginationDetails = {} } = announcementsApiResponse

  const [stopScheduledAnnouncement] = useStopScheduledAnnouncementMutation()

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  useEffect(() => {
    refetch()
  }, [refetch])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ cell: { value } }) => _.startCase(value)
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }) => {
          const statusClass = value.toLowerCase()
          return (
            <Flex gap='8px' className={styles.statusContainer}>
              <span className={`${styles.statusDot} ${styles[statusClass]}`}></span>
              <span>{_.startCase(value)}</span>
            </Flex>
          )
        }
      },
      {
        Header: 'Acknowledged',
        accessor: 'acknowledgement_stats',
        Cell: ({ cell: { value }, row }) => {
          if (!row.original.user_acknowledgement_required) {
            return 'N/A'
          }
          if (row.original.status !== 'sent') {
            return '-'
          }
          return `${value.acknowledged}/${value.total}`
        }
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: announcementsList,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      // sort
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  const [selectedStatuses, setSelectedStatuses] = useState([])

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText,
      status: selectedStatuses.length > 0 ? selectedStatuses.map((status) => status.value).join(',') : ''
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, searchText, sortBy, selectedStatuses, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  const handleDeleteClick = (announcement) => {
    setSelectedAnnouncement(announcement)
    setIsDeleteModalOpen(true)
  }

  const handleShowReminderClick = (announcement) => {
    setSelectedAnnouncement(announcement)
    setIsReminderModalOpen(true)
  }

  const handleDuplicateClick = (announcement) => {
    navigate('create', { state: { duplicateFrom: announcement } })
  }

  const handleStatusChange = (selected) => {
    setSelectedStatuses(selected || [])
    gotoPage(0)
  }

  const handleStopSchedule = (e, announcementId) => {
    e.stopPropagation()
    toast.promise(stopScheduledAnnouncement(announcementId).unwrap(), {
      loading: 'Stopping scheduled announcement...',
      success: 'Announcement schedule cancelled successfully',
      error: 'Failed to stop scheduled announcement'
    })
  }

  if (announcementApiLoading) {
    return <TableLoader rows={5} columns={4} showOptions />
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      outline: 'none',
      boxShadow: 'none',
      height: '30px',
      minHeight: '30px',
      cursor: 'pointer',
      backdropFilter: 'blur(6px)',
      border: '1px solid var(--color-border-subtle)',
      '&:hover': {
        border: '1px solid var(--color-border-subtle)'
      }
    })
  }

  return (
    <Fragment>
      <Flex justify='space-between' align='center' className={styles.tableActionContainer}>
        <Flex gap='10px' className='search-container'>
          <div className='search-wrapper'>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              className='search-input'
              placeholder='Search announcements'
              onChange={handleSearchInputChange}
            />
          </div>
          <ReactSelect
            options={STATUS_OPTIONS}
            value={selectedStatuses}
            onChange={handleStatusChange}
            placeholder='Filter by Status'
            isMulti={true}
            isClearable={true}
            components={{ MultiValue }}
            className={styles.statusFilter}
            styles={customStyles}
          />
        </Flex>
        <Flex gap='20px' align='center'>
          <Pagination
            currentPage={pageIndex + 1}
            pageSize={pageSize}
            totalItems={paginationDetails.total}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </Flex>
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className='no-records-found'>
                No data available
              </Table.Cell>
            </Table.Row>
          )}
          {rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              const announcement = row.original
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell
                        key={key}
                        {...restCellProps}
                        onClick={() => navigate(announcement.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                  <TableOptions>
                    <TableOption onClick={() => navigate(`${row.original.id}`)}>Edit</TableOption>
                    <TableOption onClick={() => handleDuplicateClick(row.original)}>Duplicate</TableOption>
                    {row.original.status === 'scheduled' && (
                      <TableOption onClick={(e) => handleStopSchedule(e, row.original.id)}>Stop Schedule</TableOption>
                    )}
                    {row.original.show_send_reminder &&
                      row.original.acknowledgement_stats.acknowledged !== row.original.acknowledgement_stats.total && (
                        <TableOption onClick={() => handleShowReminderClick(announcement)}>Send Reminder</TableOption>
                      )}
                    <TableOption onClick={() => handleDeleteClick(announcement)}>Delete</TableOption>
                  </TableOptions>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table.Root>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setSelectedAnnouncement(null)
        }}
        onOpenChange={() => {
          setIsDeleteModalOpen(false)
          setSelectedAnnouncement(null)
        }}
        id={selectedAnnouncement?.id}
        data={selectedAnnouncement}
        title='Delete Announcement'
        showFooter={false}
        bodyClassName={styles.modalBody}
        showCloseButton={false}
      />
      <ReminderModal
        open={isReminderModalOpen}
        onClose={() => {
          setIsReminderModalOpen(false)
          setSelectedAnnouncement(null)
        }}
        onOpenChange={() => {
          setIsReminderModalOpen(false)
          setSelectedAnnouncement(null)
        }}
        id={selectedAnnouncement?.id}
        data={selectedAnnouncement}
        title='Send Reminder'
        showFooter={false}
        bodyClassName={styles.modalBody}
      />
    </Fragment>
  )
}

const Announcements = () => {
  const navigate = useNavigate()

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='12px'>
          <h1 className='heading'>{PAGES[ANNOUNCEMENTS].title}</h1>
          <Button
            variant='solid'
            size='small'
            radius='full'
            className={styles.addButton}
            onClick={() => navigate('create')}
          >
            <PlusIcon />
          </Button>
        </Flex>
        <p className='sub-heading'>{PAGES[ANNOUNCEMENTS].description}</p>
      </div>
      <div className={styles.listContainer}>
        <AnnouncementsList />
      </div>
    </Box>
  )
}

export default Announcements
