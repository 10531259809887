import FormSkeleton from 'Src/componentsv3/FormSkeleton/FormSkeleton'

import styles from './AnswerForm.module.css'

const fields = [
  { type: 'input', showLabel: true },
  { type: 'textarea', showLabel: true },
  { type: 'input', showLabel: true },
  {
    type: 'input',
    showLabel: true,
    showInline: true,
    count: 2
  },
  { type: 'textarea', showLabel: true }
]

const AnswerFormSkeleton = ({ title, showHeader = false }) => {
  return (
    <FormSkeleton title={title} showHeader={showHeader} fields={fields} tipsContainerClass={styles.tipsContainer} />
  )
}

export default AnswerFormSkeleton
