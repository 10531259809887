import styles from './Announcements.module.scss'

import PageTitle from 'Components/PageTitle'

import AnnouncementForm from './AnnouncementForm'
import TipsContent from './TipsContent'

const Create = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.MainContent}>
          <div className={styles.FormContainer}>
            <div className={styles.PageTitle}>
              <PageTitle backTo='..'>Announcements</PageTitle>
            </div>
            <AnnouncementForm />
          </div>
        </div>
        <div className={styles.SidebarContent}>
          <TipsContent />
        </div>
      </div>
    </div>
  )
}

export default Create
