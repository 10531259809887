import EmptyAnnouncements from 'Icons/announcement-active.svg'

import NoContent from 'Layout/NoContent'

const noContentProps = {
  title: 'Write your first announcement',
  description: 'Create an announcement to keep your users informed about the latest news and updates.',
  ctaButtonText: 'Create announcement',
  icon: EmptyAnnouncements,
  ctaTarget: 'create'
}

const NoAnnouncements = () => {
  return <NoContent {...noContentProps} />
}

export default NoAnnouncements
