import * as yup from 'yup'

export const validationSchema = yup
  .object({
    form_type: yup.string().required('Form type is required'),
    title: yup.string().trim().required('Title is required'),
    content: yup.string().trim().required('Content is required'),
    send_to: yup.object().when('form_type', {
      is: 'advanced',
      then: (schema) =>
        schema
          .shape({
            label: yup.string(),
            value: yup.string()
          })
          .required('Send announcement to is required'),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    users: yup.array().when(['form_type', 'send_to.value'], {
      is: (form_type, send_to) => form_type === 'advanced' && send_to === 'specific_users',
      then: (schema) =>
        schema.required('At least one user is required').min(1, 'At least one user selection is required'),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    user_groups: yup.array().when(['form_type', 'send_to.value'], {
      is: (form_type, send_to) => form_type === 'advanced' && send_to === 'specific_user_groups',
      then: (schema) =>
        schema.required('At least one user group is required').min(1, 'At least one user group selection is required'),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    schedule_preference: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .when('form_type', {
        is: 'advanced',
        then: (schema) => schema.required('Schedule preference is required'),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
    scheduled_on: yup.string().when(['form_type', 'schedule_preference.value'], {
      is: (form_type, schedule_preference) => form_type === 'advanced' && schedule_preference === 'schedule_later',
      then: (schema) => schema.required('Scheduled on is required'),
      otherwise: (schema) => schema.nullable().notRequired()
    })
  })
  .required()

export default validationSchema
