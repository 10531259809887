import React, { Fragment, memo, useCallback } from 'react'

import styles from '../../ConfigModule.module.css'

import { CustomClose } from '../UtilityComponents'

const ChoiceOption = memo(({ value, onRemove, onValueChange, onKeyDown }) => (
  <div className={styles.optionRow}>
    <input
      type='text'
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      onKeyDown={onKeyDown}
      className={styles.optionInput}
      placeholder='Add Choice'
    />
    <CustomClose onClick={onRemove} />
  </div>
))

export const ChoiceContent = ({ question, onChange }) => {
  const addOption = () => {
    const updatedOptions = [...question.options, '']
    onChange({ ...question, options: updatedOptions })
  }

  const handleRemoveOption = useCallback(
    (index) => {
      const updatedOptions = question.options.filter((_, i) => i !== index)
      onChange({ ...question, options: updatedOptions })
    },
    [onChange, question]
  )

  const handleUpdateOption = useCallback(
    (index, value) => {
      const updatedOptions = [...question.options]
      updatedOptions[index] = value
      onChange({ ...question, options: updatedOptions })
    },
    [onChange, question]
  )

  const handleChoiceKeyDown = useCallback(
    (e, index) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        const newOptions = [...question.options, '']
        onChange({
          ...question,
          options: newOptions,
          isModified: true
        })

        setTimeout(() => {
          const inputs = document.getElementsByName(`choice-${question.id}`)
          if (inputs[index + 1]) {
            inputs[index + 1].focus()
          }
        }, 0)
      }
    },
    [question, onChange]
  )

  return (
    <Fragment>
      <input
        type='text'
        className={styles.questionInput}
        value={question.text || ''}
        placeholder={question.type === 'multi_choice' ? 'Add multiple choice question' : 'Add single choice question'}
        onChange={(e) => onChange({ ...question, text: e.target.value })}
      />
      <div className={styles.optionsContainer}>
        {question.options.map((option, index) => (
          <ChoiceOption
            key={index}
            value={option}
            onRemove={() => handleRemoveOption(index)}
            onValueChange={(value) => handleUpdateOption(index, value)}
            onKeyDown={(e) => handleChoiceKeyDown(e, index)}
          />
        ))}
      </div>
      <div className={styles.addChoiceContainer}>
        <div className={styles.stepLineToChoice} />
        <button className={styles.addOptionButton} onClick={addOption}>
          +
        </button>
      </div>
    </Fragment>
  )
}

export const TextContent = memo(({ question, onChange }) => (
  <Fragment>
    <input
      type='text'
      className={styles.questionInput}
      value={question.text}
      placeholder='Add Question'
      onChange={(e) => onChange({ ...question, text: e.target.value })}
    />
  </Fragment>
))
