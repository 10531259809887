import CompanyPolicyIcon from 'Src/assetsv3/logos/policy-logo.svg'
import SoftwareAccessIcon from 'Src/assetsv3/logos/software-access-logo.svg'
import SurveysIcon from 'Src/assetsv3/logos/surveys-logo.svg'

export const SOFTWARE_ACCESS = 'software-access'
export const COMPANY_POLICIES = 'company-policies'
export const SURVEY_MANAGEMENT = 'survey-management'

export const MODULES = {
  [SOFTWARE_ACCESS]: {
    icon: SoftwareAccessIcon,
    title: 'Software Access Request Management',
    description:
      'Simplify and automate employee software access requests, ensuring efficient approvals and secure provisioning.',
    path: 'software-access'
  },
  [COMPANY_POLICIES]: {
    icon: CompanyPolicyIcon,
    title: 'Company Policies',
    description:
      "Configure and centralize company policies, enabling Assist AI to instantly answer employee questions about your organization's policies.",
    path: 'company-policy'
  },
  [SURVEY_MANAGEMENT]: {
    icon: SurveysIcon,
    title: 'Survey Management',
    description:
      'Effortlessly create and manage surveys to capture user feedback, accelerate insights, and drive strategic decisions.',
    path: 'survey-management'
  }
}
