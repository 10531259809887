import FormSkeleton from 'Src/componentsv3/FormSkeleton/FormSkeleton'

import styles from './AnnouncementForm.module.css'

const fields = [
  { type: 'input', showLabel: true },
  { type: 'textarea', showLabel: true, showPreview: true },
  { type: 'input', showLabel: true },
  { type: 'input', showLabel: true }
]

const AnnouncementFormSkeleton = ({ title, showHeader = false }) => {
  return (
    <FormSkeleton
      title={title}
      showHeader={showHeader}
      fields={fields}
      showSwitch={true}
      tipsContainerClass={styles.tipsContainer}
    />
  )
}

export default AnnouncementFormSkeleton
