import { useMemo, useState } from 'react'

import { Sidebar } from '@happyfoxinc/web-components'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import styles from './Main.module.css'

import { CLIENT_FEATURE_FLAGS, FEATURE_FLAGS } from 'Constants/feature-flags'

import { useFeaturesContext } from 'Src/components/Features'

import DashboardImg from '../../assetsv3/images/backgrounds/dashboard-bg.png'
import SecondaryBgImg from '../../assetsv3/images/backgrounds/secondary-bg-img.png'
import Topbar from '../Topbar'

import {
  PAGES,
  DASHBOARD,
  ANSWERS,
  MODULES,
  WORKSPACES,
  ADMINS,
  USER_GROUPS,
  ANNOUNCEMENTS,
  REPORTS,
  APPS,
  CHANNELS
} from 'Src/pagesv3/constants'

const backgroundConfig = {
  '/dashboard': DashboardImg,
  '/modules': SecondaryBgImg,
  '/ai-assistant': SecondaryBgImg,
  default: SecondaryBgImg
}

const canShowMenuItem = (enabledFeatures, requiredFeatures) => {
  if (!requiredFeatures || requiredFeatures.length === 0) return true
  return requiredFeatures.every((feature) => enabledFeatures.includes(feature))
}

const AllMenuItems = [
  {
    icon: PAGES[DASHBOARD].icon,
    label: PAGES[DASHBOARD].title,
    path: PAGES[DASHBOARD].path
  },
  {
    icon: PAGES[MODULES].icon,
    label: PAGES[MODULES].title,
    path: PAGES[MODULES].path
  },
  { type: 'divider' },
  {
    icon: PAGES[ANSWERS].icon,
    label: PAGES[ANSWERS].title,
    path: PAGES[ANSWERS].path
  },
  { type: 'divider' },
  {
    icon: PAGES[APPS].icon,
    label: PAGES[APPS].title,
    path: PAGES[APPS].path
  },
  {
    icon: PAGES[CHANNELS].icon,
    label: PAGES[CHANNELS].title,
    path: PAGES[CHANNELS].path,
    requiredFeatures: [CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS]
  },
  {
    icon: PAGES[REPORTS].icon,
    label: PAGES[REPORTS].title,
    path: PAGES[REPORTS].path,
    requiredFeatures: [FEATURE_FLAGS.REPORTS]
  },
  {
    icon: PAGES[ANNOUNCEMENTS].icon,
    label: PAGES[ANNOUNCEMENTS].title,
    path: PAGES[ANNOUNCEMENTS].path,
    requiredFeatures: [CLIENT_FEATURE_FLAGS.WORKSPACES]
  },
  { type: 'divider' },
  {
    icon: PAGES[USER_GROUPS].icon,
    label: PAGES[USER_GROUPS].title,
    path: PAGES[USER_GROUPS].path,
    requiredFeatures: [FEATURE_FLAGS.USER_GROUPS]
  },
  {
    icon: PAGES[ADMINS].icon,
    label: PAGES[ADMINS].title,
    path: PAGES[ADMINS].path
  },
  {
    icon: PAGES[WORKSPACES].icon,
    label: PAGES[WORKSPACES].title,
    path: PAGES[WORKSPACES].path,
    requiredFeatures: [CLIENT_FEATURE_FLAGS.WORKSPACES]
  }
]

const Main = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isSearchActive, setIsSearchActive] = useState(false)

  const enabledFeatures = useFeaturesContext()

  const filteredMenuItems = useMemo(() => {
    return AllMenuItems.filter((item) => {
      if (item.type === 'divider') return true

      return canShowMenuItem(enabledFeatures, item.requiredFeatures)
    })
  }, [enabledFeatures])

  const currentBackground = useMemo(() => {
    if (location.pathname.startsWith('/modules')) {
      return backgroundConfig['/modules']
    }

    return backgroundConfig[location.pathname] || backgroundConfig.default
  }, [location.pathname])

  return (
    <div
      className={styles.MainContainer}
      style={{
        backgroundImage: `url(${currentBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Sidebar
        menuItems={filteredMenuItems}
        brandName='Assist AI'
        currentPath={location.pathname}
        onNavigate={(path) => navigate(path)}
      />
      <div className={styles.PageContainer}>
        <Topbar isSearchActive={isSearchActive} setIsSearchActive={setIsSearchActive} />
        <article className={styles.Content}>
          <Outlet />
        </article>
      </div>
    </div>
  )
}

export default Main
