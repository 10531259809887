import { Box, Button, Flex, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'

import { Fragment, useEffect, useMemo, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './Workspaces.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import SearchIcon from 'Src/assetsv3/icons/search.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useGetWorkspacesQuery } from 'Src/servicesV3/workspaceApi'
import debounce from 'Src/utils/debounce'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Src/utils/query-params'

import DeleteModal from './DeleteWorkspaceModal'

import { PAGES, WORKSPACES } from 'Src/pagesv3/constants'

const defaultParams = {
  defaultSearch: '',
  supportedSortColumns: ['created_at'],
  defaultSortedColumn: 'created_at',
  defaultSortOrder: 'desc'
}

const getInitialParams = () => {
  return getInitialParamsForTable(defaultParams)
}

const getInitialApiQueryParams = () => {
  const initialParams = getInitialParams()
  return {
    ...getInitialApiQueryParamsForTable(initialParams)
  }
}

const WorkspacesList = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const navigate = useNavigate()

  const [, setSearchParams] = useSearchParams()

  const initialParams = useMemo(getInitialParams, [])

  const [queryParams, setQueryParams] = useState(() => getInitialApiQueryParams())
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const { data: workspacesApiResponse = {}, isLoading: isWorkspaceApiLoading } = useGetWorkspacesQuery(queryParams)
  const { results: workspacesList = [], meta: paginationDetails = {} } = workspacesApiResponse

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (row) => row,
        Cell: ({ value }) => (
          <div className={styles.workspaceNameCell}>
            {value.name}
            {value.settings && value.settings.default ? <div className={styles.defaultLabel}>Default</div> : null}
          </div>
        )
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => {
          const MAX_LENGTH = 120
          return value && value.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value || '~'
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: workspacesList,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      // sort
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  const handleDelete = (workspace) => {
    setSelectedWorkspace(workspace)
    setIsDeleteModalOpen(true)
  }

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, searchText, sortBy, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
    setSelectedWorkspace(null)
  }

  if (isWorkspaceApiLoading) {
    return <TableLoader row={5} columns={2} showOptions />
  }

  return (
    <Fragment>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <div className='search-container'>
          <div className='search-wrapper'>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              className='search-input'
              placeholder='Search Workspaces'
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={columns.length} className='no-records-found'>
                No data available
              </Table.Cell>
            </Table.Row>
          )}
          {rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              const workspace = row.original
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell
                        key={key}
                        {...restCellProps}
                        onClick={() => navigate(workspace.id, { state: workspace })}
                        style={{ cursor: 'pointer' }}
                      >
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                  <TableOptions>
                    <TableOption onClick={() => navigate(workspace.id, { state: workspace })}>Edit</TableOption>
                    {!row?.original?.settings?.default && (
                      <TableOption onClick={() => handleDelete(workspace)}>Delete</TableOption>
                    )}
                  </TableOptions>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table.Root>

      {selectedWorkspace && (
        <Fragment>
          <DeleteModal
            open={isDeleteModalOpen}
            onClose={handleDeleteModalClose}
            onOpenChange={handleDeleteModalClose}
            id={selectedWorkspace?.id}
            data={selectedWorkspace}
            title='Delete Workspace'
            showFooter={false}
            bodyClassName={styles.modalBody}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

const Workspaces = () => {
  const navigate = useNavigate()

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='12px'>
          <h1 className='heading'>{PAGES[WORKSPACES].title}</h1>
          <Button
            variant='solid'
            size='small'
            radius='full'
            className={styles.addButton}
            onClick={() => navigate('create')}
          >
            <PlusIcon />
          </Button>
        </Flex>
        <p className='sub-heading'>{PAGES[WORKSPACES].description}</p>
      </div>
      <WorkspacesList />
    </Box>
  )
}

export default Workspaces
