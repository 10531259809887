import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'

import styles from './Guru.module.css'

import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import KnowledgeConfiguration from 'Src/pagesv3/Apps/components/KnowledgeConfiguration'
import SyncDisabledAlert from 'Src/pagesv3/Apps/components/SyncDisabledAlert'
import { useEnableSyncGuruMutation, useGetGuruQuery, useUninstallGuruMutation } from 'Src/servicesV3/guruApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import GuruSkeleton from './GuruSkeleton'

const Guru = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [, setSearchParams] = useSearchParams()

  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { data: app = {}, isLoading } = useGetGuruQuery({ workspace_id: currentWorkspaceId })
  const [enableSync, { isLoading: isEnablingSync }] = useEnableSyncGuruMutation()
  const [uninstallApp, { isLoading: isUninstalling }] = useUninstallGuruMutation()

  const handleEnableSync = async () => {
    const promise = enableSync().unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, {
      loading: 'Enabling sync...',
      success: 'Guru sync enabled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || 'Failed to enable sync for Guru. Try again...'
        setIsEnableSyncModalOpen(false)
        return errorMessage
      }
    })
  }

  const handleUninstall = async () => {
    const promise = uninstallApp({ workspace_id: currentWorkspaceId })
    promise.then(() => {
      setIsUninstallModalOpen(false)
    })

    toast.promise(promise, {
      loading: 'Uninstalling...',
      success: 'Guru uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || `Failed to uninstall Guru. Try again...`
        return errorMessage
      }
    })
  }

  const handleReconnect = () => {
    sessionStorage.setItem('reconnectApp', JSON.stringify(app))

    setSearchParams({ isReconnect: 'true' })
  }

  if (isLoading) {
    return <GuruSkeleton />
  }

  const isSyncDisabled = !app?.sync_info?.is_sync_enabled

  return (
    <Flex width='100%' gap='25px' direction='column' className={styles.wrapper}>
      {isSyncDisabled && <SyncDisabledAlert title='guru' onEnableSync={() => setIsEnableSyncModalOpen(true)} />}
      <AppInstalledCard
        title='Guru workspace'
        accountUrl={app.user_email_id}
        showButton
        buttonText='Reconnect'
        onButtonClick={handleReconnect}
      />
      <KnowledgeConfiguration app={app} />

      <AppFooter app={app} isUninstalling={isUninstalling} onUninstall={() => setIsUninstallModalOpen(true)} />

      <Modal
        size='small'
        title='Uninstall Guru'
        open={isUninstallModalOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        onClose={() => setIsUninstallModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
        showCloseButton={false}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isUninstalling} onClick={handleUninstall}>
              Uninstall
            </Button>
            <Button variant='outline' disabled={isUninstalling} onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        size='default'
        title='Enable Sync for Guru'
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsEnableSyncModalOpen(false)
        }}
        open={isEnableSyncModalOpen}
        onClose={() => setIsEnableSyncModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
        showCloseButton={false}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.ENABLE_APP_SYNC('Guru')}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isEnablingSync} onClick={handleEnableSync}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isEnablingSync} onClick={() => setIsEnableSyncModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Guru
