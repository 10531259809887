import { Box, Button, Flex, Modal } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import styles from './SurveyManagementModule.module.css'

import BackButton from 'Src/componentsv3/BackButton'
import {
  useCreateCampaignMutation,
  useDeleteCampaignMutation,
  useGetCampaignQuery,
  useGetCampaignsQuery,
  useUpdateCampaignMutation,
  useUpdateCampaignStatusMutation
} from 'Src/servicesV3/campaignApi'
import {
  useCreateQuestionsMutation,
  useCreateSurveyMutation,
  useDeleteQuestionMutation,
  useDeleteSurveyMutation,
  useGetSurveyQuery,
  useGetSurveysQuery,
  useUpdateQuestionMutation,
  useUpdateSurveyMutation
} from 'Src/servicesV3/surveyApi'

import CampaignsContent from './CampaignsContent'
import ConfigModule from './ConfigModule'
import CampaignConfiguration, { campaignValidationSchema } from './ConfigModule/CampaignConfiguration'
import ReportsModule from './ReportsModule/ReportsModule'
import SurveysContent from './SurveysContent'

import { MODULES, SURVEY_MANAGEMENT } from 'Src/pagesv3/Modules/constants'

const TABS = {
  SURVEYS: 'surveys',
  CAMPAIGNS: 'campaigns',
  REPORTS: 'reports'
}

const initialCampaignState = {
  name: '',
  description: '',
  type: 'immediately',
  scheduledAt: '',
  expiryDate: '',
  // repeat: 'weekly',
  surveyId: '',
  userEmails: [],
  userGroups: [],
  isAnonymous: false,
  allowMultipleResponses: false,
  distributionType: ''
}

const surveyValidationSchema = yup.object().shape({
  surveyName: yup.string().required('Survey name is required'),
  description: yup.string(),
  thankYouMessage: yup.string().required('Thank you message is required'),
  notificationMessage: yup.string().required('Notification message is required')
})

const SurveyManagementModule = () => {
  const [surveyQueryParams, setSurveyQueryParams] = useState({ search: '', limit: 10, offset: 0 })
  const [campaignQueryParams, setCampaignQueryParams] = useState({ search: '', limit: 10, offset: 0 })
  const [activeTab, setActiveTab] = useState(TABS.SURVEYS)
  const [isSetupStarted, setIsSetupStarted] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [surveyId, setSurveyId] = useState(null)
  const [questionsData, setQuestionsData] = useState({})
  const [activeMenu, setActiveMenu] = useState(null)
  const [campaignData, setCampaignData] = useState(initialCampaignState)
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false)
  const [editingSurvey, setEditingSurvey] = useState(null)
  const [editingCampaign, setEditingCampaign] = useState(null)
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    type: '',
    item: null
  })
  const [validateQuestions, setValidateQuestions] = useState(null)

  const {
    data: surveys,
    isLoading: isSurveysLoading,
    refetch: refetchSurveyList
  } = useGetSurveysQuery(surveyQueryParams)
  const {
    data: selectedSurvey,
    isLoading: isLoadingSurvey,
    refetch: refetchSurvey
  } = useGetSurveyQuery(editingSurvey?.id, {
    skip: !editingSurvey?.id
  })
  const [createSurvey] = useCreateSurveyMutation()
  const [updateSurvey] = useUpdateSurveyMutation()
  const [deleteSurvey] = useDeleteSurveyMutation()
  const [createQuestions] = useCreateQuestionsMutation()
  const [updateQuestion] = useUpdateQuestionMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()

  const {
    data: campaigns,
    isLoading: isCampaignsLoading,
    refetch: refetchCampaignList
  } = useGetCampaignsQuery(campaignQueryParams)
  const {
    data: selectedCampaign,
    isLoading: isLoadingCampaign,
    refetch: refetchCampaign
  } = useGetCampaignQuery(editingCampaign?.id, {
    skip: !editingCampaign?.id
  })
  const [createCampaign] = useCreateCampaignMutation()
  const [updateCampaign] = useUpdateCampaignMutation()
  const [updateCampaignStatus] = useUpdateCampaignStatusMutation()
  const [deleteCampaign] = useDeleteCampaignMutation()

  const surveyFormMethods = useForm({
    resolver: yupResolver(surveyValidationSchema),
    defaultValues: {
      surveyName: '',
      description: '',
      thankYouMessage:
        'Thank you for taking the time to complete this survey. Your feedback is valuable to us and will help us improve our services.',
      notificationMessage:
        'We value your feedback! Please take a moment to complete this quick survey to help us serve you better.'
    }
  })

  const campaignFormMethods = useForm({
    resolver: yupResolver(campaignValidationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      description: '',
      type: 'immediately',
      scheduledAt: '',
      expiryDate: '',
      // repeat: 'weekly',
      surveyId: '',
      isAnonymous: false,
      allowMultipleResponses: false,
      userEmails: [],
      userGroups: [],
      distributionType: ''
    }
  })

  const handleModalClose = () => {
    setIsSetupStarted(false)
    setEditingSurvey(null)
    setSurveyId(null)
    setQuestionsData({ newQuestions: [], modifiedQuestions: [], deletedQuestions: [] })
    setCurrentScreen(0)
    surveyFormMethods.reset({
      surveyName: '',
      description: '',
      thankYouMessage:
        'Thank you for taking the time to complete this survey. Your feedback is valuable to us and will help us improve our services.',
      notificationMessage:
        'We value your feedback! Please take a moment to complete this quick survey to help us serve you better.'
    })
    refetchSurveyList()
  }

  useEffect(() => {
    if (editingSurvey?.id) {
      refetchSurvey()
    }

    if (selectedSurvey && editingSurvey) {
      surveyFormMethods.reset({
        surveyName: selectedSurvey.survey.title || '',
        description: selectedSurvey.survey.description || '',
        thankYouMessage: selectedSurvey.survey.properties?.closing_message || '',
        notificationMessage: selectedSurvey.survey.properties?.cover_message || ''
      })
      setSurveyId(selectedSurvey.survey.id)
      setIsSetupStarted(true)
    }
  }, [selectedSurvey, editingSurvey, surveyFormMethods])

  const handleRowClick = (survey) => {
    if (survey && survey.id) {
      setEditingSurvey(survey)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && !event.target.closest(`.${styles.menuContainer}`)) {
        setActiveMenu(null)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [activeMenu])

  const handleStartSetup = () => {
    surveyFormMethods.reset({
      surveyName: '',
      description: '',
      thankYouMessage: 'Thank you for taking the time to complete this survey.',
      notificationMessage: 'Please take a moment to complete this quick survey.'
    })
    setQuestionsData({ newQuestions: [], modifiedQuestions: [], deletedQuestions: [] })
    setIsSetupStarted(true)
  }

  const handleCreateCampaign = () => {
    campaignFormMethods.reset(initialCampaignState)
    setIsCampaignModalOpen(true)
  }

  const handleNextScreen = async () => {
    if (currentScreen === 0) {
      const isValid = await surveyFormMethods.trigger(['surveyName', 'description'])
      if (!isValid) return
      setIsLoading(true)
      const formData = surveyFormMethods.getValues()
      const payload = {
        title: formData.surveyName?.trim(),
        description: formData.description?.trim()
      }

      try {
        if (surveyId) {
          const hasChanges =
            selectedSurvey?.survey?.title !== payload.title ||
            selectedSurvey?.survey?.description !== payload.description
          if (!hasChanges) {
            setCurrentScreen(1)
            setIsLoading(false)
            return
          }

          const promise = updateSurvey({ id: surveyId, data: payload })
          await toast.promise(promise, {
            loading: 'Updating survey...',
            success: 'Survey updated successfully!',
            error: (err) => err?.data?.error || 'Unable to update survey'
          })
        } else {
          const response = await createSurvey(payload).unwrap()
          setSurveyId(response?.survey?.id)
          toast.success('Survey created successfully!')
        }
        setCurrentScreen(1)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Survey operation error:', err)
      } finally {
        setIsLoading(false)
      }
    } else if (currentScreen === 1) {
      if (validateQuestions && !validateQuestions()) {
        return
      }

      const hasInvalidQuestion = [...questionsData.newQuestions, ...questionsData.modifiedQuestions].some(
        (q) => !q.text?.trim()
      )
      if (hasInvalidQuestion) {
        toast.error('All questions must have a non-empty text')
        return
      }

      const hasInvalidChoiceQuestion = [...questionsData.newQuestions, ...questionsData.modifiedQuestions].some(
        (q) =>
          (q.question_type === 'single_choice' || q.question_type === 'multiple_choice') &&
          (!q.choices || !q.choices.length || q.choices.every((opt) => !opt?.trim()))
      )

      if (hasInvalidChoiceQuestion) {
        toast.error('All choice questions must have at least one non-empty choice')
        return
      }

      try {
        if (questionsData?.newQuestions?.length > 0) {
          await createQuestions({
            id: surveyId,
            data: {
              questions: questionsData.newQuestions
            }
          }).unwrap()
        }

        if (questionsData?.modifiedQuestions?.length > 0) {
          await Promise.all(
            questionsData.modifiedQuestions.map((question) =>
              updateQuestion({
                questionId: question.id,
                surveyId,
                data: question
              }).unwrap()
            )
          )
        }

        if (questionsData?.deletedQuestions?.length > 0) {
          await Promise.all(
            questionsData.deletedQuestions.map((questionId) =>
              deleteQuestion({
                questionId,
                surveyId
              }).unwrap()
            )
          )
        }
        setCurrentScreen(2)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Failed to save questions', err)
      }
    } else if (currentScreen === 2) {
      const isValid = await surveyFormMethods.trigger(['thankYouMessage', 'notificationMessage'])
      if (!isValid) return
      const formData = surveyFormMethods.getValues()
      const payload = {
        properties: {
          closing_message: formData.thankYouMessage?.trim(),
          cover_message: formData.notificationMessage?.trim()
        }
      }

      try {
        const promise = updateSurvey({ id: surveyId, data: payload })
        await toast.promise(promise, {
          loading: 'Updating survey...',
          success: 'Survey updated successfully!',
          error: (err) => err?.data?.error || 'Unable to update survey'
        })
        handleModalClose()
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Survey operation error:', err)
      }
    }
  }

  const handleCancelButton = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1)
    } else {
      handleModalClose()
    }
  }

  const isNextDisabled = () => {
    if (currentScreen === 0) {
      return isLoading || !surveyFormMethods.getValues('surveyName')?.trim()
    } else if (currentScreen === 1) {
      return isLoading || !questionsData || typeof questionsData.newQuestions === 'undefined'
    } else if (currentScreen === 2) {
      const { thankYouMessage, notificationMessage } = surveyFormMethods.getValues()
      return isLoading || !thankYouMessage?.trim() || !notificationMessage?.trim()
    }
    return false
  }

  const handleModifiedQuestions = (questions) => {
    setQuestionsData(questions)
  }

  const handleDeleteSurvey = useCallback(
    (e, id) => {
      e.stopPropagation()
      setConfirmationModal({
        isOpen: true,
        type: 'survey',
        item: surveys?.results?.find((s) => s.id === id)
      })
      setActiveMenu(null)
    },
    [surveys]
  )

  const handleDeleteCampaign = (e, id) => {
    e.stopPropagation()
    setConfirmationModal({
      isOpen: true,
      type: 'campaign',
      item: campaigns?.results?.find((c) => c.id === id)
    })
    setActiveMenu(null)
  }

  const handleConfirmDelete = () => {
    const { type, item } = confirmationModal
    if (!item) return

    const promise = type === 'survey' ? deleteSurvey(item.id).unwrap() : deleteCampaign(item.id).unwrap()

    toast.promise(promise, {
      loading: `Deleting ${type}...`,
      success: `${type === 'survey' ? 'Survey' : 'Campaign'} '${
        type === 'survey' ? item.title : item.name
      }' deleted successfully`,
      error: `Failed to delete ${type}`
    })

    setConfirmationModal({ isOpen: false, type: '', item: null })
  }

  const handleCampaignStatus = async (e, campaign_id, status, scheduled_at, expiry_date) => {
    e.stopPropagation()
    try {
      const updated_status = status === 'closed' ? 'active' : 'closed'
      if (updated_status === 'active') {
        if (new Date(expiry_date) < new Date()) {
          toast.error(
            'The campaign has expired. Please change the end response collection date to activate the campaign.'
          )
          return
        }
      }
      const promise = updateCampaignStatus({
        id: campaign_id,
        data: {
          status: updated_status,
          expiry_date: updated_status === 'closed' ? new Date().toISOString() : expiry_date
        }
      })
      toast.promise(promise, {
        loading: 'Updating campaign status...',
        success: `Campaign ${updated_status === 'active' ? 'activated' : 'stopped'} successfully`,
        error: 'Failed to stop campaign'
      })

      promise.then(() => refetchCampaignList())
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Campaign update error:', err)
    }
  }

  const closeConfirmationModal = () => {
    setConfirmationModal({ isOpen: false, type: '', item: null })
  }

  const handleCampaignClick = (campaign) => {
    if (campaign && campaign.id) {
      setEditingCampaign(campaign)
      const formattedData = {
        name: campaign.name || '',
        description: campaign.description || '',
        type: campaign.distribution_schedule_type || 'immediately',
        scheduledAt: campaign.properties?.distribution_details?.scheduled_at || '',
        expiryDate: campaign.properties?.distribution_details?.expiry_date || '',
        // repeat: campaign.properties?.distribution_details?.repeat || 'weekly',
        surveyId: campaign.survey_id || '',
        isAnonymous: campaign.properties?.is_anonymous || false,
        allowMultipleResponses: campaign.properties?.allow_multiple_responses || false,
        userEmails: campaign.properties?.distribution_details?.distribute_to?.user_email_ids || [],
        userGroups: campaign.properties?.distribution_details?.distribute_to?.user_group_ids || [],
        distributionType: campaign.properties?.distribution_details?.distribute_to?.distribution_type || '',
        appRequesterProfileIds:
          campaign.properties?.distribution_details?.distribute_to?.app_requester_profile_ids || []
      }
      setCampaignData(formattedData)
      campaignFormMethods.reset(formattedData)
      setIsCampaignModalOpen(true)
    }
  }

  const handleCampaignModalClose = () => {
    setIsCampaignModalOpen(false)
    setEditingCampaign(null)
    setCampaignData(initialCampaignState)
    campaignFormMethods.reset(initialCampaignState)
    refetchCampaignList()
  }

  const handleCampaignSubmit = async (data, shouldProcess = false) => {
    try {
      const isValid = await campaignFormMethods.trigger()
      if (!isValid) {
        return
      }

      setIsLoading(true)

      const formattedData = {
        name: data.name?.trim(),
        description: data.description?.trim(),
        survey_id: data.surveyId,
        distribution_schedule_type: data.type,
        properties: {
          is_anonymous: data.isAnonymous || false,
          allow_multiple_responses: data.allowMultipleResponses || false,
          should_process: shouldProcess,
          distribution_details: {
            scheduled_at:
              data.scheduledAt && data.type !== 'immediately' ? new Date(data.scheduledAt).toISOString() : '',
            expiry_date: data.expiryDate ? new Date(data.expiryDate).toISOString() : '',
            repeat: data.type === 'recurring' ? data.repeat : '',
            distribute_to: {
              user_email_ids: data.distributionType === 'specific_user_emails' ? data.userEmails : [],
              user_group_ids: data.distributionType === 'specific_user_groups' ? data.userGroups : [],
              app_requester_profile_ids: data.appRequesterProfileIds,
              distribution_type: data.distributionType
            }
          }
        }
      }

      let promise
      if (editingCampaign) {
        promise = updateCampaign({
          id: editingCampaign.id,
          data: formattedData
        })
      } else {
        promise = createCampaign(formattedData)
      }

      await toast.promise(promise, {
        loading: editingCampaign ? 'Updating campaign...' : 'Creating campaign...',
        success: (response) => {
          if (shouldProcess) {
            return editingCampaign
              ? 'Campaign updated and initiated successfully!'
              : 'Campaign created and initiated successfully!'
          }
          return editingCampaign ? 'Campaign settings saved successfully!' : 'Campaign created successfully!'
        },
        error: (err) => {
          const errorMessage = err?.data?.error || 'Unable to process campaign operation'
          return errorMessage
        }
      })

      handleCampaignModalClose()
    } catch (err) {
      console.log('Campaign operation error:', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (activeTab === TABS.SURVEYS) {
      setCampaignQueryParams({ search: '', limit: 10, offset: 0 })
    } else if (activeTab === TABS.CAMPAIGNS) {
      setSurveyQueryParams({ search: '', limit: 10, offset: 0 })
    }
  }, [activeTab])

  const [hasSurveys, setHasSurveys] = useState(false)
  useEffect(() => {
    if (surveys?.results && !surveyQueryParams.search) {
      setHasSurveys(surveys.results.length > 0)
    }
  }, [surveys?.results, surveys?.results?.length, surveyQueryParams.search])

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.SURVEYS:
        return (
          <SurveysContent
            surveys={surveys}
            isSurveysLoading={isSurveysLoading && activeTab === TABS.SURVEYS}
            handleStartSetup={handleStartSetup}
            handleRowClick={handleRowClick}
            handleDeleteSurvey={handleDeleteSurvey}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            setSearchParams={setSurveyQueryParams}
            queryParams={surveyQueryParams}
          />
        )
      case TABS.CAMPAIGNS:
        return (
          <CampaignsContent
            campaigns={campaigns}
            isCampaignsLoading={isCampaignsLoading && activeTab === TABS.CAMPAIGNS}
            handleCampaignClick={handleCampaignClick}
            handleCampaignStatus={handleCampaignStatus}
            handleDeleteCampaign={handleDeleteCampaign}
            handleCreateCampaign={handleCreateCampaign}
            setSearchParams={setCampaignQueryParams}
            queryParams={campaignQueryParams}
          />
        )
      case TABS.REPORTS:
        return <ReportsModule />
      default:
        return null
    }
  }

  return (
    <Fragment>
      <Modal
        size='full'
        open={isSetupStarted}
        onOpenChange={handleModalClose}
        confirmText={currentScreen === 2 ? 'Finish' : 'Proceed'}
        cancelText={currentScreen === 0 ? 'Cancel' : 'Back'}
        onCancel={handleCancelButton}
        onConfirm={surveyFormMethods.handleSubmit(handleNextScreen)}
        showProgressBar
        currentStep={currentScreen + 1}
        totalSteps={3}
        title={editingSurvey ? 'Edit Survey' : 'Create Survey'}
        contentClassName={styles.modalContent}
        confirmDisabled={isNextDisabled()}
        bodyClassName={styles.configModalBody}
      >
        <FormProvider {...surveyFormMethods}>
          <ConfigModule
            key={`${currentScreen}-${surveyId}-${editingSurvey?.id}`}
            currentScreen={currentScreen}
            surveyId={surveyId}
            handleTransformedQuestions={handleModifiedQuestions}
            isEdit={!!editingSurvey}
            isLoading={isLoadingSurvey}
            onValidationError={(validateFn) => setValidateQuestions(() => validateFn)}
          />
        </FormProvider>
      </Modal>
      <Modal
        size='full'
        open={isCampaignModalOpen}
        onOpenChange={handleCampaignModalClose}
        showFooter={false}
        title={editingCampaign ? 'Edit Campaign' : 'Create Campaign'}
        contentClassName={styles.modalContent}
        bodyClassName={styles.campaignModalBody}
      >
        <FormProvider {...campaignFormMethods}>
          <CampaignConfiguration
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            editingCampaign={editingCampaign}
            isLoading={isLoadingCampaign}
            selectedCampaign={selectedCampaign}
            refetchCampaign={refetchCampaign}
            fromSurveyPage={false}
            onCancel={handleCampaignModalClose}
            onSave={(data, shouldProcess) => handleCampaignSubmit(data, shouldProcess)}
          />
        </FormProvider>
      </Modal>
      <Modal
        size='small'
        open={confirmationModal.isOpen}
        onOpenChange={closeConfirmationModal}
        showFooter={false}
        title={`Delete ${confirmationModal.type}`}
      >
        <Flex direction='column' gap='12px' align='center'>
          Are you sure you want to delete this {confirmationModal.type}?
          <Flex gap='15px'>
            <Button variant='solid' onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button variant='outline' onClick={closeConfirmationModal}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Box className='page-content'>
        <div className='heading-container'>
          <Flex align='center' gap='4px' className={styles.headingContainer}>
            <BackButton className='back-btn' />
            <h1 className='heading'>{MODULES[SURVEY_MANAGEMENT].title}</h1>
          </Flex>
          <p className='sub-heading'>{MODULES[SURVEY_MANAGEMENT].description}</p>
        </div>
        <Flex justify='space-between' className={styles.tabContainer}>
          <Flex gap='10px' className={styles.tabList}>
            {Object.values(TABS).map((tab) => {
              const isDisabled = tab !== TABS.SURVEYS && !hasSurveys
              const isAvailable = !isDisabled && tab !== activeTab

              return (
                <Button
                  key={tab}
                  className={cx(styles.tab, {
                    [styles.active]: activeTab === tab,
                    [styles.disabled]: isDisabled,
                    [styles.available]: isAvailable
                  })}
                  onClick={() => !isDisabled && setActiveTab(tab)}
                  disabled={isDisabled}
                  title={isDisabled ? 'Create a survey first' : ''}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Button>
              )
            })}
          </Flex>
          {(activeTab === TABS.SURVEYS || activeTab === TABS.CAMPAIGNS) && (
            <Button
              className={styles.addButton}
              onClick={activeTab === TABS.SURVEYS ? handleStartSetup : handleCreateCampaign}
            >
              {activeTab === TABS.SURVEYS ? 'Create Survey' : 'Create Campaign'}
              <span>+</span>
            </Button>
          )}
        </Flex>
        {renderTabContent()}
      </Box>
    </Fragment>
  )
}

export default SurveyManagementModule
