import { Box, Button, Grid, Flex, TextField } from '@happyfoxinc/web-components'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './UserGroupForm.module.css'

import { ACCOUNT_TYPE } from 'Constants/account'
import { APP_NOT_INSTALLED_MSG, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tips, { Tip } from 'Src/componentsv3/Tips'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'
import parseErrorMessage from 'Utils/error-message-parser'

import AccessConditionForm from '../AccessControl/AccessControl'
import DeleteModal from '../DeleteUserGroupModal'
import SyncedUsersStatus from '../SyncedUsersStatus'
import { getUserIdKey } from '../usergroups-helper'
import AddedFromField from './AddedFromField'

import KB_LINKS from 'Constants/kb-links'

const customMultiSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  control: (provided) => ({
    ...provided,
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px'
  })
}

const UserGroupForm = ({ userGroup = {}, isEdit = false, isLoading = false, onSubmit, isRefreshing, onRefresh }) => {
  const { sync_from_group: syncedFromGroup } = userGroup
  const { MANUAL, CONDITIONAL, SYNCED_FROM_APP } = USER_MANAGEMENT_TYPE
  const { data: account } = useGetAccountQuery()
  const accountType = account.account_type
  const isMsTeamsAccountType = accountType === ACCOUNT_TYPE.MS_TEAMS

  const navigate = useNavigate()

  const [getUsers, getUsersResult] = userGroupsApi.useLazyGetUsersQuery()

  const {
    register,
    control,
    formState: {
      errors,
      dirtyFields: { addedFrom: isAddedFromEdited, syncedFromGroup: isSyncedFromGroupEdited }
    },
    watch,
    setValue
  } = useFormContext()
  const addedFromField = watch('addedFrom')
  const isUserManuallyAdded = addedFromField?.value === MANUAL
  const isUserAddedConditionally = addedFromField?.value === CONDITIONAL

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const loadOptions = (inputValue, cb) => {
    const query = {
      name: inputValue,
      accountType
    }
    getUsers(query)
      .unwrap()
      .then((data) => {
        const options = data.map(({ is_app_installed, ...rest }) => {
          const disabled = isMsTeamsAccountType ? !is_app_installed : false
          return {
            disabled,
            ...rest
          }
        })
        cb(options)
      })
  }

  const [getAppUserGroups, getAppUserGroupsResult] = userGroupsApi.useLazyGetAppUserGroupsQuery()
  const loadAppUserGroupOptions = (inputValue, cb) => {
    getAppUserGroups({ search: inputValue, appName: addedFromField.value })
      .unwrap()
      .then((data) => {
        cb(data)
      })
  }

  const [reSyncUserGroup] = userGroupsApi.useReSyncUserGroupMutation()
  const handleReSync = () => {
    const { id } = userGroup
    const payload = { id }
    const promise = reSyncUserGroup(payload).unwrap()

    toast.promise(promise, {
      loading: `Starting the User Group Re-sync`,
      success: `Started the User Group Re-sync`,
      error: parseErrorMessage(`Unable to Re-sync the User Group. Try again...`)
    })
  }
  const isSyncedFromApp = userGroup.user_management_type === SYNCED_FROM_APP

  const appUserGroupDefaultOptions = syncedFromGroup ? [syncedFromGroup] : []
  const handleAddedFromChange = () => {
    setValue('syncedFromGroup', null, { shouldDirty: true })
    setValue('users', [])
    setValue('conditions', [])
  }

  const getUsersFieldOptionValue = (opt) => {
    const idKey = getUserIdKey(accountType)
    return opt[idKey]
  }

  const getUsersFieldOptionLabel = (opt) => {
    if (opt.disabled) {
      return `${opt.name} (${APP_NOT_INSTALLED_MSG})`
    }
    return opt.name
  }

  const onCancel = () => {
    setIsDeleteModalOpen(false)
  }

  return (
    <Grid columns='1fr 25%' gap='10rem'>
      <Box width='100%'>
        <form onSubmit={onSubmit} className={styles.formContainer}>
          <Flex direction='column' align='flex-start' justify='space-between' gap='2rem' width='100%' height='100%'>
            <Box width='100%'>
              <FormField className={styles.formFieldContainer}>
                <FormField.Field label='Name' error={errors?.name?.message} className={styles.w100} isRequired={true}>
                  <TextField.Root {...register('name')} size='2' radius='small' placeholder='Enter Name' />
                </FormField.Field>
              </FormField>
              <FormField className={styles.w100}>
                <AddedFromField onChange={handleAddedFromChange} />
              </FormField>
              {addedFromField && (
                <FormField className={styles.w100}>
                  {!isUserManuallyAdded && !isUserAddedConditionally && (
                    <FormField.Field label='Select Group' error={errors?.syncedFromGroup?.message} isRequired={true}>
                      <Controller
                        name='syncedFromGroup'
                        control={control}
                        render={({ field }) => {
                          return (
                            <ReactSelect
                              {...field}
                              placeholder='Search groups'
                              defaultOptions={appUserGroupDefaultOptions}
                              minCharsBeforeLoadingOptions={3}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              loadOptions={loadAppUserGroupOptions}
                              isLoading={getAppUserGroupsResult.isLoading}
                              loadingMessage={() => 'Searching for groups...'}
                              noOptionsMessage={({ inputValue }) => {
                                if (inputValue.length < 3) {
                                  return 'Type alteast 3 characters to start searching'
                                }
                                return `No groups found for input "${inputValue}"`
                              }}
                            />
                          )
                        }}
                      />
                    </FormField.Field>
                  )}
                  {isUserAddedConditionally && (
                    <div className={styles.conditionField}>
                      <AccessConditionForm errors={errors} />
                    </div>
                  )}
                  {isUserManuallyAdded && (
                    <FormField.Field label='Users' error={errors?.users?.message} isRequired={true}>
                      <Controller
                        control={control}
                        name='users'
                        render={({ field }) => {
                          return (
                            <ReactSelect
                              {...field}
                              placeholder='Search users'
                              isClearable={false}
                              isMulti
                              isInvalid={Boolean(errors.users)}
                              options={[]}
                              getOptionLabel={getUsersFieldOptionLabel}
                              getOptionValue={getUsersFieldOptionValue}
                              loadOptions={loadOptions}
                              isOptionDisabled={(opt) => opt.disabled}
                              isLoading={getUsersResult.isLoading}
                              loadingMessage={() => 'Searching for users...'}
                              minCharsBeforeLoadingOptions={2}
                              noOptionsMessage={({ inputValue }) => {
                                if (inputValue.length < 2) {
                                  return 'Type alteast 2 characters to start searching'
                                }
                                return `No users found for input "${inputValue}"`
                              }}
                              styles={customMultiSelectStyles}
                            />
                          )
                        }}
                      />
                    </FormField.Field>
                  )}
                </FormField>
              )}
              {isSyncedFromApp && !isAddedFromEdited && !isSyncedFromGroupEdited && (
                <SyncedUsersStatus
                  userGroup={userGroup}
                  onReSync={handleReSync}
                  isRefreshing={isRefreshing}
                  onRefresh={onRefresh}
                />
              )}
            </Box>
            <Flex gap='12px' mt='24px' direction='row-reverse' className={styles.buttonContainer}>
              <Flex gap='12px' mt='24px' direction='row-reverse' width={isEdit ? 'auto' : '100%'}>
                <Button type='submit' disabled={isLoading}>
                  Save
                </Button>
                <Button type='button' variant='outline' onClick={() => navigate('../')}>
                  Cancel
                </Button>
              </Flex>
              {isEdit && (
                <Button type='button' className={styles.deleteButton} onClick={() => setIsDeleteModalOpen(true)}>
                  Delete
                </Button>
              )}
            </Flex>
          </Flex>
          <DeleteModal
            open={isDeleteModalOpen}
            onCancel={onCancel}
            onSuccess={() => {
              setIsDeleteModalOpen(false)
              navigate('../')
            }}
            onOpenChange={onCancel}
            id={userGroup?.id}
            data={userGroup}
            title='Delete User Group'
            showFooter={false}
            bodyClassName={styles.modalBody}
          />
        </form>
      </Box>
      <div className={styles.tipsContainer}>
        <Tips title='Tips to add User Group'>
          <Tip>
            User groups allow you to group users based on different attributes like location, department, designation
            etc.. You can then restrict knowledge visibility across different user groups.
          </Tip>
          <Tip>
            <a onClick={() => navigate('/apps/category/knowledge-sources')} className={styles.link}>
              Set Knowledge Visibility
            </a>{' '}
            by configuring the knowledge base access to the appropriate user groups.
          </Tip>
          <Tip>
            Learn more about{' '}
            <a href={KB_LINKS.CREATE_USER_GROUP} target='_blank' rel='noreferrer' className={styles.link}>
              creating user groups
            </a>{' '}
            and{' '}
            <a href={KB_LINKS.SET_KNOWLEDGE_VISIBILITY} target='_blank' rel='noreferrer' className={styles.link}>
              restricting knowledge source
            </a>
            .
          </Tip>
        </Tips>
      </div>
    </Grid>
  )
}

export default UserGroupForm
