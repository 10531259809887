import React from 'react'

import styles from './CommonContentLoader.module.css'

const CommonContentLoader = ({ fields = 4 }) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.skeletonLoading} style={{ width: '60%', height: '24px', marginBottom: '24px' }} />
        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <div className={styles.buttonContent}>
              <div className={styles.buttonTextGroup}>
                <div className={styles.skeletonLoading} style={{ width: '70%', height: '20px', marginBottom: '8px' }} />
                <div className={styles.skeletonLoading} style={{ width: '90%', height: '16px' }} />
              </div>
              <div className={styles.skeletonLoading} style={{ width: '24px', height: '24px' }} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.headerSection}>
          <div className={styles.skeletonLoading} style={{ width: '40%', height: '24px', marginBottom: '24px' }} />
        </div>
        <div className={styles.fieldsContainer}>
          {Array(fields)
            .fill(null)
            .map((_, index) => (
              <div key={`field-${index}`} className={styles.fieldBlock}>
                <div className={styles.label}>
                  <div className={styles.skeletonLoading} style={{ width: '120px', height: '16px' }} />
                </div>
                <div className={styles.input}>
                  <div className={styles.skeletonLoading} style={{ width: '100%', height: '40px' }} />
                </div>
                {index < fields - 1 && <div className={styles.fieldSpacer} />}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default CommonContentLoader
