import { createContext, useContext, useState } from 'react'

import { useGetAccountQuery } from 'Src/servicesV3/authApi'

import { useGetSoftwaresQuery } from 'Src/servicesV3/softwareAccessModuleApi'

const SoftwareContext = createContext()

export const SoftwareProvider = ({ children }) => {
  const [selectedApp, setSelectedApp] = useState(null)
  const [catalogItemId, setCatalogItemId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState(null)

  const { data: account } = useGetAccountQuery()

  const { data: softwares = {}, isLoading, isFetching, isSuccess, error, refetch } = useGetSoftwaresQuery()

  const resetModalState = () => {
    setIsModalOpen(false)
    setSelectedApp(null)
    setCatalogItemId(null)
    setSelectedFeature(null)
  }

  return (
    <SoftwareContext.Provider
      value={{
        selectedApp,
        setSelectedApp,
        catalogItemId,
        setCatalogItemId,
        isModalOpen,
        setIsModalOpen,
        selectedFeature,
        setSelectedFeature,
        resetModalState,
        softwares,
        isSoftwareLoading: isLoading,
        isSoftwareFetching: isFetching,
        isSoftwareSuccess: isSuccess,
        error,
        refetchSoftwares: refetch,
        isTicketingIntegrationConnected: account.is_ticketing_integration_connected
      }}
    >
      {children}
    </SoftwareContext.Provider>
  )
}

export const useSoftwareContext = () => {
  const context = useContext(SoftwareContext)
  if (!context) {
    throw new Error('useSoftwareContext must be used within a SoftwareProvider')
  }
  return context
}
