import { Button, Flex, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useCallback, useMemo } from 'react'
import { components } from 'react-select'

import styles from './SurveyManagementModule.module.css'

import TableLoader from 'Src/componentsv3/TableLoader'

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg'
import SearchIcon from '../../../assetsv3/icons/search.svg'

const campaignColumns = [
  { key: 'name', label: 'Title' },
  { key: 'survey_title', label: 'Survey' },
  { key: 'status', label: 'Status' },
  { key: 'distribution_schedule_type', label: 'Distribution Type' },
  { key: 'updated_at', label: 'Updated on' }
]

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const dateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }
  return date
    .toLocaleString('en-US', dateOptions)
    .replace(',', '')
    .replace(/,([^\s])/, ', $1')
}

const capitalize = (str) => {
  if (!str) return '-'
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const STATUS_OPTIONS = [
  { label: 'Draft', value: 'draft' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Active', value: 'active' },
  { label: 'Closed', value: 'closed' }
]

const DISTRIBUTION_TYPE_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Immediately', value: 'immediately' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Recurring', value: 'recurring' }
]

const MultiValue = (props) => {
  const { index, getValue } = props
  const value = getValue()
  const displayChips = 2

  const title = []
  for (let i = 0; i < value.length; i++) {
    if (i >= displayChips) {
      title.push(value[i].label)
    }
  }

  if (index < displayChips) {
    return <components.MultiValue {...props} />
  } else if (index === displayChips) {
    return <div className={styles.moreSelected} title={title.join(', ')}>{`+${value.length - displayChips}`}</div>
  } else {
    return null
  }
}

const CampaignsContent = ({
  campaigns,
  isCampaignsLoading,
  handleCampaignClick,
  handleCampaignStatus,
  handleDeleteCampaign,
  handleCreateCampaign,
  setSearchParams,
  queryParams
}) => {
  const handlePageChange = useCallback(
    (page) => {
      setSearchParams((prev) => ({
        ...prev,
        offset: (page - 1) * (campaigns?.meta?.limit || 10)
      }))
    },
    [setSearchParams, campaigns?.meta?.limit]
  )

  const handleStatusChange = (selectedStatuses) => {
    setSearchParams({
      ...queryParams,
      status: selectedStatuses.map((status) => status.value).join(','),
      offset: 0
    })
  }

  const handleDistributionTypeChange = (selectedType) => {
    setSearchParams({
      ...queryParams,
      distribution_type: selectedType ? selectedType.value : '',
      offset: 0
    })
  }

  const getCampaignCellContent = (campaign, key) => {
    switch (key) {
      case 'status':
        return (
          <Flex align='center' gap='8px'>
            <div className={`${styles.statusDot} ${styles[campaign.status]}`} />
            <span className={styles.statusText}>{capitalize(campaign.status)}</span>
          </Flex>
        )
      case 'updated_at':
        return formatDate(campaign[key])
      case 'survey_title':
      case 'name':
      case 'distribution_schedule_type':
        return capitalize(campaign[key])
      default:
        return campaign[key] ? capitalize(campaign[key]) : '-'
    }
  }

  const renderCampaignCell = useMemo(() => {
    return campaigns.results.map((campaign) => ({
      ...campaign,
      renderedCells: campaignColumns.reduce(
        (acc, header) => ({
          ...acc,
          [header.key]: getCampaignCellContent(campaign, header.key)
        }),
        {}
      )
    }))
  }, [campaigns.results, getCampaignCellContent])

  if (isCampaignsLoading) {
    return <TableLoader rows={4} columns={6} showOptions />
  }

  const hasFilters = queryParams.search || queryParams.status?.length > 0 || queryParams.distribution_type
  if (!campaigns?.results?.length && !hasFilters) {
    return (
      <Flex align='center' justify='center' gap='30px' className={styles.configurationContainer}>
        <h2 className={styles.noConfigTitle}>No campaigns created yet</h2>
        <p className={styles.noConfigDescription}>
          You don't have any campaigns yet. Create a campaign to send surveys and start collecting feedback from your
          users.
        </p>
        <Button className={styles.buttonContainter} onClick={handleCreateCampaign}>
          Create Campaign
          <ArrowRightIcon height='1em' width='1em' />
        </Button>
      </Flex>
    )
  }

  const currentPage = Math.floor((campaigns?.meta?.offset || 0) / (campaigns?.meta?.limit || 10)) + 1

  return (
    <div className={styles.tableContainer}>
      <Flex justify='space-between' align='center' gap='16px' className={styles.tableActions}>
        <div className='search-container'>
          <div className='search-wrapper'>
            <SearchIcon className='search-icon' />
            <input
              type='text'
              className='search-input'
              placeholder='Search Campaigns'
              value={queryParams.search}
              onChange={(e) => setSearchParams({ ...queryParams, search: e.target.value })}
            />
          </div>
        </div>
        <Flex gap='12px' align='center'>
          <ReactSelect
            options={STATUS_OPTIONS}
            value={STATUS_OPTIONS.filter((option) => queryParams.status?.split(',').includes(option.value))}
            onChange={handleStatusChange}
            placeholder='Filter by Status'
            className={styles.statusSelect}
            isMulti={true}
            isClearable={false}
            components={{ MultiValue }}
          />
          <ReactSelect
            options={DISTRIBUTION_TYPE_OPTIONS}
            value={DISTRIBUTION_TYPE_OPTIONS.find((option) => option.value === queryParams.distribution_type)}
            onChange={handleDistributionTypeChange}
            placeholder='Distribution Type'
            className={styles.filterSelect}
            isClearable={false}
          />
          <Pagination
            currentPage={currentPage}
            pageSize={campaigns?.meta?.limit || 10}
            totalItems={campaigns?.meta?.total || 0}
            canPreviousPage={currentPage > 1}
            canNextPage={currentPage < Math.ceil((campaigns?.meta?.total || 0) / (campaigns?.meta?.limit || 10))}
            previousPage={() => handlePageChange(currentPage - 1)}
            nextPage={() => handlePageChange(currentPage + 1)}
          />
        </Flex>
      </Flex>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            {campaignColumns.map((header) => (
              <Table.ColumnHeaderCell key={header.key}>{header.label}</Table.ColumnHeaderCell>
            ))}
            <Table.ColumnHeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaigns?.results?.length > 0 ? (
            renderCampaignCell.map((campaign) => (
              <Table.Row key={campaign.id} clickable>
                {campaignColumns.map((header) => (
                  <Table.Cell key={`${campaign.id}-${header.key}`} onClick={() => handleCampaignClick(campaign)}>
                    {campaign.renderedCells[header.key]}
                  </Table.Cell>
                ))}
                <TableOptions>
                  <TableOption
                    onClick={(e) =>
                      handleCampaignStatus(e, campaign.id, campaign.status, campaign.scheduled_at, campaign.expiry_date)
                    }
                  >
                    {campaign.status !== 'closed' ? 'Stop Campaign' : 'Activate Campaign'}
                  </TableOption>
                  <TableOption onClick={(e) => handleDeleteCampaign(e, campaign.id)}>Delete</TableOption>
                </TableOptions>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={campaignColumns.length + 1}>
                <div className={styles.emptyMessage}>No campaigns match your search criteria</div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    </div>
  )
}

export default CampaignsContent
