// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qRei3KRPGjmoAtbk27gi{padding:7px}.JGjUcwZ6gj4SOa3dWBTB{margin-top:20px}.XthNbFRsHvxRAreEI4Xh{background-color:var(--color-surface-default);border-radius:var(--border-radius-md);box-shadow:0 2px 4px rgba(0,0,0,.04)}.aWV1lLOWkyEfNfryRQbQ{border-radius:0px 0px var(--border-radius-lg) var(--border-radius-lg)}.FwY7el6bwL6GXqGTmcPD{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Answers/Answers.module.css"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,eAAA,CAGF,sBACE,6CAAA,CACA,qCAAA,CACA,oCAAA,CAGF,sBACE,qEAAA,CAGF,sBACE,kBAAA","sourcesContent":[".addButton {\n  padding: 7px;\n}\n\n.listContainer {\n  margin-top: 20px;\n}\n\n.formContainer {\n  background-color: var(--color-surface-default);\n  border-radius: var(--border-radius-md);\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);\n}\n\n.modalBody {\n  border-radius: 0px 0px var(--border-radius-lg) var(--border-radius-lg);\n}\n\n.tableActionContainer {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "qRei3KRPGjmoAtbk27gi",
	"listContainer": "JGjUcwZ6gj4SOa3dWBTB",
	"formContainer": "XthNbFRsHvxRAreEI4Xh",
	"modalBody": "aWV1lLOWkyEfNfryRQbQ",
	"tableActionContainer": "FwY7el6bwL6GXqGTmcPD"
};
export default ___CSS_LOADER_EXPORT___;
