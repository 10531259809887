import { Button, Flex, TextField } from '@happyfoxinc/web-components'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './SurveyConfiguration.module.css'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Src/componentsv3/Tooltip'
import InfoIcon from 'Src/assetsv3/icons/info.svg'
import ChevronRight from 'Src/assetsv3/icons/white-chevron-right.svg'
import ListIcon from 'Src/assetsv3/logos/surveys-logo.svg'
import FormField from 'Src/componentsv3/FormField'
import { usePreviewSurveyMutation } from 'Src/servicesV3/surveyApi'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'

import CommonContentLoader from './components/CommonContentLoader/CommonContentLoader'

const SurveyConfiguration = ({ isLoading, currentScreen, surveyId }) => {
  const { data: account } = useGetAccountQuery()
  const [previewSurvey] = usePreviewSurveyMutation()
  const { getValues } = useFormContext()

  const handlePreviewClick = useCallback(async () => {
    if (!surveyId) {
      toast.error('Please save the survey first')
      return
    }

    const { thankYouMessage, notificationMessage } = getValues()

    const promise = previewSurvey({
      surveyId: surveyId,
      data: {
        properties: {
          closing_message: thankYouMessage?.trim() || 'Thank you for taking the time to complete this survey.',
          cover_message: notificationMessage?.trim() || 'Please take a moment to complete this quick survey.'
        }
      }
    })

    await toast.promise(promise, {
      loading: 'Sending preview...',
      success: 'Preview sent! Check your DM.',
      error: 'Failed to send preview'
    })
  }, [surveyId, previewSurvey, getValues])

  const {
    register,
    formState: { errors }
  } = useFormContext()

  if (isLoading) {
    return <CommonContentLoader fields={2} />
  }

  const renderBasicConfig = useMemo(
    () => (
      <div className={styles.tabContent}>
        <FormField>
          <FormField.Field error={errors?.surveyName?.message} isRequired label='Survey Name'>
            <TextField.Root
              size='1'
              radius='small'
              placeholder='Enter survey name'
              style={{ border: '1px solid var(--color-border-default)' }}
              {...register('surveyName')}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field label='Description'>
            <textarea {...register('description')} placeholder='Enter survey description' className={styles.textarea} />
          </FormField.Field>
        </FormField>
      </div>
    ),
    [errors, register]
  )

  const renderNotificationConfig = useMemo(
    () => (
      <div className={styles.tabContent}>
        <div className={styles.headerSection}>
          <Flex align='center' style={{ width: '100%', justifyContent: 'space-between' }}>
            <h3 className={styles.configTitle}>Message Configuration</h3>
            <Tooltip delayDuration={150}>
              <TooltipTrigger style={{ display: 'flex', border: 'none', background: 'none', padding: 0 }}>
                <Button
                  variant='outline'
                  onClick={handlePreviewClick}
                  className={styles.previewButton}
                  disabled={!surveyId}
                >
                  Preview Survey
                </Button>
              </TooltipTrigger>
              <TooltipContent style={{ background: 'var(--tooltip-bg)', color: 'var(--color-surface-default)', fontSize: 'var(--text-xs)' }}>
                <span>Send yourself a preview of this survey via {account?.account_type}</span>
              </TooltipContent>
            </Tooltip>
          </Flex>
        </div>

        <FormField>
          <FormField.Field error={errors?.notificationMessage?.message} isRequired label='Survey Notification Message'>
            <textarea
              {...register('notificationMessage')}
              placeholder='Message shown to users when inviting them to take your survey'
              className={styles.textarea}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field error={errors?.thankYouMessage?.message} isRequired label='Thank You Message'>
            <textarea
              {...register('thankYouMessage')}
              placeholder="Message displayed to users after they've completed your survey."
              className={styles.textarea}
            />
          </FormField.Field>
        </FormField>

        <div className={styles.scheduleTooltip}>
          <InfoIcon className={styles.infoIcon} />
          <div className={styles.tooltipText}>
            Once your survey is ready, <strong>create a campaign</strong> to send it out to users and collect responses.
            You can manage campaigns from the Campaigns section.
          </div>
        </div>
      </div>
    ),
    [handlePreviewClick, surveyId, errors?.notificationMessage?.message, errors?.thankYouMessage?.message, register]
  )

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <h2 className={styles.title}>Configure Your Survey</h2>
        <div className={styles.buttonGroup}>
          <button className={`${styles.configButton} ${styles.activeButton}`}>
            <div className={styles.buttonContent}>
              <div className={styles.buttonText}>
                <h3>{currentScreen === 0 ? 'Survey Details' : 'Survey Messages'}</h3>
                <p>
                  {currentScreen === 0
                    ? 'Provide a survey name and description to help your team easily identify and manage this survey.'
                    : 'Customize the messages users will see when receiving and completing your survey.'}
                </p>
              </div>
              <div className={styles.selectedArrowContainer}>
                <ChevronRight />
              </div>
            </div>
          </button>
        </div>
      </div>

      <div className={styles.rightSection}>
        <Flex>
          <ListIcon className={styles.propertiesIcon} />
          <h3 className={styles.propertiesTitle}>{currentScreen === 0 ? 'Basic Details' : 'Messaging'}</h3>
        </Flex>
        {currentScreen === 0 ? renderBasicConfig : renderNotificationConfig}
      </div>
    </div>
  )
}

export default SurveyConfiguration
