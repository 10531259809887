// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JhXVZKg57HmTBM2bRi0b{all:unset}.PbAk9Ltp0LkUKYJNgjk4{display:flex;flex-direction:column;gap:.7rem;align-items:center}.MySSdp9LjrrXT0kM83Aq{display:flex;align-items:center;gap:.4rem}", "",{"version":3,"sources":["webpack://./../src/componentsv3/UserGroupBadge/UserGroupBadge.module.css"],"names":[],"mappings":"AAAA,sBACE,SAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":[".tooltipTrigger {\n  all: unset;\n}\n\n.upgradeTooltip {\n  display: flex;\n  flex-direction: column;\n  gap: 0.7rem;\n  align-items: center;\n}\n\n.upgradeButton {\n  display: flex;\n  align-items: center;\n  gap: 0.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipTrigger": "JhXVZKg57HmTBM2bRi0b",
	"upgradeTooltip": "PbAk9Ltp0LkUKYJNgjk4",
	"upgradeButton": "MySSdp9LjrrXT0kM83Aq"
};
export default ___CSS_LOADER_EXPORT___;
