// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UI83WaJv75asxa7vef9g{margin-bottom:.7rem;color:var(--color-text-muted);font-size:var(--text-xs)}.KJbZokuNV4TVb_XMD3PB{padding:1.5rem;margin-top:2rem;background-color:var(--color-surface-muted);border-radius:var(--border-radius-lg)}.Iwvpt2_TeDH2SAL9LoSX{margin:1rem 0;color:var(--color-text-default);font-weight:var(--font-weight-normal)}.mx8uV0Z9u32zNXKBmHMj{list-style:inherit;padding:0 0 0 1rem;color:var(--color-text-default);font-size:var(--text-sm)}", "",{"version":3,"sources":["webpack://./../src/componentsv3/Tips/Tips.module.css"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,6BAAA,CACA,wBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,2CAAA,CACA,qCAAA,CAGF,sBACE,aAAA,CACA,+BAAA,CACA,qCAAA,CAGF,sBACE,kBAAA,CACA,kBAAA,CACA,+BAAA,CACA,wBAAA","sourcesContent":[".tip {\n  margin-bottom: 0.7rem;\n  color: var(--color-text-muted);\n  font-size: var(--text-xs);\n}\n\n.tipContainer {\n  padding: 1.5rem;\n  margin-top: 2rem;\n  background-color: var(--color-surface-muted);\n  border-radius: var(--border-radius-lg);\n}\n\n.tipTitle {\n  margin: 1rem 0;\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-normal);\n}\n\n.tipContent {\n  list-style: inherit;\n  padding: 0 0 0 1rem;\n  color: var(--color-text-default);\n  font-size: var(--text-sm);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tip": "UI83WaJv75asxa7vef9g",
	"tipContainer": "KJbZokuNV4TVb_XMD3PB",
	"tipTitle": "Iwvpt2_TeDH2SAL9LoSX",
	"tipContent": "mx8uV0Z9u32zNXKBmHMj"
};
export default ___CSS_LOADER_EXPORT___;
