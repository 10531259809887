import { Route, Routes } from 'react-router-dom'

import Channels from './Channels'
import EditChannel from './EditChannel'

const ChannelRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Channels />} />
      <Route path=':id' index element={<EditChannel />} />
    </Routes>
  )
}

export default ChannelRouter
