import { Box, Flex } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import styles from './Announcements.module.css'

import BackButton from 'Src/componentsv3/BackButton'

import AnnouncementForm from './AnnouncementForm'
import announcementFormValidationSchema from './AnnouncementForm/announcement-form-validation-schema'

const AnnouncementCreate = () => {
  const location = useLocation()
  const duplicateFrom = location.state?.duplicateFrom
  
  const formMethods = useForm({
    defaultValues: {
      id: null,
      title: '',
      content: '',
      send_to: null,
      users: null,
      user_groups: null,
      schedule_preference: null,
      scheduled_on: null,
      user_acknowledgement_required: false,
      acknowledgement_configuration: {
        button_label: ''
      }
    },
    resolver: yupResolver(announcementFormValidationSchema)
  })

  useEffect(() => {
    if (duplicateFrom) {
      const titlePrefix = 'Copy of '
      
      const formData = {
        title: `${titlePrefix}${duplicateFrom.title}`,
        content: duplicateFrom.content,
        send_to: duplicateFrom.send_to,
        schedule_preference: duplicateFrom.schedule_preference || 'now',
        scheduled_on: duplicateFrom.scheduled_on || null,
        user_acknowledgement_required: duplicateFrom.user_acknowledgement_required,
        acknowledgement_configuration: {
          button_label: duplicateFrom.acknowledgement_configuration?.button_label || 'Acknowledge'
        }
      }

      if (duplicateFrom.send_to === 'user_groups' && duplicateFrom.user_groups?.length > 0) {
        formData.visibleToGroups = duplicateFrom.user_groups.map(group => ({
          id: group.id,
          label: group.name,
          value: group.id
        }))
      }

      if (duplicateFrom.send_to === 'specific_users' && duplicateFrom.users?.length > 0) {
        formData.users = duplicateFrom.users
      }
      
      formMethods.reset(formData)

      const editor = document.querySelector('trix-editor');
      if (editor) {
        editor.editor.loadHTML(duplicateFrom.content);
      }
    }
  }, [duplicateFrom, formMethods])

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Create Announcement</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <AnnouncementForm />
        </FormProvider>
      </Box>
    </Box>
  )
}

export default AnnouncementCreate
