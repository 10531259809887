import FormSkeleton from 'Src/componentsv3/FormSkeleton/FormSkeleton'

import styles from './UserGroupForm.module.css'

const fields = [
  { type: 'input', showLabel: true },
  { type: 'input', showLabel: true },
  { type: 'input', showLabel: true },
  { type: 'textarea', showLabel: true }
]

const UserGroupFormSkeleton = ({ title, showHeader = false }) => {
  return (
    <FormSkeleton title={title} showHeader={showHeader} fields={fields} tipsContainerClass={styles.tipsContainer} />
  )
}

export default UserGroupFormSkeleton
