import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

const Options = (props) => {
  const { id } = props

  const navigate = useNavigate()

  const [deleteAnnouncement] = api.useDeleteAnnouncementMutation()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDeleteClick = useCallback(() => {
    const promise = deleteAnnouncement(id).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting Announcement...',
      success: 'Announcement deleted successfully.',
      error: parseErrorMessage('Unable to delete announcement. Try again...')
    })
  }, [deleteAnnouncement, id])

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => navigate(`/announcements/edit/${id}`)}>Edit</TableOption>
        <TableOption onClick={() => setIsDeleteModalOpen(true)}>Delete</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.DELETE_ANNOUNCEMENT}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteClick}
      />
    </Fragment>
  )
}

export default Options
