import { useCallback, Fragment } from 'react'

import _ from 'lodash'

import { Grid, Button } from '@happyfoxinc/web-components'

import PropTypes from 'prop-types'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'

import styles from './AccessControl.modules.css'

import CloseIcon from 'Icons/close.svg'

import { useAccessCondition } from './AccessConditionContext'

import MultiSelect from 'Src/pagesv3/Modules/SoftwareAccessModule/components/MultiSelectCheckbox'

const generateId = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`

const AccessConditionForm = ({ errors }) => {
  const { dropdownOptions } = useAccessCondition()

  return (
    <div className={styles.accessConditionForm}>
      <div className={styles.header}>
        <h2>Access Condition</h2>
      </div>
      <div className={styles.conditionsContainer}>
        <Grid columns='40% 50% 5%' gap='16px' className={styles.conditionRow}>
          <div className={styles.columnLabel}>Field</div>
          <div className={styles.columnLabel}>Value</div>
          <div className={styles.columnLabel}></div>
        </Grid>
        <ConditionComponent
          errors={errors}
          valueOptions={dropdownOptions?.values || []}
          fieldOptions={dropdownOptions?.fields || []}
        />
      </div>
    </div>
  )
}

const ConditionComponent = ({ errors, valueOptions, fieldOptions }) => {
  const { watch, control, getValues, setError } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'conditions'
  })

  const conditions = watch('conditions')

  // function will filter the field options based on previously selected fields
  // Eg: If "IT" is selected in row1, "IT" should not be available in the consecutive rows
  const getFilteredFieldOptions = useCallback(() => {
    const selectedFields = conditions.map((condition) => condition.field?.value)
    return fieldOptions.filter((option) => !selectedFields.includes(option.value))
  }, [conditions, fieldOptions])

  const handleAddCondition = useCallback(() => {
    const newId = generateId()
    append({
      id: newId,
      field: null,
      values: []
    })
  }, [append])

  const handleFieldChange = useCallback(
    (selectedOption, index) => {
      update(index, { field: selectedOption, values: [] })
      setError(`conditions.${index}.field`, null)
    },
    [update]
  )

  const getMultiDropdownOptions = useCallback(
    (index) => {
      const selectedFieldOption = getValues(`conditions.${index}.field`)
      return selectedFieldOption ? valueOptions[selectedFieldOption.value] : []
    },
    [getValues, valueOptions]
  )

  const CustomOption = ({ innerProps, label, data, isSelected, isFocused }) => (
    <div
      {...innerProps}
      style={{
        padding: '6px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isSelected ? '#f5f5f5' : isFocused ? '#f9f9f9' : 'transparent',
        color: 'var(--color-text-default)',
        transition: 'all 0.2s ease',
        borderRadius: 'var(--border-radius-md)',
        fontSize: 'var(--text-sm)',
        ...(data.hidden ? { display: 'none' } : {})
      }}
    >
      {label}
    </div>
  )

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '32px',
      outline: 'none',
      boxShadow: 'none',
      borderRadius: 'var(--border-radius-sm)',
      border: '1px solid var(--color-border-default)',
      '&:hover': {
        border: '1px solid var(--color-border-default)'
      }
    })
  }

  return (
    <Fragment>
      {fields.length === 0 && (
        <Fragment>
          <div className={styles.noConditionRow}>
            <p>No access condition. Click '+ Add' to configure condition.</p>
          </div>
          {errors?.conditions && <p className={styles.errorText}>{errors?.conditions?.message}</p>}
        </Fragment>
      )}
      {fields.length > 0 &&
        fields.map((condition, index) => {
          return (
            <Grid key={condition.id} columns='40% 50% 5%' gap='16px' className={styles.conditionRow}>
              <FormField className={styles.fieldContainer}>
                <FormField.Field error={errors?.conditions?.[`${index}`]?.field?.message} isRequired={true}>
                  <input type='hidden' {...control.register(`conditions.${index}.name`)} value='Access Condition 1' />
                  <Controller
                    name={`conditions.${index}.field`}
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        onChange={(option) => handleFieldChange(option, index)}
                        options={getFilteredFieldOptions()}
                        placeholder='Select Field'
                        className={styles.fieldSelect}
                        isSearchable={false}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        components={{
                          Option: CustomOption
                        }}
                        styles={customStyles}
                      />
                    )}
                  />
                </FormField.Field>
              </FormField>
              <FormField className={styles.fieldContainer}>
                <FormField.Field error={errors?.conditions?.[`${index}`]?.values?.message} isRequired={true}>
                  <Controller
                    name={`conditions.${index}.values`}
                    control={control}
                    render={({ field }) => (
                      <MultiSelect options={getMultiDropdownOptions(index)} field={field} placeholder='Select Value' />
                    )}
                  />
                </FormField.Field>
              </FormField>
              <Button variant='ghost' onClick={() => remove(index)} className={styles.removeButton}>
                <CloseIcon className={styles.closeIcon} />
              </Button>
            </Grid>
          )
        })}
      {fieldOptions.length > conditions.length && (
        <Button type='button' variant='primary' onClick={handleAddCondition} className={styles.addButton}>
          + Add
        </Button>
      )}
    </Fragment>
  )
}

ConditionComponent.propTypes = {
  valueOptions: PropTypes.object.isRequired,
  fieldOptions: PropTypes.array.isRequired
}

export default AccessConditionForm
