import { Fragment } from 'react'

import { Box, Flex, Grid } from '@happyfoxinc/web-components'

import Skeleton from 'Src/componentsv3/Skeleton'
import BackButton from 'Src/componentsv3/BackButton'

import { FIELD_CONFIG } from './config'

import './FormSkeleton.css'

const getFieldDimensions = (type, customConfig = {}) => {
  const defaultConfig = FIELD_CONFIG[type] || FIELD_CONFIG.input
  return {
    height: customConfig.height || defaultConfig.height,
    labelWidth: customConfig.labelWidth || defaultConfig.labelWidth,
    labelHeight: customConfig.labelHeight || defaultConfig.labelHeight
  }
}

const FormSkeleton = ({
  title,
  actions = 2,
  fields = [],
  showSwitch = false,
  showHeader = false,
  tipsContainerClass
}) => {
  const renderField = (field, index) => {
    const { type = 'input', config = {}, showPreview } = field
    const dimensions = getFieldDimensions(type, config)

    return (
      <Fragment key={`skeleton-field-container-${index}`}>
        {!field.showInline && (
          <div key={`skeleton-field-${index}`} className='skeleton-field' styles={field.styles}>
            {field.showLabel && (
              <Skeleton
                width={dimensions.labelWidth}
                height={dimensions.labelHeight}
                styles={{ marginBottom: '8px' }}
              />
            )}
            {field.type === 'input' && <Skeleton width='100%' height={dimensions.height} styles={field.styles} />}
            {field.type === 'textarea' && <Skeleton width='100%' height={dimensions.height} styles={field.styles} />}
            {showPreview && (
              <Flex justify='space-between' className='skeleton-preview-message'>
                <Skeleton width='100px' height='14px' />
                <Skeleton width='100px' height='14px' />
              </Flex>
            )}
          </div>
        )}
        {field.showInline && (
          <Flex key={index} gap='20px' align='center' justify='space-between'>
            {Array(field.count)
              .fill()
              .map((_, i) => (
                <Box width='100%' key={i} style={{ flex: 1, marginBottom: '24px' }}>
                  {field.showLabel && (
                    <Skeleton
                      width={dimensions.labelWidth}
                      height={dimensions.labelHeight}
                      styles={{ marginBottom: '8px' }}
                    />
                  )}
                  <Skeleton width='100%' height={dimensions.height} styles={field.styles} />
                </Box>
              ))}
          </Flex>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      {showHeader && title && (
        <Box className='page-content'>
          <div className='heading-container'>
            <Flex align='center' gap='4px'>
              <BackButton className='back-btn' />
              <h1 className='heading'>{title}</h1>
            </Flex>
          </div>
        </Box>
      )}
      <Box p='30px' className='form-container'>
        <Grid columns='1fr 25%' gap='10rem'>
          <Box width='100%'>
            {fields.map(renderField)}

            {showSwitch && (
              <div className='skeleton-switch'>
                <Skeleton width={FIELD_CONFIG.switch.width} height={FIELD_CONFIG.switch.height} circle />
                <Skeleton width={FIELD_CONFIG.switch.labelWidth} height={FIELD_CONFIG.switch.labelHeight} />
              </div>
            )}

            <Flex align='center' justify='space-between' gap='20px' direction='row-reverse'>
              <Flex gap='12px' direction='row-reverse'>
                {Array(actions)
                  .fill()
                  .map((_, i) => {
                    return <Skeleton key={i} width={FIELD_CONFIG.action.width} height={FIELD_CONFIG.action.height} />
                  })
                  .filter(Boolean)}
              </Flex>
            </Flex>
          </Box>
          <div className={tipsContainerClass}>
            <div className='skeleton-tips'>
              <Skeleton width='150px' height='20px' styles={{ marginBottom: '16px' }} />
              <Flex direction='column' gap='12px'>
                <Skeleton width='100%' height='16px' />
                <Skeleton width='100%' height='16px' />
                <Skeleton width='100%' height='16px' />
              </Flex>
            </div>
          </div>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default FormSkeleton
