// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iqUOTtYBpJYyIurx5fgu{padding:10px;max-width:350px;line-height:1.4;z-index:var(--z-tooltip);font-size:var(--text-xs);color:var(--color-surface-default);background-color:var(--tooltip-bg);box-shadow:var(--tooltip-box-shadow);border-radius:var(--border-radius-sm);animation-duration:var(--tooltip-animation-duration);animation-timing-function:var(--tooltip-animation-timing-function);transform-origin:center top}.MGbWgw2YhIwCEXBUsbUi span{white-space:pre-line}.H9mrOyYNhbOguMRatAlQ{fill:var(--tooltip-arrow-color)}", "",{"version":3,"sources":["webpack://./../src/componentsv3/Tooltip/Tooltip.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,eAAA,CACA,wBAAA,CACA,wBAAA,CACA,kCAAA,CACA,kCAAA,CACA,oCAAA,CACA,qCAAA,CACA,oDAAA,CACA,kEAAA,CACA,2BAAA,CAGF,2BACE,oBAAA,CAGF,sBACE,+BAAA","sourcesContent":[".contentContainer {\n  padding: 10px;\n  max-width: 350px;\n  line-height: 1.4;\n  z-index: var(--z-tooltip);\n  font-size: var(--text-xs);\n  color: var(--color-surface-default);\n  background-color: var(--tooltip-bg);\n  box-shadow: var(--tooltip-box-shadow);\n  border-radius: var(--border-radius-sm);\n  animation-duration: var(--tooltip-animation-duration);\n  animation-timing-function: var(--tooltip-animation-timing-function);\n  transform-origin: center top;\n}\n\n.tooltipContent span {\n  white-space: pre-line;\n}\n\n.arrow {\n  fill: var(--tooltip-arrow-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "iqUOTtYBpJYyIurx5fgu",
	"tooltipContent": "MGbWgw2YhIwCEXBUsbUi",
	"arrow": "H9mrOyYNhbOguMRatAlQ"
};
export default ___CSS_LOADER_EXPORT___;
