import { Flex } from '@happyfoxinc/web-components'

import styles from './TipToAddInChannels.module.css'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Src/componentsv3/Tooltip'

const Content = () => (
  <TooltipContent align='start' alignOffset={-13} side='right' className={styles.tooltipContent}>
    <div className={styles.heading}>Two Ways to add Assist AI to your Slack channel</div>

    <Flex direction='column' gap='10px'>
      <span className={styles.textContent}>
        Go to your channel and mention <span className={styles.strongText}>@Assist AI</span>. Slack will then prompt you
        to add Assist AI to the respective channel.
      </span>

      <Flex className={styles.dividerContainer} justify='between' align='center'>
        <span>or</span>
        <div className={styles.divider} />
      </Flex>

      <span className={styles.textContent}>
        Navigate to Channel settings {'>'} Integrations {'>'} Apps. <br /> Click{' '}
        <span className={styles.strongText}>Add an App</span> and add Assist AI.
      </span>
    </Flex>
  </TooltipContent>
)

const TipToAddInChannels = ({ triggerText }) => (
  <Tooltip delayDuration={100}>
    <TooltipTrigger asChild className={styles.tooltipTrigger}>
      <a href='' className={styles.link}>
        {triggerText}
      </a>
    </TooltipTrigger>
    <Content />
  </Tooltip>
)

export default TipToAddInChannels
