import { Navigate } from 'react-router-dom'

import styles from './Announcement.module.scss'

import PageLoader from 'Components/PageLoader'
import PageTitle from 'Components/PageTitle'
import Tips, { Tip } from 'Components/Tips'
import { ACCOUNT_TYPE } from 'Constants/account'
import { ANNOUNCEMENT_STATUS } from 'Constants/announcement-status'
import api from 'Services/api'

import AnnouncementForm from './AnnouncementForm'

const TipsContent = () => (
  <Tips title='Let Your Team Know'>
    <Tip>This one time announcement lets Assist AI introduce itself on DM to everyone in the Slack workspace.</Tip>
    <Tip>This is a sample welcome message, which you are welcome to edit based on your organization needs.</Tip>
    <Tip>
      Once the message is customized, Click <strong>'Preview'</strong>, for Assist AI to DM this welcome message to you
      on Slack. If satisfied with the message click <strong>'Send DM to all'</strong> to DM all the employees with this
      welcome message.
    </Tip>
  </Tips>
)

const Announcement = () => {
  const { isLoading, data: dashboard = {} } = api.useGetDashboardQuery()
  const { data: account = {} } = api.useGetAccountQuery()

  const isAnnouncementNotPending = dashboard.introductory_announcement_status !== ANNOUNCEMENT_STATUS.PENDING
  const isNonSlackAccountType = account.account_type !== ACCOUNT_TYPE.SLACK

  if (isLoading) {
    return <PageLoader />
  }

  if (isAnnouncementNotPending || isNonSlackAccountType) {
    return <Navigate to='..' replace />
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.MainContent}>
          <div className={styles.FormContainer}>
            <div className={styles.PageTitle}>
              <PageTitle backTo='..'>Announcements</PageTitle>
            </div>
            <AnnouncementForm />
          </div>
        </div>
        <div className={styles.SidebarContent}>
          <TipsContent />
        </div>
      </div>
    </div>
  )
}

export default Announcement
