import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetAppsQuery } from 'Src/servicesV3/appsApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import APPS_CONFIG from '../constants/appsConfig'
import { APP_CATEGORY } from '../constants/appTypes'

export const useAppsData = () => {
  const { currentWorkspaceId } = useWorkspace()
  const { category = APP_CATEGORY.ALL } = useParams()

  const {
    data: appsApiData = [],
    isLoading,
    error
  } = useGetAppsQuery({
    workspace_id: currentWorkspaceId
  })

  const ticketingIntegrationStatus = useMemo(() => {
    if (!appsApiData) return { isIntegrated: false, integratedApp: null }

    const helpdesk = appsApiData.find((app) => app.key === 'happyfox')
    const servicedesk = appsApiData.find((app) => app.key === 'happyfox_service_desk')

    const helpdeskIntegrated = helpdesk && helpdesk.status === 'linked'
    const servicedeskIntegrated = servicedesk && servicedesk.status === 'linked'

    return {
      isIntegrated: helpdeskIntegrated || servicedeskIntegrated,
      integratedApp: helpdeskIntegrated ? 'HappyFox Helpdesk' : servicedeskIntegrated ? 'HappyFox Service Desk' : null
    }
  }, [appsApiData])

  const mergedApps = useMemo(() => {
    if (!appsApiData) return []

    return appsApiData
      .map((apiApp) => {
        const staticData = APPS_CONFIG[apiApp.key]
        if (!staticData) return null

        let extraData = {}
        if (apiApp.key === 'happyfox' || apiApp.key === 'happyfox_service_desk') {
          extraData = {
            ticketingIntegrationStatus
          }
        }

        return {
          ...staticData,
          ...extraData,
          key: apiApp.key,
          status: apiApp.status
        }
      })
      .filter(Boolean)
      .filter((app) => {
        if (category === APP_CATEGORY.ALL) return true

        return Array.isArray(app.category) ? app.category.includes(category) : app.category === category
      })
      .sort((a, b) => a.status.localeCompare(b.status))
  }, [appsApiData, category, ticketingIntegrationStatus])

  const getAppById = useMemo(() => {
    return (id) => {
      const apiApp = appsApiData.find((app) => APPS_CONFIG[app.key]?.id === id)
      if (!apiApp) return null

      const staticData = APPS_CONFIG[apiApp.key]
      if (!staticData) return null

      let extraData = {}
      if (apiApp.key === 'happyfox' || apiApp.key === 'happyfox_service_desk') {
        extraData = {
          ticketingIntegrationStatus
        }
      }

      return {
        ...staticData,
        ...extraData,
        key: apiApp.key,
        status: apiApp.status
      }
    }
  }, [appsApiData, ticketingIntegrationStatus])

  return {
    apps: mergedApps,
    isLoading,
    error,
    getAppById
  }
}
