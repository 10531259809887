// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JffgEZRB_neMsGtSxSIv{width:100%;height:100%;background-color:var(--color-surface-default);border-radius:var(--border-radius-lg);box-shadow:var(--tile-shadow);padding:18px 20px}.JffgEZRB_neMsGtSxSIv .g7ZZ765ePWGxQa2CcBHU{display:flex;align-items:center;height:24px;gap:.2rem}.JffgEZRB_neMsGtSxSIv .g7ZZ765ePWGxQa2CcBHU .ubUb5tKUnWpTxGJUZHEz{font-size:var(--text-heading2);font-weight:var(--font-weight-normal);color:#000}.JffgEZRB_neMsGtSxSIv .KLF3SmZ51l158wiweeyn{margin-top:12px;height:calc(100% - 24px - 12px)}.GoTTCQ2wRQd7cx_lrX6u{flex:1 1 auto}.x8EiYFIczxfdc42deqBZ{border:none;background:rgba(0,0,0,0);width:15px;height:15px}.x8EiYFIczxfdc42deqBZ svg{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Reports/Tile/Tile.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,6CAAA,CACA,qCAAA,CACA,6BAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,SAAA,CAGF,kEACE,8BAAA,CACA,qCAAA,CACA,UAAA,CAGF,4CACE,eAAA,CACA,+BAAA,CAGF,sBACE,aAAA,CAGF,sBACE,WAAA,CACA,wBAAA,CACA,UAAA,CACA,WAAA,CAGF,0BACE,UAAA,CACA,WAAA","sourcesContent":[".tile {\n  width: 100%;\n  height: 100%;\n  background-color: var(--color-surface-default);\n  border-radius: var(--border-radius-lg);\n  box-shadow: var(--tile-shadow);\n  padding: 18px 20px;\n}\n\n.tile .header {\n  display: flex;\n  align-items: center;\n  height: 24px;\n  gap: .2rem;\n}\n\n.tile .header .title {\n  font-size: var(--text-heading2);\n  font-weight: var(--font-weight-normal);\n  color: black;\n}\n\n.tile .body {\n  margin-top: 12px;\n  height: calc(100% - 24px - 12px);\n}\n\n.spacer {\n  flex: 1 1 auto;\n}\n\n.toolTip {\n  border: none;\n  background: transparent;\n  width: 15px;\n  height: 15px;\n}\n\n.toolTip svg {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile": "JffgEZRB_neMsGtSxSIv",
	"header": "g7ZZ765ePWGxQa2CcBHU",
	"title": "ubUb5tKUnWpTxGJUZHEz",
	"body": "KLF3SmZ51l158wiweeyn",
	"spacer": "GoTTCQ2wRQd7cx_lrX6u",
	"toolTip": "x8EiYFIczxfdc42deqBZ"
};
export default ___CSS_LOADER_EXPORT___;
