import {
  Button,
  Heading,
  PaginationControls,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import _ from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './AnnouncementList.module.css'

import PageLoader from 'Components/PageLoader'
import SearchInput from 'Components/SearchInput'
import api from 'Services/api'
import * as date from 'Utils/date'
import debounce from 'Utils/debounce'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Utils/query-params'

import NoAnnouncements from './NoAnnouncements'
import Options from './Options'

const defaultParams = {
  defaultSearch: '',
  supportedSortColumns: ['created_at', 'updated_at'],
  defaultSortedColumn: 'created_at',
  defaultSortOrder: 'desc'
}

const getInitialParams = () => getInitialParamsForTable(defaultParams)

const getInitialApiQueryParams = () => ({
  ...getInitialApiQueryParamsForTable(getInitialParams())
})

const AnnouncementTable = () => {
  const navigate = useNavigate()

  const initialParams = useMemo(getInitialParams, [])
  const [queryParams, setQueryParams] = useState(() => getInitialApiQueryParams())
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)
  const [, setSearchParams] = useSearchParams()

  const { data = {}, isLoading, isFetching, isSuccess } = api.useGetAnnouncementsQuery(queryParams)
  const { results: announcements = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => _.startCase(value)
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => _.startCase(value)
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        disableSortBy: false,
        Cell: ({ cell: { value } }) => date.ago(value)
      }
    ],
    []
  )

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: announcements,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, searchText, sortBy, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  if (isLoading || isFetching) {
    return <PageLoader />
  }

  if (!isSuccess || !announcements || (announcements.length === 0 && searchText === '')) {
    return <NoAnnouncements />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <div className={styles.SearchInputContainer}>
          <SearchInput placeholder='Search' value={searchText} onChange={handleSearchInputChange} />
        </div>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <Button variant='primary' onClick={() => navigate('create')}>
          New Announcement
        </Button>
      </TableActions>
      <Table className={styles.AnnouncementTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps())

                    return (
                      <Th key={key} isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <TableRow>
              <Td colSpan={columns.length + 1} className={styles.NoFilterResults}>
                No results found for given query
              </Td>
            </TableRow>
          ) : (
            rows.map((row) => {
              prepareRow(row)
              const { key, ...rowProps } = row.getRowProps()
              const announcement = row.original

              return (
                <TableRow key={key} {...rowProps} clickable>
                  {row.cells.map((cell) => {
                    const { key, ...cellProps } = cell.getCellProps()
                    return (
                      <Td key={key} {...cellProps} onClick={() => navigate(`/announcements/edit/${announcement.id}`)}>
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                  <Td>
                    <Options id={announcement.id} />
                  </Td>
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const AnnouncementList = () => (
  <Fragment>
    <Heading>Announcements</Heading>
    <Text variant='muted'>Announcements are used to communicate important information to specific users or team.</Text>
    <AnnouncementTable />
  </Fragment>
)

export default AnnouncementList
