import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  useGetSoftwareAccessControlQuery,
  useSaveSoftwareAccessControlMutation
} from 'Src/servicesV3/softwareAccessModuleApi'

const accessControlFormSchema = yup
  .object()
  .shape({
    is_full_access: yup.boolean().required('Full access is required'),
    access_controls: yup.array().when('is_full_access', {
      is: false,
      then: (schema) =>
        schema
          .of(
            yup.object().shape({
              conditions: yup.array().of(
                yup.object().shape({
                  label: yup.string().required('Label is required'),
                  value: yup.array().of(yup.string()).required('Value is required').min(1, 'Value is required')
                })
              )
            })
          )
          .test('at-least-one-condition', 'At least one condition is required', function (value) {
            return value && value.length > 0 && value.every((ac) => ac.conditions.length > 0)
          }),
      otherwise: (schema) => schema.notRequired()
    })
  })
  .noUnknown()

const prepareDefaultValues = (response) => ({
  is_full_access: response?.is_full_access || false,
  access_controls:
    response?.access_controls?.map((control) => {
      const conditions = control.conditions.map((condition) => ({
        label: condition.id,
        value: condition.allowed_values
      }))
      return {
        ...control,
        conditions,
        isEdit: false
      }
    }) || []
})

export const useAccessControlForm = (catalogItemId, isActive) => {
  const {
    data: response,
    isLoading: isAccessControlLoading,
    isFetching: isAccessControlFetching
  } = useGetSoftwareAccessControlQuery(
    { catalogItemId },
    {
      skip: !catalogItemId || !isActive,
      refetchOnMountOrArgChange: true
    }
  )

  const isLoading = isAccessControlLoading || isAccessControlFetching

  const [save, saveResults] = useSaveSoftwareAccessControlMutation()

  const methods = useForm({
    defaultValues: prepareDefaultValues(response),
    resolver: yupResolver(accessControlFormSchema),
    mode: 'onChange'
  })

  useEffect(() => {
    let mounted = true

    if (response && isActive && mounted) {
      const values = prepareDefaultValues(response)
      methods.reset(values, {
        keepDefaultValues: true,
        keepDirty: false
      })
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, response])

  const { append, remove, update } = useFieldArray({
    control: methods.control,
    name: 'access_controls'
  })

  if (!isActive) {
    return { methods: null, isLoading: false }
  }

  const handleAddAccessControl = () => {
    const access_controls = methods.getValues('access_controls')
    if (access_controls?.length) {
      access_controls.forEach((access_control, index) => {
        if (access_control.conditions.length === 0) {
          remove(index)
        }
      })
    }
    append({
      isEdit: true,
      conditions: [{ label: null, value: null }]
    })
  }

  const resetForm = () => {
    methods.reset(prepareDefaultValues(response), {
      errors: {},
      keepIsSubmitted: false
    })
  }

  const isAccessControlFormDirty = () => {
    return !_.isEqual(methods.getValues(), prepareDefaultValues(response))
  }

  const handleSave = async (formData) => {
    await methods.trigger()
    const data = formData || methods.getValues()
    if (data.is_full_access) {
      data.access_controls = []
      methods.setValue('access_controls', [])
    } else {
      const access_controls = data.access_controls.map((control) => ({
        ...control,
        isEdit: false
      }))
      methods.setValue('access_controls', access_controls)
    }
    const payload = {
      is_full_access: data.is_full_access,
      access_controls: data.access_controls
    }
    payload.access_controls = payload.access_controls.map((control, index) => {
      const { isEdit, ...rest } = control
      rest.name = rest.name || `Access Condition ${index + 1}`
      if (rest && rest.conditions.length) {
        const conditions = control.conditions.map((condition) => {
          return {
            id: condition.label,
            allowed_values: condition.value
          }
        })
        rest.conditions = conditions
      }
      return rest
    })

    return save({ catalogItemId, ...payload })
      .unwrap()
      .then((response) => response)
      .catch(() => Promise.reject(new Error('Failed to save access control')))
  }

  return {
    methods,
    handleAddAccessControl,
    handleRemoveAccessControl: remove,
    updateAccessControl: update,
    resetForm,
    save: handleSave,
    isAccessControlFormDirty,
    isLoading,
    saveResults
  }
}
