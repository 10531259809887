import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import styles from './AzureAD.module.css'

import { useFeature } from 'Src/components/Features'
import { ACCOUNT_TYPE } from 'Src/constants/account'
import { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'
import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import AiActions from 'Src/pagesv3/Apps/components/AiActions'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import { RedirectModal } from 'Src/pagesv3/Apps/components/InstallScreen/InstallScreen'
import QuickActions from 'Src/pagesv3/Apps/components/QuickActions'
import { useAppsData } from 'Src/pagesv3/Apps/hooks/useAppsData'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import {
  useGetAzureADAIActionsQuery,
  useGetAzureADAppQuery,
  useUninstallAzureADMutation
} from 'Src/servicesV3/azureADApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import AzureADSkeleton from './AzureADSkeleton'

const AzureAD = () => {
  const { appId } = useParams()

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false)

  const { currentWorkspaceId } = useWorkspace()
  const { data: account } = useGetAccountQuery()
  const { data: app = {}, isLoading } = useGetAzureADAppQuery({ workspace_id: currentWorkspaceId })
  const { data: aiActionTableData = {}, isLoading: aiActionTableDataLoading } = useGetAzureADAIActionsQuery()
  const [uninstall, { isLoading: uninstallLoading }] = useUninstallAzureADMutation()

  const isTicketingSystemConnected = account.is_ticketing_integration_connected
  const isMSTeamAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS

  const isDMWorkflowEnabled = useFeature([CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])
  const enableQuickActionsConfig = isDMWorkflowEnabled && isTicketingSystemConnected

  const displayAiActions = isMSTeamAccountType && app.is_ai_actions_allowed

  const { getAppById } = useAppsData()
  const installedApp = getAppById(appId)

  const handleUninstall = async () => {
    const promise = uninstall().unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: 'Uninstalling AzureAD',
      success: 'AzureAD integration uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.message || 'Unable to uninstall. Try again'
        return errorMessage
      }
    })
  }

  if (isLoading || aiActionTableDataLoading) {
    return <AzureADSkeleton />
  }

  return (
    <Flex width='100%' gap='25px' direction='column'>
      <AppInstalledCard
        title='Azure AD'
        showButton
        buttonText='Reconnect'
        showIcon={false}
        onButtonClick={() => {
          setIsRedirectModalOpen(true)
        }}
      />
      {enableQuickActionsConfig && <QuickActions data={app.quick_actions} />}
      {displayAiActions && <AiActions data={aiActionTableData} />}
      <AppFooter app={app} isUninstalling={uninstallLoading} onUninstall={() => setIsUninstallModalOpen(true)} />

      <Modal
        size='small'
        title='Uninstall Azure AD'
        open={isUninstallModalOpen}
        onCancel={() => setIsUninstallModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        showCloseButton={false}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={uninstallLoading} onClick={handleUninstall}>
              Delete
            </Button>
            <Button variant='outline' disabled={uninstallLoading} onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {app && installedApp?.status === 'linked' && isRedirectModalOpen && (
        <RedirectModal
          isOpen={isRedirectModalOpen}
          onClose={() => setIsRedirectModalOpen(false)}
          appTitle={installedApp?.title}
          redirectUrl={app?.authorize_url}
        />
      )}
    </Flex>
  )
}

export default AzureAD
