import { useCallback } from 'react'

import { Button, Flex, Modal } from '@happyfoxinc/web-components'

import toast from 'react-hot-toast'

import styles from './Workspaces.module.css'

import { useNavigate } from 'react-router-dom'

import { useDeleteWorkspaceMutation } from 'Src/servicesV3/workspaceApi'

const DeleteModal = ({ id, data, onClose, ...rest }) => {
  const navigate = useNavigate()

  const [deleteWorkspace, deleteWorkspaceResult] = useDeleteWorkspaceMutation()

  const handleDelete = useCallback(() => {
    const toastId = toast.loading('Deleting workspace...')
    const promise = deleteWorkspace(id).unwrap()
    promise
      .then((_response) => {
        toast.dismiss(toastId)
        toast.success(`Deleted workspace '${data.name}'. Successfully...`)
        onClose()
        navigate('/workspaces')
      })
      .catch((_error) => {
        toast.dismiss(toastId)
        toast.error(`Error while deleting workspace '${data.name}'. Try again.`)
      })
  }, [id, data, deleteWorkspace, onClose])

  return (
    <Modal size='small' {...rest}>
      <Flex direction='column' gap='20px' align='center'>
        <div>
          Are you sure to delete <span className={styles.textHighlight}>{data.name}</span> workspace?
        </div>
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteWorkspaceResult.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteWorkspaceResult.isLoading} onClick={() => onClose()}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default DeleteModal
