import _ from 'lodash'

import { createContext, useContext } from 'react'

const defaultContextValue = {
  metaData: {},
  dropdownOptions: {
    fields: [],
    values: []
  },
  isLoading: true
}

const AccessConditionContext = createContext(defaultContextValue)

export const AccessConditionProvider = ({ dropdownOptions, children }) => {
  return (
    <AccessConditionContext.Provider
      value={{
        dropdownOptions
      }}
    >
      {children}
    </AccessConditionContext.Provider>
  )
}

export const useAccessCondition = () => useContext(AccessConditionContext)
