import { Box, Flex, Modal } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import styles from './AiActionConfigureModal.module.css'

import FormField from 'Src/componentsv3/FormField'
import MergeInput from 'Src/componentsv3/MergeInput/MergeInput'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import {
  AI_ACTION_DESCRIPTIONS,
  APPROVAL_STATUS_OPTIONS,
  TICKET_CREATION_OPTIONS,
  TICKET_SUBJECT_TEMPLATE
} from 'Src/constants/aiActions'
import { AI_ACTION_MERGE_FIELDS } from 'Src/constants/mergeFields'
import { TICKETING_INTEGRATION } from 'Src/constants/ticketingIntegration'
import { agentsApi } from 'Src/servicesV3/agentsApi'
import { useUpdateAppsAIActionsMutation } from 'Src/servicesV3/aiActionsApi'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetHelpdeskMetaInfoQuery } from 'Src/servicesV3/helpdeskAppApi'
import { useGetServicedeskMetadataQuery } from 'Src/servicesV3/servicedeskAppApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { useAppDetailContext } from '../../hooks/AppDetailContext'

const validationSchema = (isHfHelpdeskTicketingConnected) => {
  const defaultCategoryOrTeam = isHfHelpdeskTicketingConnected ? 'default_category' : 'default_team'

  const defaultCategoryOrTeamError = isHfHelpdeskTicketingConnected
    ? 'Please select a category'
    : 'Please select a team'
  return yup.object().shape({
    is_enabled: yup.boolean().required('Please provide the enable status'),
    is_approval_needed: yup.boolean().required('Please provide the approval status'),
    approvers: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string()
        })
      )
      .notRequired()
      .when('is_approval_needed', {
        is: true,
        then: (schema) => schema.min(1, 'No agents selected').required()
      }),
    is_ticketing_enabled: yup.boolean().required('Please provide the ticketing status'),
    [defaultCategoryOrTeam]: yup
      .object()
      .nullable()
      .notRequired()
      .when('is_ticketing_enabled', {
        is: true,
        then: (schema) =>
          schema
            .shape({
              id: yup.string()
            })
            .required(defaultCategoryOrTeamError)
      }),

    subject_template: yup
      .string()
      .notRequired()
      .when('is_ticketing_enabled', {
        is: true,
        then: (schema) => schema.required('Please provide the ticket subject template')
      }),

    default_solved_status: yup
      .object()
      .nullable()
      .notRequired()
      .when('is_ticketing_enabled', {
        is: true,
        then: (schema) =>
          schema
            .shape({
              id: yup.string()
            })
            .required('Please select a ticket status')
      })
  })
}

const prepareFormData = (
  data,
  isHfHelpdeskTicketingConnected,
  isHfServicedeskTicketingConnected,
  appId,
  isDefaultEnabled
) => {
  return {
    is_enabled: isDefaultEnabled ? true : data?.is_enabled ?? false,
    is_approval_needed: data?.settings?.is_approval_needed ?? false,
    approvers: data?.settings?.approvers ?? [],
    is_ticketing_enabled: data?.settings?.is_ticketing_enabled ?? false,
    ...(isHfHelpdeskTicketingConnected && {
      default_category: data?.settings?.default_category || null
    }),
    ...(isHfServicedeskTicketingConnected && {
      default_team: data?.settings?.default_team || null
    }),
    subject_template: data?.settings?.subject_template || TICKET_SUBJECT_TEMPLATE[appId],
    default_solved_status: data?.settings?.default_solved_status ?? null
  }
}

const AiActionConfigureModal = ({ data, isOpen, onClose, isDefaultEnabled }) => {
  const appId = data?.id

  const { currentWorkspaceId } = useWorkspace()

  const [getAgents, { isLoading: isAgentsLoading }] = agentsApi.useLazyGetAgentsQuery()
  const { data: account } = useGetAccountQuery()

  const isTicketingIntegrationConnected = account.is_ticketing_integration_connected
  const isHfHelpdeskTicketingConnected = account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_HELP_DESK
  const isHfServicedeskTicketingConnected =
    account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_SERVICE_DESK

  const { data: helpdeskMeta, isLoading: helpdeskLoading } = useGetHelpdeskMetaInfoQuery(
    { workspace_id: currentWorkspaceId },
    { skip: !isHfHelpdeskTicketingConnected }
  )

  const { data: servicedeskMeta, isLoading: servicedeskLoading } = useGetServicedeskMetadataQuery(
    { workspace_id: currentWorkspaceId },
    { skip: !isHfServicedeskTicketingConnected }
  )

  const ticketingAppMeta = isHfHelpdeskTicketingConnected ? helpdeskMeta : servicedeskMeta
  const ticketingAppMetaLoading = isHfHelpdeskTicketingConnected ? helpdeskLoading : servicedeskLoading

  const [updateAppsAIAction] = useUpdateAppsAIActionsMutation()

  const formMethods = useForm({
    defaultValues: {
      ...prepareFormData(
        data,
        isHfHelpdeskTicketingConnected,
        isHfServicedeskTicketingConnected,
        appId,
        isDefaultEnabled
      )
    },
    resolver: yupResolver(validationSchema(isHfHelpdeskTicketingConnected))
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset
  } = formMethods

  const isEnabled = watch('is_enabled')
  const isApprovalNeeded = watch('is_approval_needed')
  const isTicketingEnabled = watch('is_ticketing_enabled')
  const defaultTeam = watch('default_team')

  const { id: appName } = useAppDetailContext()

  useEffect(() => {
    if (isDefaultEnabled) {
      setValue('is_enabled', true, {
        shouldDirty: true
      })
    }
  }, [isDefaultEnabled, setValue])

  useEffect(() => {
    if (!isEnabled) {
      setValue('is_approval_needed', false)
      setValue('is_ticketing_enabled', false)
    }

    if (isTicketingEnabled) {
      setValue('subject_template', data?.settings?.subject_template || TICKET_SUBJECT_TEMPLATE[appId])
    }
  }, [isEnabled, isTicketingEnabled, setValue])

  useEffect(() => {
    if (data) {
      reset({
        ...prepareFormData(
          data,
          isHfHelpdeskTicketingConnected,
          isHfServicedeskTicketingConnected,
          appId,
          isDefaultEnabled
        )
      })
    }
  }, [data, reset, isHfHelpdeskTicketingConnected, isHfServicedeskTicketingConnected])

  const loadAgentOptions = (inputValue, cb) => {
    getAgents({ name: inputValue })
      .unwrap()
      .then((data) => {
        const options = data.map(({ user_profile_id: id, name, email }) => ({ id, name, email }))
        cb(options)
      })
  }

  const formatAgentsOptionLabel = ({ name, email }, { context }) => {
    if (context === 'menu') {
      return `${name} (${email})`
    }
    return name
  }

  const handleFormSubmit = (formData) => {
    const payload = {
      is_enabled: formData.is_enabled,
      is_approval_needed: formData.is_approval_needed,
      is_ticketing_enabled: formData.is_ticketing_enabled
    }

    if (formData.is_approval_needed) {
      payload.approvers = formData.approvers?.map(({ id }) => parseInt(id))
    }

    if (formData.is_ticketing_enabled) {
      const categoryOrTeamProperty = isHfHelpdeskTicketingConnected ? 'default_category' : 'default_team'
      payload[categoryOrTeamProperty] = parseInt(formData[categoryOrTeamProperty]?.id)
      payload.default_solved_status = parseInt(formData.default_solved_status?.id)
      payload.subject_template = formData.subject_template
    }

    const promise = updateAppsAIAction({ appName, appId, payload }).unwrap()

    toast.promise(promise, {
      loading: 'Updating action...',
      success: () => {
        onClose()
        return 'Action updated successfully'
      },
      error: parseErrorMessage('Unable to update action. Try again...')
    })
  }

  // const shouldDisableButton = updateAppsAIActionLoading || !isDirty

  const defaultCategoryOptions = ticketingAppMeta?.all_categories || []
  const defaultTeamOptions = ticketingAppMeta?.all_teams || []

  const statusOption = isHfHelpdeskTicketingConnected
    ? ticketingAppMeta?.solved_statuses
    : ticketingAppMeta?.solved_statuses?.incident?.[defaultTeam?.id]

  return (
    <Modal
      contentClassName={styles.modalWidth}
      open={isOpen}
      onCancel={onClose}
      onConfirm={handleSubmit(handleFormSubmit)}
      onOpenChange={onClose}
      title='Configure AI Action'
      size='xlarge'
    >
      {ticketingAppMetaLoading && 'Loading'}
      {!ticketingAppMetaLoading && (
        <FormProvider {...formMethods}>
          <form>
            <Flex direction='column' gap='15px' className={styles.headerContainer}>
              <Flex gap='15px' align='center'>
                <h2 className={styles.heading}>{data?.name}</h2>
                <FormField className={styles.switchFieldContainer}>
                  <FormField.Field error={errors.is_enabled?.message}>
                    <Controller
                      name='is_enabled'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Flex>
                          <label className={styles.switch} onClick={(event) => event.stopPropagation()}>
                            <input type='checkbox' checked={value} onChange={onChange} />
                            <span className={styles.slider} />
                          </label>
                        </Flex>
                      )}
                    />
                  </FormField.Field>
                </FormField>
              </Flex>
              <p className={styles.description}>{AI_ACTION_DESCRIPTIONS[appId]}</p>
            </Flex>
            <div className={styles.modalContent}>
              <div className={styles.formContainer}>
                <h3 className={styles.subHeading}>Need Approval?</h3>
                <FormField>
                  <FormField.Field error={errors.is_approval_needed?.message} label='Select Approval Status'>
                    <Controller
                      name='is_approval_needed'
                      control={control}
                      render={({ field }) => (
                        <Box width='330px'>
                          <ReactSelect
                            {...field}
                            options={APPROVAL_STATUS_OPTIONS}
                            value={APPROVAL_STATUS_OPTIONS.find((option) => option.value === field.value)}
                            onChange={(option) => field.onChange(option.value)}
                            isDisabled={!isEnabled}
                          />
                        </Box>
                      )}
                    />
                  </FormField.Field>
                </FormField>
                {isApprovalNeeded && isEnabled && (
                  <FormField>
                    <FormField.Field error={errors?.approvers?.message} label='Select Agents'>
                      <Controller
                        name='approvers'
                        control={control}
                        render={({ field }) => (
                          <Box width='330px'>
                            <ReactSelect
                              {...field}
                              className={styles.Select}
                              formatOptionLabel={formatAgentsOptionLabel}
                              getOptionValue={({ id }) => id}
                              placeholder='Type to search'
                              isClearable={false}
                              isMulti
                              options={[]}
                              loadOptions={loadAgentOptions}
                              isLoading={isAgentsLoading}
                              loadingMessage={() => 'Searching for agents...'}
                              minCharsBeforeLoadingOptions={2}
                              noOptionsMessage={({ inputValue }) => {
                                if (inputValue.length < 2) {
                                  return 'Type alteast 2 characters to start searching'
                                }
                                return `No agents found for input "${inputValue}"`
                              }}
                            />
                          </Box>
                        )}
                      />
                    </FormField.Field>
                  </FormField>
                )}
              </div>
              <div className={`${styles.formContainer} ${styles.rightSide}`}>
                {!isTicketingIntegrationConnected && (
                  <div className={styles.infoContainer}>
                    Ticket creation is not allowed due to the integration not being connected
                  </div>
                )}
                <h3 className={styles.subHeading}>Ticket Creation</h3>
                <FormField>
                  <FormField.Field
                    error={errors.is_ticketing_enabled?.message}
                    label='Would you like to create ticket for this action'
                  >
                    <Controller
                      control={control}
                      name='is_ticketing_enabled'
                      render={({ field }) => (
                        <Box width='330px'>
                          <ReactSelect
                            {...field}
                            options={TICKET_CREATION_OPTIONS}
                            value={TICKET_CREATION_OPTIONS.find((option) => option.value === field.value)}
                            onChange={(option) => field.onChange(option.value)}
                            isDisabled={!isEnabled || !isTicketingIntegrationConnected}
                          />
                        </Box>
                      )}
                    />
                  </FormField.Field>
                </FormField>
                {isTicketingEnabled && isEnabled && (
                  <Fragment>
                    {isHfHelpdeskTicketingConnected && (
                      <FormField>
                        <FormField.Field error={errors.default_category?.message} label='Select Category'>
                          <Controller
                            control={control}
                            name='default_category'
                            render={({ field }) => (
                              <Box width='330px'>
                                <ReactSelect
                                  {...field}
                                  options={defaultCategoryOptions}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                />
                              </Box>
                            )}
                          />
                        </FormField.Field>
                      </FormField>
                    )}
                    {isHfServicedeskTicketingConnected && (
                      <FormField>
                        <FormField.Field error={errors.default_team?.message} label='Select Team'>
                          <Controller
                            control={control}
                            name='default_team'
                            render={({ field }) => (
                              <Box width='330px'>
                                <ReactSelect
                                  {...field}
                                  options={defaultTeamOptions}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  onChange={(option) => {
                                    field.onChange(option)
                                    setValue('default_solved_status', null)
                                  }}
                                />
                              </Box>
                            )}
                          />
                        </FormField.Field>
                      </FormField>
                    )}
                    <FormField>
                      <FormField.Field error={errors.subject_template?.message} label='Ticket Closed Subject'>
                        <Box width='400px'>
                          <MergeInput showTextArea name='subject_template' mergeFields={AI_ACTION_MERGE_FIELDS} />
                        </Box>
                      </FormField.Field>
                    </FormField>
                    {statusOption && (
                      <FormField>
                        <FormField.Field error={errors.default_solved_status?.message} label='Ticket Closed Status'>
                          <Controller
                            control={control}
                            name='default_solved_status'
                            render={({ field }) => (
                              <Box width='330px'>
                                <ReactSelect
                                  {...field}
                                  options={statusOption}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                />
                              </Box>
                            )}
                          />
                        </FormField.Field>
                      </FormField>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </Modal>
  )
}

export default AiActionConfigureModal
