// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mM5Uy6dX24D6tR1RYgbr{position:relative}.BT2JEHSQJb4KdVJfXbMw{position:absolute;top:-28px;right:0}.HOOOfj6BQ3P7IHCObxUf{position:relative}.eXxMsP6MAE0j7GllxAn6{position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0);z-index:10;cursor:not-allowed}.bIIRLd3Af2MUFI28rWzQ trix-toolbar,.bIIRLd3Af2MUFI28rWzQ .trix-button-row{display:none !important}.bIIRLd3Af2MUFI28rWzQ trix-editor{background-color:var(--color-disabled-default) !important;border-color:var(--color-border-default) !important;color:var(--color-text-muted) !important}", "",{"version":3,"sources":["webpack://./../src/componentsv3/MergeTrixEditor/MergeTrixEditor.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,OAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,8BAAA,CACA,UAAA,CACA,kBAAA,CAGF,0EAEE,uBAAA,CAGF,kCACE,yDAAA,CACA,mDAAA,CACA,wCAAA","sourcesContent":[".FieldContainer {\n  position: relative;\n}\n\n.MergeFieldContainer {\n  position: absolute;\n  top: -28px;\n  right: 0;\n}\n\n.editorWrapper {\n  position: relative;\n}\n\n.disabledOverlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: transparent;\n  z-index: 10;\n  cursor: not-allowed;\n}\n\n.disabled :global(trix-toolbar),\n.disabled :global(.trix-button-row) {\n  display: none !important;\n}\n\n.disabled :global(trix-editor) {\n  background-color: var(--color-disabled-default) !important;\n  border-color: var(--color-border-default) !important;\n  color: var(--color-text-muted) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FieldContainer": "mM5Uy6dX24D6tR1RYgbr",
	"MergeFieldContainer": "BT2JEHSQJb4KdVJfXbMw",
	"editorWrapper": "HOOOfj6BQ3P7IHCObxUf",
	"disabledOverlay": "eXxMsP6MAE0j7GllxAn6",
	"disabled": "bIIRLd3Af2MUFI28rWzQ"
};
export default ___CSS_LOADER_EXPORT___;
