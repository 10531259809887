import { Box } from '@happyfoxinc/web-components'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './Modules.module.css'

import { useGetModulesQuery } from 'Src/servicesV3/modulesApi'

import { MODULES } from './constants'
import ModuleCard from './ModuleCard/ModuleCard'

import { PAGES, MODULES as MODULES_KEY } from 'Src/pagesv3/constants'

const ModulesHeader = () => (
  <div className='heading-container'>
    <h1 className='heading'>{PAGES[MODULES_KEY].title}</h1>
    <p className='sub-heading'>{PAGES[MODULES_KEY].description}</p>
  </div>
)

const ModulesSkeleton = () => (
  <div className={styles.grid} role='list'>
    {Object.entries(MODULES).map((_, index) => (
      <ModuleCard key={`skeleton-${index}`} isLoading />
    ))}
  </div>
)

const ModulesSection = () => {
  const navigate = useNavigate()
  const { data: modulesResponse = [], isLoading, error } = useGetModulesQuery()

  const modules = useMemo(
    () =>
      modulesResponse.reduce((acc, module) => {
        const moduleConfig = MODULES[module.name]
        return moduleConfig ? [...acc, { ...module, ...moduleConfig }] : acc
      }, []),
    [modulesResponse]
  )

  return (
    <Box className='page-content'>
      <div className={styles.wrapper}>
        <ModulesHeader />
        {(isLoading || error || !modules.length) && <ModulesSkeleton />}
        {!isLoading && !error && (
          <div className={styles.grid} role='list'>
            {modules.map((module) => (
              <ModuleCard key={module.name} {...module} onClick={() => navigate(module.path)} />
            ))}
          </div>
        )}
      </div>
    </Box>
  )
}

export default ModulesSection
