import { useCallback } from 'react'

import { Button, Flex, Modal } from '@happyfoxinc/web-components'

import toast from 'react-hot-toast'

import { useDeleteUserGroupMutation } from 'Src/servicesV3/userGroupsApi'

import parseErrorMessage from 'Utils/error-message-parser'

export const USERGROUP_DELETE_MESSAGE = {
  KB_ASSOCIATED: 'This user group is associated with one or more knowledge source. Are you sure you want to proceed?',
  NO_KB_ASSOCIATED: 'Are you sure to delete this user group?'
}

const DeleteModal = (props) => {
  const { id, data, onSuccess, onCancel, ...restProps } = props
  const [deleteUserGroup, deleteUserGroupApiResults] = useDeleteUserGroupMutation()

  const handleDelete = useCallback(() => {
    try {
      const promise = deleteUserGroup(id).unwrap()
      toast.promise(promise, {
        loading: 'Deleting user group',
        success: `Successfully deleted user group '${data.name}'`,
        error: parseErrorMessage(`Error while deleting user group '${data.name}'. Try again.`)
      })
      onSuccess()
    } catch (error) {
      onCancel()
    }
  }, [id, data, deleteUserGroup, onSuccess, onCancel])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        {data?.mapped_knowledge_scopes?.length > 0
          ? USERGROUP_DELETE_MESSAGE.KB_ASSOCIATED
          : USERGROUP_DELETE_MESSAGE.NO_KB_ASSOCIATED}
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteUserGroupApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteUserGroupApiResults.isLoading} onClick={props.onCancel}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default DeleteModal
