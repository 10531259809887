import { useEffect, useState } from 'react'

import { Outlet, Route, Routes } from 'react-router-dom'

import { AccessConditionProvider } from './AccessControl/AccessConditionContext'
import UserGroupCreate from './UserGroupCreate'
import UserGroupEdit from './UserGroupEdit'
import UserGroups from './UserGroups'

import { useGetUserGroupMetadataQuery } from 'Src/servicesV3/userGroupsApi'

const UserRouter = () => {
  const [dropdownOptions, setDropdownOptions] = useState()

  const { data = {}, isLoading } = useGetUserGroupMetadataQuery({}, { refetchOnMountOrArgChange: true })
  const { condition_fields = null } = data

  useEffect(() => {
    if (isLoading || !data) return

    const values = condition_fields
      ? condition_fields.reduce((acc, field) => {
          acc[field.id] = field.allowed_values.map((value) => ({
            label: value,
            value: value
          }))
          return acc
        }, {})
      : []

    const fields = condition_fields
      ? condition_fields.map((field) => ({
          label: field.name,
          value: field.id
        }))
      : []

    setDropdownOptions({ fields, values })
  }, [isLoading, data, condition_fields, setDropdownOptions])

  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route
          path='create'
          element={
            <AccessConditionProvider dropdownOptions={dropdownOptions}>
              <UserGroupCreate />
            </AccessConditionProvider>
          }
        />
        <Route
          path=':id'
          index
          element={
            <AccessConditionProvider dropdownOptions={dropdownOptions}>
              <UserGroupEdit />
            </AccessConditionProvider>
          }
        />
        <Route path='' index element={<UserGroups />} />
      </Route>
    </Routes>
  )
}

export default UserRouter
