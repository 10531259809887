import { Box, Flex } from '@happyfoxinc/web-components'
import cx from 'classnames'
import DOMPurify from 'dompurify'
import { Fragment } from 'react'

import styles from './Components.module.css'

import EditIcon from 'Src/assetsv3/icons/edit.svg'
import TickIcon from 'Src/assetsv3/icons/tick.svg'
import TrashIcon from 'Src/assetsv3/icons/trash.svg'

const ActionIconGroup = ({ index, step, updateStep, onDelete }) => (
  <Flex align='center' justify='space-between'>
    <button
      type='button'
      className={styles.editButton}
      onClick={() => {
        updateStep(index, { ...step, isEdit: true })
      }}
    >
      <EditIcon />
    </button>
    <button
      type='button'
      className={cx(styles.iconButton, styles.deleteIcon)}
      onClick={() => {
        onDelete(index)
      }}
    >
      <TrashIcon />
    </button>
  </Flex>
)

const ReadOnlyFields = ({ step, stepLabel, index, updateStep, onDelete }) => {
  if (!step.fields) {
    return null
  }

  return (
    <Fragment>
      {step.fields.map((field, fieldIndex) => {
        if (field.name === 'recipients') {
          const recipients = field.recipients?.map((value) => value.toString())
          const recipientNames = recipients
            ? field.values.map((item) => (recipients.includes(item.id.toString()) ? item.name : '')).filter(Boolean)
            : []

          return (
            <Flex
              align='flex-start'
              justify='space-between'
              wrap='wrap'
              gap='3px'
              key={`${field.name}-${index}-${fieldIndex}`}
              style={{ flex: '1' }}
            >
              <Flex align='flex-end' wrap='wrap' gap='3px'>
                <h3 className={styles.stepTitle}>
                  {stepLabel}
                  <span className={styles.stepType}>
                    &nbsp;to&nbsp;
                    {recipientNames.map((name, i) => (
                      <span key={`${field.name}-${i}-${name}`}>
                        <strong>{name}</strong>
                        {i !== recipientNames.length - 1 && ', '}
                      </span>
                    ))}
                  </span>
                </h3>
              </Flex>
              <ActionIconGroup index={index} step={step} updateStep={updateStep} onDelete={onDelete} />
            </Flex>
          )
        }

        if (field.name === 'instruction') {
          return (
            <Fragment key={`container-${field.name}-${index}-${fieldIndex}`}>
              <div style={{ marginBottom: '0.5rem' }} />
              <Box key={`box-${field.name}-${index}-${fieldIndex}`} p='0 1.5rem'>
                <p
                  className={cx(styles.stepType)}
                  style={{
                    margin: '4px 0'
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(field.instruction.replace(/(?:\r\n|\r|\n)/g, '<br>'))
                  }}
                />
              </Box>
            </Fragment>
          )
        }

        if (field.name === 'user_acknowledgement' && field.user_acknowledgement) {
          return (
            <Box width='90%' key={`${field.name}-${index}-${fieldIndex}`} style={{ marginTop: '0.5rem' }}>
              <p className={styles.acknowledgement}>
                <span className={styles.tickIconWrapper}>
                  <TickIcon />
                </span>
                User Acknowledgement Required
              </p>
            </Box>
          )
        }

        if (field.name === 'approvers') {
          const approvers = field?.approvers?.map((value) => value.toString()) || []
          const approverNames = field.values
            .map((item) => (approvers.includes(item.id.toString()) ? item.name : ''))
            .filter(Boolean)

          return (
            <Fragment key={`${field.name}-${index}-${fieldIndex}`}>
              <Flex align='flex-start' justify='space-between' wrap='wrap' gap='3px'>
                <Flex align='flex-end' wrap='wrap' gap='3px'>
                  <h3 className={styles.stepTitle}>{stepLabel}</h3>
                </Flex>
                <ActionIconGroup index={index} step={step} updateStep={updateStep} onDelete={onDelete} />
              </Flex>
              <span className={styles.stepType}>
                Approval required from&nbsp;
                {approverNames.map((name, i) => (
                  <span key={`${field.name}-${i}-${name}`}>
                    <strong>{name}</strong>
                    {i !== approverNames.length - 1 && (
                      <small style={{ fontSize: '8.5px', opacity: 1 }}>&nbsp;(or)&nbsp;</small>
                    )}
                  </span>
                ))}
              </span>
            </Fragment>
          )
        }

        return null
      })}
    </Fragment>
  )
}

export default ReadOnlyFields
