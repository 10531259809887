import { Box, Button, Flex, Modal, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'

import styles from './Outline.module.css'

import ReactSelect from 'Src/componentsv3/ReactSelect'
import {
  useAddCollectionsMutation,
  useGetCollectionsQuery,
  useGetSyncedCollectionsQuery,
  useRemoveSyncedCollectionsMutation
} from 'Src/servicesV3/outlineApi'
import debounce from 'Src/utils/debounce'

import OutlineSkeleton, { CollectionsConfigSkeleton } from './OutlineSkeleton'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const CollectionsTable = ({ onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [collectionToDelete, setCollectionToDelete] = useState(null)
  const [queryParams, setQueryParams] = useState(defaultApiParams)

  const { data = {}, isLoading: isCollectionsLoading } = useGetSyncedCollectionsQuery(queryParams)
  const { results: syncedCollections = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Collections',
        accessor: 'collection_name'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: syncedCollections,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  const openDeleteModal = useCallback((collection) => {
    setCollectionToDelete(collection)
    setShowDeleteModal(true)
  }, [])

  const handleDelete = useCallback(() => {
    onDelete(collectionToDelete.collection_id)
    setShowDeleteModal(false)
  }, [collectionToDelete, onDelete])

  if (isCollectionsLoading) {
    return <CollectionsConfigSkeleton />
  }

  return (
    <Fragment>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <h2 className={styles.heading}>Collections currently syncing from</h2>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>
        {syncedCollections.length === 0 ? (
          <div className={styles.emptyState}>
            Currently not syncing any collections. Add collections to start syncing
          </div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                  <TableOptions>
                    <TableOption onClick={() => openDeleteModal(row.original)}>Delete</TableOption>
                  </TableOptions>
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table.Root>

      <Modal
        size='small'
        title={`Delete ${collectionToDelete?.collection_name}`}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onOpenChange={() => setShowDeleteModal(false)}
        showFooter={false}
        showCloseButton={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          Are you sure you want to delete this collection?
          <Flex gap='15px'>
            <Button variant='solid' onClick={handleDelete}>
              Delete
            </Button>
            <Button variant='outline' onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Fragment>
  )
}

const CollectionsConfig = () => {
  const [selectedCollections, setSelectedCollections] = useState([])
  const { data = {}, isLoading: isCollectionsLoading } = useGetCollectionsQuery()
  const { results: collections = [] } = data
  const [addCollections, { isLoading: addCollectionsLoading }] = useAddCollectionsMutation()
  const [removeCollection] = useRemoveSyncedCollectionsMutation()

  const handleAddCollections = useCallback(() => {
    const collectionIds = selectedCollections.map((collection) => collection.id)
    const promise = addCollections({ collection_ids: collectionIds }).unwrap()
    promise.then(() => setSelectedCollections([]))

    toast.promise(promise, {
      loading: 'Adding collections',
      success: 'Collections added successfully',
      error: 'Unable to add collections. Try again'
    })
  }, [addCollections, selectedCollections])

  const handleRemoveCollection = useCallback(
    async (id) => {
      const promise = removeCollection(id).unwrap()

      toast.promise(promise, {
        loading: 'Deleting collection',
        success: 'Collection deleted successfully',
        error: 'Unable to delete collection. Try again...'
      })
    },
    [removeCollection]
  )

  if (isCollectionsLoading) {
    return <OutlineSkeleton />
  }

  const disableSubmitButton = selectedCollections.length === 0 || addCollectionsLoading

  return (
    <Box>
      <div className={styles.headingWrapper}>
        <h2 className={styles.heading}>Add collections to sync from </h2>
      </div>
      <Flex gap='15px'>
        <Box width='100%'>
          <ReactSelect
            isMulti
            value={selectedCollections}
            onChange={(options) => setSelectedCollections(options)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={collections}
            placeholder='Select collections'
          />
        </Box>
        <Button variant='solid' type='submit' disabled={disableSubmitButton} onClick={handleAddCollections}>
          + Add
        </Button>
      </Flex>
      <CollectionsTable onDelete={handleRemoveCollection} />
    </Box>
  )
}

export default CollectionsConfig
