import { Box, Flex } from '@happyfoxinc/web-components'

import toast from 'react-hot-toast'

import { useNavigate } from 'react-router-dom'

import styles from './Workspaces.module.css'

import BackButton from 'Src/componentsv3/BackButton'

import { useCreateWorkspaceMutation } from 'Src/servicesV3/workspaceApi'

import parseErrorMessage from 'Src/utils/error-message-parser'

import WorkspaceForm from './WorkspaceForm'

const CreateWorkspace = () => {
  const navigate = useNavigate()

  const [createWorkspace, createWorkspaceResult] = useCreateWorkspaceMutation()

  const handleCreateWorkspace = async (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      visibility: data.visibility,
      example_queries: data.example_queries
    }

    if (data.visibleToGroups?.length) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    const toastId = toast.loading('Creating workspace...')
    const promise = createWorkspace(payload).unwrap()
    promise
      .then((workspace) => {
        toast.dismiss(toastId)
        toast.success('Workspace created successfully')
        navigate(`../` + `${workspace.id}`, { state: workspace })
      })
      .catch((_error) => {
        toast.dismiss(toastId)
        toast.error(parseErrorMessage('Unable to create workspace. Try again...'))
      })
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Create Workspace</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <WorkspaceForm
          onSubmit={handleCreateWorkspace}
          onCancel={() => navigate('/workspaces')}
          isSubmitting={createWorkspaceResult.isLoading}
        />
      </Box>
    </Box>
  )
}

export default CreateWorkspace
