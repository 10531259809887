import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import keyMap from 'Constants/key-map'
import { useGetAzureADAppQuery } from 'Src/servicesV3/azureADApi'
import { useInstallConfluenceMutation } from 'Src/servicesV3/confluenceApi'
import { useInstallGitBookMutation } from 'Src/servicesV3/gitbookApi'
import { useInstallGuruMutation } from 'Src/servicesV3/guruApi'
import { useAuthorizeHelpdeskAppMutation } from 'Src/servicesV3/helpdeskAppApi'
import { useGetNotionAppQuery } from 'Src/servicesV3/notionApi'
import { useInstallOktaMutation } from 'Src/servicesV3/oktaApi'
import { useInstallOutlineMutation } from 'Src/servicesV3/outlineApi'
import { useAuthorizeServicedeskAppMutation } from 'Src/servicesV3/servicedeskAppApi'
import { useGetSharepointAppQuery } from 'Src/servicesV3/sharepointApi'
import { useInstallSimpplrMutation } from 'Src/servicesV3/simpplrApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const {
  HAPPYFOX_HELPDESK,
  HAPPYFOX_SERVICE_DESK,
  NOTION,
  SHAREPOINT,
  OKTA,
  AZURE_AD,
  CONFLUENCE,
  GURU,
  SIMPPLR,
  GITBOOK,
  OUTLINE
} = keyMap

const REDIRECT_APPS = [NOTION.ID, SHAREPOINT.ID, AZURE_AD.ID]
const WORKSPACE_ID_APPS = [NOTION.ID, SHAREPOINT.ID, GITBOOK.ID, OUTLINE.ID]

const redirectUrlApis = {
  [NOTION.ID]: useGetNotionAppQuery,
  [SHAREPOINT.ID]: useGetSharepointAppQuery,
  [AZURE_AD.ID]: useGetAzureADAppQuery
}

const installationApis = {
  [HAPPYFOX_HELPDESK.ID]: useAuthorizeHelpdeskAppMutation,
  [HAPPYFOX_SERVICE_DESK.ID]: useAuthorizeServicedeskAppMutation,
  [OKTA.ID]: useInstallOktaMutation,
  [CONFLUENCE.ID]: useInstallConfluenceMutation,
  [GURU.ID]: useInstallGuruMutation,
  [SIMPPLR.ID]: useInstallSimpplrMutation,
  [GITBOOK.ID]: useInstallGitBookMutation,
  [OUTLINE.ID]: useInstallOutlineMutation
}

export const useInstallApp = (appId) => {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const { currentWorkspaceId } = useWorkspace()
  const isRedirectApp = REDIRECT_APPS.includes(appId)
  const shouldPassWorkspaceId = WORKSPACE_ID_APPS.includes(appId)

  const redirectResult =
    isRedirectApp && redirectUrlApis[appId]
      ? redirectUrlApis[appId](shouldPassWorkspaceId ? { workspace_id: currentWorkspaceId } : {})
      : {}
  const { data: appApiResponse = {}, isLoading: isAppLoading = false } = redirectResult

  let installMutation = null
  let installResult = { isLoading: false, error: null }

  if (!isRedirectApp && installationApis[appId]) {
    ;[installMutation, installResult = {}] = installationApis[appId]()
  }

  useEffect(() => {
    if (isRedirectApp && appApiResponse?.authorize_url) {
      setRedirectUrl(appApiResponse.authorize_url)
    }
  }, [appApiResponse, isRedirectApp])

  const installApp = useCallback(
    async (formData = {}) => {
      if (!installMutation) return { success: false }

      const shouldIncludeWorkspaceId = WORKSPACE_ID_APPS.includes(appId)
      const payload = shouldIncludeWorkspaceId
        ? {
            workspace_id: currentWorkspaceId,
            ...formData
          }
        : formData

      try {
        const response = await installMutation(payload).unwrap()

        if (response?.authorize_url) {
          setRedirectUrl(response.authorize_url)
          return {
            success: true,
            requiresRedirect: true,
            redirectUrl: response.authorize_url
          }
        }

        toast.success('App installed successfully')
        return { success: true, requiresRedirect: false }
      } catch (error) {
        toast.error(error?.data?.error || 'Failed to install app')
        return { success: false, requiresRedirect: false }
      }
    },
    [installMutation, currentWorkspaceId, appId]
  )

  return {
    installApp,
    redirectUrl,
    isLoading: isRedirectApp ? isAppLoading : installResult.isLoading,
    error: installResult.error,
    isRedirectApp
  }
}
