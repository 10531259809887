// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w7KG2nChDJdcgBSqx1Dy{border-radius:0px 0px var(--border-radius-lg) var(--border-radius-lg)}.m_fm1fBfkNm8cY65WUL5{display:inline-flex;align-items:center;gap:5px}.UXNZLYutreyM_HFeW8AG{width:8px;height:8px;border-radius:var(--border-radius-circle)}.ATUE6srHHhG8ZUbq7ftO{background-color:var(--draft-color)}.Nn0e3hPhH98xxPcz0A4Q{background-color:var(--success-color)}.BVtrBRMl4ZE4U5tCUk5b,.O719hBCIUTPfVV06hSon{background-color:var(--error-color)}.mdOw0WnKi5QuTVwLpgCg{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Admins/Admins.module.css"],"names":[],"mappings":"AAAA,sBACE,qEAAA,CAGF,sBACE,mBAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,SAAA,CACA,UAAA,CACA,yCAAA,CAGF,sBACE,mCAAA,CAGF,sBACE,qCAAA,CAGF,4CAEE,mCAAA,CAGF,sBACE,kBAAA","sourcesContent":[".modalBody {\n  border-radius: 0px 0px var(--border-radius-lg) var(--border-radius-lg);\n}\n\n.statusWrapper {\n  display: inline-flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.statusDot {\n  width: 8px;\n  height: 8px;\n  border-radius: var(--border-radius-circle);\n}\n\n.invited {\n  background-color: var(--draft-color);\n}\n\n.active {\n  background-color: var(--success-color);\n}\n\n.deactivated,\n.expired {\n  background-color: var(--error-color);\n}\n\n.tableActionContainer {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "w7KG2nChDJdcgBSqx1Dy",
	"statusWrapper": "m_fm1fBfkNm8cY65WUL5",
	"statusDot": "UXNZLYutreyM_HFeW8AG",
	"invited": "ATUE6srHHhG8ZUbq7ftO",
	"active": "Nn0e3hPhH98xxPcz0A4Q",
	"deactivated": "BVtrBRMl4ZE4U5tCUk5b",
	"expired": "O719hBCIUTPfVV06hSon",
	"tableActionContainer": "mdOw0WnKi5QuTVwLpgCg"
};
export default ___CSS_LOADER_EXPORT___;
